<template>
  <b-sidebar
    no-header
    width="100%"
    id="edit-istituto"
    :title="`Edit Istituto: ${formIstituto.nome_istituto}`"
    left
    bg-variant="dark"
    shadow
    backdrop
    backdrop-variant="dark"
    @hidden="$emit('hidden')"
  >
    <template #footer="{ hide }">
      <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
        <small class="text-muted float-left text-white">Nome Componente: src/components/crm/editIstituto.vue</small>
        <strong class="mr-auto">{{ $store.state.user.username }}</strong>
        <b-button variant="success" @click="editIstituto">Modifica</b-button>
        <b-button variant="danger"  @click="hide" class="ml-3">Chiudi</b-button>
      </div>
    </template>
    <template #default="{}">
      <b-card bg-variant="dark" class="rounded-0">
        <h4 style="color: #f9f9fa">Modifica dell'Istituto <span style="font-style: oblique"><b>{{ formIstituto.nome_istituto }}</b></span></h4>
      </b-card>
      <div class="text-center">
        <b-spinner variant="primary" label="Text Centered" v-if="loaded"></b-spinner>
      </div>
      <b-card>
        <b-tabs nav-class="nav-tabs-v2" content-class="pt-2" card>
          <b-tab title="Anagrafica Principale">
            <b-form v-on:submit.prevent="SidebarEditIstituto" >
              <b-row>
                <b-col>
                  <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                    <div class="d-inline-flex">
                      <b-form-group label="Cod Istituto:" label-for="codiceIstituto" class="w-25 mr-2">
                        <b-form-input
                          id="codiceIstituto"
                          v-model="formIstituto.codice_istituto"
                          placeholder="Ricerca il codice"
                          size="sm"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group id="input-group-1" label="Nome Istituto:" label-for="nomeIstituto" class="w-75">
                        <b-form-input
                          id="nomeIstituto"
                          type="text"
                          placeholder="Inserisci il nome"
                          size="sm"
                          name="nome_istituto"
                          v-model = "formIstituto.nome_istituto"
                          @change="onInput"
                        ></b-form-input>
                        <b-link @click="associaIstituto"><small>Istituto Padre</small></b-link>
                        <small class="ml-2" v-if="formIstituto.parent">istituto associato con: {{ formIstituto.parent }}</small>
                      </b-form-group>
                    </div>
                  </b-card>
                  <b-card class="mb-2 shadow" bg-variant="gray-050">
                    <div class="d-inline-flex">
                      <b-form-group id="regione" label="Regione:" label-for="regione" class="mr-2 w-75">
                        <b-form-input
                          id="regione"
                          placeholder="Inserisci la Regione"
                          size="sm"
                          v-model="formIstituto.regione"
                          @change="onInput"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group id="provincia" label="Provincia:" label-for="provincia" class="w-25">
                        <b-form-input
                          id="provincia"
                          placeholder="Inserisci la Provincia"
                          size="sm"
                          v-model="formIstituto.provincia"
                          @change="onInput"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <b-form-group label="Città:" label-for="citta" class="w-75">
                      <b-form-input
                        id="citta"
                        v-model="formIstituto.citta"
                        placeholder="Inserisci la Città"
                        size="sm"
                        @change="onInput"
                      ></b-form-input>
                    </b-form-group>
                    <div class="d-inline-flex">
                      <b-form-group class="w-75 mr-2" id="indirizzo1" label="Indirizzo1:" label-for="indirizzo1">
                        <b-form-input
                          id="indirizzo1"
                          placeholder="Inserisci l'indirizzo"
                          size="sm"
                          v-model="formIstituto.indirizzo1"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group class="w-25" id="cap" label="CAP:" label-for="cap">
                        <b-form-input
                          id="cap"
                          placeholder="CAP"
                          size="sm"
                          v-model="formIstituto.cap"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </b-card>
                </b-col>
                <b-col>
                  <b-card class="shadow" bg-variant="gray-050">
                    <div class="d-inline-flex">
                      <b-form-group id="telefono1" label="telefono1:" label-for="telefono1" class="mr-2">
                        <b-form-input
                          id="telefono1"
                          placeholder="Inserisci il Telefono"
                          size="sm"
                          v-model="formIstituto.telefono1"
                          @change="onInput"
                        ></b-form-input>
                      </b-form-group>
                      <b-form-group id="email1" label="email1:" label-for="email1">
                        <b-form-input
                          id="email1"
                          placeholder="Inserisci l'email"
                          size="sm"
                          v-model="formIstituto.email1"
                          @change="onInput"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                    <b-form-group id="website" label="WebSite:" label-for="website">
                      <b-form-input
                        id="website"
                        placeholder="WebSite"
                        size="sm"
                        v-model="formIstituto.website"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group label="Iscritti:">
                      <b-form-select
                        id="iscritti"
                        v-model="formIstituto.numero_iscritti"
                        :options="iscritti"
                        size="sm"
                      >
                      </b-form-select>
                    </b-form-group>
                    <b-form-group>
                      <b-form-textarea
                        id="note"
                        v-model="formIstituto.note"
                        placeholder="Note..."
                        rows="7"
                        max-rows="8">
                      </b-form-textarea>
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </b-tab>
          <b-tab title="Informazioni secondarie">
            <b-row cols="12">
              <b-col cols="6">
                <b-card class="shadow" bg-variant="gray-050">
                  <b-form-group label="Indirizzo 2">
                    <b-form-input v-model="formIstituto.indirizzo2" type="text" placeholder="Indirizzo 2"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Email 2">
                    <b-form-input v-model="formIstituto.email2" type="text" placeholder="Email 2"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Telefono 2">
                    <b-form-input v-model="formIstituto.telefono2" type="text" placeholder="Telefono 2"></b-form-input>
                  </b-form-group>
                </b-card>
              </b-col >
              <b-col cols="6">
                <b-card class="shadow" bg-variant="gray-050">
                  <b-form-group label="Indirizzo 3">
                    <b-form-input v-model="formIstituto.indirizzo3" type="text" placeholder="Indirizzo 3"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Email 3">
                    <b-form-input v-model="formIstituto.email3" type="text" placeholder="Email 3"></b-form-input>
                  </b-form-group>
                  <b-form-group label="Telefono 3">
                    <b-form-input v-model="formIstituto.telefono3" type="text" placeholder="Telefono 3"></b-form-input>
                  </b-form-group>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Tipologia&Lingua">
            <b-row>
              <b-col>
                <b-card class="mb-2 shadow" bg-variant="gray-050">
                  <b-form-group label="Tipologia Istituto" v-if="formIstituto && formIstituto.tipologia !== undefined">
                    <b-form-select
                      v-model="formIstituto.tipologia"
                      multiple
                      :options="tipologie"
                      value-field="id"
                      :select-size="12"
                      text-field="tipologia">
                    </b-form-select>
                  </b-form-group>
                  <b-badge pill variant="dark" v-for="(id, index) in formIstituto.tipologia" :key="index">
                    {{ get_tipologia_from_options(id) }}
                  </b-badge>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="shadow" bg-variant="gray-050">
                  <b-row>
                    <b-col>
                      <b-form-checkbox
                        v-model="formIstituto.seconda_lingua"
                        size="sm"
                        switch
                      >Seconda Lingua
                      </b-form-checkbox>
                    </b-col>
                    <b-col>
                      <b-form-checkbox
                        v-model="formIstituto.terza_lingua"
                        size="sm"
                        switch
                      >Terza Lingua
                      </b-form-checkbox>
                    </b-col>
                  </b-row>
                  <b-form-group v-if="formIstituto && formIstituto.lingua !== undefined">
                    <b-form-select
                      v-model="formIstituto.lingua"
                      text-field="lingua"
                      value-field="id"
                      multiple
                      :select-size="4"
                      :options="options"
                      @change="onInput"
                    ></b-form-select>
                  </b-form-group>
                  <b-badge pill variant="dark" v-for="(id, index) in formIstituto.lingua" :key="index">
                    {{ get_lingua_from_options(id) }}
                  </b-badge>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Contatti/Eventi" @click="storeIdInVuex">
            <b-row>
              <b-col>
                <table-evento-istituto :istitutoId="dati.id"></table-evento-istituto>
                <br>
                <table-contatti-istituto :IdIstituto="dati.id"></table-contatti-istituto>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-card>
    </template>
    <b-modal size="lg" title="Associa ad Istituto Padre" id="associaIstituto" header-bg-variant="dark" header-text-variant="white">
      <search-istituti @inserisciIstituto="prendoIdIstitutoEAssocio"></search-istituti>
    </b-modal>
  </b-sidebar>
</template>

<script>
import IstitutiService from "@/services/istituto.service";
import searchIstituti from "@/components/crm/searchIstituti";
import TableContattiIstituto from "./TableContattiIstituto";
import TableEventoIstituto from "../evento/istituto/TableEventoIstituto";
export default {
  name: 'SidebarEditIstituto',
  components: {
    searchIstituti,
    TableContattiIstituto,
    TableEventoIstituto
  },
  props: {
    dati: {
      type: Object,
      required: true
    }
  },
  data(){
    return {
      formIstituto: {
        "id" : this.dati.id,
        "codice_istituto": this.dati.codice_istituto,
        "nome_istituto": this.dati.nome_istituto,
        "parent": this.dati.parent,
        "regione": this.dati.regione,
        "indirizzo1": this.dati.indirizzo1,
        "indirizzo2": this.dati.indirizzo2,
        "indirizzo3": this.dati.indirizzo3,
        "cap": this.dati.cap,
        "citta": this.dati.citta,
        "provincia": this.dati.provincia,
        "telefono1": this.dati.telefono1,
        "telefono2": this.dati.telefono2,
        "telefono3": this.dati.telefono3,
        "email1": this.dati.email1,
        "email2": this.dati.email2,
        "email3": this.dati.email3,
        "numero_iscritti": this.dati.numero_iscritti,
        "orientamento": this.dati.orientamento,
        "seconda_lingua": this.dati.seconda_lingua,
        "terza_lingua": this.dati.terza_lingua,
        "scuola": this.dati.scuola,
        "importanza": this.dati.importanza,
        "lingua": this.dati.lingua,
        "tipologia": this.dati.tipologia,
        "note": this.dati.note,
        "website": this.dati.website
      },
      anno_scolastico: [],
      iscritti: [
        {value: 'Da 0 a 250', text: 'Da 0 a 250'},
        {value: 'Da 251 a 500', text: 'Da 251 a 500'},
        {value: 'Da 501 a 750', text: 'Da 501 a 750'},
        {value: 'Da 751 a 1000', text: 'Da 751 a 1000'},
        {value: 'Da 1001 a 1500', text: 'Da 1001 a 1500'}
      ],
      options: [], // faccio la get delle lingue il risultato è un oggetto  {"id": 1, "lingua": "Francese" },{"id": 2,"lingua": "Tedesco"},{"id": 3,"lingua": "Spagnolo"},
      loaded: false,
      search: '',
      value:[],
      language:[],
      tipologie:[],
      tipologieSelected:[],
      idContattoDaTableContatti: null,
      nomeIstittoPadre:'',
      // Contatti
    }
  },
  mounted() {
    this.insertDati()
    this.getAnniScolastici()
    this.getLingue()
    this.getTipologie()
    console.log('DATI: ', this.dati.id)
  },
  methods: {
    storeIdInVuex() {
      console.log('this.dati in editIstituto', this.dati)
      this.$store.dispatch('istituto/setIstituto', this.dati)
    },
    get_lingua_from_options(id) {
      const elem = this.options.find(e => {
        return e.id === id
      })
      return elem ? elem.lingua : null
    },
    get_tipologia_from_options(id) {
      const elem = this.tipologie.find(e => {
        return e.id === id
      })
      return elem ? elem.tipologia : null
    },
    onInput() {
      this.$emit('update:dati', this.form)
    },
    getAnniScolastici() {
      IstitutiService.getAnniScolastici()
        .then(resp => {
          this.anno_scolastico = resp.data
        })
        .catch()
    },
    getLingue(){
      IstitutiService.getLingue()
        .then(resp => {
          console.log(resp.data)
          this.options = resp.data
        })
        .catch()
    },
    getTipologie() {
      IstitutiService.getTipologie()
        .then(resp => {
          this.tipologie = resp.data
        })
        .catch()
    },
    insertDati() {
      this.loaded = false
      console.log(this.form)
    },
    editIstituto() {
      IstitutiService.updateScuole(this.formIstituto)
        .then(() => {
          this.$nextTick(() => {
            this.$emit('alertEditSuccess', true)
            this.$root.$emit('bv::toggle::collapse', 'edit-istituto')

          })
        })
        .catch(e => {
          this.$bvToast.toast(`Istituto NON modificato!`, {
            title: `Non è stato possibile modificare questo Istituto ${e}`,
            variant: 'danger',
            solid: true,
            autoHideDelay: 5000,
            appendToast: true
          })
        })
    },
    associaIstituto() {
      this.$bvModal.show('associaIstituto')
    },
    prendoIdIstitutoEAssocio(item){
      this.form.parent = item.id
      this.nomeIstitUtoPadre = item.nome_istituto
    }
  },
}
</script>

<style>
.head {
  text-align: left;
}
</style>
