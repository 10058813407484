import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_APP_URL

class BugsService {
    getBugs() {
        this.loading = true;
        let bug = API_URL + "ticketing/";
        let status = API_URL + "status-ticketing/";
        const getBugs = axios.get(bug, { headers: authHeader() });
        const getStatus = axios.get(status, { headers: authHeader() });
        return axios.all([getBugs, getStatus,])
    }
    getOnlyBugs(){
        return axios.get(API_URL + 'ticketing/', {headers: authHeader()});
    }
    filterBugs(params) {
        return axios.get(API_URL + 'ticketing/', {headers: authHeader(), params:params});
    }

    addBug(payload){
        return axios.post(API_URL + 'ticketing/', payload, {headers: authHeader()})
    }
    editBug(payload){
        console.log(payload)
        return axios.patch(API_URL + 'ticketing/' + `${payload.id}` +'/', payload, {headers: authHeader()})
    }
    getStatus(){
        return axios.get(API_URL + 'status-ticketing/', {headers: authHeader()})
    }
    closeBug(payload){
        console.log(payload)
        payload.item.status = 4
        return axios.patch(API_URL + 'ticketing/' + `${payload.item.id}` +'/', payload.item, {headers: authHeader()})
    }
    deleteBug(payload){
        payload.item.delete = true
        return axios.patch(API_URL + 'ticketing/' + `${payload.item.id}` +'/', payload.item, {headers: authHeader()})
    }

}
export default new BugsService();
