<template>
  <b-modal
    ref="add-contatto"
    id="add-contatto"
    size="lg"
    header-bg-variant="dark"
    header-text-variant="white"
    title="Inserisci un nuovo contatto"
    @ok="aggiungiContatto">
    <b-row>
      <b-col>
        <b-tabs nav-class="nav-tabs-v2" content-class="pt-2" fill>
          <b-tab title="Informazioni di Base" active>
            <b-card bg-variant="gray-050">
              <b-form>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="nome"
                      label="*Nome:"
                      label-for="nome">
                      <b-form-input
                        id="nome"
                        v-model="form.nome"
                        placeholder="Inserisci il nome"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="cognome" label="*Cognome:">
                      <b-form-input
                        id="cognome"
                        v-model="form.cognome"
                        placeholder="Inserisci il Cognome"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="tipo" label="*Tipo:" label-for="tipo">
                      <b-form-select
                        id="tipo"
                        v-model="form.tipo"
                        :options="tipo"
                        :text-field="tipo.label"
                        :value-field="tipo.key"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group id="email-personale" label="Email Personale:" label-for="email-personale">
                      <b-form-input
                        id="email-personale"
                        placeholder="Email Personale"
                        v-model="form.email_personale"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="email" label="Email:" label-for="email">
                      <b-form-input
                        id="email"
                        placeholder="Email"
                        v-model="form.email"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group id="cellulare" label="Cellulare:" label-for="cellulare">
                      <b-form-input
                        id="cellulare"
                        placeholder="Inserisci il cellulare"
                        v-model="form.cellulare"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="sesso" label="*Sesso:" label-for="sesso">
                      <b-form-select
                        id="sesso"
                        :options="sesso"
                        placeholder="Inserisci il sesso"
                        v-model="form.sesso"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-file
                      id="avatar"
                      placeholder="Inserisci un avatar"
                      v-model="form.avatar"
                    ></b-form-file>
                    <b-form-group id="note" label="Note:" label-for="note">
                      <b-form-textarea
                        id="note"
                        placeholder="Inserisci delle note"
                        rows="6"
                        v-model="form.note"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-tab>
          <b-tab title="Informazioni Scolastiche"></b-tab>
          <b-tab title="Informazioni Personali"></b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <small class="text-danger">Nome Componente:</small><small> src/components/crm/istituto/ModaleContattoIstituto.vue</small>
  </b-modal>
</template>

<script>
import ContattiService from '@/services/contatti.service'
export default {
  name: "ModaleContattoIstituto",
  props: ['istitutoID'],
  data() {
    return {
      form: {
        "nome": "",
        "cognome": "",
        "tipo": null,
        "email_personale": "",
        "email": "",
        "cellulare": "",
        "avatar": null,
        "sesso": null,
        "materia": "",
        'note': '',
      },
      tipo: [
        {value:'Dirigente Scolastico',text:'Dirigente'},
        {value:'Referente Scolastico',text:'Referente'},
        {value:'Docente',text:'Docente'},
        {value:'Generico',text:'Generico'},
      ],
      sesso: [
        {value:'Uomo',text:'Uomo'},
        {value:'Donna',text:'Donna'},
      ],
    }
  },
  mounted() {
    this.initForm()
  },
  methods: {
    initForm() {
      return this.form = {
        "nome": "",
        "cognome": "",
        "tipo": null,
        "email_personale": "",
        "email": "",
        "cellulare": "",
        "avatar": null,
        "sesso": null,
        "materia": "",
        'note': '',
      }
    },
    aggiungiContatto() {

      const payload = this.form
      payload.istituto = this.$store.state.istituto.istituto.id
      console.log('Istituto Id: ', payload)
      ContattiService.postContatti(payload)
        .then(() =>{
          this.form = {}
          this.$bvToast.toast('Contatto aggiunto con successo', {
            title: 'Add Contatto',
            variant: 'success',
            solid: true
          })
        })
        .catch(e => {
          this.$bvToast.toast(`Errore: ${e.response}`, {
            title: 'Errore Contatto',
            variant: 'danger',
            solid: true
          })
        })
    }
  }
}
</script>

<style>
.b-sidebar-body {
    background-color: #EBEEF4;
}
</style>
