<template>
  <b-modal
    v-if="istituti"
    id="associaScuola"
    size="xl"
    hide-header
    no-fade>
    <b-card class="mx-0 px-0" no-body>
      <b-card-header header-bg-variant="dark" style="color: white">
        <h3>Cerca e Associa</h3>
        <h6 class="text-muted">Numero Istituti: {{ this.istituti.length }}</h6>
        {{ leadSelezionato }}
      </b-card-header>
      <b-card-text>
        <b-input-group size="sm">
          <b-form-input
            v-model="filterScuole"
            type="search"
            id="filterInput"
            placeholder="Digita per Ricercare"
          ></b-form-input>
          <b-input-group-append>
            <b-button :disabled="!filterScuole" @click="InitScuole()">Clear</b-button>
          </b-input-group-append>
        </b-input-group>
        <b-table
          :fields="fieldsScuole"
          :items="istituti"
          striped
          select-mode="single"
          @row-clicked="onRowSelected2"
          selectable
          small
        >
        </b-table>
        <b-pagination
          class="mr-3"
          size="sm"
          :total-rows="totalIstituti"
          :per-page="perPage"
          v-model="currentPageIstituti"
          next-text="next"
          prev-text="previous"
          @change="handlePageChangeIstituti"
        >
        </b-pagination>
      </b-card-text>
    </b-card>
  </b-modal>
</template>

<script>
import IstitutoService from "@/services/istituto.service";
import LeadService from "@/services/lead.service";

export default {
  name: "AssociaScuoleALead",
  props: {
    leadsSelezionati: Object,
    leadSelezionato: Number

  },
  data() {
    return {
      filterScuole:'',
      istituti:[],
      fieldsScuole: [
        {key: 'id', label: 'id', sortable: false},
        {key: 'nome_istituto', label: 'Nome Istituto', sortable: false},
        {key: 'citta', label: 'Città', sortable: false},
        // {key:'tipologia', label:'Tipologia', sortable:false},
      ],
      // Pagination Istituti
      perPage: 50,
      currentPageIstituti: 1,
      totalIstituti: 0,
      pageIstituti: null,
      multiSelectLead: {
        lead_ids: [],
        associa_scuola: null,
      },
      selected: {

      }

    }
  },
  computed: {
    filterSchool() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },
  },
  watch: {
    filterScuole(newValue) {
      if(newValue.length >= 4) {
        this.searchIstituti()
      }
    },
  },
  mounted() {
    this.InitScuole()
  },
  methods: {
    InitScuole(){
      IstitutoService.getIstitutiSoloNome()
        .then(resp => {
          this.istituti = resp.data.results
          this.currentPageIstituti = resp.data.results.page
          this.totalIstituti= resp.data.total
          this.pageIstituti=null
        })
        .catch()
    },
    searchIstituti() {
      const params = {
        search: this.filterScuole
      }
      IstitutoService.getIstitutiSoloNome(params)
        .then(resp => {
          this.istituti = resp.data.results
        })
    },
    handlePageChangeIstituti(pageNumber) {
      const params  = {
        page: pageNumber
      }
      IstitutoService.getIstitutiSoloNome(params)
        .then(resp => {
          this.istituti = resp.data.results
          this.currentPageIstituti = resp.data.page
          this.totalIstituti= resp.data.total
          this.pageIstituti=null
        })
        .catch(e => {
          for(const [key,value] of Object.entries(e.response.data)) {
            this.$bvToast.toast(`Pagination Error!`, {
              title: `Pagination Error ${key}: ${value}`,
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000,
              appendToast: true
            })
          }
        })
    },
    onRowSelected2(item) {
      console.log("item", item)
      if (this.multiSelectLead.lead_ids.length > 1) {
        this.multiSelectLead.associa_scuola = item.id
        LeadService.editLeadBulk(this.multiSelectLead)
          .then(() => {
            this.$bvToast.toast(`Lead modificato!`, {
              title: 'Scuola associata con successo',
              variant: 'success',
              solid: true,
              autoHideDelay: 5000,
              appendToast: true
            })
            this.$bvModal.hide('associaScuola')
            this.searchAndFilterLeadwithPagination(this.page)
            // this.$emit('refresLead')
          })
      } else {
        this.selected.associa_scuola = item.id
        this.multiSelectLead.associa_scuola = item.id
        const payload = {
          id: this.leadSelezionato,
          associa_scuola: item.id
        }
        LeadService.editLead(payload)
          .then(() => {
            this.$bvToast.toast(`Scuola Associata!`, {
              title: 'Scuola associata con successo',
              variant: 'success',
              solid: true,
              autoHideDelay: 5000,
              appendToast: true
            })
            // this.searchAndFilterLeadwithPagination(this.page)
            this.$bvModal.hide('associaScuola')
          })
          .catch(e => {
            for (const [key, value] of Object.entries(e.response.data)) {
              this.$bvToast.toast(`Lead NON modificato!`, {
                title: `Non è stato possibile modificare questo Lead il campo ${key} contiene un valore ${value}`,
                variant: 'danger',
                solid: true,
                autoHideDelay: 5000,
                appendToast: true
              })
            }
          })
      }
    },
  }
}
</script>

<style scoped>

</style>
