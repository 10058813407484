<template>
        <!-- BEGIN #content -->
<!--        <div id="content" class="app-content p-0">
            &lt;!&ndash; BEGIN profile &ndash;&gt;
            <div class="profile">
                &lt;!&ndash; BEGIN profile-header &ndash;&gt;
                <div class="profile-header">
                    <div class="profile-header-cover"></div>

                    <div class="profile-header-content">
                        <div class="profile-header-img">
                            <b-img :src="user.avatar" alt="Avatar" @click="editImage"></b-img>
                        </div>
                        <ul class="profile-header-tab nav nav-tabs nav-tabs-v2">
                            <li class="nav-item">
                                <b-link href="#" class="nav-link" v-bind:class="{'active': tabShow === 'profiloInfo' }" @click="showTab('profiloInfo')">
                                    <div class="nav-field">Informazioni Base</div>
                                </b-link>
                            </li>
                            <li class="nav-item">
                                <b-link href="#" class="nav-link" v-bind:class="{'active': tabShow === 'profiloDettagli' }" @click="showTab('profiloDettagli', 'detail')">
                                    <div class="nav-field">Dettagli Studente</div>
                                </b-link>
                            </li>
                            <li class="nav-item">
                                <b-link href="#" class="nav-link" v-bind:class="{'active': tabShow === 'profiloPianoDiStudio' }"  @click="showTab('profiloPianoDiStudio')">
                                    <div class="nav-field">Piano di Studi</div>
                                </b-link>
                            </li>
                            <li class="nav-item">
                                <b-link href="#" class="nav-link" v-bind:class="{'active': tabShow === 'profiloCorsi' }"  @click="showTab('profiloCorsi')">
                                    <div class="nav-field">Corsi</div>
                                </b-link>
                            </li>
                            <li class="nav-item">
                                <b-link href="#" class="nav-link" v-bind:class="{'active': tabShow === 'profiloEsami' }"  @click="showTab('profiloEsami')">
                                    <div class="nav-field">Esami</div>
                                </b-link>
                            </li>
                        </ul>
                    </div>
                </div>
                &lt;!&ndash; END profile-header &ndash;&gt;

                &lt;!&ndash; BEGIN profile-container &ndash;&gt;
                <div class="profile-container">
                    &lt;!&ndash; BEGIN profile-sidebar &ndash;&gt;
                    <div class="profile-sidebar">
                        <div class="desktop-sticky-top">
                            &lt;!&ndash; profile info &ndash;&gt;
                            <h4>{{ user.first_name}} {{ user.last_name }}</h4>
                            <div class="font-weight-600 mb-3 text-muted mt-n2"><i class="fas fa-at"></i><small>{{ user.email_istituzionale }}</small></div>
                            <p>
                                {{ user.bio }}
                            </p>
                            <div class="mb-1">
                                <i class="fa fa-map-marker-alt fa-fw text-muted"></i><b-badge variant="dark" size="xl">{{ user.profile }}</b-badge>
                            </div>
                            <div class="mb-3">
                                <i class="fa fa-link fa-fw text-muted"></i>
                            </div>
                            <hr class="mt-4 mb-4" />
                            &lt;!&ndash; people-to-follow &ndash;&gt;
                        </div>
                    </div>
                    &lt;!&ndash; END profile-sidebar &ndash;&gt;

                    &lt;!&ndash; BEGIN profile-content &ndash;&gt;
                    <div class="profile-content">
                        <b-row>
                            <b-col cols="10">
                                <div class="tab-content p-0">
                                    &lt;!&ndash; BEGIN tab-pane &ndash;&gt;
                                    <div class="tab-pane fade" v-bind:class="{'show active': tabShow === 'profiloInfo'}">
                                        <b-card no-body class="mb-3">
                                            <b-card-body>
                                                <div style="text-align: end">
                                                    <b-button variant="dark" @click="salvaContatto">Salva</b-button>
                                                </div>
                                                &lt;!&ndash; post header &ndash;&gt;
                                                        <b-form>
                                                            <label>ID:</label>
                                                            <b-form-input type="text" v-model="user.user_id" disabled>
                                                                {{ user.user_id }}
                                                            </b-form-input>
                                                            <label>Last Login</label>
                                                            <b-form-input type="text" v-model="user.last_login" disabled>
                                                                {{ user.last_login | formatDate }}
                                                            </b-form-input>
                                                            <label>Username</label>
                                                            <b-form-input type="text" v-model="user.username">
                                                                {{ user.username }}
                                                            </b-form-input>
                                                            <label>Nome</label>
                                                            <b-form-input type="text" v-model="user.first_name">
                                                                {{ user.first_name }}
                                                            </b-form-input>
                                                            <label>Cognome</label>
                                                            <b-form-input type="text" v-model="user.last_name">
                                                                {{ user.last_name }}
                                                            </b-form-input>
                                                            <label>Iscritto dal:</label>
                                                            <b-form-datepicker type="text" v-model="user.date_joined">
                                                                {{ user.date_joined | formatDate() }}
                                                            </b-form-datepicker>
                                                            <label>Email</label>
                                                            <b-form-input type="text" v-model="user.email_istituzionale">
                                                                {{ user.email_istituzionale }}
                                                            </b-form-input>
                                                            <label>Profilo: </label>
                                                            <b-form-select
                                                                v-model="user.profile"
                                                                :options="option"
                                                            >
                                                            </b-form-select>
                                                            <label>Stato:</label>
                                                            <b-checkbox switch v-model="user.is_active">
                                                                <span v-if="user.is_active">Attivo</span>
                                                                <span v-else>Disattivato</span>
                                                            </b-checkbox>
                                                        </b-form>
                                                {{ libretto }}
                                            </b-card-body>
                                            <b-card-footer class="pt-3 pb-3">

                                            </b-card-footer>
                                        </b-card>
                                    </div>
                                    &lt;!&ndash; END tab-pane &ndash;&gt;

                                    &lt;!&ndash; BEGIN tab-pane &ndash;&gt;
                                    <div class="tab-pane fade" v-bind:class="{'show active': tabShow === 'profiloDettagli'}">
                                        <div class="list-group">
                                            <div class="card mb-3">
                                                <div class="card-body">
                                                    <div style="text-align: end">
                                                        <b-button variant="dark" @click="salvaDettagli">Salva</b-button>
                                                    </div>
                                                    <form>
                                                        <div class="mb-3 row">
                                                            <label for='matricola' class="col-sm-2 col-form-label">Matricola:</label>
                                                            <div class="col-4">
                                                                <input class="form-control" id='matricola' size="sm" :value="this.details.matricola" disabled>
                                                            </div>
                                                        </div>
                                                        <b-card class="mb-2 pb-0" no-body>
                                                            <b-card-header class="text-white" style="background: #3a3f51">Anagrafica:</b-card-header>
                                                            <b-card-body>
                                                            <div class="mb-3 row">
                                                            <label for='nome' class="col-sm-2 col-form-label">Nome:</label>
                                                            <div class="col-md-3">
                                                                <input class="form-control" id='nome' size="sm" :value="this.details.nome">
                                                            </div>
                                                            <div class="col-md-2"></div>
                                                                <label for='cognome' class="cols-sm-2 col-form-label">Cognome:</label>
                                                                <div class="col-md-3 mb-2">
                                                                    <input class="form-control" id='cognome' size="sm" :value="this.details.cognome">
                                                                </div>
                                                                <label for='luogo_di_nascita' class="col-sm-2 col-form-label">Nato/a:</label>
                                                                <div class="col-md-3">
                                                                    <input class="form-control" id='luogo_di_nascita' size="sm" :value="this.details.luogo_di_nascita">
                                                                </div>
                                                                <div class="col-md-2 float-left"></div>
                                                                <label for='provincia' class="cols-sm-2 col-form-label">Provincia:</label>
                                                                <div class="col-md-3 mb-2">
                                                                    <input class="form-control" id='provincia' size="sm" :value="this.details.provincia_di_nascita">
                                                                </div>
                                                        </div>
                                                            </b-card-body>
                                                        </b-card>
                                                        <b-card class="mb-2 pb-0" no-body>
                                                            <b-card-header class="text-white" style="background: #3a3f51">Recapiti:</b-card-header>
                                                            <b-card-body>
                                                            <div class="mb-3 row">
                                                                <label for='email' class="col-sm-2 col-form-label">Email Privata:</label>
                                                                <div class="col-md-3 mb-2">
                                                                    <input class="form-control" id='email' size="sm" :value="this.details.email">
                                                                </div>
                                                                <div class="col-md-2"></div>
                                                                <label for='email_ist' class="cols-sm-2 col-form-label">Email Ist.:</label>
                                                                <div class="col-md-3 mb-2">
                                                                    <input class="form-control" id='email_ist' size="sm" :value="this.details.email_istituzionale">
                                                                </div>
                                                                <label for='telefono_fisso' class="col-sm-2 col-form-label">Telefono:</label>
                                                                <div class="col-md-3">
                                                                    <input class="form-control" id='telefono_fisso' size="sm" :value="this.details.telefono_fisso">
                                                                </div>
                                                                <div class="col-md-2"></div>
                                                                <label for='telefono_cellulare' class="cols-sm-2 col-form-label">Cellulare:</label>
                                                                <div class="col-md-3 mb-2">
                                                                    <input class="form-control" id='telefono_cellulare' size="sm" :value="this.details.telefono_cellulare">
                                                                </div>
                                                                <label for='indirizzo_residenza' class="col-sm-2 col-form-label">Indirizzo [Res.]:</label>
                                                                <div class="col-md-3 mb-2">
                                                                    <input class="form-control" id='indirizzo_residenza' size="sm" :value="this.details.indirizzo_residenza">
                                                                </div>
                                                                <div class="col-md-2"></div>
                                                                <label for='numero_civico_residenza' class="cols-sm-2 col-form-label">Civico:</label>
                                                                <div class="col-md-3 float-right">
                                                                    <input class="form-control" id='numero_civico_residenza' size="sm" :value="this.details.numero_civico_residenza">
                                                                </div>
                                                            </div>
                                                            </b-card-body>
                                                        </b-card>
                                                        <div class="mb-3 row">
                                                            <label for='tipo_di_diploma' class="col-sm-2 col-form-label">Diplomato In:</label>
                                                            <div class="col-md-3">
                                                                <input class="form-control" id='tipo_di_diploma' size="sm" :value="this.details.tipo_di_diploma">
                                                            </div>
                                                            <div class="col-md-2"></div>
                                                                <label for='sede_di_studi' class="cols-sm-2 col-form-label">Sede Ateneo:</label>
                                                                <div class="col-md-3 float-right">
                                                                    <input class="form-control" id='sede_di_studi' size="sm" :value="this.details.sede_di_studi" disabled>
                                                                </div>
                                                        </div>
&lt;!&ndash;                                                        <div class="mb-3 row">&ndash;&gt;
&lt;!&ndash;                                                            <label for='nome' class="col-sm-2 col-form-label">Nome:</label>&ndash;&gt;
&lt;!&ndash;                                                            <div class="col-md-4">&ndash;&gt;
&lt;!&ndash;                                                                <input class="form-control" id='nome' size="sm" :value="this.details.nome">&ndash;&gt;
&lt;!&ndash;                                                            </div>&ndash;&gt;
&lt;!&ndash;                                                                <label for='cognome' class="cols-sm-2 col-form-label">Cognome:</label>&ndash;&gt;
&lt;!&ndash;                                                                <div class="col-md-4">&ndash;&gt;
&lt;!&ndash;                                                                    <input class="form-control" id='cognome' size="sm" :value="this.details.cognome">&ndash;&gt;
&lt;!&ndash;                                                                </div>&ndash;&gt;
&lt;!&ndash;                                                        </div>&ndash;&gt;



                                                        <div class="mb-3 row">
                                                            <div class="col-sm-10 offset-sm-2">
                                                                <button type="submit" class="btn btn-primary">Sign in</button>
                                                            </div>
                                                        </div>
                                                    </form>
&lt;!&ndash;                                                    <b-list-group >&ndash;&gt;
&lt;!&ndash;                                                        <div class="d-inline-block">&ndash;&gt;
&lt;!&ndash;                                                        <b-list-group-item size="sm" :id='`item${i}`'><label>{{ k }}</label>: <b-input  ></b-input></b-list-group-item>&ndash;&gt;
&lt;!&ndash;                                                        </div>&ndash;&gt;
&lt;!&ndash;                                                    </b-list-group>&ndash;&gt;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    &lt;!&ndash; END tab-pane &ndash;&gt;

                                    &lt;!&ndash; BEGIN tab-pane &ndash;&gt;
                                    <div class="tab-pane fade" v-bind:class="{'show active': tabShow ==='profiloPianoDiStudio'}">
                                        <div class="card mb-3">
                                            <div class="card-body">
                                                <b-table
                                                    :items="materie"
                                                    :fields="fields"
                                                    small
                                                    hover
                                                    outlined
                                                    striped
                                                    thead-class="bg-dark text-white"
                                                >
                                                    <template v-slot:cell(#)="row">
                                                        {{ row.index + 1 }}
                                                    </template>
                                                    <template v-slot:cell(actions)="">
                                                        <b-btn class="mr-1" size="sm" variant="dark" disabled>iscriviti</b-btn>
                                                    </template>
                                                </b-table>
                                                </div>
                                            </div>
                                        </div>
                                    &lt;!&ndash; END tab-pane &ndash;&gt;

                                    <div class="tab-pane fade" v-bind:class="{'show active': tabShow ==='profiloCorsi'}">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            <b-table
                                                :items="libretto.get_info.corsi"
                                                :fields="corsiFields"
                                                striped
                                                hover></b-table>
                                        </div>
                                    </div>
                                    </div>

                                     &lt;!&ndash; END tab-pane &ndash;&gt;
                                    &lt;!&ndash; BEGIN tab-pane &ndash;&gt;
                                    <div class="tab-pane fade" v-bind:class="{'show active': tabShow ==='profiloEsami'}">
                                    <div class="card mb-3">
                                        <div class="card-body">
                                            Esami Sostenuti:
                                        </div>
                                    </div>
                                    </div>
                                    &lt;!&ndash; END tab-pane &ndash;&gt;
                                </div>
                            </b-col>
                            <b-col cols="2">
                                <div class="desktop-sticky-top d-none d-lg-block">
                                    <b-card>
                                        altro menu
                                        {{ this.user }}
                                    </b-card>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    &lt;!&ndash; END profile-content &ndash;&gt;
                </div>
                &lt;!&ndash; END profile-container &ndash;&gt;
            </div>
            &lt;!&ndash; END profile &ndash;&gt;
        </div>-->
    <div id="content" class="app-content p-0">
        Staff
    </div>
        <!-- END #content -->
</template>

<script>
import AuthService from '@/services/auth.service'
import StudenteService from "../../services/studente.service";
export default {
    name: "profileStaff",
    data(){
        return {
            user:[],
            libretto: [],
            fields:[
                {key:'insegnamento__anno_di_corso', label:'anno', sortable: true },
                {key:'insegnamento__nome', sortable: true},
                {key:'_nome'},
                {key:'actions', label:'Actions'}
            ],
            corsiFields: [
                {key:'insegnamento__anno_di_corso', label:'anno', sortable: true },
                {key:'insegnamento__nome', label:'Corso', sortable: true },
                {key:'insegnamento__moduli___nome', label:'Lezione' },
                {key:'insegnamento__moduli__ore', label:'Ore' },
                {key:'insegnamento__moduli__cfu', label:'Crediti' },
                {key:'Freq', label:'Freq' },
            ],
            details:[],
            materie: [],
            tabShow: 'profiloInfo',
            option: [
                {value:'Lead', text:'Lead'},
                {value:'Studente', text:'Studente'},
                {value:'Professore', text:'Professore'},
                {value:'Staff', text:'Staff'},
                {value:'Direzione', text:'Direzione'}
            ]

        }
    },
    created() {
      this.user = this.$store.state.auth.user
    },
    mounted() {
        this.initLibretto()
    },
    methods:{
        initLibretto() {
            if (this.$store.state.auth.user.profile==='Studente') {
                StudenteService.getLibretto()
                    .then(resp => {
                        this.libretto = resp.data[0]
                        this.materie = resp.data[0].materie
                    })
                    .catch()
            } else {
                return this.libretto = []
            }
        },
        showTab: function(tab, detail) {
            if(detail) {
                this.tabShow = tab;
                console.log('richiamo i dettagli dello studente')
                StudenteService.getDetails()
                    .then(resp => {
                        this.details = resp.data.results[0]
                    })
                    .catch()
            } return this.tabShow = tab
        },
        salvaContatto() {
            delete this.user.avatar
            AuthService.updateUser(this.user)
                .then(resp => {
                    console.log(resp.status)
                    })
                .catch()
        },
        salvaDettagli() {
            console.log('salvaDettagli')
        },
        editImage(){
            console.log('cambia l immagine')
        }
    }
}
</script>

<style scoped>
.div-list :hover {
    background: #fdc100;
    color: white;
}
.headerClass {
    background-color: #03032d;
}
</style>
