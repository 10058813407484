<template>

    <div>
        <video ref="videoPlayer" class="video-js vjs-matrix-forest"></video>
    </div>
</template>

<script>
import videojs from 'video.js';
import '@videojs/themes/dist/forest/index.css';

export default {
    name: "VideoPlayer",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return {
            player: null
        }
    },
    // mounted() {
    //     this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
    //         console.log('onPlayerReady', this);
    //     })
    // },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
            this.player.log('onPlayerReady', this);
        });
    },

    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    }
}

</script>
