import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL


class IstitutiService {
    getLingue(){
        return axios.get(API_URL + 'lingua_istituto/', { headers: authHeader()})
    }
    getAnniScolastici(){
        return axios.get(API_URL + 'anni_scolastici/', {headers: authHeader()})
    }
    getIstituti(params) {
        return axios.get(API_URL + 'istituti/',{headers: authHeader(), params:params});
    }
    getIstitutiSoloNome(params) {
        return axios.get(API_URL + 'istituti/?choices', {headers: authHeader(), params: params});
    }
    insertIstituto(data) {
        return axios.post(API_URL + 'istituti/', data, {headers: authHeader()} )
    }
    setImportanza(payload){
        return axios.patch(API_URL + 'istituti/' + payload.id + '/', payload, { headers: authHeader() })
    }
    getFilterContatti(item){
        return axios.all([
            axios.get(API_URL + `contatti/?istituto=${item.id}&tipo=Dirigente+Scolastico`, {headers: authHeader()}),
            axios.get(API_URL + `contatti/?istituto=${item.id}&tipo=Docente`, {headers: authHeader()}),
            axios.get(API_URL + `contatti/?istituto=${item.id}&tipo=Referente+Scolastico`, {headers: authHeader()}),
            axios.get(API_URL + `contatti/?istituto=${item.id}&tipo=Generico`, {headers: authHeader()}),
        ])
    }

    getRegioniChoices(){
        return axios.get(API_URL + 'istituti/regioni_choices/', { headers: authHeader()})
    }
    getProvinceChoices(regione){
        return axios.get(API_URL + 'istituti/province_choices/?regione=' + regione, { headers: authHeader()})
    }
    deleteIstituto(id){
        return axios.delete(API_URL + 'istituti/' + id + '/', { headers: authHeader()})
    }
    updateScuole(data){
        console.log(data.id)
        return axios.put(API_URL + 'istituti/' + data.id + '/', data, { headers: authHeader()})
    }
    getTipologie() {
        return axios.get(API_URL + 'tipologia_istituti/', { headers: authHeader() })
    }
    getPageIstituti(p){
        return axios.get(API_URL + 'istituti/?page=' + p, {headers:  authHeader()})
    }
    searchIstituti(params){
        return axios.get(API_URL + 'istituti/', {headers: authHeader(), params:params})
    }
    getExportIstituti(){
        return axios.get(API_URL +'export-istituti-csv/', {headers: authHeader(), responseType: 'blob'});
    }

    choiceFieldIstituti(params){
        return axios.get(API_URL +'istituti/?fields=' + params, {headers: authHeader(), params: 'fields'}); //  responseType: 'blob'
    }

}
export default new IstitutiService();
