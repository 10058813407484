<template>
	<div id="header" class="app-header shadow-none" v-if="$store.state.auth.status.loggedIn">
		<!-- BEGIN mobile-toggler -->
		<div class="mobile-toggler">
			<button type="button" class="menu-toggler" v-on:click="toggleAppSidebarMobile">
				<span class="bar"></span>
				<span class="bar"></span>
				<span class="bar"></span>
			</button>
		</div>
		<!-- END mobile-toggler -->

		<!-- BEGIN brand -->
		<div class="brand">
			<div class="desktop-toggler">
				<button type="button" class="menu-toggler" @click="toggleAppSidebarMinify">
					<span class="bar"></span>
					<span class="bar"></span>
					<span class="bar"></span>
				</button>
			</div>

			<a href="#" class="brand-logo">
				<img src="/assets/img/logo_white.svg" alt="UNICOLLEGE SSML"/>
			</a>

		</div>
		<!-- END brand -->

		<!-- BEGIN menu -->
		<div class="menu">
            <span class="menu-search"></span>
            <b-dropdown
              v-if="$store.state.auth.user.profile === 'Staff'"
              class="menu-item border-left float-lg-right"
              right toggle-tag="a"
              variant="link"
              :no-caret="true" toggle-class="menu-link"
              menu-class="dropdown-notification">
                <template v-slot:button-content>
                    <div class="menu-icon"><i class="fas fa-list"></i></div>
                    <div class="menu-label">{{ listaleads.length }}</div>
                </template>
                <h6 class="dropdown-header text-gray-900 mb-1">Liste personali</h6>
                <hr>
                <div v-for="ll in listaleads" :key="ll.id">
                    <b-link @click="richiamaLead(ll.leads)">{{ll.nome}}</b-link>
                </div>
            </b-dropdown>
			<b-dropdown
        v-if="$store.state.auth.user.profile === 'Staff'"
        class="menu-item float-lg-right"
        right  toggle-tag="a" variant="link" :no-caret="true"
        toggle-class="menu-link" menu-class="dropdown-notification">
				<template v-slot:button-content>
					<div class="menu-icon"><i class="fa fa-bell"></i></div>
					<div class="menu-label">{{ notificationData.length }}</div>
				</template>
				<h6 class="dropdown-header text-gray-900 mb-1">Notifications</h6>
				<template v-if="notificationData && notificationData.length > 0">
					<a href="#" class="dropdown-notification-item" v-for="(notification, index) in notificationData" v-bind:key="index">
						<div class="dropdown-notification-icon">
							<i v-if="notification.icon" v-bind:class="notification.icon"></i>
							<img v-if="notification.image" v-bind:src="notification.image" width="26" />
						</div>
						<div class="dropdown-notification-info">
							<div class="title">{{ notification.title }}</div>
							<div class="time">{{ notification.time }}</div>
						</div>
						<div class="dropdown-notification-arrow">
							<i class="fa fa-chevron-right"></i>
						</div>
					</a>
				</template>
				<template v-else>
					<div class="dropdown-notification-item bg-white">
						No record found
					</div>
				</template>
				<div class="p-2 text-center mb-n1">
					<a href="#" class="text-gray-800 text-decoration-none">See all</a>
				</div>
			</b-dropdown>
			<b-dropdown class="menu-item float-right border-left" right toggle-tag="a" variant="link" :no-caret="true" toggle-class="menu-link border-0">
				<template v-slot:button-content>
					<div class="menu-img online">
						<div class="d-flex align-items-center justify-content-center w-100 h-100 bg-gray-800 text-gray-300 rounded-circle overflow-hidden">
                            <i class="fa fa-user fa-2x mb-n3"></i>
            </div>
					</div>
            <div class="text-white float-left">
              <span>Profilo: {{ $store.state.auth.user.profile }}</span><br>
              {{ currentUserEmail }}
            </div>

				</template>
<!--				<b-dropdown-item  link-class="d-flex align-items-center" href="#" @click="profileUser">-->
<!--					Edit Profile <i class="fa fa-user-circle fa-fw ml-auto text-gray-400 f-s-16"></i>-->
<!--				</b-dropdown-item>-->
<!--				<b-dropdown-item link-class="d-flex align-items-center" href="#">-->
<!--					Inbox <i class="fa fa-envelope fa-fw ml-auto text-gray-400 f-s-16"></i>-->
<!--				</b-dropdown-item>-->
<!--				<b-dropdown-item link-class="d-flex align-items-center" href="#">-->
<!--					Calendar <i class="fa fa-calendar-alt fa-fw ml-auto text-gray-400 f-s-16"></i>-->
<!--				</b-dropdown-item>-->
<!--				<b-dropdown-item link-class="d-flex align-items-center" href="#">-->
<!--					Settings <i class="fa fa-wrench fa-fw ml-auto text-gray-400 f-s-16"></i>-->
<!--				</b-dropdown-item>-->
<!--				<b-dropdown-divider />-->
				<b-dropdown-item link-class="d-flex align-items-center" href="#" @click="logOut">
					Log Out <i class="fa fa-toggle-off fa-fw ml-auto text-gray-400 f-s-16"></i>
				</b-dropdown-item>
			</b-dropdown>
		</div>
		<!-- END menu -->
	</div>
</template>

<script>
import AppOptions from '../config/AppOptions.vue'
import ListaLead from '../services/listleads.service'
import AuthService from "@/services/auth.service";

export default {
  name: 'Header',
  data() {
		return {
			appOptions: AppOptions,
			notificationData: [],
            listaleads:[]
		}
  },
    mounted() {
      // this.initListaLead()
    },
    computed:{
        currentUserEmail() {
            const user = this.$store.state.auth.user;
            return user ? user.email : ""
        },
    },
	methods: {
        initListaLead() {
            let params = {
                user: this.$store.state.auth.user.account_id
            }
            ListaLead.getListeLeads(params)
                .then(resp => {
                    this.listaleads = resp.data.results
                })
                .catch()
        },
        profileUser(){
            let self = this;
            if( this.$store.state.auth.user.profile === 'Studente'){
                self.$router.push({path:'/profile-studente'}).catch(()=>{});
            }
            else if (this.$store.state.auth.user.profile === 'Docente') {
                self.$router.push({path:'/profile-docente'}).catch(()=>{});
            }
            else {
                self.$router.push({path:'/profile-staff'}).catch(()=>{});
            }

        },
		toggleAppSidebarMobile() {
			this.appOptions.appSidebarMobileToggled = !this.appOptions.appSidebarMobileToggled;
		},
		toggleAppSidebarMinify() {
			this.appOptions.appSidebarMinified = !this.appOptions.appSidebarMinified;
		},
		checkForm: function(e) {
			e.preventDefault();
			this.$router.push({ path: '/extra/search' }).catch(()=>{});
		},
        richiamaLead(leads) {
          console.log(leads)
            const params = {
              lista_leads: leads
            }
            ListaLead.getListeLeads(params).then(
              this.$store.dispatch('lead/tmpLead', leads)
            )
            // this.$store.dispatch('lead/tmpLead', leads)
            // this.$nextTick(() => {
            //     this.$root.$emit('component1')
            // })
        },
        logOut() {
            AuthService.logout().then(() => {
                this.$store.dispatch('auth/logout').then(() => {
                    this.$router.push({ name: 'Login'})
                })}
            );
      }
	}
}
</script>
