
export const settings = {
    namespaced: true,
    state : {
        leads: false,
        events:false,
        followup:false,
    },
    mutations: {
        updateOpenBug(state, openedBugs){
            state.openBugs = openedBugs
        },
        REFRESH_LEAD(state, value) {
            state.leads = value
            console.log('adesso nelle mutations e il valore è:', this.leads)
        }
        },
    actions: {
        refreshLead(commit, value) {
            console.log('sono nelle actions')
            commit('REFRESH_LEAD', value)
        }
    }
}
