<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <b-breadcrumb>
            <b-breadcrumb-item href="#">BREADCRUMB</b-breadcrumb-item>
            <b-breadcrumb-item active>HOME PAGE</b-breadcrumb-item>
        </b-breadcrumb>

        <h1 class="page-header">
            Lista Dirigenti <small>page header description goes here...</small>
        </h1>

        <p>
            Start build your page here
        </p>
    </div>
    <!-- END #content -->
</template>

<script>
export default {
name: "ListaDirigenti"
}
</script>

<style scoped>

</style>
