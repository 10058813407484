<template>
        <b-form>
            <b-form-group
                label='Issue'
                label-cols="2"
                label-for="issue">
                <b-input id='issue' placeholder="Issue"  :value="editForm.issue" v-model="editForm.issue"></b-input>

            </b-form-group>
            <b-form-group
                label='Descrizione'
                label-cols="2"
                label-for="descrizione">
                <b-textarea id="descrizione" placeholder="Descrizione..." rows="4" :value="editForm.description" v-model="editForm.description">

                </b-textarea>
            </b-form-group>
            <b-row class="mt-3">
                <b-col>
                    <b-form-group
                        label="Status"
                        label-cols="4"
                        label-for="status">
                        <b-form-select value="editForm.status" v-model="editForm.status">
                            <b-form-select-option
                                v-for="st in status"
                                :key="st.id"
                                :value="st.id"
                                :label="st.status">
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        label="Tipo"
                        label-cols="4"
                        label-for="tipo">
                        <b-form-select
                            id="status"
                            v-model="editForm.tipo"
                            :options="tipo"
                            text-field="value"
                            value-field="value"
                            size="sm"
                        >
                        </b-form-select>
                        <small class="text-muted">Inserire il tipo di Ticket</small>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        label="Priorità"
                        label-cols="4"
                        label-for="priorità">
                        <b-form-select
                            id="priorità"
                            value="editForm.priority" v-model="editForm.priority">
                            <b-form-select-option
                                v-for="ni in priority"
                                :key="ni.key"
                                :value="ni.label"
                                :label="ni.label">
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="col-xl-12">
                <b-col class="col-xl-6">
                    <label label-for="date">Scegli la Data</label>
                    <b-input-group class="mb-3">
                        <b-form-input
                            id="date"
                            v-model="editForm.date"
                            :value="editForm.date"
                            type="date"
                            placeholder="YYYY-MM-DD"
                            size="sm"
                        ></b-form-input>
                        <!--                        <b-input-group-append>-->
                        <!--                            <b-form-datepicker-->
                        <!--                                v-model="this.editForm.date"-->
                        <!--                                button-only-->
                        <!--                                right-->
                        <!--                                locale="en-US"-->
                        <!--                                aria-controls="example-input"-->
                        <!--                                size="sm"-->
                        <!--                            ></b-form-datepicker>-->
                        <!--                        </b-input-group-append>-->
                    </b-input-group>
                </b-col>
                <b-col class="col-xl-6">
                    <!--                    <label label-for="delete">Cancellato?</label>-->
                    <!--                    <b-form-checkbox id="delete" :value="editForm.delete" v-model="editForm.delete" name="check-button" switch>{{editForm.delete}}</b-form-checkbox>-->
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col>
                    <b-button variant="danger" aria-colspan="8" @click="$root.$emit('bv::hide::modal', 'editBug')" size="sm">Annulla</b-button>
                    <b-button variant="success" class="ml-2" @click="editBug(form)" size="sm">Invia</b-button>
                </b-col>
            </b-row>
        </b-form>
</template>

<script>
import BugsService from '@/services/bugs.service'
export default {
    props:{
        bug:{
            type: Object
      }
    },
    name: "EditBug",
    data() {
        return {
            form: {
                issue: "",
                priority: null,
                description: "",
                date: null,
                status: null,
                delete: false,
            },
            editForm:{
                id: this.bug.item.id,
                issue: this.bug.item.issue,
                priority: this.bug.item.priority,
                tipo: this.bug.item.tipo,
                description: this.bug.item.description,
                date: this.bug.item.date,
                status: this.bug.item.status,
                delete: this.bug.item.delete
            },
            tipo: [
                {key:'bug', value:'Bug'},
                {key:'feature', value:'Feature'},
                {key:'revision', value:'Revision'}
            ],
            priority: [
                {key:1,label: 'basso', variant:'info'},
                {key:2,label: 'medio'},
                {key:3,label: 'urgente'},
                {key:4,label: 'bloccante'},
            ],
            status:[],
        }
    },
    mounted() {
        BugsService.getStatus()
            .then(response =>{
                this.status = response.data
            })
            .catch()
    },
    methods: {
        editBug() {
            BugsService.editBug(this.editForm)
                .then(()  => {
                        this.$emit('initBugsService')
                        this.$root.$emit('bv::refresh::bugTable', 'bugTable')
                        this.$root.$emit('bv::hide::modal', 'editBug')

                }
                )
                .catch()
        },
    }
}
</script>


<style scoped>

</style>
