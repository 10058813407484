<template>
  <!-- BEGIN #content -->
  <div id="content" class="app-content" ref="listaLead">
    <small class="text-muted">Nome Pagina: src/pages/crm/lead/ListaLead.vue</small>
    <alert-success id="alertSuccess"></alert-success>
    <b-alert
      :show="showAlert"
      dismissible
      variant="danger"
    >
      Lead cancellato con successo!
    </b-alert>
    <b-alert
      variant="success"
      dismissible
      :show="leadAssociato"
      @dismissed="dismissCountDown=0"
    >Lead Associato con successo!</b-alert>
    <b-alert
      id="alertEditSuccess"
      variant="success"
      dismissible
      :show="leadModificato"
      @dismissed="dismissCountDown=0"
    >Lead modificato con successo!</b-alert>
    <b-alert
      :show="dismissCountDown"
      variant="warning"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
      fade
      size="sm"
    >
      <span class="float-left" style="border-bottom: 1px dotted;" @click="copyEmail(dati)">{{ dati.lead_email }} </span> modificato con successo!
      <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="1px"
      ></b-progress>
    </b-alert>
    <b-overlay :show="overlay" type="grow"
               variant="dark"
               blur="blur"
               rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon style="color: #FFFFFF" icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label" style="color: #FFFFFF">Please wait...</p>
        </div>
      </template>
      <b-row>
        <b-col cols="12">
          <b-card bg-variant="dark">
            <b-row>
              <b-col cols="2">
                <h3 style="color: #f9f9fa"><i class="fas fa-user-astronaut"></i> Leads: {{ totalItems }}</h3>
              </b-col>
              <b-col cols="6">
                <b-input-group class="mb-2">
                  <b-form-input
                    v-model="searchTerm"
                    type="search"
                    id="filterInput"
                    placeholder="Digita per Ricercare"
                    @keydown.enter.native="searchAndFilterLead"
                    size="sm"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button @click="searchAndFilterLead" size="sm">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
                <small class="text-muted text-secondary">
                  Campi di ricerca:
                  Nome, Cognome, Email, Nome Istituto Scolastico,Open Day, Fiera, DEM
                </small>
              </b-col>
              <b-col cols="4">
                <b-button v-b-toggle.collapse-1 variant="danger" size="sm" class="float-right ml-1" @click="clearRangeFilter">Filtri&Ricerche</b-button>
                <b-button class="float-right ml-1" size="sm" variant="giallo" @click="handleDownload">
                  <b-spinner small v-if="loadingSpinnerExportExcel"></b-spinner>
                  Esporta Lead</b-button>
                <b-button variant="success" size="sm" class="float-right" v-b-toggle.SidebarAddLead>Aggiungi Lead</b-button>
                <b-button size="sm" class="mb-2" @click="InitLead">
                  <i class="fas fa-sync-alt"></i> Refresh
                </b-button>
              </b-col>
              <!--                                <b-link style="color: #FFFFFF" v-b-modal.openDouble @click="findDupt">Trova Duplicati</b-link> <b style="color: #FFFFFF">| </b>-->
              <!--                                <b-link style="color: #FFFFFF" @click="associaScuolaConLeads">Associa Lead</b-link> <b style="color: #FFFFFF">| </b>-->
              <!--                                <b-link style="color: #FFFFFF" @click="filtraLeadDeactive">-->
              <!--                                    Nuovi Leads <span class='badge badge-warning' id='CartCount' v-if="this.showBadgeNewLead === true && this.tempoPerCheckNuoviLead >= 0 ">({{ this.tempoPerCheckNuoviLead }})</span>-->
              <!--                                </b-link> <b style="color: #FFFFFF">| </b>-->
              <!--                                <b-link v-if="filters" @click="InitLead()" style="color: #ff9900">Rimuovi Filtri</b-link>-->
              <!--                                <b-link v-if="actionLead" style="color: #ff0000" v-b-modal.deleteLead>Elimina</b-link> |-->
              <!--                                <b-link v-if="actionLead" style="color: #ff9900" @click="associaScuolaConLeads">Associa</b-link>-->

            </b-row>
            <b-row>
              <b-col cols="12">
                <b-collapse id="collapse-1" class="mt-2">
                  <b-row>
                    <b-col cols="2" class="offset-lg-0 border-left pl-2 ml-2 border-warning">
                      <b-form-group class="ml-0 font-italic" label="Per Eventi:" style="color: white" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-2"
                          v-model='tipo_evento'
                          :aria-describedby="ariaDescribedby"
                          name="openday"
                          unchecked-value=""
                          value="Open Day"
                          @change="searchAndFilterLead"
                        >Open Day</b-form-checkbox>
                        <b-form-checkbox
                          v-if="tipo_evento === 'Open Day'"
                          class="ml-4"
                          style="color: white"
                          id="checkbox-2.1"
                          v-model="od_modalita_di_partecipazione"
                          :aria-describedby="ariaDescribedby"
                          name="openday-online"
                          unchecked-value=""
                          value="On Line"
                          @change="searchAndFilterLead"
                        >On Line</b-form-checkbox>
                        <b-form-checkbox
                          v-if="tipo_evento === 'Open Day'"
                          class="ml-4"
                          style="color: white"
                          id="checkbox-2.2"
                          v-model="od_modalita_di_partecipazione"
                          :aria-describedby="ariaDescribedby"
                          name="openday-presenza"
                          unchecked-value=""
                          value="Presenza"
                          @change="searchAndFilterLead"
                        >Presenza</b-form-checkbox>
                        <b-form-checkbox
                          v-if="tipo_evento === 'Open Day'"
                          class="ml-5"
                          style="color: white"
                          id="checkbox-2.2.1"
                          v-model="ha_partecipato"
                          :aria-describedby="ariaDescribedby"
                          name="ha-partecipato-presenza"
                          unchecked-value=""
                          value="True"
                          @change="searchAndFilterLead"
                        >Ha Partecipato?</b-form-checkbox>
                        <b-form-checkbox
                          v-if="tipo_evento === 'Open Day'"
                          class="ml-5"
                          style="color: white"
                          id="checkbox-2.1.2"
                          v-model="od_test_iscrizione"
                          :aria-describedby="ariaDescribedby"
                          name="test-ammissione"
                          value="True"
                          @change="searchAndFilterLead"
                        >Test Ammissione</b-form-checkbox>
                        <b-form-select
                          class="w-75 ml-5"
                          v-if="od_test_iscrizione"
                          size="sm"
                          v-model="tst_nome"
                          :options="sessioni_test"
                          text-field="nome_sessione"
                          value-field="nome_sessione"
                          @change="searchAndFilterLead"
                        >
                          <b-form-select-option value="" autofocus>scegli un test ...</b-form-select-option>
                        </b-form-select>
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-4"
                          v-model="filter4"
                          :aria-describedby="ariaDescribedby"
                          name="evento_istituto"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >Evento Istituto</b-form-checkbox>
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-4.1"
                          v-model="tipo_evento"
                          :aria-describedby="ariaDescribedby"
                          name="test"
                          value="Test"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >Test</b-form-checkbox>
                        <b-form-checkbox
                          class="ml-4"
                          v-if="tipo_evento === 'Test'"
                          style="color: white"
                          id="checkbox-4.1.1"
                          v-model="tst_ha_pagato"
                          :aria-describedby="ariaDescribedby"
                          name="test-pagato"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >Test Pagato</b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="1" class="offset-lg-0 border-left pl-2 ml-2 border-warning">
                      <b-form-group class="font-italic" label="Per Sede:" style="color: white" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-5"
                          v-model="f_sede_interesse"
                          :aria-describedby="ariaDescribedby"
                          name="sede_interesse"
                          value="Torino"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >Torino</b-form-checkbox>
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-6"
                          v-model="f_sede_interesse"
                          :aria-describedby="ariaDescribedby"
                          name="sede_interesse"
                          value="Firenze"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >Firenze</b-form-checkbox>
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-7"
                          v-model="f_sede_interesse"
                          :aria-describedby="ariaDescribedby"
                          name="sede_interesse"
                          value="Mantova"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >Mantova</b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="1" class="offset-lg-0 border-left pl-2 ml-2 border-warning">
                      <b-form-group class="font-italic" label="Per Anno:" style="color: white" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-11"
                          v-model="anno_interesse"
                          :aria-describedby="ariaDescribedby"
                          name="anno_interesse"
                          value="2021-2022"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >2021/2022</b-form-checkbox>
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-8"
                          v-model="anno_interesse"
                          :aria-describedby="ariaDescribedby"
                          name="anno_interesse"
                          value="2022-2023"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >2022/2023</b-form-checkbox>
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-9"
                          v-model="anno_interesse"
                          :aria-describedby="ariaDescribedby"
                          name="anno_interesse"
                          value="2023-2024"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >2023/2024</b-form-checkbox>
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-10"
                          v-model="anno_interesse"
                          :aria-describedby="ariaDescribedby"
                          name="anno_interesse"
                          value="2024-2025"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >2024/2025</b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="1" class="offset-lg-0 border-left pl-2 ml-2 border-warning">
                      <b-form-group class="font-italic" label="Per Tipo Ev.:" style="color: white" v-slot="{ ariaDescribedby }">
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-12"
                          v-model="evento_followup"
                          :aria-describedby="ariaDescribedby"
                          name="Evento"
                          value="evento"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >Evento</b-form-checkbox>
                        <b-form-checkbox
                          style="color: white"
                          id="checkbox-121"
                          v-model="evento_followup"
                          :aria-describedby="ariaDescribedby"
                          name="FollowUp"
                          value="followup"
                          unchecked-value=""
                          @change="searchAndFilterLead"
                        >FollowUp</b-form-checkbox>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2" class="offset-lg-0 border-left pl-2 ml-2 border-warning">
                      <b-form-group
                        class="font-italic"
                        label="Per data:"
                        style="color: white"
                      >
                        <date-range-picker
                          v-model="dateRange"
                          :lang="lang"
                          class="w-auto"
                          @update="searchAndFilterLead"
                        >
                          <template v-slot:input="picker" style="min-width: 350px;">
                            {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                          </template>
                        </date-range-picker>
                      </b-form-group>
                      <b-button class="mt-2" variant="yellow" size="sm" @click="clearRange">Clear Range</b-button>
                    </b-col>
                    <b-col cols="2" class="offset-lg-0 border-left pl-2 ml-2 border-warning">
                      <span style="color: #FFFFFF">Stai filtrando <span style="color: #f4c414">{{ totalItems }}</span> leads per:<br></span>
                      <span v-if="listaPersonale.id > 0" class="text-white">Perchè hai selezionato una lista personale: <span class="text-danger">{{ listaPersonale.nome }}</span></span>
                      <span style="color: #FFFFFF" v-if="tipo_evento">Evento: <b-badge>{{tipo_evento}}</b-badge><br></span>
                      <span style="color: #FFFFFF" v-if="od_modalita_di_partecipazione">modalità di partecipazione: <b-badge class="ml-1" >{{od_modalita_di_partecipazione}}</b-badge><br></span>
                      <span style="color: #FFFFFF" v-if="ha_partecipato">che abbia <b-badge class="ml-1" >partecipato</b-badge><br></span>
                      <span style="color: #FFFFFF" v-if="od_test_iscrizione">che abbia richiesto il: <b-badge class="ml-1" >test ammissione</b-badge><br></span>
                      <span style="color: #FFFFFF" v-if="f_sede_interesse">per la Sede di: <b-badge class="ml-1" >{{ f_sede_interesse}}</b-badge><br></span>
                      <span style="color: #FFFFFF" v-if="dateRange.startDate">range di date  tra il
                                                <b-badge class="ml-1" >{{ dateRange.startDate | formatDate }}</b-badge> e il <b-badge class="ml-1" >{{ dateRange.endDate | formatDate}}</b-badge><br>
                                            </span>
                      <span style="color: #FFFFFF" v-if="evento_followup">tutti quello che abbiano almeno 1 <b-badge>{{evento_followup}}</b-badge> assegnato<br></span>
                      <span style="color: #FFFFFF" v-if="anno_interesse">interessati all'anno  <b-badge>{{anno_interesse}}</b-badge><br></span><br>
                      <b-button class="mt-2" variant="yellow" size="sm" @click="clearFilter">Clear Filter</b-button>
                    </b-col>
                    <b-col cols="2" class="float-right">
                      <ListePersonali
                        :refreshList=false
                        @callListaPersonale="callListaPersonale"
                        @clearFilter="clearFilter"

                        @searchAndFilterLeadwithPagination="searchAndFilterLeadwithPagination">
                      </ListePersonali>
                      <!--                                            <b-card bg-variant="dark lighten-3" border-variant="warning">-->
                      <!--                                              <span class="text-white">Liste Personali:</span>-->
                      <!--                                              <div v-for="(list, index) in listaleads" :key="index" class="text-white">-->
                      <!--                                                <b-link @click="callListaPersonale(list)" class="text-white"> {{index + 1}}) {{ list.nome }}</b-link>-->
                      <!--                                                <b-link class="float-lg-right small text-white" @click="eliminaLista(list)"><i class="fas fa-trash"></i></b-link>-->
                      <!--                                              </div>-->
                      <!--&lt;!&ndash;                                                <span style="color: white">Ultimo Lead Modificato:</span><br>-->
                      <!--                                                <span style="color: white">Nome: {{ $store.state.lead.lead.lead_nome }}</span><br>-->
                      <!--                                                <span style="color: white">Cognome: {{ $store.state.lead.lead.lead_cognome }}</span><br>-->
                      <!--                                                <span style="color: white">Email: <b-link-->
                      <!--                                                    v-model="searchTerm"-->
                      <!--                                                    @click="searchLead($store.state.lead.lead.lead_email)">{{ $store.state.lead.lead.lead_email }}</b-link></span>&ndash;&gt;-->
                      <!--                                            </b-card>-->
                    </b-col>
                  </b-row>
                  <h6 style="color: white">
                  </h6>
                </b-collapse>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="mt-2" no-body>
            <b-table
              id="LeadTable"
              v-if="lead"
              :items="lead"
              :fields="computedFields"
              class="font-weight-light"
              hover
              striped
              outlined
              foot-clone
              select-mode="multi"
              :sort-by.sync="sortBy"
              :per-page="0"
              head-variant="dark"
              ref="tableLead"
              @row-dblclicked="editLead"
            >
              <template #thead-top="">
                <b-tr>
                  <b-th colspan="6">
                    <b-link @click="modificaLeadSelezionati" class="text-warning">Modifica Lead Selezionati</b-link> |
                    <b-link @click="creaUnaListaPersonale" class="text-warning">Crea Una Lista Personale</b-link>
                    <span v-if="leadsChecked.length > 0 "> | Hai selezionato {{ leadsChecked.length }} leads</span>
                  </b-th>
                  <b-th colspan="6">
                  </b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                  <b-th></b-th>
                </b-tr>
              </template>
              <template #head(selected)="">
                <b-form-checkbox v-model="select" @change="selectAllItems">
                </b-form-checkbox>
              </template>
              <template #cell(selected)="row" style="max-width: 30px">
                <b-form-group>
                  <b-form-checkbox v-model="row.item.selected" @change="onLeadSelected($event, row.item)"></b-form-checkbox>
                </b-form-group>
              </template>
              <template #cell(numero_eventi)="row">
                <b-button style="background-color: #0cd096" v-if="row.item.numero_eventi > 0" v-b-tooltip.hover title="Numero Eventi" size="sm" @click="getEventi(row.item)">
                  <strong>{{ row.item.numero_eventi }}</strong>
                </b-button>
              </template>
              <template #cell(follow_up)="row">
                <div v-if="row.item.follow_up.chiusi < row.item.follow_up.follow">
                  <b-button
                    size="sm"
                    :variant="row.item.follow_up.chiusi ===
                                                  row.item.follow_up.follow - 1 ? 'yellow':'danger'" v-b-tooltip.hover
                    :title="`aperti ${row.item.follow_up.aperti} - chiusi ${row.item.follow_up.chiusi}`" @click="getFollowUp(row.item)">
                    <strong>{{ row.item.follow_up.follow }}</strong>
                  </b-button>
                </div>
                <div v-else-if="row.item.follow_up.follow === 0" style="text-align: center; vertical-align: text-bottom">
                  <b-link style="color: #afadad; vertical-align: text-bottom;" @click="addFollowUp(row.item)"><i class="fas fa-calendar-plus">
                  </i></b-link>
                </div>
                <div v-else-if="row.item.follow_up.chiusi === row.item.follow_up.follow">
                  <b-button size="sm" variant="dark"  v-b-tooltip.hover
                            :title="`aperti ${row.item.follow_up.aperti} - chiusi ${row.item.follow_up.chiusi}`" @click="getFollowUp(row.item)">
                    <strong>{{ row.item.follow_up.follow }}</strong>
                  </b-button>
                </div>
              </template>
              <template #cell(anno_di_interesse)="row">
                <h5><b-badge>{{ reduceYear(row.item.anno_di_interesse) }}</b-badge></h5>
              </template>
              <template #cell(full_name)="row">
                <b-link v-b-tooltip.hover title="Modifica Lead" style="color: #050505; border-bottom: 1px dotted; text-decoration: none " @click="editLead(row.item)">{{row.item.full_name}}</b-link>
              </template>
              <template #cell(lead_email)="row">
                <div  @click="copyEmail(row.item)">
                  <b-link v-b-tooltip.hover title="Copia Email Lead" style="color: #050505; border-bottom: 1px dotted; text-decoration: none">{{ row.item.lead_email}}</b-link>
                </div>
                <small style="color: #70695a" v-if="row.item.lead_indirizzo">{{row.item.lead_indirizzo}}</small>
                <small style="color: #70695a" row.item.lead_comune> {{row.item.lead_comune}}</small>
              </template>
              <template #cell(istituto_scolastico)="row" class="w-auto">
                <div
                  style="color: #050505"
                  v-if="row.item.istituto_scolastico"
                  v-b-tooltip.hover
                  :title="row.item.istituto_scolastico">
                  {{ truncateField(row.item.istituto_scolastico)}}...
                </div>
                <small style="color: #70695a" v-if="row.item.istituto_classe">classe: {{row.item.istituto_classe}}</small>
              </template>
              <template #cell(lead_cellulare)="row">
                <div style="color: #050505">{{ row.item.lead_cellulare}}</div>
                <small style="color: #70695a" v-if="row.item.lead_cf">CF: {{row.item.lead_cf}}</small>
              </template>
              <template #cell(active)="row">
                <div v-if="row.item.active === true">
                  ok
                </div>
              </template>
              <template #cell(cognome)="row">
                {{ row.item.cognome | uppercase(true) }}
                <b-badge variant="danger" v-if="today === row.item.create_at">NEW</b-badge>
              </template>
              <template #cell(checkbox)="row">
                <b-form-checkbox @change="leadSelected(row.item.id)"></b-form-checkbox>
              </template>
              <template #cell(ultima_dem)="row">
                                    <span :id="row.item.email" v-if="row.item.ultima_dem" style="color: teal; align-items: center">
                                        <b-tooltip :target="row.item.lead_email">{{ row.item.ultima_dem }}</b-tooltip>
                                        <i class="fa fa-check-circle "></i>
                                    </span>
              </template>
              <template #cell(ti_fiera)="row">
                                    <span :id="row.item.ti_fiera" v-if="row.item.ti_fiera" style="color: teal; align-items: center; text-transform: capitalize">
                                        <b-tooltip :target="row.item.ti_fiera">{{ row.item.ti_fiera }}</b-tooltip>
                                        <i class="fa fa-check-circle "></i>
                                    </span>
              </template>
              <template #cell(associa_scuola)="row">
                  <b-link v-if="row.item.associa_scuola" @click.prevent="onRowSelected(row.item)">
                    <h6><span style="color: #3d801c;">
                    <i class="fas fa-university"></i>
                  </span>
                    </h6>
                  </b-link>
                 <b-link  v-else @click.prevent="onRowSelected(row.item)">
                   <h6 style="color: #afadad;">
                                      <span>
                    <i class="fas fa-university"></i>
                  </span>
                   </h6>
                 </b-link>
              </template>
              <template #cell(sede_interesse)="row">
                <h6><b-badge
                  :variant="row.item.sede_interesse === 'Firenze' ? 'dark':(row.item.sede_interesse === 'Torino' ? 'pink':(row.item.sede_interesse === 'Mantova' ? 'yellow':''))"
                  v-if="row.item.sede_interesse">{{ row.item.sede_interesse }}
                </b-badge></h6>
              </template>
              <template #cell(coinvolgimento)="row" >
                <b-button size="sm" variant="dark" style="color: #fff;" v-if="row.item.coinvolgimento <= 45 && row.item.coinvolgimento > 0" v-b-tooltip.hover title="Coinvolgimento">{{ row.item.coinvolgimento}}%</b-button>
                <b-button size="sm" variant="teal" style="color: #fff;"  v-if="row.item.coinvolgimento >= 46">{{ row.item.coinvolgimento}}</b-button>

              </template>
              <template #cell(ti_email_chiamata)="row">
                <span v-if="row.item.ti_email_chiamata" style="color: #008000; align-items: center"><i class="fa fa-check-circle "></i>xx</span>
              </template>
              <template #cell(lead_convertito_in_studente)="row">
                <span v-if="row.item.lead_convertito_in_studente" style="color: teal; align-items: center"><i class="fa fa-check-circle "></i></span>
              </template>
              <template #cell(ti_test)="row">
                <span v-if="row.item.ti_test === true" style="color: teal; align-items: center"><i class="fa fa-check-circle "></i></span>
              </template>
              <template #cell(ti_partecipazione_od)="row">
                                    <span v-if="row.item.ti_partecipazione_od" style="color: teal; align-items: center">
                                        <i class="fa fa-check-circle "></i></span>
              </template>
              <template #cell(ti_colloquio)="row">
                <span v-if="row.item.ti_colloquio === true" style="color: teal; align-items: center"><i class="fa fa-check-circle "></i></span>
              </template>
              <template #cell(ti_chat)="row">
                <span v-if="row.item.ti_chat === true" style="color: teal; align-items: center"><i class="fa fa-check-circle "></i></span>
              </template>
              <template #cell(ti_open_day)="row">
                <span v-if="row.item.ti_open_day === true" style="color: teal; align-items: center"><i class="fa fa-check-circle "></i></span>
              </template>
              <template #cell(action)="row">
                <b-link @click="onRowSelected(row)" style="text-align: center; color: #3a3f51">Edit</b-link>
              </template>
              <template #cell(actions)="row">
                <b-dropdown dropleft size="sm"  variant="dark" toggle-class="text-decoration-none" no-caret >
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <b-dropdown-item @click="editLead(row.item)"><i class="fas fa-edit mr-2"></i>Modifica Lead</b-dropdown-item>
                  <b-dropdown-item style="color: #d36363" v-b-modal.scelta_anno_immatricolazione @click="tmpLead(row)"><i class="fas fa-user-graduate"></i>Converti in Studente</b-dropdown-item>
                  <b-dropdown-item @click="eliminaLead(row.item.id)" variant="danger"><i class="fas fa-trash mr-2"></i>Elimina Lead</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="getEventi(row.item)"><i class="fas fa-eye"></i> Mostra Eventi</b-dropdown-item>
                  <b-dropdown-item @click="addEvento(row.item)"><i class="fas fa-calendar-check"></i> Aggiungi Evento</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="addFollowUp(row.item)"><i class="fas fa-phone"></i> Aggiungi FollowUp</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
            <b-row>
              <b-col cols="4">
                <b-pagination
                  class="mr-3"
                  size="sm"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  v-model="currentPage"
                  limit="10"
                  next-text="next"
                  prev-text="previous"
                  @change="searchAndFilterLeadwithPagination"
                  aria-controls="LeadTable"
                >
                </b-pagination>
              </b-col>
              <b-col cols="3" class="float-left">
                <div style="margin:auto;text-align: left;">
                  <ul class="pagination">
                    <li class="page-item active text-muted text-underline" size="sm">Pagine: {{ parseInt(totalItems / this.perPage).toFixed(0) }}</li>
                    <li class="page-item active text-muted text-underline" size="sm">Lead Totali: {{totalItems}}</li>
                  </ul>
                </div>
              </b-col>
              <b-col cols="3">
                <b-form-select :options="pageOptions" v-model="perPage" class="col-md-3" size="sm"/>
                Lead per Pagina
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <b-modal id="openDouble" size="xl" :title="`Trovati ${doubleLead.length} Lead Duplicati `" header-bg-variant="dark" header-text-variant="white">
      <b-table
        :items="doubleLead"
        :fields="doubleFields"
        small
        striped
        hover
      ><template #cell(action)="row">
        <b-link @click="getDupt(row.item.email)" class="mr-2"><i class="fa fa-eye" style="color: #3a3f51"></i></b-link>
        <b-link @click="deleteLead(row.item)"><i class="fa fa-trash" style="color: #d36363"></i></b-link>
      </template>
      </b-table>
    </b-modal>

    <b-modal size="lg" id="modal-ricerca" title="Ricerca Scuole" hide-footer header-bg-variant="dark"
             header-text-variant="white" no-fade>
      <search-scuole/>
    </b-modal>
    <b-modal size="lg" id="get_double_leads" title="Confronto Lead Duplicati" scrollable header-bg-variant="dark" header-text-variant="white">
      <b-row cols="12" >
        <b-col cols="6" v-for="double in this.get_double_leads" :key="double.id">
          <b-card>
            <b-row><b-col class="float-left"><b-link>edit[WIP]</b-link></b-col></b-row>
            <div v-for="(v, k ) in double" :key="k">
              <b>{{ k }}</b> <span>{{ v }}</span>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="deleteLead" @ok="deleteLead" title="Cancella Lead" header-bg-variant="dark" header-text-variant="white" body-bg-variant="white" no-fade>
      Sei sicuro di voler cancellare questo Lead?
    </b-modal>
    <b-modal id="matricola_lead" header-bg-variant="dark" header-text-variant="white" title="Nuovo Studente Creato">
      Creato un nuovo studente con matricola numero:
      <b>{{ this.matricola}}</b>
    </b-modal>
    <b-modal id="modalEventi" size="xl" header-bg-variant="dark" header-text-variant="white" title="Eventi" @ok="InitLead" @close="InitLead">
      <table-evento-lead :l_id="this.l_id" :show="showModaleEventi" :get-element="sendElement" :full-name="full_name"></table-evento-lead>
      <template #modal-footer>
        <small class="text-muted float-left">Nome Pagina: src/pages/crm/lead/listaLead.vue</small>
        <small class="float-right">account: {{ $store.state.auth.user.email }}</small>
        <b-button size="sm" type="reset" variant="dark" class="mr-2 float-right" @click="chiudiModalEventi">Chiudi</b-button>
      </template>
    </b-modal>
    <b-modal
      id="mostraFollowUp"
      size="xl"
      header-bg-variant="dark"
      header-text-variant="white"
      title="Lista FollowUp"
      @ok="closeAndRefreshFollowUp">
      <table-followup-lead ref="table-followup" :item="leadFollowUp" @refreshLead="this.InitLead"></table-followup-lead>
    </b-modal>
    <lead-evento v-if="showEvento" id="aggiungi-evento" ref="eve" :l_id="l_id" :modeBulk="modeBulk" @hide="getEventi()"  @hidden="resetInfoModal"></lead-evento>
    <modale-followup-lead ref="fup" :item="leadFollowUp" @refreshLead="this.InitLead"></modale-followup-lead>
    <b-modal id="scelta_anno_immatricolazione" title="Scegli Anno di Immatricolazione" @ok="convertLeadToStudent">
      <b-form-group>
        <b-form-select v-model="anno_immatricolazione_selected" :options="anno_immatricolazione" text-field="text" value-field="text">
        </b-form-select>
      </b-form-group>
      {{ anno_immatricolazione_selected }}
    </b-modal>
    <!--FIXME trasferire la modale nel componente-->
    <b-modal
      id="modaleBulkLead"
      title="Azione sui Lead Selezionati"
      header-bg-variant="dark"
      header-text-variant="white"
      size="lg"
      @ok="azioneSuLeadSelezionati">
      <b-table
        id="tableLeadSelezionati"
        striped
        small
        :items="leadsChecked"
        :fields="fieldsList"
        :per-page="perPageSelectedLead"
        :current-page="currentPageSelectedLead"
        selectable
        select-mode="multi">
      </b-table>
      <b-row>
        <b-col class="d-inline-flex">
          <b-pagination
            v-model="currentPageSelectedLead"
            :total-rows="rowsLeadsCheckedTotal"
            :per-page="perPageSelectedLead"
            limit="10"
            size="sm"
            aria-controls="tableLeadSelezionati"
          ></b-pagination>
          <span class="ml-lg-5">Pagine: {{ pagesLeadsCheckedTotal }}</span>
        </b-col>
      </b-row>

      <hr>
      <b-form-group label-for="Scegli Azione">
        <b-form-select v-model="bulkSelected" id="Scegli Azione">
          <b-form-select-option value="rimuovi_da_lista">Rimuovi dalla Lista</b-form-select-option>
          <b-form-select-option value="aggiungi_alla_lista">Aggiungi alla Lista</b-form-select-option>
          <b-form-select-option value="bulk_evento">Associa Evento</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group label="Scegli la lista"  v-if="bulkSelected === 'aggiungi_alla_lista'">
        <b-form-select
          v-model="listaSelezionataInModale"
          :options="listaleads"
          text-field="nome"
          value-field="id"
        ></b-form-select>
      </b-form-group>
      <b-form-group v-if="bulkSelected === 'bulk_evento'">
        <b-card>
          <b-link @click="apriModaleEvento(leadsChecked)">Apri la modale Evento</b-link>
        </b-card>
      </b-form-group>
    </b-modal>
    <!--FIXME trasferire la modale nel componente-->
    <b-modal
      id="creaListaLeads"
      title="Lista Personale"
      header-bg-variant="dark"
      header-text-variant="white"
      @ok="salvaListaPersonale">
      <b-alert v-if="leadsChecked.length < 1" show bg-variant="unicollege" class="text-xl-center"><span class="text-underline text-uppercase">Per Creare una lista personale prima devi selezionare dei Leads</span></b-alert>
      <b-form-group class="text-muted" label="Dai un nome alla lista:">
        <b-form-input v-on:keyup.enter="salvaListaPersonale" v-model="nomeLista"></b-form-input>
      </b-form-group>
    </b-modal>
    <associa-scuole-a-lead
      id="associaScuola"
      :leadSelezionato="singleLead"
      :leadsSelezionati="multiSelectLead"
      @refresLead="searchAndFilterLeadwithPagination">
    </associa-scuole-a-lead>
    <sidebar-add-lead id="SidebarAddLead"></sidebar-add-lead>
    <sidebar-edit-lead v-if="Object.keys(dati).length > 0" ref="SidebarEditLead" id="SidebarEditLead" :dati="dati"
                       @alertEditSuccess="leadModificatoFunction"
                       @sidebarClosed="dati = {}"></sidebar-edit-lead>
  </div>
  <!-- END #content -->
</template>

<script>
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import 'vue2-datepicker/locale/it';
import DateRangePicker from "vue2-daterange-picker";
import FileSaver from 'file-saver'
import LeadEvento from "@/components/crm/evento/lead/ModaleEventoLead";
import LeadService from '@/services/lead.service'
import ListaLead from "@/services/listleads.service";
import ListaLeadService from '@/services/listleads.service'
import ListePersonali from "@/components/crm/ListePersonali";
import ModaleFollowupLead from "../../../components/crm/followup/lead/ModaleFollowupLead";
import SidebarAddLead from "@/components/crm/lead/SidebarAddLead"
import SidebarEditLead from "@/components/crm/lead/SidebarEditLead";
import StudentiService from "../../../services/studenti.service";
import Swal from "sweetalert2";
import TableEventoLead from "../../../components/crm/evento/lead/TableEventoLead";
import TableFollowupLead from "../../../components/crm/followup/lead/TableFollowupLead";
import TestService from '@/services/universita/test.service'
import UserService from '@/services/user.service'
import alertSuccess from "@/components/utility/alertSuccess";
import moment from 'moment'
import searchScuole from "@/components/crm/searchScuole";
import {formatDate} from "@fullcalendar/vue";
import AssociaScuoleALead from "@/components/lead/AssociaScuoleALead";
export default {
  name: "ListaLead",
  components: {
    AssociaScuoleALead,
    DateRangePicker,
    LeadEvento,
    ListePersonali,
    ModaleFollowupLead,
    SidebarAddLead,
    SidebarEditLead,
    TableEventoLead,
    TableFollowupLead,
    alertSuccess,
    searchScuole,
  },
  data(){
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 6);
    return {
      today: moment(new Date()).format('DD-MM-YYYY'),
      darkMode: false,
      dark: false,
      actionLead: false,
      doubleFields: [
        {key: 'nome', label: 'Nome', sortable: false},
        {key: 'cognome', label: 'Cognome', sortable: false},
        {key: 'telefono', label: 'Cell.', sortable: false},
        {key: 'email', label: 'Email', sortable: true},
        {key: 'cf', label: 'CF', sortable: false},
        {key: 'istituto_scolastico', label: 'Istituto', sortable: false},
        {key: 'action', label: 'Actions'},
      ],
      // checkbox: true,
      // Pagination Lead
      currentPage: 1,
      page: null,
      pageOptions: [10, 25, 50, 100, 250],
      perPage: 25,
      next: '',
      previous: '',
      totalItems: 0,
      // Pagination Selected Lead in modale
      currentPageSelectedLead:1,
      perPageSelectedLead: 15,
      pageSelectedLead: 1,
      doubleLead: [],
      // Variabili d'appoggio
      // Istituto
      sortBy: 'cognome',
      sortDesc: false,
      lead: [],
      // modale di bulk
      leads: [],
      actions: [
        {value: null, text: 'Please select some item'},
        {key: 'modale-evento', label: 'Evento'},
        {key: 'modale-evento', label: 'Interazione'},
        {key: 'followup', label: 'FollowUp'},
      ],
      modeBulk: false,
      matricola: '',
      fields: [
        {key: 'selected', label: '', thClass: 'font-weight-light'},
        {key: 'numero_eventi', label: 'Ev.', thClass: 'font-weight-light'},
        {key: 'follow_up', label: 'Fol.', thClass: 'font-weight-light'},
        {key: 'anno_di_interesse', label: 'Anno', sortable: true, thClass: 'font-weight-light'},
        {key: 'full_name', label: 'FullName', sortable: true, thClass: 'font-weight-light'},
        {key: 'lead_email', label: 'Email', sortable: false, thClass: 'font-weight-light'},
        {key: 'istituto_scolastico', label: 'Istituto', sortable: true, thClass: 'font-weight-light'},
        {key: 'lead_cellulare', label: 'Cellulare', class: 'text-center', thClass: 'font-weight-light'},
        {key: 'lead_convertito_in_studente', label: 'Studente', class: 'text-center', sortable: true, thClass: 'font-weight-light'},
        {key: 'ti_fiera', label: 'Fiera', class: 'text-center', sortable: true, thClass: 'font-weight-light'},
        //{key:'ti_webinar', label: 'Webinar', class: 'text-center', sortable:true },
        {key: 'ti_test', label: 'TA', class: 'text-center', sortable: true, thClass: 'font-weight-light'},
        {key: 'ti_open_day', label: 'OD', class: 'text-center', sortable: true, thClass: 'font-weight-light'},
        {key: 'ti_partecipazione_od', label: 'POD', class: 'text-center', sortable: true, thClass: 'font-weight-light'},
        {key: 'associa_scuola', label: 'A', sortable: false, class: "text-center", thClass: 'font-weight-light'},
        {key: 'sede_interesse', label: 'S', sortable: true, class: "text-center", thClass: 'font-weight-light'},
        {key: 'coinvolgimento', label: '%', sortable: true, thClass: 'font-weight-light'},
        {key: 'actions', label: 'A', thClass: 'font-weight-light'}
      ],
      allSelected: [],
      dati: {},
      singleLead: null,
      selected: null,
      get_double_leads: [],
      // SPINNER
      loadingSpinnerExportExcel: false,
      // multiSelectLead: {
      //     'ids': [],
      //     'scuola': null,
      // },
      multiSelectLead: {
        lead_ids: [],
        associa_scuola: null,
      },
      leadAssociato: false,
      leadModificato: false,
      exportLead: [],
      idScuolaSelezionata: null,
      leadsNew: 0,
      tempoPerCheckNuoviLead: null,
      // CONTROLLER
      busy: false,
      showBadgeNewLead: false,
      filterScuole: null,
      overlay: true,
      showAlert: false,
      uploadPercentage: 0,
      // SEARCH & FILTER
      searchActive: false,
      searchTerm: '',
      filters: false,
      filter: null,
      // FILTRI OPEN DAY
      tipo_evento: '',
      evento_followup: '',
      od_iscrizione: '',
      ha_partecipato: '',
      od_accompagnatori: '',
      od_modalita_di_partecipazione: '',
      od_test_iscrizione: '',
      // FILTRI TEST AMMISSIONE
      tst_nome: '',
      f_sede_interesse: '',
      f_evento: "",
      f_interazione: "",
      f_followup: "",
      filter4: '',
      tst_iscrizione: '',
      tst_ha_pagato: '',
      filter5: '',
      anno_interesse: '',
      filter7: '',
      filter8: '',
      anno: [
        {value: '2020-2021', text: '2020-2021'},
        {value: '2021-2022', text: '2021-2022'},
        {value: '2022-2023', text: '2022-2023'},
        {value: '2023-2024', text: '2023-2024'},
        {value: '2024-2025', text: '2024-2025'},
      ],
      anno_immatricolazione: [
        {value: "anno_2018_2019", text: "2018-2019"},
        {value: "anno_2019_2020", text: "2019-2020"},
        {value: "anno_2020_2021", text: "2020-2021"},
        {value: "anno_2021_2022", text: "2021-2022"},
        {value: "anno_2022_2023", text: "2022-2023"},
        {value: "anno_2023_2024", text: "2023-2024"},
        {value: "anno_2024_2025", text: "2024-2025"}
      ],
      anno_immatricolazione_selected: null,
      leadTemporary: {},
      // datePicker
      dateformat: 'MM/DD/YYYY',
      lang: 'it',
      dateRange: {
        startDate,
        endDate,
      },
      updateValues: '',
      // checkbox
      checked: this.selected,
      // eventi
      eventi: [],
      // LISTE LEADS
      listaSelezionataInModale: '',
      listaleads: [],
      l_id: [],
      select: false, // usata
      selectable: [], // usata dalla checkbox di ogni lead come variabile di appoggio
      leadsChecked: [], // lista di lead selezionati
      fieldsList: [
        {key: 'id', label: 'ID', sortable: false},
        {key: 'full_name', label: 'FullName', sortable: true},
        {key: 'lead_email', label: 'Email', sortable: false},
      ], // lista colonne nella tabella modifica lead
      bulkSelected: null,
      showModaleEventi: false,
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      sendElement: false, // serve per la richiesta della tableEventi dalla lista Lead
      full_name: '', // serve per la richiesta della tableEventi dalla lista Lead
      // followUp
      leadFollowUp: {},
      // settings
      refreshLead: this.$store.state.settings.leads,
      // sessioni
      sessioni_test: [],
      listaPersonale: [],
      nomeLista:'',
      idInListaPersonale: [],
      showEvento: false,
      // Evento
    }
  },
  watch: {
    od_test_iscrizione(value) {
      if(value === true){
        return null
      } else {
        this.tst_nome = ''
      }
    },
    tipo_evento(value) {
      if(value === true) {
        return null
      } else {
        this.od_modalita_di_partecipazione= ''
        this.tst_nome = ''
        this.ha_partecipato = ''
        this.od_test_iscrizione = ''
      }
    },
    od_modalita_di_partecipazione(value) {
      if(value === '') {
        this.tst_nome = ''
        this.ha_partecipato = ''
        this.od_test_iscrizione = ''
      } else {
        return null
      }
    },
    refreshLead(nValue) {
      if(nValue){
        this.InitLead()
      }
    },
    perPage(value) {
      console.log(value)
      this.searchAndFilterLeadwithPagination()
    },
    page() {
      this.select = false
    },
    // liste
    bulkSelected (val) {
      if (val === 'aggiungi_alla_lista') {
        this.initListaLead()
      }
    },
    listaPersonale (val) {
      console.log(this.listaPersonale.id != null)
      if (parseInt(val.id) >= 1) {
        this.searchAndFilterLeadwithPagination(this.page)
      }
    }
  },
  filters: {
    date (val) {
      return val ? val.toLocaleString() : ''
    },
    uppercase: function (value, onlyFirstCharacter) {
      if (!value) {
        return '';
      }
      value = value.toString();
      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      } else {
        return value.toUpperCase();
      }
    }
  },
  computed: {
    rowsLeadsCheckedTotal() {
      return this.leadsChecked.length
    },
    pagesLeadsCheckedTotal() {
      return (this.leadsChecked.length / this.perPageSelectedLead).toFixed(0)
    },
    returnPercent() {
      const leads = this.lead.filter(this.lead.coinvolgimento === 90)
      return leads.length
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    },

    computedFields() {
      // If the user isn't an admin, filter out fields that require auth.
      if (!this.checkbox)
        return this.fields.filter(field => !field.checkbox);
      // If the user IS an admin, return all fields.
      else
        return this.fields;
    },
    // selected() {
    //   return this.lead.filter(leads => leads.selected)
    // },
  },
  mounted() {
    this.numeroLeadDeactive()
    this.InitLead()
    this.showBadgeNewLead = false
    this.clearRange()
    this.initSessioni()
  },
  beforeDestroy() {
    clearInterval(this.tempoPerCheckNuoviLead)
  },
  methods: {
    apriModaleEvento(leadChecked) {
      const leads = leadChecked.map(item => item.id)
      this.l_id = leads
      this.showEvento = true
      console.log(leads)
    },
    initListaLead() {
      let params = {
        user: this.$store.state.auth.user.user_id
      }
      ListaLead.getListeLeads(params)
        .then(resp => {
          this.listaleads = resp.data.results
        })
        .catch()
    },
    callListaPersonale(items) {
      this.listaPersonale = items
    },
    azioneSuLeadSelezionati() {
      if (this.bulkSelected === 'rimuovi_da_lista') {
        let mapID = this.leadsChecked.map(items => items.id)
        const payload = {
          user: this.$store.state.auth.user.account_id,
          id: this.listaPersonale.id,
          nome: this.listaPersonale.nome,
          leads: mapID
        }
        ListaLead.modificaListaLead(payload).then(() => {
          this.selectable = []
          this.searchAndFilterLeadwithPagination(this.page)
        }).catch()
      } else {
        let mapID = this.leadsChecked.map(items => items.id)
        const payload = {
          user: this.$store.state.auth.user.account_id,
          id: this.listaSelezionataInModale,
          leads: mapID
        }
        ListaLead.addListaLead(payload).then(() => {
          this.selectable = []
          this.searchAndFilterLeadwithPagination(this.page)
        }).catch()
      }
    },
    selectAllItems(val) {
      this.lead.forEach(item => {
        item.selected = val
        this.onLeadSelected(val, item)
      })
    },
    truncateField(item) {
      return item.substring(0, 15)
    },
    clearFilter(){
      this.listaPersonale = 0
      this.tipo_evento='',
        this.dateRange={},
        this.evento_followup='',
        this.od_iscrizione= '',
        this.ha_partecipato= '',
        this.od_accompagnatori= '',
        this.od_modalita_di_partecipazione= '',
        this.od_test_iscrizione= '',
        // FILTRI TEST AMMISSIONE
        this.tst_nome= '',
        this.f_sede_interesse='',
        this.f_evento="",
        this.f_interazione="",
        this.f_followup="",
        this.filter4='',
        this.tst_iscrizione='',
        this.tst_ha_pagato='',
        this.anno_interesse='',
        this.InitLead()
    },
    reduceYear(item) {
      if(item) {
        return item.slice(2, 4) + '/' + item.slice(7, 9)
      }return ''

    },
    copyEmail(item){
      navigator.clipboard.writeText(item.lead_email)
      this.$bvToast.toast(`${item.lead_email}`, {
        title: 'Email copiata:',
        autoHideDelay: 5000,
        variant:'success'
      })
    },
    modificaLeadSelezionati() {
      // this.l_id = this.allSelected.map(select => select.id)
      this.l_id = this.allSelected
      this.$bvModal.show("modaleBulkLead")
    },
    // SEZIONE LISTA PERSONALE
    creaUnaListaPersonale() {
      this.l_id = this.leadsChecked
      this.$bvModal.show('creaListaLeads')
    },
    salvaListaPersonale(){
      const selezionatiUnoAUno = this.leadsChecked.map(item => item.id)
      console.log(selezionatiUnoAUno)
      const payload = {
        user: this.$store.state.auth.user.user_id,
        nome: this.nomeLista,
        leads: selezionatiUnoAUno
      }
      ListaLeadService.postListaLeads(payload)
        .then(() => {
          ListePersonali.refreshList = true
          this.$bvModal.hide('creaListaLeads')
        })
        .catch(error => {
          console.log(error)
        })
    },
    richiamaLeadDaListaPersonale(){
      this.overlay = true
      const leads_obj = this.$store.state.lead.lead
      const lead_array =  Object.values(leads_obj)
      const leads = []
      for(const lead in lead_array) {
        leads.push(lead)
      }

      console.log('dovrebbe essere un array', leads)
      const params = {
        lista_lead: [leads]

      }
      LeadService.getLead(params)
        .then(resp => {
          this.lead = this.appendSelected(resp.data.results)
          this.currentPage = resp.data.results.page
          this.totalItems = resp.data.total
          this.next = resp.data.links.next
          this.perPage = resp.data.page_size
          this.filters = false
          this.overlay = false
          //this.currentPage = currentPage
        })
        .catch()

    },
    openModal(){
      this.modeBulk = true
      this.$bvModal.show(`${this.bulkSelected}`)
      console.log(this.bulkSelected)
    },
    leadSelected(id) {
      if (this.multiSelectLead.lead_ids.includes(id)) {
        for (let i = 0; i < this.multiSelectLead.lead_ids.length; i++) {
          if (this.multiSelectLead.lead_ids[i] === id) {
            this.multiSelectLead.lead_ids.splice(i, 1);
            i--;
          }
        }
        this.multiSelectLead.lead_ids.splice(id)
      } else {
        this.multiSelectLead.lead_ids.push(id);
        this.actionLead = true
      }
    },
    numeroLeadDeactive() {
      LeadService.filterLeadDeactivate()
        .then(resp => {
          this.tempoPerCheckNuoviLead = resp.data.total
          this.showBadgeNewLead = true
        })
        .catch()

    },
    handleDownload() {
      this.loadingSpinnerExportExcel = true
      const params = {
        filter1: this.filter1,
        filter2: this.filter2,
        filter3: this.filter3,
        filter4: this.filter4,
        filter5: this.filter5,
        filter6: this.filter6,
      }
      console.log(params)
      LeadService.getExportLead(params)
        .then(response => {
          FileSaver.saveAs(response.data, 'leads.xlsx')
          this.loadingSpinnerExportExcel = false
        })
        .catch()
    },
    onRowSelected(row) {
      console.log('row: ', row)
      this.singleLead = row.id
      this.multiSelectLead.lead_ids = row.id
      this.selected = row
      this.$bvModal.show('associaScuola')
    },

    editLead(item){
      this.dati = item
      this.$nextTick(() =>{
        this.$root.$emit('bv::toggle::collapse', 'SidebarEditLead')
      })
    },
    InitLead() {
      this.overlay = true
      const params = {
        page_size: this.perPage
      }
      UserService.getLead(params)
        .then(resp => {
          this.lead = resp.data.results.reduce((struct, item) => {
            const newItem = Object.assign({}, item)
            newItem.selected = false;
            struct.push(newItem)
            return struct
          }, [])
          this.currentPage = resp.data.results.page
          this.totalItems = resp.data.total
          this.next = resp.data.links.next
          this.perPage = resp.data.page_size
          this.filters = false
          this.overlay = false
          //this.currentPage = currentPage
        })
    },
    initSessioni() {
      TestService.getSessione()
        .then(resp => {
          this.sessioni_test = resp.data
        })
        .catch()
    },
    searchAndFilterLeadwithPagination(pageNumber) {
      this.page = pageNumber
      this.overlay = true
      if(this.evento_followup === 'followup') {
        const params = {
          page: pageNumber,
          page_size: this.perPage,
          lista_leads: this.listaPersonale.id,
          // vecchi campi
          evento_followup: this.evento_followup,
          tipo_evento: this.tipo_evento,
          od_iscrizione: this.od_iscrizione,
          od_modalita_di_partecipazione: this.od_modalita_di_partecipazione, // od_modalita_di_partecipazione
          ha_partecipato: this.ha_partecipato, // od_ha_partecipato
          od_test_iscrizione: this.od_test_iscrizione,
          // tst_iscrizione: this.filter212, // tst_iscrizione
          tst_nome: this.tst_nome, // tst_nome
          tst_iscrizione: this.tst_iscrizione,
          tst_ha_pagato: this.tst_ha_pagato,
          sede_interesse: this.f_sede_interesse,
          anno_di_interesse: this.anno_interesse,
          followup_range: {
            start_date: formatDate(this.dateRange.startDate),
            end_date: formatDate(this.dateRange.endDate),
          },
          search: this.searchTerm
        }
        LeadService.filterLead(params)
          .then(resp => {
            this.lead = this.appendSelected(resp.data.results)
            this.currentPage = resp.data.page
            this.totalItems = resp.data.total
            this.next = resp.data.links.next
            this.perPage = resp.data.page_size
            this.filters = false
            this.overlay = false
          })
      } else {
        const params = {
          page: pageNumber,
          lista_leads: this.listaPersonale.id,
          page_size: this.perPage,
          // vecchi campi
          evento_followup: this.evento_followup,
          tipo_evento: this.tipo_evento,
          od_iscrizione: this.od_iscrizione,
          od_modalita_di_partecipazione: this.od_modalita_di_partecipazione, // od_modalita_di_partecipazione
          ha_partecipato: this.ha_partecipato, // od_ha_partecipato
          od_test_iscrizione: this.od_test_iscrizione,
          // tst_iscrizione: this.filter212, // tst_iscrizione
          tst_nome: this.tst_nome, // tst_nome
          tst_iscrizione: this.tst_iscrizione,
          tst_ha_pagato: this.tst_ha_pagato,
          sede_interesse: this.f_sede_interesse,
          anno_di_interesse: this.anno_interesse,
          evento_range: {
            start_date: formatDate(this.dateRange.startDate),
            end_date: formatDate(this.dateRange.endDate),
          },
          search: this.searchTerm
        }
        LeadService.filterLead(params)
          .then(resp => {
            this.lead = this.appendSelected(resp.data.results)
            this.currentPage = resp.data.page
            this.totalItems = resp.data.total
            this.next = resp.data.links.next
            this.perPage = resp.data.page_size
            this.filters = false
            this.overlay = false
          })
      }
    },
    searchAndFilterLead() {
      this.overlay = true
      if(this.evento_followup === 'followup'){
        const params = {
          evento_followup:this.evento_followup,
          tipo_evento:this.tipo_evento,
          od_iscrizione: this.od_iscrizione,
          od_modalita_di_partecipazione: this.od_modalita_di_partecipazione, // od_modalita_di_partecipazione
          ha_partecipato: this.ha_partecipato, // od_ha_partecipato
          od_test_iscrizione: this.od_test_iscrizione,
          // tst_iscrizione: this.filter212, // tst_iscrizione
          tst_nome: this.tst_nome, // tst_nome
          tst_iscrizione: this.tst_iscrizione,
          tst_ha_pagato: this.tst_ha_pagato,
          sede_interesse: this.f_sede_interesse,
          anno_di_interesse: this.anno_interesse,
          followup_range: {
            start_date: formatDate(this.dateRange.startDate),
            end_date: formatDate(this.dateRange.endDate),
          },
          search: this.searchTerm
        }
        LeadService.filterLead(params)
          .then(resp => {
            this.lead = this.appendSelected(resp.data.results)
            this.currentPage = resp.data.page
            this.totalItems = resp.data.total
            this.next = resp.data.links.next
            this.perPage = resp.data.page_size
            this.filters = false
            this.overlay = false
          })
      }

      const params = {
        evento_followup:this.evento_followup,
        tipo_evento:this.tipo_evento,
        od_iscrizione: this.od_iscrizione,
        od_modalita_di_partecipazione: this.od_modalita_di_partecipazione, // od_modalita_di_partecipazione
        ha_partecipato: this.ha_partecipato, // od_ha_partecipato
        od_test_iscrizione: this.od_test_iscrizione,
        // tst_iscrizione: this.filter212, // tst_iscrizione
        tst_nome: this.tst_nome, // tst_nome
        tst_iscrizione: this.tst_iscrizione,
        tst_ha_pagato: this.tst_ha_pagato,
        sede_interesse: this.f_sede_interesse,
        anno_di_interesse: this.anno_interesse,
        evento_range: {
          start_date: formatDate(this.dateRange.startDate),
          end_date: formatDate(this.dateRange.endDate),
        },
        search: this.searchTerm
      }
      LeadService.filterLead(params)
        .then(resp => {
          this.lead = this.appendSelected(resp.data.results)
          this.currentPage = resp.data.page
          this.totalItems = resp.data.total
          this.next = resp.data.links.next
          this.perPage = resp.data.page_size
          this.filters = false
          this.overlay = false
        })
    },
    checkEmail(){
      this.email_sendend = !this.email_sendend
    },
    getDupt(email){
      UserService.getDubt(email)
        .then(resp => {
          this.get_double_leads = resp.data.results
          this.$bvModal.show('get_double_leads')
        })
        .catch()
    },
    eliminaLead(id){
      Swal.fire({
        title: 'Sei Sicuro?',
        text: "Non potrai riprendere questo elemento una volta cancellato!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, cancellalo!'
      }).then((result) => {
        if (result.isConfirmed) {
          UserService.deleteLead(id)
            .then(resp => {
              Swal.fire(
                'Deleted!',
                `Your file has been deleted. Status: ${resp.status}`,
                'success'
              )
              this.InitLead()
            })
        }
      })
    },
    deleteLead(){
      let id = this.multiSelectLead.lead_ids[0]
      UserService.deleteLead(id)
        .then(() => {
          this.actionLead = false
          this.multiSelectLead.lead_ids = []
          this.checkbox = !this.checkbox
          this.$bvToast.toast(`Lead cancellato!`, {
            title: 'Lead cancellato con successo',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
            appendToast: true
          })
          this.InitLead()

        })
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    leadModificatoFunction(){
      this.searchAndFilterLeadwithPagination(this.page)
      this.dismissCountDown = this.dismissSecs
      this.showDismissibleAlert = true
      this.$bvToast.toast(`Lead modificato!`, {
        title: 'Modificato con successo',
        solid: true,
        variant: 'success',
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    // Studente
    tmpLead(row) {
      this.leadTemporary = row.item
    },
    convertLeadToStudent: function () {
      console.log('TEMPORARY LEAD:', this.leadTemporary)
      this.$nextTick(() => {
          if (this.anno_immatricolazione_selected != null) {
            if (this.leadTemporary.lead_convertito_in_studente) {
              alert('Non posso convertire questo Lead perchè è già uno Studente')
            } else {
              const payload = {
                "nome": this.leadTemporary.lead_nome,
                "cognome": this.leadTemporary.lead_cognome,
                "email": this.leadTemporary.lead_email,
                // "email_istituzionale": "",
                "lead_email": this.leadTemporary.lead_email,
                "anno_immatricolazione": this.anno_immatricolazione_selected,
                "telefono_fisso": "",
                "telefono_cellulare": this.leadTemporary.lead_cellulare,
                "istituto_scolastico": this.leadTemporary.istituto_scolastico,
                "citta": this.leadTemporary.lead_citta,
                "luogo_di_nascita": this.leadTemporary.lead_luogo_di_nascita || '',
                // "provincia_di_nascita": this.leadTemporary.lead_luogo_di_nascita || '',
                "data_nascita": this.leadTemporary.lead_data_di_nascita || null,
                "codice_fiscale": this.leadTemporary.lead_cf || '',
                // "indirizzo_residenza": "",
                // "numero_civico_residenza": "",
                // "citta_di_residenza": "",
                // "provincia_di_residenza": "",
                // "cap_di_residenza": "",
                // "residenza_domicilio": false,
                "indirizzo_domicilio": this.leadTemporary.lead_indirizzo || '',
                // "numero_domicilio": "",
                "citta_di_domicilio": this.leadTemporary.lead_citta || '',
                // "numero_civico_domicilio": "",
                "classe": this.leadTemporary.istituto_classe || '',
                // "tipo_di_diploma": "",
                // "data_iscrizione": "null",
                "sede_di_studi": this.leadTemporary.sede_interesse || '',
                // "laureato_il": "null",
                "note": this.leadTemporary.note || '',
                // "modulo_immatricolazione": false,
                // "carta_di_identita": false,
                // "diploma": false,
                // "user": "null",
                "lead": this.leadTemporary.id,
                // "test_ingresso": "null",
                // "istituto_provenienza": this.leadTemporary.istituto_provenienza,
                // "piano_di_studi": "null",
                // "corsi": []
              }
              StudentiService.postLeadToStudente(payload)
                .then(resp => {
                  this.matricola = resp.data.matricola
                  this.$bvModal.show('matricola_lead')
                  const payload = {
                    id: this.leadTemporary.id,
                    lead_convertito_in_studente: true
                  }
                  LeadService.editLead(payload)
                    .then(resp => {
                      this.InitLead()
                      console.log('Questa è la risposta: ', resp)
                    })
                  console.log(resp)
                })
            }
          } else {
            alert('Non posso convertire il lead in studente se non scegli un anno di immatricolazione')
          }
        }
      )


    },
    // Eventi
    addEvento(item) {
      this.l_id = item.id
      console.log('passo in ListaLead.vue riga 1593')
      this.$nextTick(() => {
        this.showEvento = true
        // this.$bvModal.show('modale-evento')
      })
    },
    addFollowUp(item) {
      this.leadFollowUp = item
      this.$nextTick(() => {
        this.$bvModal.show('aggiungi-followup')
      })

    },
    getFollowUp(item) {
      this.leadFollowUp = item
      this.$nextTick(() => {
        this.$bvModal.show('mostraFollowUp')
      })
    },
    getEventi(item){
      if(item){
        this.l_id = item.id
        this.showModaleEventi = true
        this.full_name = item.full_name
        this.sendElement = true
        this.$bvModal.show('modalEventi')
      } else {
        this.showModaleEventi = true
        this.full_name = ''
        this.sendElement = true
        this.$bvModal.show('modalEventi')
      }
    },
    resetInfoModal() {
      this.showEvento = false
    },
    chiudiModalEventi(){
      this.$bvModal.hide('modalEventi')
    },
    // Filtri
    handlePick(e) {
      console.log(e)
    },
    clearRangeFilter(){
      this.dateRange = Object.assign({})
    },
    clearRange() {
      this.dateRange = Object.assign({})
      this.InitLead()
    },
    // funzioni da controllare o cancellare
    eraseSearch() {
      this.searchTerm = ''
      this.InitLead()
    },
    findDupt(){
      UserService.findDubt()
        .then(resp => {
          this.doubleLead = resp.data
        })
        .catch(err => {
          console.log(err.status)
        })
    },
    associaScuolaConLeads(){
      this.$bvModal.show('associaScuola')
    },
    salvaLead(row){
      this.busy = true
      LeadService.saveLead(row)
        .then(() => {
          this.busy = false
          this.initLead()

        })
        .catch(e =>{
          alert(e)
          this.busy = false
        })

    },
    filtraLeadDeactive() {
      this.filters = true
      LeadService.filterLeadDeactivate()
        .then(resp => {
          this.filters = true
          this.leadsNew = resp.data.total
          this.lead = this.appendSelected(resp.data.results)
        })
        .catch()

    },
    addChiamata(id) {
      this.$root.$emit('bv::show::modal', 'newInteractionEdit', id)
    },
    onRowSelected3(items){
      this.selected.associa_scuola = items.id
      this.multiSelectLead.associa_scuola = items.id
      //this.singleLead = items[0].id
      // LeadService.editLeadBulk(this.multiSelectLead)
      //     .then(resp =>{
      //         console.log(resp.status)
      //         this.leadAssociato = true
      //         this.$bvModal.hide('associaScuola')
      //     })
    },
    closeAndRefreshFollowUp(){
      this.$refs["table-followup"].getFollowUpChecked()
      this.InitLead()
      this.$bvModal.hide('mostraFollowUp')
    },
    appendSelected(lead) {
      return lead.reduce((struct, item) => {
        const newItem = Object.assign({}, item)
        const selectedItem = this.leadsChecked.find(leadChecked => item.id === leadChecked.id)
        newItem.selected = selectedItem ? true : false;
        struct.push(newItem)
        return struct
      }, [])
    },
    onLeadSelected(checked, selectedItem) {
      const selectedItemExists = this.leadsChecked.find(item => item.id === selectedItem.id)
      if (checked && !selectedItemExists) {
        this.leadsChecked.push(selectedItem)
      } else if (!checked && selectedItemExists) {
        this.leadsChecked = this.leadsChecked.filter(item => item.id !== selectedItem.id);
      }
    }
  }
}
</script>

<style scoped>
.tr {

}
.capitalize { text-transform: capitalize; }
/*.table tbody tr:hover td, .table tbody tr:hover th {*/
/*    background-color: #c2d7d7 !important;*/
/*}*/
.sr-only {
  color: #1abc36;
}
.badge {

}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#CartCount {
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
}
#Iscrizione Open Day {
  color: #c67605;
}
</style>
