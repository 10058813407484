<template>
  <div>
    <b-overlay :show="overlayEventi" type="grow"
               variant="dark"
               blur="blur"
               rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon style="color: #FFFFFF" icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label" style="color: #FFFFFF">Please wait...</p>
        </div>
      </template>
      Eventi: <strong>{{ eventi.length}}</strong> |  Lead:  <strong v-if="eventi.length > 0">{{ eventi[0].lead_full_name}}</strong>
      <b-button variant="dark" @click="addEvento" class="float-right mb-1">Aggiungi Evento</b-button>
      <b-table
        :items="eventi"
        :fields="eventiLeadFields"
        small
        hover
        striped
        :selectable="selecta"
        :select-mode="selectMode"
        show-empty
        @row-selected="rigaSelezionata"
        empty-text="Non ci sono ancora Eventi Associati">
        <template #cell(created_at)="row">
          <h5><b-badge variant="dark" class="font-weight-bold">{{ row.item.created_at | formatDate}}</b-badge></h5>
        </template>
        <template #cell(tipo_evento)="row">
          <h5><b-badge variant="dark" class="font-weight-bold">{{ row.item.tipo_evento }}</b-badge></h5>
        </template>
        <template #cell(tipo_interazione)="row">
          <h5><b-badge variant="yellow" class="font-weight-bold">{{ row.item.tipo_interazione }}</b-badge></h5>
        </template>
        <template #cell(email_staff)="row">
          <!--                                <b-list-group horizontal v-for="(email, index) in row.item.email_staff" :key="index">-->
          <!--                                    <b-list-item><b-badge variant="dark">{{email}}</b-badge></b-list-item>-->
          <!--                                </b-list-group>-->
          <b-link :id="`emails_staff-${row.item.id}`" style="color: #3c4e71">{{row.item.email_staff.length}}</b-link>
          <b-popover :target="`emails_staff-${row.item.id}`" triggers="hover">
            <div v-for="(email, index) in row.item.email_staff" :key="index">
              <span>{{index + 1}} - {{email}}</span>
            </div>
          </b-popover>
        </template>
        <template #cell(sintesi)="row">
          <b-link
            :id="`sintesi-${row.item.id}`"
            @click="openDetail(row.item)"
            style="color: #3c4e71" class="text-underline">campi extra</b-link>
          <b-popover :target="`sintesi-${row.item.id}`" triggers="hover">
            <div v-for="(item, index) in row.item" :key="index">
              <span>{{index + 1}} - {{item}}</span>
            </div>
          </b-popover>
        </template>
        <template #cell(sede)="row">
          <h5><b-badge variant="dark" class="font-weight-bold" >{{ row.item.sede}}</b-badge></h5>
        </template>
        <template #cell(note)="row">
          <div
            style="color: #050505"
            v-if="row.item.note"
            v-b-tooltip.hover
            :title="row.item.note">
            {{ eventoRestrict(row.item.note)}} ...
          </div>
        </template>
        <template #row-details="row">
          <div v-for="(items, index) in row" :key="index">
                        <span class="ml-2 mr-2" v-for="(item, index) in items" :key="index">
                           <strong>{{ index }}:</strong> {{item}} <br>
                        </span>
          </div>
        </template>
        <template #cell(action)="row">
          <b-link @click="row.toggleDetails" style="color: #3A4051;" class="mr-2" v-b-tooltip.hover title="Guarda i Dettagli">
            <i class='fas fa-eye'></i>
          </b-link>
          <b-link @click="openEditEventoModal(row.item.id)"><i class="fa fa-edit ml-2"></i></b-link>
          <b-link @click="deleteEvento(row.item)"><i style="color: #9e0808" class="fa fa-trash ml-2"></i></b-link>
        </template>
      </b-table>
    </b-overlay>
    <b-modal id="campi-extra" size="lg">
      <code>
                <pre>
                    {{ item }}
                </pre>
      </code>
    </b-modal>
    <modale-evento-lead v-if="showEvento" :l_id="l_id" @hidden="resetInfoModal"></modale-evento-lead>
    <modale-edit-evento-lead v-if="idEventoToEdit" id="modale-edit-evento" :id-evento="idEventoToEdit" @hidden="resetInfoModal"></modale-edit-evento-lead>
    <small class="text-muted">Nome Componente: src/components/crm/evento/TableEventoLead.vue</small>
  </div>
</template>

<script>
import EventService from "../../../../services/event.service";
import ModaleEditEventoLead from "@/components/crm/evento/lead/ModaleEditEventoLead";
import ModaleEventoLead from "@/components/crm/evento/lead/ModaleEventoLead";
import Swal from "sweetalert2";


export default {
  name: "TableEventoLead",
  components: {
    ModaleEditEventoLead,
    ModaleEventoLead
  },
  props: {
    show: Boolean,
    l_id: Number,
    getElement: Boolean,
    full_name: String,
    nome: String,
    selecta: Boolean,
    selectMode: String
  },
  data() {
    return {
      eventi: [],
      eventiLeadFields: [
        {key: 'created_at', label: 'Creato'},
        {key: 'tipo_evento', label: 'Tipo Ev.'},
        {key: 'tipo_interazione', label: 'Tipo Int.'},
        {key: 'sede', label: 'Sede'},
        {key: 'email_staff', label: 'Orientatore'},
        {key: 'od_nome', label: 'Nome OD'},
        {key: 'note', label: 'Note'},
        {key: 'sintesi', label: 'Sintesi'},
        {key: 'action', label: 'Action'},
      ],
      tipo: [
        {key: 1, label: "Open Day"},
        {key: 2, label: "Fiera"},
        {key: 3, label: "Test"},
        {key: 4, label: "Colloquio Individuale"},
        {key: 5, label: "Informazioni"},
      ],
      item: '',
      overlayEventi: false,
      showTable: this.show || false,
      getElements: this.getElement || false,
      nameLead: this.full_name || '',
      lead: this.getLead,
      idEventoToEdit: null,
      nomeModale: '',
      editEvento: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      showEvento: false
    }
  },
  mounted() {
    this.getEventi()
    console.log('mounted table')
  },
  computed: {
    getLead: {
      get() {
        return this.l_id
      }
    },
  },
  methods: {
    rigaSelezionata(event){
      this.$emit('rigaSelezionata', event[0])
    },
    eventoRestrict(evento) {
      return evento.substring(0, 30)
    },
    getEventi() {
      this.overlayEventi = true
      const payload = {
        lead: this.l_id
      }
      EventService.getEventi(payload)
          .then(resp => {
            this.eventi = resp.data.results
            this.overlayEventi = false
            this.showTable = true
          })
          .catch()
    },
    openDetail(item) {
      this.item = item
      this.$bvModal.show('campi-extra')
    },
    addEvento() {
      this.showEvento = true
      // this.$bvModal.show('modale-evento')
      // this.$root.$emit('bv::show::modal', 'aggiungi-evento-modal')
    },
    salvaEventoInModale() {
      this.$refs.aggiungiEvento.saveEvento()
    },
    openEditEventoModal(id) {
      // this.editEvento.title = `Row index: ${index}`
      // this.$store.dispatch('evento/getEvento', item)
      this.idEventoToEdit = id
      this.$root.$emit('bv::show::modal', 'modale-edit-evento')
    },
    deleteEvento(item) {
      Swal.fire({
        title: 'Sei Sicuro?',
        text: "Non potrai riprendere questo elemento una volta cancellato!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, cancellalo!'
      }).then((result) => {
        if (result.isConfirmed) {
          EventService.deleteEvento(item.id)
              .then(resp => {
                Swal.fire(
                    'Deleted!',
                    `Your file has been deleted. Status: ${resp.status}`,
                    'success'
                )
                this.getEventi()
              })

        }
      })
      console.log(item)
    },
    resetInfoModal() {
      this.getEventi()
      this.idEventoToEdit = null
      this.showEvento = false
    },
  },
  watch: {
    nameLead: { //<-- Edited
      handler(newVal, oldVal) {
        console.log(oldVal)
        return newVal
      },
    },
  }
}
</script>

<style scoped>

</style>
