<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <b-overlay :show="overlay" type="grow"
                   variant="dark"
                   blur="blur"
                   rounded="sm">
            <template #overlay>
                <div class="text-center">
                    <b-icon style="color: #FFFFFF" icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                    <p id="cancel-label" style="color: #FFFFFF">Please wait...</p>
                </div>
            </template>
            <b-row>
                <b-col cols="12">
                    <b-card bg-variant="dark">
                        <b-card-title style="color: white">
                            <b-row>
                                <b-col cols="2">
                                    <i class="fab fa-wpforms"></i> Form presenti: {{ this.totalItems }}
                                </b-col>
                                <b-col cols="8">
                                    <b-form-input
                                        v-model="searchInFormValue"
                                        size="sm"
                                        type="search"
                                        placeholder="Cerca per Email Lead"></b-form-input>
                                </b-col>
                                <b-col cols="2">
                                    <b-button v-b-toggle.collapse-1 variant="danger" size="sm" class="float-right">Filtri&Ricerche</b-button>
                                </b-col>
                            </b-row>
                        </b-card-title>
                        <b-row>
                            <b-col cols="12">
                                <b-row>
                                    <b-col cols="12">
                                        <b-collapse id="collapse-1" class="mt-2">
                                            <b-card bg-variant="dark">
                                                <b-row>
                                                    <b-col cols="2" class="border-warning border-left">
                                                        <b-form-group label="Filtra per Sede" style="color: white" v-slot="{ ariaDescribedby }">
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-0"
                                                                v-model="filter.sede"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Firenze"
                                                                unchecked-value=""
                                                                value="Firenze"
                                                                @change="searchAndfilterForm"
                                                            >Firenze</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-0.1"
                                                                v-model="filter.sede"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Torino"
                                                                unchecked-value=""
                                                                value="Torino"
                                                                @change="searchAndfilterForm"
                                                            >Torino</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-0.2"
                                                                v-model="filter.sede"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Mantova"
                                                                unchecked-value=""
                                                                value="Mantova"
                                                                @change="searchAndfilterForm"
                                                            >Mantova</b-form-checkbox>
                                                        </b-form-group>
                                                      <span class="text-white">{{ filter.sede }}</span>
                                                    </b-col>
                                                    <b-col cols="2" class="offset-lg-0 border-left pl-2 border-warning">
                                                        <b-form-group label="Form OpenDay" style="color: white" v-slot="{ ariaDescribedby }">
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-1"
                                                                v-model="filter.iop"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Iscrizione Open Day"
                                                                @change="searchAndfilterForm"
                                                            >Iscrizione Open Day</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-2"
                                                                v-model="filter.iopg_old"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Iscrizione Open Day generico"
                                                                value="Iscrizione OD generico"
                                                                @change="searchAndfilterForm"
                                                            >Iscrizione OD generico***</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-2.1"
                                                                v-model="filter.iopg"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Iscrizione open day generico"
                                                                @change="searchAndfilterForm"
                                                            >Iscrizione open day generico</b-form-checkbox>
                                                        </b-form-group>
                                                        <hr>
                                                        <b-form-group label="Form Test" style="color: white" v-slot="{ ariaDescribedby}">
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-3"
                                                                v-model="filter"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Simulazione test di ammissione"
                                                                unchecked-value=""
                                                                value="Simulazione test di ammissione"
                                                                @change="searchAndfilterForm"
                                                            >Simulazione test di ammissione</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-13"
                                                                v-model="filter"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="test ammissione"
                                                                unchecked-value=""
                                                                value="Test Ammissione"
                                                                @change="searchAndfilterForm"
                                                            >Test Ammissione</b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="2" class="offset-lg-0 border-left pl-2 border-warning">
                                                        <b-form-group label="Form Colloquio:" style="color: white" v-slot="{ ariaDescribedby }">
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-7"
                                                                v-model="filter.oi"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Orientamento Individuale"
                                                                value="Orientamento Individuale"
                                                                unchecked-value=""
                                                                @change="searchAndfilterForm"
                                                            >Orientamento Individuale***</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-7.1"
                                                                v-model="filter.ci"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Colloquio Individuale"
                                                                value="Colloquio Individuale"
                                                                unchecked-value=""
                                                                @change="searchAndfilterForm"
                                                            >Colloquio Individuale</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-7.2"
                                                                v-model="filter.cig"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Colloquio Individuale Generico"
                                                                value="Colloquio individuale generico"
                                                                unchecked-value=""
                                                                @change="searchAndfilterForm"
                                                            >Colloquio Individuale Generico</b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="2" class="offset-lg-0 border-left pl-2 border-warning">
                                                        <b-form-group label="Form Landing Page" style="color: white" v-slot="{ ariaDescribedby }">
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-5"
                                                                v-model="filter"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Form Landing messaggio"
                                                                value="Form Landing messaggio"
                                                                unchecked-value=""
                                                                @change="searchAndfilterForm"
                                                            >Form Landing messaggio***</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-5.1"
                                                                v-model="filter"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Form Landing con messaggio"
                                                                value="Form Landing con messaggio"
                                                                unchecked-value=""
                                                                @change="searchAndfilterForm"
                                                            >Form Landing messaggio</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-6"
                                                                v-model="filter"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Form Landing"
                                                                value="Form Landing"
                                                                unchecked-value=""
                                                                @change="searchAndfilterForm"
                                                            >Form Landing</b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="2" class="offset-lg-0 border-left pl-2 border-warning" >
                                                        <b-form-group label="Form Generici:" style="color: white" v-slot="{ ariaDescribedby }">
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-4"
                                                                v-model="filter"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Grazie per aver contattato Unicollege"
                                                                unchecked-value=""
                                                                value="Grazie per aver contattato Unicollege"
                                                                @change="searchAndfilterForm"
                                                            >Grazie per aver contattato Unicollege</b-form-checkbox>
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-4.1"
                                                                v-model="filter"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Contatti"
                                                                unchecked-value=""
                                                                value="Contatti"
                                                                @change="searchAndfilterForm"
                                                            >Contatti</b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                    <b-col cols="2" class="offset-lg-0 border-left pl-2 border-warning">
                                                        <b-form-group label="Form Da Convertire:" style="color: white" v-slot="{ ariaDescribedby }">
                                                            <b-form-checkbox
                                                                style="color: white"
                                                                id="checkbox-4.2"
                                                                v-model="filter"
                                                                :aria-describedby="ariaDescribedby"
                                                                name="Da Fare"
                                                                unchecked-value=""
                                                                value="Da Fare"
                                                                @change="searchAndfilterForm"
                                                            >Da Fare</b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
                                                <hr>
                                                <b-row>
                                                    <b-col>
                                                        <label style="color: white">Filtra per data di Ricezione:</label>
                                                        <date-range-picker
                                                            v-model="dateRange"
                                                            :lang="lang"
                                                            @update="searchAndFilterFormPerDate"
                                                        >
                                                            <template v-slot:input="picker" style="min-width: 350px;">
                                                                {{ picker.startDate | formatDate }} - {{ picker.endDate | formatDate }}
                                                            </template>
                                                        </date-range-picker>
                                                        <b-button class="mt-2 ml-3" variant="yellow" size="sm" @click="clearRange">Clear Range</b-button>
                                                    </b-col>
                                                </b-row>
                                                <hr>
                                                <b-row>
                                                    <b-col>
                                                        <b-card bg-variant="dark" text-variant="white" border-variant="dark">
                                                            <span style="color: gray">Stai filtrando per: <span style="font-style: italic; color: white">{{ filter}}</span></span><br>
                                                            <span>*** Sono i form arrivati prima del 25 luglio 2022 a cui è stato cambiato il Nome</span>
                                                        </b-card>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </b-collapse>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
            <b-card>
            <b-card-title>
                Sono Presenti tutti i form che arrivano dal sito unicollegessml.it
            </b-card-title>
            <b-card-body>
                <b-table
                    :items="forms"
                    :fields="fieldsForm"
                    hover
                    striped
                    small
                    @row-clicked="onRowClicked"
                    :per-page="0"
                >
                    <template #cell(id)="row">
                        <span  v-if="row.item.trasform_to_lead">{{ row.item.id}}</span>
                        <span v-else style="font-weight: bold">{{ row.item.id}}</span>
                    </template >
                    <template #cell(form_id)="row">
                        <span v-if="row.item.trasform_to_lead">{{ row.item.form_id}}</span>
                        <span style="font-weight: bold" v-else>{{ row.item.form_id}}</span>
                    </template >
                        <template #cell(form_name)="row">
                        <div  @click="copyEmail(row.item)">
                            <span style="border-bottom: 1px dotted;" v-if="row.item.trasform_to_lead" v-b-tooltip:hover :title="row.item.form_value.lead_email && row.item.form_value.lead_cognome">{{ row.item.form_name}}</span>
                            <span v-else v-b-tooltip:hover :title="row.item.form_value.lead_email"
                                  style="border-bottom: 1px dotted; font-weight: bold">{{ row.item.form_name}}</span>
                        </div>
                    </template>
                    <template #cell(form_date)="row">
                        <span v-if="row.item.trasform_to_lead">{{ row.item.form_date}}</span>
                        <span v-else style="font-weight: bold" >{{ row.item.form_date}}</span>
                    </template>
                    <template #row-details="row">
                        <b-row>
                            <b-col cols="8">
                                    <code lang="json" style="color: #212837">
                                        <span class="" v-for="(valore, index) in row.item.form_value" :key="index">
                                            {{ index }}: <b>{{ valore }}</b><br>
                                        </span>
                                    </code>
                            </b-col>
                        </b-row>

                    </template>
                    <template #cell(actions)="row" class="float-right">
                        <b-link @click="row.toggleDetails" style="color: #3A4051;" class="mr-2" v-b-tooltip.hover title="Guarda i Dettagli">
                             <i class='fas fa-eye'></i>
                        </b-link>
<!--                        <b-link @click="openDetail(row)" style="color: #3A4051;" v-else v-b-tooltip.hover title="Nascondi Dettagli"><i class="fas fa-lock-open"></i></b-link> |-->
                        <b-link @click="setReaded(row.item)" style="color: #033a02" v-if="row.item.sync_data === true" v-b-tooltip.hover title="Segna Come Letto"><i class="fas fa-box"></i></b-link>
                        <b-link @click="setReaded(row.item)" style="color: #8d7e6a" v-else v-b-tooltip.hover title="Segna Come Non Letto"><i class="fas fa-box-open"></i></b-link> |
                        <b-link @click="formAlredyCreated(row.item)" style="color: #567a04" v-if="row.item.trasform_to_lead" v-b-tooltip.hover title="Lead già creato"><i class="fas fa-user-astronaut"></i></b-link>
                        <b-link @click="transformFormToLead(row.item)" style="color: #3a3f51" v-else v-b-tooltip.hover title="Crea Lead "><i class="fas fa-user-astronaut"></i></b-link> |
<!--                        <b-link @click="dettagliLead(row)" style="color: #8d7e6a">Dettagli Lead</b-link> |-->
                        <b-link @click="nascondiForm(row.item)" style="color: #8d7e6a" v-b-tooltip.hover title="Nascondi Form"><i class="fas fa-eye-slash"></i></b-link> |
                        <b-link @click="eliminaForm(row.item)" style="color: #d50f0f" v-b-tooltip.hover title="Elimina Form"><i class="fas fa-trash"></i></b-link>
                    </template>
                </b-table>
                <b-row>
                    <b-col cols="3">
                        <b-pagination
                            class="mr-3"
                            size="sm"
                            :total-rows="totalItems"
                            :per-page="perPage"
                            v-model="currentPage"
                            next-text="next"
                            prev-text="previous"
                            @change="searchAndfilterFormwithPagination"
                            aria-controls="LeadTable"
                        >
                        </b-pagination>
                    </b-col>
                    <b-col cols="3">
                        <div style="margin:auto;text-align: left;">
                            <ul class="pagination">
                                <li class="page-item active" size="sm"><a class="page-link">Total {{totalItems}}</a></li>
                            </ul>
                        </div>
                    </b-col>
                    <b-col cols="3">
                        <b-form-select :options="pageOptions" v-model="perPage" class="col-md-3" size="sm"/>
                        Lead per Pagina
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        </b-overlay>
    </div>
</template>

<script>
import LeadService from "../../../services/lead.service";
import {formatDate} from "@fullcalendar/vue";
import Swal from "sweetalert2";
export default {
  name: "FormDalSito",
  data() {
        const startDate = new Date();
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 6);
        return {
            forms: [],
            fieldsForm: [
                {key:"id", label:'#ID', sortable: true},
                {key:"form_id", label:'Form ID', sortable: true},
                {key:"form_name", label:'Form Name', sortable: true},
                {key:"form_date", label:'Data', sortable: true},
                {key:"actions", label:'actions'},
            ],
            selectedItem: null,
            newLead: {
                "active": false,
                "sede_interesse": null,
                "anno_di_interesse": null,
                "lead_cognome": "",
                "lead_nome": "",
                "lead_email": "",
                "lead_cellulare": "",
                "lead_indirizzo": "",
                "lead_citta": "",
                "lead_cap": "",
                "lead_comune": "",
                "lead_provincia": "",
                "lead_cf": "",
                "lead_data_di_nascita": "",
                "lead_luogo_di_nascita": "",
                "privacy": false,
                "come_ci_hai_conosciuto": "",
                "ultima_dem": "",
                "istituto_classe": "",
                "istituto_provincia": "",
                "istituto_scolastico": "",
                "istituto_citta": "",
                "data_diploma": null,
                "ti_email_chiamata": false,
                "ti_email_colloquio": false,
                "ti_evento_istituto": false,
                "ti_data_lezione": "",
                "ti_fiera": "",
                "ti_webinar": "",
                "ti_open_day": false,
                "ti_data_od": "",
                "ti_sede_od": null,
                "ti_partecipazione_od": false,
                "ti_chat": false,
                "ti_chiamata": false,
                "ti_chiamata_detail": "",
                "ti_evento_tipologia": null,
                "ti_evento_data": null,
                "ti_evento_sede": "",
                "ti_fac_simile_test": false,
                "ti_fac_simile_test_data": null,
                "ti_note": "",
                "test_richiesta_ammissione": false,
                "test_data_iscrizione": null,
                "test_codice": "",
                "test_pagamento": false,
                "test_immatricolazione": false,
                "test_seconda_lingua": "",
                "test_terza_lingua": "",
                "test_voto_scuola": "",
                "test_costo_iscrizione": null,
                "accompagnatori": "",
                "ci_modalita_preferenza": "",
                "ci_preferenza_contatto": "",
                "ci_orario_colloquio": "",
                "ci_data_colloquio": null,
                "ci_messaggio": "",
                "ci_interessato_a": "",
                "ci_cosa_vuoi_studiare": null,
                "coinvolgimento": null,
                "modalita_di_pagamento": null,
                "note": "",
                "altro": "",
                "json": null,
                "associa_scuola": null
            },
            filter: {
              sede: '',
              iop: false,
              iopg_old: false,
              iopg: false,
              sta: false,
              ta: false,
              oi: false,
              ci: false,
              cig: false,
              flm: false,
              flm_old: false,
              fl: false,
            },
            sede:'',
            detailsShowing: false,
            overlay: true,
            // Pagination Form
            currentPage: 1,
            numeroFormPagina: 25,
            pageOptions:[10,25,50],
            perPage: 25,
            totalItems: 0,
            // datePicker
            dateformat: 'MM/DD/YYYY',
            lang:'it',
            dateRange: {
                startDate,
                endDate,
            },
            updateValues: '',
            numberPage: 0,
            searchInFormValue:''
        }
    },
  watch:{
    searchInFormValue(newValue) {
      if(newValue.length >= 3) {
        this.overlay = true
        const payload = {
          lead_email: newValue
        }
        LeadService.getFilterFormDalSito(payload)
          .then(resp => {
            this.forms = resp.data.results
            this.currentPage = resp.data.page
            this.totalItems = resp.data.total
            this.next = resp.data.links.next
            this.perPage = resp.data.page_size
            this.overlay = false
          })
      }
      if(newValue.length <=2) {
        this.initForms()
      }
    },
  },
  mounted() {
        this.initForms()
        this.clearRange()
    },
  methods: {
        copyEmail(item){
            if(item.form_value.lead_email){
                navigator.clipboard.writeText(item.form_value.lead_email)
                this.$bvToast.toast(`${item.form_value.lead_email}`, {
                    title: 'Email copiata:',
                    autoHideDelay: 5000,
                    variant:'success'
                })
            } else if(item.form_value.email) {
                navigator.clipboard.writeText(item.form_value.email)
                this.$bvToast.toast(`${item.form_value.email}`, {
                    title: 'Email copiata:',
                    autoHideDelay: 5000,
                    variant: 'success'
                })
            } else if(item.form_value.Email) {
                navigator.clipboard.writeText(item.form_value.Email)
                this.$bvToast.toast(`${item.form_value.Email}`, {
                    title: 'Email copiata:',
                    autoHideDelay: 5000,
                    variant: 'success'
                })
            }else {
                this.$bvToast.toast('Email non presente', {
                    title: 'Email non copiata:',
                    autoHideDelay: 5000,
                    variant:'danger'
                })
            }

        },
        initForms() {
            this.overlay = true
            LeadService.getFormDalSito()
                .then(resp => {
                    this.forms = resp.data.results
                    this.currentPage = resp.data.page
                    this.totalItems = resp.data.total
                    this.next = resp.data.links.next
                    this.perPage = resp.data.page_size
                    this.overlay = false
                    }
                )
                .catch()
        },
        searchAndFilterFormPerDate() {
            this.overlay = true
            const payload = {
                sede: this.filter.sede,
                iop: this.filter.iop,
                iopg: this.filter.iopg,
                start_date: formatDate(this.dateRange.startDate),
                end_date: formatDate(this.dateRange.endDate)
            }
            LeadService.getFilterFormDalSito(payload)
                .then(resp => {
                    this.forms = resp.data.results
                    this.currentPage = resp.data.page
                    this.totalItems = resp.data.total
                    this.next = resp.data.links.next
                    this.perPage = resp.data.page_size
                    this.overlay = false
                })
        },
        searchAndfilterForm() {
            this.overlay = true
            const filterStruct = {
              // search: this.filter,
              sede: this.filter.sede,
              iop: this.filter.iop,
              iopg_old: this.filter.iopg_old,
              iopg: this.filter.iopg,
              oi: this.filter.oi,
              ci: this.filter.ci,
              cig: this.filter.cig,
            }
            const query = Object.keys(filterStruct).map(key => key + '=' + filterStruct[key]).join('&')
            const payload = {query: query}
            LeadService.getFilterFormDalSito(payload)
                .then(resp => {
                    this.forms = resp.data.results
                    this.currentPage = resp.data.page
                    this.totalItems = resp.data.total
                    this.next = resp.data.links.next
                    this.perPage = resp.data.page_size
                    this.overlay = false
                })
        },
        searchAndfilterFormwithPagination(pageNumber) {
            this.numberPage = pageNumber
            this.overlay = true
            const params = {
              page: pageNumber,
              sede: this.filter.sede,
              iop: this.filter.iop,
              iopg: this.filter.iopg,
              // search: this.filter,
                }
            LeadService.getFilterFormDalSito(params)
                .then(resp => {
                    this.forms = resp.data.results
                    this.currentPage = resp.data.page
                    this.totalItems = resp.data.total
                    this.next = resp.data.links.next
                    this.perPage = resp.data.page_size
                    this.overlay = false
                })
                },
        setReaded(item) {
            let form ={
                id: item.id,
                sync_data: !item.sync_data
            }
          LeadService.setReaded(form)
              .then(resp => {
                this.initForms()
                this.$bvToast.toast(`${resp.data.form_name}`, {
                  title: 'Form Letto/Non Letto:',
                  autoHideDelay: 5000,
                  variant: 'success'
                })
              })
              .catch()
        },
        formAlredyCreated(item) {
            Swal.fire({
                background: "#1d222c",
                title: 'Lead ed Evento già creato',
                text: `Questo form è già stato lavorato ed assegnato ad un Lead con
                Email: ${item.form_value.lead_email}`,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok',
                width: '25%',
            })
        },
        transformFormToLead(item){
            LeadService.trasformFormToLeadAndEvent(item.id)
                .then(resp => {
                    this.$bvToast.toast(`Lead Creato o Aggiornato con Successo!`, {
                        title: 'Lead creato/associato con successo',
                        variant: 'success',
                        solid: true,
                        autoHideDelay: 4000,
                        appendToast: true
                    })
                    this.searchAndfilterFormwithPagination()
                    navigator.clipboard.writeText(resp.data.lead_email)
                    this.$bvToast.toast(`${resp.data.lead_email}`, {
                        title: 'Email copiata:',
                        autoHideDelay: 5000,
                        variant:'success'
                    })

                })
                .catch(e => {
                    this.$bvToast.toast(`ERRORE: ${e.status}!`, {
                        title: 'Errore creazione Lead!!!',
                        variant: 'danger',
                        solid: true,
                        autoHideDelay: 5000,
                        appendToast: true
                    })
                })
        },
        eliminaForm(item) {
            Swal.fire({
                title: 'Sei Sicuro?',
                text: `Non potrai riprendere questo elemento una volta cancellato!
                Email: ${item.form_value.lead_email}`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, cancellalo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    LeadService.deleteFormDalSito(item.id)
                        .then(resp => {
                            Swal.fire(
                                'Deleted!',
                                `Your file has been deleted. Status: ${resp.status}`,
                                'success'
                            )
                            this.searchAndfilterFormwithPagination(this.numberPage)
                        })

                }
            })
        },
        nascondiForm(item) {
            this.overlay = true
            const params = {
                id: item.id,
                sync_data: true,
                active: false
            }
            LeadService.setReaded(params)
                .then(() => {
                    this.overlay = false
                    this.searchAndfilterForm()
                })
                .catch(err => {
                    this.overlay = false
                    console.log(err)
                })
        },
        onRowClicked(item) {
            this.selectedItem = item
        },
        toggleDetails(row) {
            if(row._showDetails){
                this.$set(row, '_showDetails', false)
            }else{
                this.$nextTick(() => {
                    this.$set(row, '_showDetails', true)
                })
            }
        },
        openDetail(row){
            row.toggleDetails()
        },
        clearRange() {
            this.dateRange = Object.assign({})
            this.initForms()
        },
    }
}
</script>

<style scoped>
.swal-html-container, .swal-title{
    color: white !important;
}

</style>
