<template>
  <div>
    <b-modal
      size="xl"
      id="dettaglio-contatti"
      ref="dettaglio-contatti"
      title="Dettagli Contatti"
      hide-header
      hide-footer>
      <b-row>
        <b-col cols="12" v-show="istitutoShow">
          <b-card bg-variant="dark" class="m-0 pt-1">
            <h4 style="color: #f9f9fa"><i class="fas fa-id-card-alt"></i>
              Lista Contatti dell'Istituto: <span style="font-style: oblique">{{ this.istitutoDetail.nome_istituto }}</span></h4>
            <b-btn variant="yellow" v-b-modal.add-contatto>Aggiungi Contatto</b-btn>
          </b-card>
          <b-tabs nav-class="nav-tabs-v2" content-class="pt-2" fill>
            <b-tab active>
              <template v-slot:title>
                Referente
                <b-badge variant="dark" v-if="referente.length > 0">{{ referente.length }}</b-badge>
              </template>
              <b-row cols="12">
                <b-col cols="12">
                  <card-details
                    :items="referente"
                    :istitutoDetail="istitutoDetail"
                    @eventiList="eventiList"
                    @inviaEvento="inviaEvento"
                    @editContatto="editContatto"
                    @openTime="openTime">
                  </card-details>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                Dirigente
                <b-badge v-if="dirigenti.length > 0" variant="dark">{{ dirigenti.length }}</b-badge>
              </template>
              <b-row cols="12">
                <b-col cols="6">
                  <card-details
                    ref="add-dirigente"
                    :items="dirigenti"
                    :istitutoDetail="istitutoDetail"
                    @eventiList="eventiList"
                    @inviaEvento="inviaEvento"
                    @editContatto="editContatto"
                    @openTime="openTime">
                  </card-details>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                Professore
                <b-badge variant="dark" v-if="professori.length > 0">{{ professori.length }}</b-badge>
              </template>
              <card-details
                :items="professori"
                :istitutoDetail="istitutoDetail"
                @eventiList="eventiList"
                @inviaEvento="inviaEvento"
                @editContatto="editContatto"
                @openTime="openTime"
              ></card-details>
            </b-tab>
            <b-tab>
              <template v-slot:title>
                Generico
                <b-badge variant="dark" v-if="generico.length > 0">{{ generico.length }}</b-badge>
              </template>
              <card-details
                :items="generico"
                :istitutoDetail="istitutoDetail"
                @eventiList="eventiList"
                @inviaEvento="inviaEvento"
                @editContatto="editContatto"
                @openTime="openTime"
              ></card-details>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row><!--  Dettagli Contatti-->
      <small class="text-muted float-left">Nome Pagina: src/pages/crm/listaContatti.vue</small>
    </b-modal>
  </div>
</template>
<script>
import CardDetails from "@/components/crm/cardDetails"

export default {
  name: 'lista-contatti',
  components: {CardDetails},
  props: {
    dirigenti: {},
    eventiList: {},
    generico: {},
    inviaEvento: {},
    istitutoDetail: {},
    istitutoShow: {},
    openTime: {},
    professori: {},
    referente: {}
  },
  data() {
      return {
          contatto:{}
      }
  },
  methods: {
      editContatto(p) {
          this.contatto = p
          //this.$refs['dettaglio-contatti'].hide()
          this.currentModal = this.$refs['dettaglio-contatti']
          // this.currentModal.hide()
      },
  }
}
</script>
<style scoped>
main {
  width: 150px;
}

h1 {
  margin: 0;
  display: inline-block;
}

button {

}
</style>
