<template>
    <div id="content" class="app-content">
        <h1 class="page-header">
            Prenotazione Appelli <b-btn @click="initSessione(sessioneSelected)"><i class="fas fa-sync-alt"></i></b-btn>
        </h1>
        <hr>
        <b-row v-if="$store.state.auth.user.profile === 'Staff'">
            <b-col>
                Scegliere il Docente <b-link @click="setNullDocente"><i class="fa fa-trash" ></i></b-link> {{ this.docente }}
                <b-form-select v-model="docente" :options="docenti" text-field="get_full_name" value-field="corsi"></b-form-select>
            </b-col>
            <b-col>
                Scegliere la Sessione:
                <b-form-select v-model="sessioneSelected" :options="sessioni" text-field="nome_sessione" value-field="id"></b-form-select>
            </b-col>
        </b-row>
        <b-row v-else-if="$store.state.auth.user.profile === 'Docente'">
            <b-col>
                <b-col>
                    Scegliere la Sessione:
                    <b-form-select v-model="sessioneSelected" :options="sessioni" text-field="nome_sessione" value-field="id"></b-form-select>
                </b-col>
            </b-col>
        </b-row>
        <b-row v-if="show">
            <b-col>
                <b-card>
                    <uni-full-calendar
                        id="fc"
                        class="fc"
                        :resourceAreaColumns="resourceAreaColumns"
                        :resources="resources"
                        :events="events"
                        :start="start"
                        :end="end"
                        @dateSelect="handleDateSelect"
                        @eventClick="handleEventClick"
                        @eventDrop="handleChangeEvent"
                    >
                </uni-full-calendar>
                </b-card>
                <div>
                    <b-card bg-variant="dark">
                        <b-card-sub-title style="text-align: center">
                        <span style="color: #d36363;">
                            Questa sessione inizierà in data <b>{{ this.start}}</b>
                            e terminerà in data <b>{{ this.end }}</b>
                            i giorni disponibili per questa sessione sono {{ this.sessione.durata_sessione}}
                        </span>
                        </b-card-sub-title>
                    </b-card>
                </div>
                <b-card>
                    <b-tabs content-class="mt-3">
                        <b-tab title="Corsi Disponibili" active>
                            <b-table
                                :items="getCorsi"
                                :fields="fieldsCorsi"
                                v-model="currentItems"
                                hover
                                small
                            >
                                <template #cell(nome)="{detailsShowing, item}">
                                        <b-link @click="toggleDetails(item)">{{ item.nome}}</b-link>
                                </template>l
<!--                                <template v-slot:row-details="{item}">-->
<!--                                        Studenti Iscritti al Corso: {{ item.studenti_iscritti }}-->
<!--                                </template>-->
                            </b-table>
                        </b-tab>
                        <b-tab title="Appelli Inseriti">
                            <draggable v-if="start" v-model="materie" group="people" @start="drag=true" @end="drag=false">
                                <b-list-group v-for="element in materie" :key="element.id">
                                    <b-list-group-item>{{ element.nome }} <span class="float-right">Studenti: {{ element.studenti_iscritti }}</span></b-list-group-item>
                                </b-list-group>
                            </draggable>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </b-col>
        </b-row>
<!--        MODALI-->
        <b-modal
            header-bg-variant="dark"
            header-text-variant="white"
            title="Inserisci Appello"
            id="prenotazione"
            ref="prenotazione"
            no-fade
            no-stacking
            hide-footer
        >
            <prenotazione-aula
                ref="prenotazione"
                :clickInfo="clickInfo"
                :event="this.currentEvent"
                :sessione="this.sessioneSelected"
                :corsi="this.corsi"
                @refreshEvent="initSessione(sessioneSelected)"
                @eventRender="eventRender"></prenotazione-aula>
        </b-modal>
        <b-modal
            v-if="clickInfo !== null"
            id="modificaEvento"
            header-bg-variant="dark"
            header-text-variant="white"
            title="Modifica Appello"
            no-fade
            no-stacking
        >
            <template v-slot:modal-footer>
                <div class="float-left">
                    <b-btn variant="danger" class="mr-xl-5">Elimina Appello</b-btn>
                </div>
                <div class="float-right">
                    <b-btn class="mr-2 ml-xl-5">Chiudi</b-btn>
                    <b-btn variant="dark" @click="editEvent">Modifica Appello</b-btn>
                </div>
            </template>
            <b-form id="form" >
                <b-form-input :value="this.clickInfo.event.extendedProps.title"></b-form-input>
                <b-form-textarea :value="this.clickInfo.event.extendedProps.note"></b-form-textarea>
                <span style="width: 10px"><b-form-input v-model="formEditEvent.colore" type='color' id='colorPicker'></b-form-input></span>
                {{ this.clickInfo.event }}
                <hr>
                {{ this.formEditEvent }}
            </b-form>
        </b-modal>
        {{ this.events }}
    </div>
</template>

<script>
import uniFullCalendar from '@/components/widgets/uniFullCalendar'
import Schedule_classroomService from "../../services/schedule_classroom/schedule_classroom.service";
import prenotazioneAula from '@/components/test/prenotazioneAula'
import SessioneService from "../../services/universita/sessioni.service";
import { BPopover } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import DocentiService from "../../services/docenti.service";

export default {
    components: {
        uniFullCalendar,
        prenotazioneAula,
        draggable

    },
    data: function() {
        return {
            resourceAreaColumns:[
                {
                    field: 'title',
                    headerContent: 'Aule',
                    width:'70%'

                },
                {
                    field: 'occupancy',
                    headerContent: 'N° Posti',
                    width:'15%'
                },
                {
                    field: 'occupazione_covid',
                    headerContent: 'N° Posti CVD',
                    width:'15%'
                },
            ],
            resources:[],
            events:[],
            currentEvent: [],
            sessioni:[],
            sessioneSelected: null,
            sessione: {},
            show: false,
            modalShow: false,
            start:null,
            end:null,
            clickInfo: null,
            selected:[],
            formEditEvent: {
                id: '',
                title: '',
                colore:this.selected,
                note:''
            },
            color: [
                { item: 'red', name: 'red' },
                { item: 'orange', name: 'orange' },
                { item: 'dark', name: 'dark' },
                { item: 'green', name: 'green' },
                ],
            materie:[],
            corsi: [],
            fieldsCorsi: [
                {'key': 'nome', 'label':'Nome Corso'},
            ],
            currentItems:[],
            partial :null,
            fieldsPartial: [
                {'key': 'nome', 'label':'Nome Corso'}
            ],
            docenti: [],
            docente:null
        }
    },
    mounted() {
        this.initSessioni()
        this.initMaterie()
        this.initCorsi()
        this.initDocenti()
    },
    computed: {
        getCorsi() {
            if(this.docente.length > 0) {
                const corsi_docente = this.corsi.filter( item => this.docente.includes(item.id))
                return corsi_docente
            }
            return this.corsi
        },
        refresh() {
            return this.sessioneSelected * 1
        }
    },
    methods: {
        setNullDocente() {
            return this.docente = []
        },
        toggleDetails(row) {
            if(row._showDetails){
                this.$set(row, '_showDetails', false)
            }else{
                this.$nextTick(() => {
                    this.$set(row, '_showDetails', true)
                })
            }
        },
        initMaterie() {
          Schedule_classroomService.getMaterie()
            .then(resp => {
                this.materie = resp.data
            })
        },
        initCorsi() {
            Schedule_classroomService.getCorsi()
                .then(resp => {
                    this.corsi = resp.data
                })
        },
        initSessioni() {
            SessioneService.getSessioni()
                .then(resp => {
                    this.sessioni = resp.data
                })
                .catch()
        },
        initSessione(value){
            SessioneService.getDetails(value)
                .then(resp => {
                    this.sessione = resp.data
                    this.start = resp.data.data_inizio_sessione
                    this.end = resp.data.data_fine_sessione
                    this.resources = resp.data.details_aule.reduce((array, item) =>{
                        item.occupancy = item.occupazione
                        array.push(item)
                        return array
                    }, [])
                    Schedule_classroomService.getAppelli(value)
                        .then(resp => {
                            // this.events = resp.data.results
                            this.events = resp.data.results.reduce((array, item) =>{
                                item.color = item.colore
                                array.push(item)
                                return array
                            }, [])
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch();
        },
        initDocenti(){
            DocentiService.getDocenti()
                .then(resp => {
                    this.docenti = resp.data.results
                })
                .catch()
        },
        getSessione(value) {
            console.log(value)
        },
        handleDateSelect(selectInfo) {
            console.log('selectInfo: ', selectInfo )
            this.currentEvent = selectInfo
            console.log(selectInfo)
            this.$bvModal.show('prenotazione')
        },
        handleEventClick(event) {
            this.clickInfo = event
            this.$bvModal.show('modificaEvento')
            // if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
            //     clickInfo.event.remove()

        },
        // handleEventsSet(arg, clickInfo) {
        //     console.log('events:', arg)
        //     console.log('clickInfo: ', clickInfo)
        //     this.currentEvent = arg
        // },
        handleChangeEvent(info){
            let infoResources = info.event.getResources();
            const resourceIds = infoResources[0]._resource.id;
            // let resourceTitle = infoResources[0]._resource.title;
            // console.log('INFO RESOURCE ID: ', resourceId)
            // console.log('INFORESOURCES: ', infoResources)
            // console.log('INFO title: ', resourceTitle)
            // const appello = {
            //     ...evento,
            //     ...resourceId
            // };
            // const appello = Object.assign(evento,resourceId);
            const appello = {
                resourceId: resourceIds,
                start:info.event.start,
                end:info.event.end,
                id:info.event.id,
            }
            console.log('APPELLO: ', appello)
            Schedule_classroomService.updateAppelli(appello)
                .then(resp => {
                    console.log(resp)
                })
                .catch()
            console.log(info.event.id, info.event.start.toISOString(), info.event.end.toISOString())
        },
        editEvent() {
            console.log(this.formEditEvent)
        },
        eventRender(info){
            // CONFIG FOR THE PopOver CLASS
            const config = {
                title: 'I am a title',
                content: "This text will show up in the body of the PopOver",
                placement: 'auto', // can use any of Popover's placements(top, bottom, right, left etc)
                container: 'null', // can pass in the id of a container here, other wise just appends to body
                boundary: 'scrollParent',
                boundaryPadding: 5,
                delay: 0,
                offset: 0,
                animation:true,
                trigger: 'hover', // can be 'click', 'hover' or 'focus'
                html: false, // if you want HTML in your content set to true.
            }

            const target = info.el;
            const toolpop = new BPopover(target, config, this.$root);

            console.log('TOOLPOP', toolpop);
        },
    },
    watch: {
        sessioneSelected: {
            handler(value) {
                this.initSessione(value)
                this.show = true
            }
        },
        docente: {
            handler() {
                return this.getCorsi
            }
        }
    }
}
</script>
<style scoped>
.fc .fc-timeline-slot-frame {
    background: #212837;
}
</style>
<!--<style scoped>-->

<!--h2 {-->
<!--    margin: 0;-->
<!--    font-size: 16px;-->
<!--}-->

<!--ul {-->
<!--    margin: 0;-->
<!--    padding: 0 0 0 1em;-->
<!--}-->

<!--li {-->
<!--    margin: 1.5em 0;-->
<!--    padding: 0;-->
<!--}-->

<!--b { /* used for event dates/times */-->
<!--    margin-right: 3px;-->
<!--}-->



<!--.demo-app-sidebar {-->
<!--    width: 300px;-->
<!--    line-height: 1.5;-->
<!--    background: #1e789b;-->
<!--    border-right: 1px solid #d3e2e8;-->
<!--}-->

<!--.demo-app-sidebar-section {-->
<!--    padding: 0.5em;-->
<!--}-->

<!--.demo-app {-->
<!--    display: flex;-->
<!--    min-height: 100%;-->
<!--    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;-->
<!--    font-size: 14px;-->
<!--}-->
<!--.demo-app-main {-->
<!--    flex-grow: 1;-->
<!--    padding: 2em;-->
<!--}-->
<!--</style>-->
