import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL


class taskListService {
    getTask() {
        this.loading = true;
        return axios.get(API_URL + 'task/', {headers: authHeader()});
    }
}
export default new taskListService();
