import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '@/store/auth.module'
import { bugs } from '@/store/bugs.module'
import { lead } from '@/store/lead.module'
import { evento } from "@/store/evento.module";
import { istituto} from "@/store/istituto.module"
import createPersistedState from "vuex-persistedstate"
import {settings} from "./settings.module";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user:'',
    },
    getters: {},
    mutations: {},
    actions: {},
    modules: {
      auth: auth,
      bugs: bugs,
      evento: evento,
      istituto: istituto,
      lead: lead,
      settings:settings,
    },
  plugins:[ createPersistedState() ]
})
