<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body>
                <b-card-header header-bg-variant="dark" header-text-variant="white">
                    <h4><b></b>Numero di Leads: <b>{{ this.studenti.length }}</b></h4>
                </b-card-header>
                <b-card-text>
                    <b-table
                        :items="studenti"
                        :fields="fieldLead"
                        small
                        striped
                        :sort-by.sync="sortBy"
                    >
                    </b-table>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row> <!--  Dettagli Studenti-->
</template>

<script>
import LeadService from "@/services/lead.service"
export default {
    name: "listaLeadsAssociati",
    props: [
        'id',
    ],
    data() {
        return {
            istituto_id: this.id,
            studenti:[],
            sortBy: 'lead_cognome',
            fieldLead:[
                {key:'lead_nome', label:'Nome', sortable:true},
                {key:'lead_cognome', label:'Cognome', sortable:true},
                {key:'lead_email', label:'Email', sortable:true},
            ],
        }
    },
    mounted() {
        this.initLead()
    },
    methods: {
      initLead() {
          LeadService.selectLeadFromInstitute(this.istituto_id)
              .then(resp => {
                  this.studenti = resp.data.results //.sort(function(a, b){return a['cognome']-b['cognome']})

              })
            .catch()
      }
    }
}
</script>

<style scoped>

</style>
