<template>
    <div id="app">
      <FilePond
        ref="Filepond"
        allow-multiple="true"
        allow-reverte="true"
        :server="server"
      ></FilePond>
      <div v-for="(upload, index) in Object.values(this.uploadProgress) " :key="index">
        <b-progress
          v-if="upload.progress !== upload.total" :value="upload.progress"
          class="mb-2"
          variant="dark"
          :max="upload.total"
          show-progress
          animated
        ></b-progress>
      </div>
    </div>
</template>

<script>
import VueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import authHeader from "@/services/auth.header";
import axios from "axios";

const API_URL = process.env.VUE_APP_API_URL


export default {
  name: "app",
  props: [],
  components: {
    FilePond: VueFilePond(
      FilePondPluginFileValidateType,
      FilePondPluginImagePreview
    )
  },
  data: function () {
    return {
      idFile: {},
      server: {
        process: (fieldName, file, metadata, load, error, progress, abort) => { // transfer, options
          const formData = new FormData();
          formData.append('file', file, file.name);
          formData.append('nome_file', file.name);

          this.uploadProgress = {
            ...this.uploadProgress,
            [file.name]: {total: 1, progress: 0}
          }

          const CancelToken = axios.CancelToken;
          const source = CancelToken.source();

          // Request Axios
          axios.post(API_URL + 'lms_media/', formData, {
            headers: authHeader(),
            cancelToken: source.token,
            onUploadProgress: (e) => {
              this.uploadProgress = {
                ...this.uploadProgress,
                [file.name]: {total: e.total, progress: e.loaded}
              }
              progress(false, e.loaded, e.total);
            }
          }).then(resp => {
            this.idFile = resp.data
            this.$emit('idFile', resp.data.id)
            this.uploadProgress = {
              ...this.uploadProgress,
              [file.name]: {total: 1, progress: 1}
            }
            load(resp.data.id)
          }).catch(err => {
            console.log(err)
            error('Upload Fallito')
          })
          return {
            abort: () => {
              source.cancel('Operation canceled by the user.');
              abort();
            },
          };
        },
        revert: (source) => {
          console.log(source)
          axios.delete(API_URL + 'lms_media/' + source + '/', {
            headers: authHeader(),
          }).then(resp => {
            console.log(resp.data)
          }).catch(err => {
            console.log(err)
          })
        },
      },
      uploadProgress: {},
      myFiles: [],
      file: "",
      setState: null
    }
  },
  watch: {},
  methods: {
    emptyFiles() {
      // eslint-disable-next-line no-debugger

      this.$refs.Filepond.removeFiles()
      console.log(this.$refs.pond)
    },
    removeFalse() {
      return false
    },
    handleProcessFile: function (error, file) {
      console.log('error: ', error, 'file: ', file)
    },
    handleFilePondInit: function (file, progress) {
      const isFileUploadPending = progress !== 1;
      console.log('isFileUploadPending: ', isFileUploadPending)
    },
    onLoad(response) {
      console.log('response: ', response)
      return response // it print the id returned by the server
    },
    onProcessFile(error, file) {
      this.file = file.filename.replace(/\s/g, '-')
    },
    onAddFile(error, file) {
      console.log('error: ', error, 'file: ', file)
    },
    async onRemoveFile() {
      console.log('rimuovo i files')
      // let url = process.env.VUE_APP_AWS_ENDPOINT + '/' + this.file // event.file.name has only the name after the slash / so the actual filename
      // await deleteObjectByKey(url)
    },

  }
}



</script>
