import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL


class SchdeuleService {
    getAule() {
        return axios.get(API_URL + 'aule/', {headers: authHeader() })
    }
    getAppelli(sessione) {
        return axios.get(API_URL + 'appelli/?sessione=' + sessione, { headers: authHeader() })
    }
    postAppelli(payload) {
        return axios.post(API_URL + 'appelli/', payload, {headers: authHeader()})
    }
    updateAppelli(payload){
        return axios.patch(API_URL + 'appelli/' + payload.id + '/', payload, {headers:authHeader()} )
    }
    getMaterie() {
        return axios.get(API_URL + 'modulo/', { headers: authHeader() })
    }
    getCorsi() {
        return axios.get(API_URL + 'corsi/', {headers: authHeader()})
    }
    getPartialCorsi(id){
        return axios.get(API_URL + 'corsi/' + id, {headers:authHeader()})
    }
    getLezioni() {
        return axios.get(API_URL + 'prenotazione_aule/?page_size=1000', {headers:authHeader()})
    }
}

export default new SchdeuleService();
