<template>
  <b-modal
    id="ViewPreview"
    size="xl"
    title="Preview Lezione"
    header-bg-variant="dark"
    header-text-variant="white"
  >
    <b-card border-variant="white">
      <b-card-title class="d-inline-flex">
        <p class="text-muted mr-3">Titolo Lezione:</p> <h4>{{ preview.titolo }}</h4>
      </b-card-title>
      <b-card-sub-title>
        <div class="d-inline-flex">
          <p class="text-muted mr-3">Annotazioni:</p> <h6 class="text-dark">{{ preview.annotazione }}</h6>
        </div>
      </b-card-sub-title>
      <b-card-body>
          <b-row v-for="(file, index) in preview.files" :key="index" cols="12">
            <b-col v-if="file.fileType === 'video'">
              <b-card
                class="mb-2">
                <div class="d-flex justify-content-between align-items-center"><i class="fas fa-film"></i>
                  {{ file.fileExt }}
                </div>
                <video-player
                  :options="{
                           autoplay: false,
                           controls: true,
                           fluid: true,
                           bigPlayButton: true,
                           sources: [{src: file.file}, ],
                   }"
                />
              </b-card>
            </b-col>
            <!-- FILE AUDIO -->
            <b-col v-if="file.fileType === 'audio'">
              <b-card
                class="mb-2">
                <div class="d-flex justify-content-between align-items-center">
                  <i class="fas fa-volume-up"></i> {{ file.fileExt }}
                </div>
                <video-player
                  id="audio"
                  :options="{
                          autoplay: false,
                          controls: true,
                          fluid: true,
                          bigPlayButton: true,
                         sources: [{src: file.file}, ],
                        }"
                />
              </b-card>
            </b-col>
            <!-- FILE DOC -->
            <b-col v-if="file.fileType === 'doc'">
              <b-card
                class="mb-2">
                <div class="d-flex justify-content-between align-items-center"><i
                  class="fas fa-file-alt"></i>{{ file.nome_file }} <a download="" v-bind:href="file.file" target="_blank">
                  <b-button variant="outline-primary">Download</b-button>
                </a></div>
              </b-card>
            </b-col>
          </b-row>
      </b-card-body>
    </b-card>
  </b-modal>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer";

export default {
  name: "ViewPreview",
  props:['preview'],
    components: {
    VideoPlayer,
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped>

</style>
