import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL


class TestService {
    getSessione() {
        return axios.get(API_URL + 'sessioni_test/', { headers:authHeader()})
    }
    addSessione(payload){
        return axios.post(API_URL + 'sessioni_test/', payload, { headers: authHeader()})
    }
    editSessione(payload){
        return axios.patch(API_URL + 'sessioni_test/' + payload.id + '/', payload, {headers: authHeader()} )
    }
    getTest() {
        return axios.get(API_URL + 'test_ingresso/', { headers: authHeader() })
    }
    getLingue(){
        return axios.get(API_URL + 'lingua_istituto/', { headers: authHeader()})
    }
    postTest(payload){
        return axios.post(API_URL + 'test_ingresso/', payload, {headers: authHeader()})
    }
    patchTest(payload){
        return axios.patch(API_URL + 'test_ingresso/' + payload.id + '/', payload, { headers: authHeader()})
    }
    getSessioneFromID(id){
        return axios.get(API_URL + 'test_ingresso/?sessione=' + id, { headers:authHeader()})
    }
    getLeadFromID(id){
        return axios.get(API_URL + 'lead/?active=&email=&id=' + id, { headers:authHeader()})
    }
}
export default new TestService();
