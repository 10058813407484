<template>
    <b-sidebar id="aggiungi-istituto"
               title="Aggiungi Istituto"
               no-header
               right
               shadow='lg'
               :visible="visible"
               backdrop
               backdrop-variant="dark"
               width="50%">
        <template #footer="{ hide }">
            <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                <b-button variant="success" @click="inserisciScuola()">Inserisci</b-button>
                <b-button variant="danger"  @click="hide" class="ml-3">Chiudi</b-button>
                <strong class="ml-auto">|</strong>
            </div>
        </template>
            <b-card bg-variant="dark" class="rounded-0">
                <h4 style="color: #f9f9fa">Inserisci un nuovo Istituto</h4>
                <b-button variant="yellow" v-b-modal.modal-ricerca>Ricerca Scuole</b-button>
            </b-card>
            <b-card >
                <b-tabs nav-class="nav-tabs-v2" content-class="pt-2" fill>
                    <b-tab title="Anagrafica Principale" active card>
                            <b-form>
                                <b-row>
                                    <b-col>
                                        <b-card class="mb-2 pb-0" bg-variant="gray-050">
                                            <div class="d-inline-flex">
                                                <b-form-group label="Cod. Istituto:" label-for="codiceIstituto" class="w-25 mr-2">
                                                    <b-form-input
                                                        id="codiceIstituto"
                                                        v-model="form.codice_istituto"
                                                        placeholder="Codice Ist"
                                                        size="sm"
                                                    ></b-form-input>
                                                </b-form-group>
                                                <b-form-group id="nome_istituto" label="Nome Istituto:" label-for="nomeIstituto" class="w-75" >
                                                    <b-form-input
                                                        id="nomeIstituto"
                                                        type="text"
                                                        placeholder="Nome"
                                                        size="sm"
                                                        name="nome_istituto"
                                                        v-model = "form.nome_istituto"
                                                    ></b-form-input>
                                                    <b-link @click="associaIstituto"><small>Istituto Padre</small></b-link>
                                                    <small class="ml-2" v-if="nomeIstittoPadre">istituto associato con: {{ nomeIstittoPadre }}</small>
                                                </b-form-group>
                                            </div>
                                        </b-card>
                                        <b-card class="card-shadow" bg-variant="gray-050">
                                            <div class="d-inline-flex">
                                                <b-form-group id="regione" label="Regione:" label-for="regione" class="w-75 mr-2">
                                                    <b-form-input
                                                        id="regione"
                                                        placeholder="Regione"
                                                        size="sm"
                                                        v-model="form.regione"
                                                    ></b-form-input>
                                                </b-form-group>
                                                <b-form-group id="provincia" label="Provincia:" label-for="provincia" class="w-25">
                                                    <b-form-input
                                                        id="provincia"
                                                        placeholder="Provincia"
                                                        size="sm"
                                                        v-model="form.provincia"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </div>

                                            <b-form-group class="w-75" label="Città:" label-for="città">
                                                <b-form-input
                                                    id="citta"
                                                    v-model="form.citta"
                                                    placeholder="Città"
                                                    size="sm"
                                                ></b-form-input>
                                            </b-form-group>
                                            <div class="d-inline-flex">
                                                <b-form-group class="mr-2" id="indirizzo1" label="Indirizzo1:" label-for="indirizzo1">
                                                    <b-form-input
                                                        id="indirizzo1"
                                                        placeholder="Indirizzo"
                                                        size="sm"
                                                        v-model="form.indirizzo1"
                                                    ></b-form-input>
                                                </b-form-group>
                                                <b-form-group id="cap1" label="Cap1:" label-for="cap1">
                                                    <b-form-input
                                                        id="cap1"
                                                        placeholder="CAP"
                                                        size="sm"
                                                        v-model="form.cap"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </div>
                                        </b-card>
                                    </b-col>
                                    <b-col>
                                        <b-card bg-variant="gray-050">
                                            <div class="d-inline-flex">
                                                <b-form-group class="mr-2" label="Email1:" label-for="email1">
                                                    <b-form-input
                                                        id="email"
                                                        v-model="form.email1"
                                                        placeholder="Email"
                                                        size="sm"
                                                    ></b-form-input>
                                                </b-form-group>
                                                <b-form-group label="Telefono1:" label-for="telefono1">
                                                <b-form-input
                                                    id="telefono1"
                                                    v-model="form.telefono1"
                                                    placeholder="Telefono"
                                                    size="sm"
                                                ></b-form-input>
                                            </b-form-group>
                                            </div>
                                            <b-form-group id="website" label="WebSite:" label-for="website">
                                                <b-form-input
                                                    id="website"
                                                    placeholder="WebSite"
                                                    size="sm"
                                                    v-model="form.website"
                                                ></b-form-input>
                                            </b-form-group>
                                            <b-form-group label="Iscritti:">
                                                <b-form-select
                                                    id="iscritti"
                                                    v-model="form.numero_iscritti"
                                                    :options="iscritti"
                                                    size="sm"
                                                >
                                                </b-form-select>
                                            </b-form-group>
                                                <b-form-group>
                                                    <b-form-textarea
                                                        id="note"
                                                        v-model="form.note"
                                                        placeholder="Note..."
                                                        rows="7"
                                                        max-rows="8">
                                                    </b-form-textarea>
                                                </b-form-group>
                                        </b-card>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-2">
                                    <b-col>

                                    </b-col>
                                </b-row>
                            </b-form>
                    </b-tab>
                    <b-tab title="Informazioni secondarie">
                            <b-row cols="12">
                                <b-col cols="6">
                                    <b-card bg-variant="gray-050">
                                        <b-form-group label="Indirizzo 2">
                                            <b-form-input v-model="form.indirizzo2" type="text" placeholder="Indirizzo 2"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Email 2">
                                            <b-form-input v-model="form.email2" type="text" placeholder="Email 2"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Telefono 2">
                                            <b-form-input v-model="form.telefono2" type="text" placeholder="Telefono 2"></b-form-input>
                                        </b-form-group>
                                    </b-card>
                                </b-col >
                                <b-col cols="6">
                                    <b-card bg-variant="gray-050">
                                        <b-form-group label="Indirizzo 3">
                                            <b-form-input v-model="form.indirizzo3" type="text" placeholder="Indirizzo 3"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Email 3">
                                            <b-form-input v-model="form.email3" type="text" placeholder="Email 3"></b-form-input>
                                        </b-form-group>
                                        <b-form-group label="Telefono 3">
                                            <b-form-input v-model="form.telefono3" type="text" placeholder="Telefono 3"></b-form-input>
                                        </b-form-group>
                                    </b-card>
                                </b-col>
                            </b-row>
                    </b-tab>
                    <b-tab title="Tipologia&Lingue">
                        <b-card bg-variant="gray-050">
                            <b-form-group label="Tipologia Istituto">
                                <b-form-select
                                    v-model="form.tipologia"
                                    multiple
                                    :options="tipologie"
                                    value-field="id"
                                    :select-size="12"
                                    text-field="tipologia">
                                </b-form-select>
                            </b-form-group>
                            <b-badge pill variant="dark" v-for="(id, index) in form.tipologia" :key="index" class="mr-2">
                                {{ get_tipologia_from_options(id) }}
                            </b-badge>
                        </b-card>
                        <b-card class="mt-2" bg-variant="gray-050">
                            <b-row>
                                <b-col>
                                    <b-form-checkbox
                                        id="seconda-lingua"
                                        v-model="form.seconda_lingua"
                                        size="sm"
                                    >Seconda Lingua </b-form-checkbox>
                                </b-col>
                                <b-col>
                                    <b-form-checkbox
                                        class="right"
                                        id="terza-lingua"
                                        v-model="form.terza_lingua"
                                        size="sm"
                                    > Terza Lingua</b-form-checkbox>
                                </b-col>
                            </b-row>
                            <hr>
                            <b-form-group >
                                <b-form-select
                                    v-model="form.lingua"
                                    text-field="lingua"
                                    value-field="id"
                                    multiple
                                    :select-size="5"
                                    :options="options"
                                ></b-form-select>
                            </b-form-group>
                            <b-badge pill variant="dark" v-for="(id, index) in form.lingua" :key="index" class="mr-2">
                                {{ get_lingua_from_options(id) }}
                            </b-badge>
                        </b-card>
                    </b-tab>
                 </b-tabs>
            </b-card>
        <b-modal size="lg" id="modal-ricerca" title="Ricerca Scuole" hide-footer header-bg-variant="dark"
                 header-text-variant="white">
            <search-scuole @insertCode="insertCode" />
        </b-modal>
        <b-modal size="lg" title="Associa ad Istituto Padre" id="associaIstituto" header-bg-variant="dark" header-text-variant="white">
            <search-istituti @inserisciIstituto="prendoIdIstitutoEAssocio"></search-istituti>
        </b-modal>
    </b-sidebar>
</template>

<script>
import searchScuole from "@/components/crm/searchScuole";
import searchIstituti from "@/components/crm/searchIstituti";
import IstitutiService from "@/services/istituto.service"
export default {
    components: {
        searchScuole,
        searchIstituti
    },
    name: "addIstituto",
    data(){
        return {
            options:[],
            tipologie:[],
            value: [],
            //////////////////////
            form: {
                codice_istituto:"",
                anno_scolastico: "",
                nome_istituto: "",
                parent:null,
                regione: "",
                indirizzo1: "",
                indirizzo2: "",
                indirizzo3: "",
                cap: "",
                citta: "",
                provincia: "",
                email1:'',
                email2:'',
                email3:'',
                telefono1:'',
                telefono2:'',
                telefono3:'',
                orientamento:'',
                seconda_lingua:false,
                terza_lingua:false,
                scuola:"",
                numero_iscritti:"",
                lingua:[],
                tipologia: [],
                note:'',
                website:''
            },
            iscritti: [
                {value: 'Da 0 a 250', text: 'Da 0 a 250'},
                {value: 'Da 251 a 500', text: 'Da 251 a 500'},
                {value: 'Da 501 a 750', text: 'Da 501 a 750'},
                {value: 'Da 751 a 1000', text: 'Da 751 a 1000'},
                {value: 'Da 1001 a 1500', text: 'Da 1001 a 1500'}
            ],
            anno_scolastico: [],
                // { value: null, text: 'Please select an option' },
                // { value: 1, text: '2019' },
                // { value: 2, text: '2020' },
                // { value: 3, text: '2021' },
            language:[],
            // lingua: [
            //     { value: null, text: 'Please select an option' },
            //     { value: "Spagnolo", text: 'Spagnolo' },
            //     { value: "Francese", text: 'Francese' },
            //     { value: "Tedesco", text: 'Tedesco' },
            //     { value: "Russo", text: 'Russo' },
            //     { value: "Cinese", text: 'Cinese' },
            //     { value: "Giapponese", text: 'Giapponese' },
            //     { value: "Arabo", text: 'Arabo' },
            //     { value: "Portoghese", text: 'Portoghese' },
            //     { value: "Coreano", text: 'Coreano' },
            // ],
            lingue_selezionate:[],
            orientamento: [],
            visible:false,
            disabled: false,
            nomeIstittoPadre:''
        }
    },
    mounted() {
        this.getAnniScolastici()
        this.getLingue()
        this.getTipologie()
    },
    methods: {
        get_lingua_from_options(id) {
            const elem = this.options.find(e => {
                return e.id === id
            })
            return elem ? elem.lingua : null
        },
        get_tipologia_from_options(id) {
            const elem = this.tipologie.find(e => {
                return e.id === id
            })
            return elem ? elem.tipologia : null
        },
        handleSelect(options) {
            console.log(options);
        },
        insertCode(value) {
            console.log(value)
            this.form.codice_istituto = value.codice_scuola
            this.scuola = value.codice_scuola
            this.form.nome_istituto = value.denominazione_scuola
            this.form.regione = value.regione
            this.form.provincia = value.provincia
            this.form.citta = value.descrizione_comune
            this.form.indirizzo1 = value.indirizzo_scuola
            this.form.cap = value.cap_scuola
            this.form.email1 = value.indirizzo_email_scuola
            this.form.website = value.sito_web_scuola
            this.form.note = "PEC: " + value.indirizzo_pec_scuola
            this.$bvModal.hide('modal-ricerca')
        },
        getAnniScolastici() {
            IstitutiService.getAnniScolastici()
                .then(resp => {
                    this.anno_scolastico = resp.data
                })
                .catch()
        },
        getLingue() {
            IstitutiService.getLingue()
                .then(resp => {
                    this.options = resp.data
                })
                .catch()
        },
        getTipologie() {
            IstitutiService.getTipologie()
                .then(resp => {
                    this.tipologie = resp.data
                })
                .catch()
        },
        inserisciScuola() {
            IstitutiService.insertIstituto(this.form)
                .then(() => {
                    this.$root.$emit('bv::toggle::collapse', 'aggiungi-istituto')
                    this.$emit('show_alert')

                })
                .catch(e => {
                    let error;
                    for (error; error in e;)
                        console.log(this.error.response.data)
                    this.$bvToast.toast(`Errore: ${e.status}`, {
                        title: 'Errore Istituto',
                        variant: 'danger',
                        solid: true
                    })
                })
        },
        associaIstituto() {
            this.$bvModal.show('associaIstituto')
        },
        prendoIdIstitutoEAssocio(item){
            this.form.parent = item.id
            this.nomeIstittoPadre = item.nome_istituto

        }
    }
}
</script>

<style scoped>
.card-shadow {
    font-shadow: 2px 2px #333 ;
}
</style>

