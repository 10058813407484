export const evento = {
    namespaced: true,
    state: {
        evento: {}
    },
    mutations: {
        GETEVENTO(state, evento) {
            state.evento = evento
        }
    },
    actions: {
        getEvento({commit}, evento) {
            commit('GETEVENTO', evento)
        }
    },
}
