<template>
	<!-- BEGIN error -->
	<div class="error-page">
		<!-- BEGIN error-page-content -->
		<div class="error-page-content">
			<div class="error-img">
				<div class="">RESTRICTED AREA</div>
				<img src="public/assets/img/page/404.svg" alt=""  />
			</div>
            <br>
            <div>
                <h1>Oops!</h1>
                <h3>Spiacenti ma questa è una zona riservata!</h3>
                <p class="text-muted mb-2">
                    Here are some helpful links instead:
                </p>
                <router-link to="/dashboard" class="btn btn-primary">Go to Homepage</router-link>
            </div>
		</div>
		<!-- END error-page-content -->
	</div>
	<!-- END error -->
</template>

<script>
	import AppOptions from '../config/AppOptions.vue'

	export default {
		mounted() {
			AppOptions.appWithoutSidebar = true;
			AppOptions.appWithoutHeader = true;
			AppOptions.appContentFullWidth = true;
		},
		beforeRouteLeave (to, from, next) {
			AppOptions.appWithoutSidebar = false;
			AppOptions.appWithoutHeader = false;
			AppOptions.appContentFullWidth = false;
			next();
		},
	}
</script>
