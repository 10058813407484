<template>
  <b-modal
    v-model="showModal"
    size="lg"
    id="ModaleEventoIstituto"
    hide-footer
    header-bg-variant="dark"
    header-text-variant="white"
    :title="title"
    @hide="closeModal"
  ><b-card no-body border-variant="white">
      <b-form v-if="evento" class="mt-2">
        <b-row>
          <b-col class="d-inline-flex">
            <b>Evento con:</b>
            <b-form-checkbox
              class="ml-3"
              v-model="istitutoChecked"
              disabled
            >Istituto</b-form-checkbox>
            <b-form-checkbox
              class="ml-3"
              v-model="contattoChecked"
            >Contatto</b-form-checkbox>
            <span class="ml-5">parent: {{ replyID }}</span>
          </b-col>
        </b-row>
        <hr class="text-muted">
        <b-row>
          <b-col>
            <b-card border-variant="white">
              <label>Scegli il tipo:</label><span class="text-danger ml-2">Required</span>
              <b-form-select
                v-model="evento.tipo"
                :options="tipoChoices"
                value-field="value"
                text-field="text"
                size="sm">
              </b-form-select>
              <label>Oggetto</label>
              <b-form-input v-model="evento.oggetto"></b-form-input>
              <label>Note: </label><span class="text-danger ml-2">Required</span>
              <b-form-textarea
                v-model="evento.note"
                placeholder="inserisci la nota..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-card>
          </b-col>
          <b-col>
            <b-card border-variant="white" aria-valuemax="500">
              <label>Data evento</label>
              <b-form-datepicker
                v-model="evento.data_evento"
                locale="it"
                today-button
                reset-button
                close-button
                :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
              ></b-form-datepicker>
              <label>Luogo</label>
              <b-form-input v-model="evento.luogo"></b-form-input>
              <b-form-group label="Condividi Con:">
                <b-form-select
                  v-if="eventManagersLoaded"
                  multiple
                  :options="eventManagers"
                  v-model="evento.event_managers"
                  value-field="id"
                  text-field="email">
                </b-form-select>
              </b-form-group>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-checkbox>Inviata Dem</b-form-checkbox>
            <b-form-checkbox>Partecipato OD</b-form-checkbox>
            <hr>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card class="mt-2" border-variant="white">
              <b-button variant="dark" size="md" class="mr-2 float-right" @click="onSubmit" v-if="!isEdit">Inserisci</b-button>
              <b-button variant="dark" size="md" class="mr-2 float-right" @click="onSubmit" v-else>Modifica</b-button>
              <b-button type="reset" variant="danger" size="md" class="mr-2 float-right">Reset</b-button>
              <b-button variant="secondary" size="md" @click="closeModal" class="mr-2 float-right">Chiudi</b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-modal>
</template>

<script>
import EventService from '@/services/event.service'
import UserService from '@/services/user.service'

export default {
  name:"ModaleEventoIstituto",
  props: {
    eventLogIdToEdit: {
      type: [Number, null],
      default: null
    },
    replyID: {
      type: [Number, null],
      default: null
    },

  },
  data() {
    return {
      showModal: false,
      title: 'Aggiungi Evento',
      istitutoChecked: true,
      contattoChecked: false,
      eventManagers: [],
      eventManagersLoaded: false,
      evento: null,
      showForm: true,
      tipoChoices: [
        {value: null, text: 'Scegli un tipo di evento'},
        {value: 'to_do', text: 'To Do'},
        {value: 'call_in', text: 'Chiamata in entrata'},
        {value: 'call_out', text: 'Chiamata in uscita'},
        {value: 'email_in', text: 'Email Ricevuta'},
        {value: 'email_out', text: 'Email Inviata'},
        {value: 'app_tel', text: 'Appuntamento Telefonico'},
        {value: 'appuntamento', text: 'Appuntamento'},
        {value: 'whatsapp', text: 'Whatsapp'},
        {value: 'dem', text: 'DEM'},
        {value: 'vsa', text: 'Visita senza appuntamento'},
        {value: 'sms', text: 'Spedizione materiale a scuola'},
        {value: 'll', text: 'Lezione / Laboratorio'},
        {value: 'pcto', text: 'PCTO'},
        {value: 'oas', text: 'Orientamento a scuola'},
        {value: 'live chat', text: 'Live Chat'},
        {value: 'instagram', text: 'Instagram'},
      ],
      isEdit: null,
      replayID: null
    }
  },
  computed: {},
  mounted() {
    this.initEventManagerChoices().then(
      () => {
        this.initForm()
      }
    );
  },
  watch: {
    eventLogIdToEdit (val) {
      if (val) {
        this.isEdit = true
        this.initForm()
      } else {
        this.isEdit = false
        this.evento = {
          contatto: '',
          istituto: '',
          oggetto: '',
          note: '',
          data_evento: null,
          luogo: '',
          tipo: null,
          event_managers: [],
        }
        this.showModal = true
        console.log('id null')
      }

    },
    istitutoChecked(value) {
      this.evento.istituto = value ? this.$store.state.istituto.istituto.id : ''
    },
    contattoChecked(value) {
      this.evento.contatto = value ? this.$store.state.istituto.contatto.id : ''
    },
    replyID(value) {
      this.replayID = this.replyID
      console.log('Sono nel watch della modale: ', value)
      if (value !== null) {
        this.evento = {
          contatto: '',
          istituto: '',
          oggetto: '',
          note: '',
          reply:true,
          parent: this.replayID,
          data_evento: null,
          luogo: '',
          tipo: null,
          event_managers: [],
        }
        console.log('questo è levento nel watch: ',this.evento)
      }
    }
  },
  methods: {
    initEventManagerChoices() {
      return UserService.getEventManager()
        .then(
          (resp) => {
            this.eventManagers = resp.data;
            this.eventManagersLoaded = true;
          }
        )
    },
    initForm() {
      if (this.isEdit) {
        this.title = 'Modifica Evento'
        EventService.getEventLogById(this.eventLogIdToEdit).then(
          resp => {
            const evento = resp.data
            this.evento = {
              contatto: evento.contatto,
              istituto: evento.istituto,
              oggetto: evento.oggetto,
              note: evento.note,
              data_evento: evento.data_evento,
              luogo: evento.luogo,
              tipo: evento.tipo,
              event_managers: evento.event_managers,
            }
            this.contattoChecked = evento.contatto !== null
            this.istitutoChecked = evento.istituto !== null
          }
        )
      }
      if (this.replyID !== null) {
        this.title = 'Reply Evento'
        this.evento = {
          contatto: '',
          istituto: '',
          oggetto: '',
          note: '',
          parent: this.replayID,
          reply:true,
          data_evento: null,
          luogo: '',
          tipo: null,
          event_managers: [],
        }
      }
      else {
        this.evento = {
          contatto: '',
          istituto: '',
          oggetto: '',
          note: '',
          reply:false,
          data_evento: null,
          luogo: '',
          tipo: null,
          event_managers: [],
        }
      }
    },
    closeModal() {
      this.replayID = null
      this.istitutoChecked = true
      this.contattoChecked = false
      this.isEdit = false
      this.evento = {
        contatto: '',
        istituto: '',
        oggetto: '',
        note: '',
        data_evento: null,
        luogo: '',
        parent:null,
        reply: false,
        tipo: null,
        event_managers: [],
      }
      this.$bvModal.hide('ModaleEventoIstituto')
    },
    onSubmit() {
      this.evento['account'] = this.$store.state.auth.user.user_id
      this.evento['istituto'] = this.$store.state.istituto.istituto.id
      const xhr = this.isEdit ? EventService.patchEventLog(this.eventLogIdToEdit, this.evento) : EventService.postEventLog(this.evento)
      xhr.then(
        () => {
          this.$bvToast.toast(`Evento Modificato con successo`, {
            title: 'Modifica Evento',
            autoHideDelay: 5000,
            variant: 'success'
          })
          this.$bvModal.hide('ModaleEventoIstituto')
        }
      ).catch(error => {
        console.log(error)
      })
    },
  }
}
</script>

<style scoped>

</style>
