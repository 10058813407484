import axios from 'axios';
const API_URL = process.env.VUE_APP_APP_URL
const RESET_PASSWORD = process.env.VUE_APP_RESET_PASSWORD
import authHeader from '@/services/auth.header';

class AuthService {
  login(user) {
    return axios
      .post(API_URL + 'authenticate/', {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          console.log(response.data)
          localStorage.setItem('local_user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }
  logout() {
    return axios.post(API_URL + 'rest-auth/logout/', { headers: authHeader()}).then(
      () => {
        localStorage.removeItem('user')
      },
    ).catch(error => {
      console.log(error)
    })

  }
  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
  updateUser(payload){
    return axios.patch(API_URL + 'accounts/' + payload.id + '/', payload, { headers: authHeader()} )
  }
  changePassword(payload) {
    return axios.put(API_URL + "change-password/", payload, { headers : authHeader() })
  }
  sendEmailToReset(email) {
    console.log(RESET_PASSWORD)
    return axios.post(RESET_PASSWORD + 'request-reset-email/', email)
  }
  checkTokenPassword(uidb64, token) {
    return axios.get(RESET_PASSWORD+ 'reset-password/' + uidb64 + '/' + token)
  }
  sendNewPassword(payload) {
    return axios.patch(RESET_PASSWORD + 'password-reset-complete/', payload)
  }

}
export default new AuthService();
