<template>
    <b-sidebar
        id="sidebar-studente"
        ref="sidebar-studente"
        :right=this.transition
        width="100%"
        no-header
        left
        @hidden="clearSidebarForm()"
    >
        <b-card bg-variant="dark" class="rounded-0">
            <b-row>
                <b-col cols="3">
                    <h4 style="color: #f9f9fa">{{ this.title_modal }} Studente</h4>
                    <small class="text-muted">nomeComponente: src/components/studente/sidebarStudente.vue</small>
                </b-col>
                <b-col cols="7">
                </b-col>
                <b-col cols="2">
                    <span style="color: white">Vecchia Matricola: {{ this.studente.vecchio_numero_matricola }}</span><br>
                    <span style="color: white">Matricola: {{ this.studente.matricola }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="rounded-0">
            <b-tabs nav-class="nav-tabs-v2" content-class="pt-2" card>
                <b-form ref="form" >
                    <!-- INFORMAZIONI ANAGRAFICA STUDENTE -->
                    <b-tab title="Anagrafica Principale" active bg-variant="white">
                        <b-card bg-variant="white" no-body border-variant="white">
                            <b-row>
                                <b-col cols="3">
                                    <b-card title="Nome/Cognome:" bg-variant="gray-100" class="text-center shadow">
                                        <b-form-group
                                            class="text-left"
                                            label="Nome:"
                                            label-for="nome">
                                            <b-form-input size="sm" id="nome" ref="nome" v-model="studente.nome"></b-form-input>
                                        </b-form-group>
                                        <b-form-group
                                            class="text-left"
                                            label="Cognome:"
                                            label-for="cognome">
                                            <b-form-input  size="sm" id="cognome" v-model="studente.cognome" ></b-form-input>
                                        </b-form-group>
                                    </b-card>
                                </b-col>
                                <b-col cols="3">
                                    <b-card class="text-center shadow" title="Data e luogo di nascita:" bg-variant="gray-100">
                                        <b-form-group
                                            class="text-left"
                                            label="Nato/a a:"
                                            label-for="nato_a">
                                            <b-form-input  size="sm" id="luogo_di_nascita" v-model="studente.luogo_di_nascita"></b-form-input>
                                        </b-form-group>
                                        <b-form-group
                                            class="text-left"
                                            label="Data di Nascita:"
                                            label-for="data_nascita">
                                            <b-form-datepicker size="sm" id="data_nasscita" v-model="studente.data_nascita" locale="it" ></b-form-datepicker>
                                        </b-form-group>
                                    </b-card>
                                </b-col>
                                <b-col cols="6">
                                    <b-card title="Recapiti:" bg-variant="gray-100" class="shadow">
                                        <b-row>
                                            <b-col cols="6">
                                                <b-form-group
                                                    label="Email:"
                                                    label-for="email">
                                                    <b-form-input  size="sm" id="email" v-model="studente.email"></b-form-input>
                                                </b-form-group>
                                                <b-form-group
                                                    label="Email Istituzionale:"
                                                    label-for="email_istituzionale">
                                                    <b-form-input type="text" size="sm" id="email_istituzionale" v-model="studente.email_istituzionale"></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="6">
                                                <b-form-group
                                                    label="Telefono:"
                                                    label-for="telefono_fisso">
                                                    <b-form-input size="sm" id="telefono_fisso" v-model="studente.telefono_fisso"></b-form-input>
                                                </b-form-group>
                                                <b-form-group
                                                    label="Cellulare:"
                                                    label-for="telefono_cellulare">
                                                    <b-form-input size="sm" id="telefono_cellulare" v-model="studente.telefono_cellulare"></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-card>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">
                                    <b-card class="mt-2 shadow" title="Residenza:" bg-variant="gray-100">
                                        <div class="d-lg-inline-flex">
                                            <b-form-group
                                                label="Indirizzo Residenza:"
                                                label-for="indirizzo_residenza">
                                                <b-form-input size="sm" id="indirizzo_residenza" v-model="studente.indirizzo_residenza"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Civico:"
                                                label-for="numero_civico_residenza" style="max-width: 50px">
                                                <b-form-input size="sm" id="numero_civico_residenza" v-model="studente.numero_civico_residenza"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Città:"
                                                label-for="citta_di_residenza">
                                                <b-form-input size="sm" id="citta_di_residenza" v-model="studente.citta_di_residenza"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Comune:"
                                                label-for="comune_di_residenza">
                                                <b-form-input size="sm" id="comune_di_residenza" v-model="studente.comune_di_residenza"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Cap:"
                                                label-for="cap_di_residenza"
                                                style="max-width: 60px">
                                                <b-form-input size="sm" id="cap_di_residenza" v-model="studente.cap_di_residenza"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Provincia:"
                                                label-for="provincia_di_residenza">
                                                <b-form-input size="sm" id="provincia_di_residenza" v-model="studente.provincia_di_residenza"></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </b-card>
                                </b-col>
                                <b-col cols="6">
                                    <b-card class="mt-2 shadow" title="Domicilio:" bg-variant="gray-100">
                                        <div class="d-lg-inline-flex">
                                            <b-form-group
                                                label="Indirizzo Domicilio:"
                                                label-for="indirizzo_domicilio">
                                                <b-form-input size="sm" id="indirizzo_domicilio" v-model="studente.indirizzo_domicilio"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Civico:"
                                                label-for="numero_civico_domicilio" style="max-width: 50px">
                                                <b-form-input size="sm" id="numero_civico_domicilio" v-model="studente.numero_civico_domicilio"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Città:"
                                                label-for="citta_di_domicilio">
                                                <b-form-input size="sm" id="citta_di_domicilio" v-model="studente.citta_di_domicilio"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Comune:"
                                                label-for="comune_di_domicilio">
                                                <b-form-input size="sm" id="comune_di_domicilio" v-model="studente.comune_di_domicilio"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Cap:"
                                                label-for="cap_di_domicilio"
                                                style="max-width: 60px">
                                                <b-form-input size="sm" id="cap_di_domicilio" v-model="studente.cap_di_domicilio"></b-form-input>
                                            </b-form-group>
                                            <b-form-group
                                                label="Provincia:"
                                                label-for="provincia_di_domicilio">
                                                <b-form-input size="sm" id="provincia_di_domicilio" v-model="studente.provincia_di_domicilio"></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </b-card>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="3">
                                    <b-card title="Matricola:" class="mt-2 shadow" bg-variant="gray-100">
                                        <b-form-group
                                            label="Vecchio Numero di Matricola:"
                                            label-for="vecchio_numero_matricola">
                                            <b-form-input size="sm" id="vecchio_numero_matricola" v-model="studente.vecchio_numero_matricola"></b-form-input>
                                        </b-form-group>
                                        <b-form-group
                                            label="Numero Matricola Nuovo:"
                                            label-for="matricola">
                                            <b-form-input size="sm" id="matricola" v-model="studente.matricola"></b-form-input>
                                        </b-form-group>
                                    </b-card>
                                </b-col>
                                <b-col>
                                    <b-card title="Documenti:" class="mt-2 shadow" bg-variant="gray-100">
                                        <b-card-body >
                                            <b-row>
                                                <b-col class="d-inline-flex">
                                                    <b-form-group
                                                        class="ml-5"
                                                        label="CI:"
                                                        label-for="vecchio_numero_matricola">
                                                        <b-form-checkbox
                                                            size="sm"
                                                            id="carta_identita"
                                                            v-model="studente.carta_di_identita">{{ studente.carta_di_identita === false ? 'No': 'Si'  }}</b-form-checkbox>
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="ml-5"
                                                        label="Diploma:"
                                                        label-for="diploma">
                                                        <b-form-checkbox size="sm" id="diploma" v-model="studente.diploma">{{ studente.diploma === false ? 'No': 'Si' }}</b-form-checkbox>
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="ml-5"
                                                        label="Cert. Dipl.:"
                                                        label-for="certificato_diploma">
                                                        <b-form-checkbox size="sm" id="certificato_diploma" v-model="studente.certificato_diploma">{{ studente.certificato_diploma === false ? 'No': 'Si' }}</b-form-checkbox>
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="ml-5"
                                                        label="Autocertificazione"
                                                        label-for="autocertificazione">
                                                        <b-form-checkbox size="sm" id="autocertificazione" v-model="studente.autocertificazione">{{ studente.autocertificazione === false ? 'No': 'Si' }}</b-form-checkbox>
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="ml-5"
                                                        label="M. da Bol."
                                                        label-for="marca_bollo">
                                                        <b-form-checkbox size="sm" id="marca_bollo" v-model="studente.marca_bollo">{{ studente.marca_bollo === false ? 'No': 'Si' }}</b-form-checkbox>
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="ml-5"
                                                        label="Cert. Ling."
                                                        label-for="certificazione_linguistica">
                                                        <b-form-checkbox size="sm" id="certificazione_linguistica" v-model="studente.certificazione_linguistica">{{ studente.certificazione_linguistica === false ? 'No': 'Si' }}</b-form-checkbox>
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="ml-5"
                                                        label="Rinuncia Form"
                                                        label-for="rinuncia_formale">
                                                        <b-form-checkbox size="sm" id="rinuncia_formale" v-model="studente.rinuncia_formale">{{ studente.rinuncia_formale === false ? 'No': 'Si' }}</b-form-checkbox>
                                                    </b-form-group>
                                                    <b-form-group
                                                        class="ml-5"
                                                        label="Fototessera"
                                                        label-for="fototessera">
                                                        <b-form-checkbox size="sm" id="fototessera" v-model="studente.fototessera">{{ studente.fototessera === false ? 'No': 'Si' }}</b-form-checkbox>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <hr>
                                            <b-row>
                                                <b-col class="d-inline-flex">
                                                    <b-form-group
                                                        label="Carta d'Identità: ">
                                                        <b-form-file size="sm" placeholder="Carta Identità"></b-form-file>
                                                    </b-form-group>
                                                    <b-form-group
                                                        label="Diploma">
                                                        <b-form-file size="sm" placeholder="Diploma"></b-form-file>
                                                    </b-form-group>
                                                    <b-form-group
                                                        label="Autocertificazione">
                                                        <b-form-file size="sm" placeholder="Autocertificazione"></b-form-file>
                                                    </b-form-group>
                                                    <b-form-group
                                                        label="Rinuncia Formale">
                                                        <b-form-file size="sm" placeholder="Rinuncia Formale"></b-form-file>
                                                    </b-form-group>

                                                </b-col>
                                            </b-row>
                                        </b-card-body>
                                    </b-card>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-card title="Note:" class="mt-2 shadow" bg-variant="gray-100">
                                        <b-form-group>
                                            <b-form-textarea size="sm" id="note" v-model="studente.note" ></b-form-textarea>
                                        </b-form-group>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-tab>
                    <b-tab title="Informazioni Parenti">
                        <b-row>
                            <b-col cols="6">
                                <b-card title="Padre:" class="mt-2 shadow" bg-variant="gray-100">
                                    <b-form-group
                                    label="Nome:"
                                    label-for="genitore_padre_nome">
                                    <b-form-input size="sm" id="genitore_padre_nome" v-model="studente.genitore_padre_nome"></b-form-input>
                                </b-form-group>
                                    <b-form-group
                                    label="Cognome:"
                                    label-for="genitore_padre_cognome">
                                    <b-form-input size="sm" id="genitore_padre_cognome" v-model="studente.genitore_padre_cognome"></b-form-input>
                                </b-form-group>
                                    <b-form-group
                                        label="Email"
                                        label-for="genitore_padre_email">
                                        <b-form-input size="sm" id="genitore_padre_email" v-model="studente.genitore_padre_email"></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        label="Telefono"
                                        label-for="genitore_padre_telefono">
                                        <b-form-input size="sm" id="genitore_padre_telefono" v-model="studente.genitore_padre_telefono"></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        label="Cellulare"
                                        label-for="genitore_padre_cellulare">
                                        <b-form-input size="sm" id="genitore_padre_cellulare" v-model="studente.genitore_padre_cellulare"></b-form-input>
                                    </b-form-group>
                                </b-card>
                            </b-col>
                            <b-col>
                                <b-card title="madre:" class="mt-2 shadow" bg-variant="gray-100">
                                    <b-form-group
                                        label="Nome:"
                                        label-for="genitore_madre_nome">
                                        <b-form-input size="sm" id="genitore_madre_nome" v-model="studente.genitore_madre_nome"></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        label="Cognome:"
                                        label-for="genitore_madre_cognome">
                                        <b-form-input size="sm" id="genitore_madre_cognome" v-model="studente.genitore_madre_cognome"></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        label="Email"
                                        label-for="genitore_madre_email">
                                        <b-form-input size="sm" id="genitore_madre_email" v-model="studente.genitore_madre_email"></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        label="Telefono"
                                        label-for="genitore_madre_telefono">
                                        <b-form-input size="sm" id="genitore_madre_telefono" v-model="studente.genitore_madre_telefono"></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        label="Cellulare"
                                        label-for="genitore_madre_cellulare">
                                        <b-form-input size="sm" id="genitore_madre_cellulare" v-model="studente.genitore_madre_cellulare"></b-form-input>
                                    </b-form-group>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Percorso di Studi">
                        <b-row>
                            <b-col>
                              <b-card title="Scuola:" class="mt-2 shadow" bg-variant="gray-100">
                                <b-form-group
                                  label="Nome:"
                                  label-for="nome">
                                  <b-form-input size="sm" id="nome" v-model="studente.nome"></b-form-input>
                                </b-form-group>
                                <b-form-group
                                  label="Cognome:"
                                  label-for="cognome">
                                  <b-form-input size="sm" id="cognome" v-model="studente.cognome"></b-form-input>
                                </b-form-group>
                              </b-card>
                            </b-col>
                            <b-col>
                                <b-form-group
                                    label="Nome:"
                                    label-for="nome">
                                    <b-form-input size="sm" id="nome" v-model="studente.nome"></b-form-input>
                                </b-form-group>
                                <b-form-group
                                    label="Cognome:"
                                    label-for="cognome">
                                    <b-form-input size="sm" id="cognome" v-model="studente.cognome"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Libretto delle Studente">
                        <b-row>
                            <b-col>
                            </b-col>
                            <b-col>
                            </b-col>
                        </b-row>
                    </b-tab>
                    <b-tab title="Documenti e Foto">
                        <b-row>
                            <b-col>
                            </b-col>
                            <b-col>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-form>
            </b-tabs>
        </b-card>
        <template  #footer="{}">
            <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                <b-button @click="editAddStudente" variant="success" v-if="!spinnerNewStudente">{{ sceltaBottone }}</b-button>
                <b-button variant="dark" disabled v-if="spinnerNewStudente">
                    <b-spinner small type="grow"></b-spinner>
                    Loading...
                </b-button>
                <b-button variant="gray-700" class="ml-3">Salva e Continua</b-button>
                <b-button variant="danger" v-b-toggle.sidebar-studente class="ml-3">Chiudi</b-button>
                <strong class="ml-auto">{{ $store.state.user.username }}</strong>
            </div>
        </template>
    </b-sidebar>
</template>

<script>
import StudentiService from "../../services/studenti.service";
export default {
    name: "sidebarStudente",
    props: {
        show: {
            type: Boolean,
        },
        studente: {
            type: Object,
            require: true
        },
        title_modal: {
            type: String,
            require: true
        },
        transition: {
            type: Boolean,
        },
        edit: {
            type: Boolean,
            require: true
        }
    },
    data() {
        return {
            modifica_studente: this.initForm,
                // matricola: this.studente.matricola,
                // nome: this.studente.nome,
                // cognome: this.studente.cognome,
                // email: this.studente.email,
                // email_istituzionale: this.studente.email_istituzionale,
                // lead_email: "",
                // telefono_fisso: "",
                // telefono_cellulare: "",
                // istituto_scolastico: "",
                // istituto_citta: "",
                // luogo_di_nascita: "",
                // provincia_di_nascita: "",
                // data_nascita: null,
                // codice_fiscale: "",
                // indirizzo_residenza: "",
                // numero_civico_residenza: "",
                // citta_di_residenza: "",
                // comune_di_residenza: "",
                // provincia_di_residenza: "",
                // cap_di_residenza: "",
                // residenza_domicilio: false,
                // indirizzo_domicilio: "",
                // numero_domicilio: "",
                // citta_di_domicilio: "",
                // numero_civico_domicilio: "",
                // comune_di_domicilio: "",
                // provincia_di_domicilio: "",
                // cap_di_domicilio: "",
                // genitore_padre_nome: "",
                // genitore_padre_cognome: "",
                // genitore_padre_email: "",
                // genitore_padre_telefono: "",
                // genitore_padre_cellulare: "",
                // genitore_madre_nome: "",
                // genitore_madre_cognome: "",
                // genitore_madre_email: "",
                // genitore_madre_telefono: "",
                // genitore_madre_cellulare: "",
                // classe: "",
                // tipo_di_diploma: "",
                // data_iscrizione: null,
                // sede_di_studi: "",
                // laureato_il: null,
                // modulo_immatricolazione: false,
                // ModuloDiImmatricolazione: null,
                // carta_di_identita: false,
                // CartaDiIdentita: null,
                // diploma: false,
                // Diploma: null,
                // avatar_utente: null,
                // privacy: "",
                // newsletter: false,
                // note: "",
                // user: null,
                // lead: null,
                // test_ingresso: null,
                // istituto_provenienza: null,
                // piano_di_studi: null,
                // corsi: []
            spinnerNewStudente: false,
            form: {},
        }
    },

    created() {},
    computed: {
        sceltaBottone() {
          if(this.title_modal === 'Modifica') {
              return 'Modifica'
          }  return 'Inserisci'
        },
        // initStudente() {
        //     return this.modifica_studente = this.studente
        // }
        initForm() {
            return Object.assign({} || this.studente)
        }
    },
    methods: {
        editAddStudente() {
            if(this.title_modal === 'Modifica') {
                StudentiService.putStudente(this.studente)
                    .then(resp => {
                        this.modifica_studente = {}
                        this.$emit('studenteModificato', true)
                        this.$root.$emit('bv::toggle::collapse', 'sidebar-studente')
                        console.log(resp)
                    })
                console.log(this.modifica_studente)
            }
            if(this.title_modal === 'Aggiungi') {
                StudentiService.postStudente(this.studente)
                    .then(resp => {
                        this.modifica_studente = {}
                        this.$emit('studenteModificato', true)
                        this.$root.$emit('bv::toggle::collapse', 'sidebar-studente')
                        console.log(resp)
                    })
            }
        },
        clearSidebarForm() {
            this.modifica_studente = {}
            console.log(this.modifica_studente)
        },
    }
}
</script>

<style scoped>
    custom_input {
        color: black;
        text-transform: uppercase;
    }
    .vl {
        border-left: 6px solid green;
        height: 500px;
    }
</style>
