var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app",class:{
		'app-sidebar-minified': _vm.appOptions.appSidebarMinified,
		'app-sidebar-mobile-toggled': _vm.appOptions.appSidebarMobileToggled,
		'app-sidebar-mobile-closed': _vm.appOptions.appSidebarMobileClosed,
		'app-content-full-height': _vm.appOptions.appContentFullHeight,
		'app-content-full-width': _vm.appOptions.appContentFullWidth,
		'app-without-sidebar': _vm.appOptions.appWithoutSidebar,
		'pt-0': _vm.appOptions.appWithoutHeader,
		'app-boxed-layout': _vm.appOptions.appBoxedLayout,
		'app-footer-fixed': _vm.appOptions.appWithFooter
	},attrs:{"id":"app"}},[_c('vue-ins-progress-bar'),(!_vm.appOptions.appWithoutHeader)?_c('Header'):_vm._e(),(!_vm.appOptions.appWithoutSidebar)?_c('Sidebar'):_vm._e(),_c('router-view'),(_vm.appOptions.appWithFooter)?_c('Footer'):_vm._e(),_c('b-modal',{attrs:{"id":"first_login_app","centered":"","no-close-on-backdrop":"","hide-footer":"","header-bg-variant":"dark","header-text-variant":"white","title":"CAMBIO PASSWORD","no-close-on-esc":"","hide-header-close":""}},[_c('b-form',[_c('b-form-group',{attrs:{"label":"Vecchia Password"}},[_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.old_password),callback:function ($$v) {_vm.old_password=$$v},expression:"old_password"}}),_vm._v(" "+_vm._s(_vm.old_password)+" ")],1),_c('b-form-group',{attrs:{"label":"Nuova Password"}},[_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.new_password),callback:function ($$v) {_vm.new_password=$$v},expression:"new_password"}}),_vm._v(" "+_vm._s(_vm.new_password)+" ")],1),_c('b-form-group',{attrs:{"label":"Ripeti Password"}},[_c('b-form-input',{attrs:{"type":"password"},model:{value:(_vm.new_password2),callback:function ($$v) {_vm.new_password2=$$v},expression:"new_password2"}}),_vm._v(" "+_vm._s(_vm.new_password2)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.showError),expression:"showError"}]},[_vm._v("Le password non coincidono")])],1)],1),_c('b-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.allOk),expression:"allOk"}],staticClass:"float-right",attrs:{"variant":"dark"},on:{"click":_vm.cambioPassword}},[_vm._v("Cambia Password")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }