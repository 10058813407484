<template>
    <b-modal
        ref="editFollowup"
        id="edit-followup"
        size="lg"
        header-bg-variant="dark"
        header-text-variant="white"
        title="Modifica FollowUp"
        @ok="salvaFollowUp">
        <b-form>
            <b-card>
                <b-card-body>
                    <b-row>
                        <b-col>
                            <label title="Scegli Orientatore">Scegli Orientatore con cui condivide il FollowUp:</label>
                            <div style="border-radius: 0.3rem">
                                <v-select multiple :options="users" label="email_istituzionale" v-model="form.orientatori" :reduce="staff => staff.id"></v-select>
                                <small class="card-subtitle text-muted">Non Obbligatorio</small>
                            </div>
                        </b-col>
                        <b-col>
                            <b-form-group label="Data FollowUp" v-if="this.form.data_follow">
                              <b-form-datepicker v-model="form.data_follow"></b-form-datepicker>
                              <small class="card-subtitle text-danger">Obbligatorio</small>
                            </b-form-group>

                        </b-col>
                    </b-row>
                    <b-form-group label="Azione">
                      <b-form-select v-model="form.oggetto" :options="action" text-field="text" value-field="text"></b-form-select>
                      <small class="card-subtitle text-danger">Obbligatorio</small>
                    </b-form-group>
                    <b-form-group label="Dettagli">
                        <b-form-textarea v-model="form.dettagli"></b-form-textarea>
                    </b-form-group>
                    <hr>
                    <b-form-checkbox switch v-model="form.fatto">Fatto</b-form-checkbox>
                </b-card-body>
            </b-card>
        </b-form>
    </b-modal>
</template>

<script>
import EventService from "../../../../services/event.service";
import UserService from "@/services/user.service";
export default {
    name: "followUp",
    props: ['itemFollowUp'],
    data() {
        return {
            form: Object.assign({}, this.itemFollowUp),
            action: [
                {value:"richiamare", text: 'Richiamare'},
                {value:"mandare_email", text: 'Mandare Email'},
                {value:"fissare_app", text: 'Fissare Appuntamento'},
            ],
            users:[],
        }
    },
    mounted() {
        this.initCustomUser()
    },
    computed: {
        getItemFollowUp() {
            return this.itemFollowUp ? this.itemFollowUp.length : 0
        }
    },
    methods:{
        initCustomUser(){
            const payload = {
                is_event_manager: true
            }
            UserService.getUser(payload)
                .then(resp => {
                    this.users = resp.data.results
                })
        },
        salvaFollowUp(){
            const payload = {
                ...this.form
            }
            EventService.editFollowUp(payload)
                .then(resp => {
                    console.log(resp)
                    this.$emit('refreshFollowup')
                    this.$bvModal.hide('edit-followup')
                })
                .catch()
            console.log(payload)
        }
    },
    watch: {
        itemFollowUp(){
            this.form = Object.assign({}, this.itemFollowUp)
        },
    }
}
</script>

<style scoped>

</style>

