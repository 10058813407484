<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <b-breadcrumb>
            <b-breadcrumb-item href="#">BREADCRUMB</b-breadcrumb-item>
            <b-breadcrumb-item active>HOME PAGE</b-breadcrumb-item>
        </b-breadcrumb>

        <h1 class="page-header">
            Dashboard Liste <small>page header description goes here...</small>
        </h1>
        <div>
        <b-row cols="12">
            <b-col cols="10">
                <b-card>
                    <h4 style="color: #5064B9">Lista Istituti</h4>
                    <b-table small sticky-header="200px" :items="items" striped head-variant="dark" hover ></b-table>
                </b-card>
                <b-card>
                </b-card>
            </b-col>
            <b-col cols="2">
                <b-card>
                <h4 style="color: #5064B9">Stats Istituti</h4>
                    <b-list-group>
                        <b-list-group-item>Numero Istituti</b-list-group-item>
                    </b-list-group>
                </b-card>
                <b-card>
                    <h4 style="color: #5064B9">Stats Lead</h4>
                    <b-list-group>
                        <b-list-group-item>Numero Lead: <b></b> </b-list-group-item>
                    </b-list-group>
                </b-card>
            </b-col>
        </b-row>
        </div>
    </div>
    <!-- END #content -->
</template>

<script>
import dashboardListService from '@/services/dashboardList.service'
export default {
    name: "dashboardList",
    data() {
        return {
            filterLead: null,
            statistiche:[]
        }
    },
    computed: {
        sortOptions() {
            // Create an options list from our fields
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        }
    },
    mounted() {
        dashboardListService.getDashBoard()
            .then(response => {
                this.statistiche = response.data
            })

    }
}
</script>

<style scoped>

</style>
