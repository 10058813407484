<template>
                <span class="coinvolgi" style="align-items: center"><b>{{ coinvolgimentoPerc }}%</b></span>
</template>

<script>
export default {
        name: "coinvolgimento",
        props:{
          lead: {
              type: Object,
              required: true
          }
        },
        data(){
            return {
                new_dato: 0
            }
        },
        computed:{
            coinvolgimentoPerc(){
                let peso = 10
                const pesoUltimaDem = 5
                const pesoEmail = 5
                const pesoLezione = 5
                const pesoFiera = 5
                const pesoWebinar = 5
                const pesoTest = 20
                const pesoOpenDay = 5
                const pesoPartecOpenDay = 10
                const pesoColloquio = 15
                const pesoChat = 5
                const pesoChiamata = 5
                peso += this.lead.ultima_dem ? pesoUltimaDem : 0;
                peso += this.lead.ti_email_chiamata ? pesoEmail : 0;
                peso += this.lead.ti_lezione ? pesoLezione : 0;
                peso += this.lead.ti_fiera ? pesoFiera : 0;
                peso += this.lead.ti_webinar ? pesoWebinar : 0;
                peso += this.lead.ti_test ? pesoTest : 0;
                peso += this.lead.ti_open_day ? pesoOpenDay : 0;
                peso += this.lead.ti_partecipazione_od ? pesoPartecOpenDay : 0;
                peso += this.lead.ti_colloquio ? pesoColloquio : 0;
                peso += this.lead.ti_chat ? pesoChat : 0;
                peso += this.lead.ti_chiamata ? pesoChiamata : 0;
                return peso
            },
        },
        watch: {
            coinvolgimentoPerc(value){
                this.lead.coinvolgimento = value
            }
        }
}
</script>

<style scoped>
    .coinvolgi {
        font-size: 2em;
        color: #1ABD36;
        text-shadow: #dae0ec;
        font-style: oblique;
        float: end;
    }
</style>
