<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <b-row>
            <b-col cols="12">
                <h4>TEST</h4>
                <b-form-file v-model="fileToUpload" type="/upload" @input="uploadFile"></b-form-file>
            </b-col>
        </b-row>
    </div>
</template>

<script>
/*const aws = require('aws-sdk')
const s3 = new aws.S3({
    endpoint: `https://eu2.contabostorage.com/uni-store-video`, // e.g. https://eu2.contabostorage.com/bucketname
    accessKeyId: "3aa7b0c1f77c4721a130baf3dea9cf6e",
    secretAccessKey: "b9de024ba6492a978433263a32bd9325",
    s3BucketEndpoint: true,
});*/
import authHeader from "@/services/auth.header";
import axios from "axios";

export default {
    name: "paginaPerTest",
    components: {
    },
    data() {
        return {
            fileToUpload: null,
            titolo: 'Titolo'
        }
    },
    methods: {
        async uploadFile() {
            const url = 'http://127.0.0.1:8000/api/lms/uploadFiles/'
            const blob = this.fileToUpload
            const formData = new FormData()
            formData.append('fileS3', blob)
            formData.append('titolo', this.titolo)
            axios.put(url, formData, {headers: authHeader()}).then(resp => {
                console.log(resp)
            }).catch(error => {
                console.log(error)
            })
        }
    }
}
</script>

<style scoped>

</style>
