import AuthService from '../services/auth.service';
import UserService from '@/services/user.service';

// const user = JSON.parse(localStorage.getItem('user'))
// const initialState = user
//     ? { status: { loggedIn: true }, user }
//     : { status: { loggedIn: false }, user: null };

export const auth = {
    namespaced: true,
    state: {
      user: [],
      status: {
        loggedIn: false
      }
    },
    actions: {
        login({ commit }, user) {
          console.log('sono nelle actions dello store e sto facendo la chiamata al server')
            return AuthService.login(user)
              .then(
                user => {
                    commit('loginSuccess', user);
                    // dispatch('setUser')
                    return Promise.resolve(user);
                },
                error => {
                    commit('loginFailure', error);
                    return Promise.reject(error);
                }
            ).catch(e => {
                console.log(e)
              })
        },
        setUser(context){
            let id = context.state.user.user_id
            UserService.getSetUser(id).then(
                // response => {
                //     context.commit('setUserInState', response.data)
                // }
            )
        },
        logout({ commit }) {
                    commit('logout');
        },
        register({ commit }, user) {
            return AuthService.register(user).then(
                response => {
                    commit('registerSuccess');
                    return Promise.resolve(response.data);
                },
                error => {
                    commit('registerFailure');
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        loginSuccess(state, user) {
          console.log('la chiamata è andata a buon fine, sono nelle mutations e setto lo status e lo user', user)
            state.status.loggedIn = true;
            state.user = user;
        },
        loginFailure(state, error) {
            state.status.loggedIn = false;
            state.user = null;
            state.error = error;
        },
        logout(state) {
            state.status.loggedIn = false;
            state.user = null;
            localStorage.clear()
        },
        registerSuccess(state) {
            state.status.loggedIn = false;
        },
        registerFailure(state) {
            state.status.loggedIn = false;
        },
        // setUserInState(state, user) {
        //     // state.user = user
        //     //localStorage.setItem('user', user.auth)
        //     // console.log('X state.user: ', state.user, user)
        // }
    }
};
