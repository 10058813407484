<template>
  <div>
    <b-card bg-variant="dark lighten-3" border-variant="warning">
      <span class="text-white">Liste Personali:</span>
      <b-link class="text-white float-right ml-2" v-b-tooltip.top title="Refresh delle Liste" @click="initListaLead"><b-icon icon="arrow-clockwise" :animation='showAnimation ? `throb` : `false`' font-scale="1"></b-icon></b-link> |
      <b-link class="text-white float-right ml-2" v-b-tooltip.top title="Modifica le Liste" @click="editList"><b-icon icon="pencil-square" font-scale="1"></b-icon></b-link>

      <b-form-select v-model="listaChecked" v-on:change="callListaPersonale">
        <option :value="null" disabled>-- Please select an option --</option>
        <option v-for="option in listaleads" :value="option" :key="option.id">
          {{ option.nome }}
        </option>
      </b-form-select>
    </b-card>
    <b-modal id="edit-list" title="Modifica Liste" header-bg-variant="dark" header-text-variant="white">
      <b-table
        small
        striped
        hover
        :fields="fields"
        :items="listaleads">
        <template #cell(leads)="row">
          {{ row.item.leads.length }}
      </template>
        <template #cell(actions)="row">
          <b-link @click="eliminaLista(row.item.id)" class="text-dark"><i class="fa fa-trash"></i></b-link> |
          <b-link @click="modificaLista(row.item.id)" class="text-dark"> <i class="fa fa-pen-fancy"></i></b-link>
        </template>
      </b-table>
    </b-modal>
  </div>

</template>

<script>
import Swal from "sweetalert2";
import ListaLead from "@/services/listleads.service";

export default {
  name: "ListePersonali",
  props: {
    refreshList: Boolean
  },
  data() {
    return {
      listaPersonale: Number | String,
      listaleads: [],
      fields: [
        {key: 'id', label: 'ID'},
        {key: 'nome', label: 'Nome Lista'},
        {key: 'leads', label: 'Leads N°'},
        {key: 'actions', label: 'Actions'},
      ],
      listaChecked: null,
      showAnimation: false,
    }
  },
  watch: {
    refreshList(val) {
      if (val === true) {
        this.initListaLead()
      }
    }
  },
  mounted() {
    this.initListaLead()
  },
  methods: {
    editList() {
      this.$bvModal.show('edit-list')
    },
    initListaLead() {
      this.showAnimation = true
      let params = {
        user: this.$store.state.auth.user.user_id
      }
      ListaLead.getListeLeads(params)
        .then(resp => {
          this.listaleads = resp.data.results
          this.showAnimation = false
          this.$emit('searchAndFilterLeadwithPagination')
        })
        .catch()
    },
    callListaPersonale() {
      this.$emit('callListaPersonale', this.listaChecked)
    },
    eliminaLista(id) {
      Swal.fire({
        title: 'Sei Sicuro?',
        text: "Non potrai riprendere questo elemento una volta cancellato!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, cancellalo!'
      }).then(result => {
          if(result.isConfirmed) {
            ListaLead.deleteList(id).then(() => {
              this.$bvToast.toast('Lista canellata', {
                title: 'Lista cancellata',
                autoHideDelay: 5000,
                variant:'success'
              })
              this.initListaLead()
              this.$emit( 'searchAndFilterLeadwithPagination')
              this.$emit('clearFilter')

            })
          }
        }

      )},
    modificaLista(id) {
      console.log(id)
    }
  }
}
</script>

<style scoped>
.custom-icon {
 font-scale: 0.5;
}
</style>
