<script>
import Home from '../pages/Dashboard.vue'
import PageLogin from "@/pages/Login/PageLogin";
import PageChangePassword from "@/pages/Login/PageChangePassword";
import PageError from "@/pages/PageError";
// import profileStudente from "@/pages/accounts/profileStudente";
// import profileDocente from "@/pages/accounts/profileDocente";
import profileStaff from "@/pages/accounts/profileStaff";
// CRM URL
// elements
import dashboardList from "@/pages/dashboardList";
import listaDirigenti from "@/pages/crm/listaDirigenti";
import listaPresidi from "@/pages/crm/listaPresidi";
import ListaLead from "@/pages/crm/lead/ListaLead";
import ListaIstituti from "@/pages/crm/istituto/ListaIstituti"
import listaTask from "@/pages/crm/listaTask";
import FormDalSito from "@/pages/crm/form/FormDalSito";
import Scuole from "@/pages/scuole/Scuole";
import settings from "@/pages/universita/settings";
// import ListaPianoDiStudi from "@/pages/universita/pds/listaPianoDiStudi";
// import dettaglioTest from "@/pages/universita/test/dettaglioTest";
// import nuovoTest from "@/pages/universita/test/nuovoTest";
import listaStudenti from "@/pages/universita/studenti/listaStudenti";
// import listaSessioni from "@/pages/universita/test/listaSessioni";
// import sessioni from "@/pages/universita/sessioni/sessioni"
import PrenotazioneAppelli from "@/components/test/PrenotazioneAppelli"
// import PrenotazioneAule from "@/pages/universita/calendario/PrenotazioneAule"
// LMS
import dashboardLms from "@/pages/universita/lms/dashboardLms"
import studenteLms from "@/pages/studenti/studenteLms/studenteLms"

import {Role} from "./role";
// PAGINA PER FARE TEST
import paginaPerTest from "@/pages/paginaPerTest"
import Bug from "@/pages/bug/Bug"


const routes = [
  { path: '/home', name: 'home', component: Home, meta: { authorize: [Role.Staff] }},
  { path: '/login', name: 'Login', component: PageLogin, meta: { guest: true } },
  { path: '/reset-password/:uidb64/:token', name: 'PageChangePassword', component: PageChangePassword, meta: { guest: true }, },
  { path: '/profile-studente', name: 'profileStudente', component: studenteLms, meta: { authorize: [Role.Studente] } },
  { path: '/universita/dashboardLms', name: 'dashboardLms', component: dashboardLms, meta: { authorize: [Role.Docente]} },
  { path: '/profile-staff', name: 'profileStaff', component: profileStaff, meta: { authorize: [Role.Staff] }},
  // UNIVERSITÀ
  // { path: '/universita/piano-di-studi', name: 'PianoDiStudi', component: ListaPianoDiStudi, meta: { authorize: [Role.Staff, Role.Docente] } },
  // { path: '/sessioni/lista', name: 'ListaSessioni', component: listaSessioni, meta: { authorize: [Role.Staff, Role.Docente] } },
  // { path: '/test/dettaglio/:id', props:true, name: 'dettaglio-test', component: dettaglioTest, meta: { authorize: [Role.Staff, Role.Docente] } },
  // { path: '/test/nuovo/', name: 'nuovo-test', component: nuovoTest, meta: { authorize: [Role.Staff, Role.Docente] } },
  { path: '/studenti/lista/', name: 'listaStudenti', component: listaStudenti, meta: { authorize: [Role.Staff, Role.Docente] } },
  // { path: '/universita/sessioni/', name: 'sessioni', component: sessioni, meta: { authorize: [Role.Staff, Role.Docente] } },
  // { path: '/universita/prenotazione-aule-corsi', name: 'prenotazione-aule', component: PrenotazioneAule, meta: { authorize: [Role.Staff, Role.Docente] } },
  // { path: '/universita/lms', name: 'LMS', component: PrenotazioneAule, meta: { authorize: [Role.Docente] } },
  // SETTINGS
  { path: '/settings/settings', name: 'settings', component: settings, meta: { authorize: [Role.Staff] } },
  { path: '/settings/scuole', name: 'scuole', component: Scuole, meta: { authorize: [Role.Staff] } },
  // DASHBOARD
  { path: '/dashboard/list', name: 'DashboardListe', component: dashboardList, meta: { authorize: [Role.Staff] } },
  // CRM
  { path: '/crm/istituto/list', component: ListaIstituti, meta: { authorize: [Role.Staff] } },
  { path: '/crm/lead/list', component: ListaLead, meta: { authorize: [Role.Staff] } },
  // { path: '/crm/lead/list',props:true, name:'listaLead', component: listaLead, meta: { authorize: [Role.Staff] } },
  { path: '/crm/dirigenti/list', component: listaDirigenti, meta: { authorize: [Role.Staff] }  },
  { path: '/crm/presidi/list', component: listaPresidi, meta: { authorize: [Role.Staff] }  },
  // { path: '/crm/contatti/list', component: listaContatti  },
  { path: '/crm/task/list', component: listaTask, meta: { authorize: [Role.Staff] }  },
  { path: '/crm/form/formdalsito', component: FormDalSito, meta: { authorize: [Role.Staff] }  },

  // LMS
  { path: '/universita/dashboardLms', name: 'LMS', component: dashboardLms, meta: { authorize: [Role.Docente, Role.Staff] } },
  { path: '/studenti/studenteLms', name: 'studenteLms', component: studenteLms, meta: { authorize: [Role.Studente] } },

  // URL TEST PER TESTARE COMPONENTI
  { path: '/bug', component: Bug},
  { path: '/universita/appelli', component: PrenotazioneAppelli,meta: { authorize: [Role.Staff, Role.Docente] }  },
  // PAGE ERROR OR RESTRICTED AREA
  { path: '/restricted', component: PageError, meta: { authorize: [Role.Staff] }  },
  { path: '/paginaTest', component: paginaPerTest, meta: { authorize: [Role.Staff] }  },

  // URL DI DJANGO
  { path: '/api' },
  { path: '/admin' },
  { path: '/api-auth' },
  { path: '/rest-auth' },
  { path: '/accounts' },
  { path: '/auth' },
  { path: '*', redirect: '/home' },
]

export default routes;
</script>
