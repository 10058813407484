<template>
    <div>
        <b-alert
            id="alertSuccess"
            :show="dismissCountDown"
            dismissible
            variant="warning"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
        >
            Modificato / Inserito con successo!
        </b-alert>
    </div>
</template>

<script>
export default {
    name:'alertSuccess',
    props: {
        dismissCountDown: Number
    },
    data() {
        return {
            dismissSecs: 3,

        }
    },
    methods: {
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        }
    }
}
</script>

