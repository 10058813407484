import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_APP_URL

class UserService {
    getUser(params){
        return axios.get(API_URL + 'staff/', { headers: authHeader(), params:params})
    }
    getSetUser(id) {
        return axios.get(API_URL + 'accounts/' + id + '/', {headers: authHeader()});
    }

    getSearchLead(search){
        return axios.get(API_URL + 'nuovi-lead/?search=' + search, {headers: authHeader() });
    }
    getSearchLeadnextPage(params, search){
        return axios.get(API_URL + 'nuovi-lead/?search=' + search, {headers: authHeader(), params: params});
    }
    getLeadFromID(id){
        return axios.get(API_URL + '?active=&email=&id=' + id, { headers:authHeader()})
    }
    getLead(params){
        return axios.get(API_URL + 'nuovi-lead/', {headers: authHeader(), params:params});
    }
    findDubt(){
        return axios.get(API_URL + 'nuovi-lead/find_double/', { headers: authHeader() });
    }
    getDubt(email){
        return axios.get(API_URL + 'nuovi-lead/?email=' + email, { headers: authHeader() });
    }
    deleteLead(id){
        return axios.delete(API_URL +'lead/'+ id + '/', {headers: authHeader()});
    }
    createUser(staff){
        return axios.post( API_URL + 'staff/', staff, { headers: authHeader()})
    }
    getEventManager(){
        return axios.get(API_URL + 'accounts/?is_event_manager=true', { headers: authHeader()} )
    }

}

export default new UserService();
