import Vue from 'vue'
import App from './App.vue'
import routes from './config/AppRoutes'

// plugins
import VueRouter from 'vue-router'
import VueBootstrap from 'bootstrap-vue'
import VueInsProgressBar from 'vue-ins-progress-bar'
import VueCustomScrollbar from 'vue-custom-scrollbar'
import VueApexCharts from 'vue-apexcharts'
import VueHljs from 'vue-hljs'
import DateRangePicker from 'vue2-daterange-picker'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import VueColorPicker from '@caohenghu/vue-colorpicker'
import VueCountdown from '@chenfengyuan/vue-countdown'
import VueMaskedInput from 'vue-maskedinput'
import wysiwyg from "vue-wysiwyg"
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format'
import AddToCalendar from 'vue-add-to-calendar'
import Multiselect from 'vue-bootstrap-multiselect';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueBootstrapToasts from "vue-bootstrap-toasts";
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
// Plugin per l'autenticazione con google
import VueAuthenticate from 'vue-authenticate'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
// VueStudio SCSS
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
// import '@fullcalendar/core/main.css'
import '@fullcalendar/daygrid/main.css'
import '@fullcalendar/timegrid/main.css'
// import '@fullcalendar/list/main.css'
import '@fullcalendar/bootstrap/main.css'
import 'vue-hljs/dist/vue-hljs.min.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import 'vue-wysiwyg/dist/vueWysiwyg.css'
import './scss/styles.scss'
import store from './store'
import moment from "moment";
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
// // Services
//
// import UniversitaService from './services/universita/universita.service'
// import SettingService from './services/universita/settings.service'
// import TestService from './services/universita/test.service'
// import AuthService from './services/auth.service'
// import ContattiService from './services/contatti.service'
// // import dashboardListService from './services/dashboard.service'
// import dashboardListService from './services/dashboardList.service'
// import EventService from './services/event.service'
// import IstitutiService from './services/istituto.service'
// import LeadService from './services/istituto.service'
// import ScuoleService from './services/scuole.service'
// import StudentiService from './services/studenti.service'
// import TaskService from './services/tasks.service'
// import UserService from './services/user.service'
//
//
// // API
// Vue.prototype.$universitaService = UniversitaService
// Vue.prototype.$settingService = SettingService
// Vue.prototype.$testService = TestService
// Vue.prototype.$authService = AuthService
// Vue.prototype.$contattiService = ContattiService
// Vue.prototype.$dashboardListService = dashboardListService
// Vue.prototype.$eventService = EventService
// Vue.prototype.$istitutiService = IstitutiService
// Vue.prototype.$lead = LeadService
// Vue.prototype.$scuole = ScuoleService
// Vue.prototype.$studenti = StudentiService
// Vue.prototype.$task = TaskService
// Vue.prototype.$user = UserService

Vue.component('v-select', vSelect)


Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(AddToCalendar)
Vue.config.productionTip = false
Vue.use(VueAuthenticate, {
    baseUrl: 'http://127,0.0.1:8000', // Your API domain
    storageType: 'localStorage',
    tokenPath: 'token',

    providers: {
        google: {
            clientId: '672739367605-lpn6pqs79pi00pam7u4d750ds9tj0a1d.apps.googleusercontent.com',
            redirectUri: 'http://127.0.0.1:8080/Dashboard',
            url: 'authenticationUrl',
        }
    }
})

// Configuraiozne Client Google API
// const clientConfig = {
//     apiKey: 'ZpQvSaY-yFwKci2B-aLxhCDO',
//     clientId: '672739367605-lpn6pqs79pi00pam7u4d750ds9tj0a1d.apps.googleusercontent.com',
//     discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
//     // see all available scopes here: https://developers.google.com/identity/protocols/googlescopes'
//     scope: 'https://www.googleapis.com/auth/admin.directory.resource.calendar',
//
//     // works only with `ux_mode: "prompt"`
//     refreshToken: true,
// }
let VueCookie = require('vue-cookie');
Vue.use(require('vue-moment'));
Vue.use(wysiwyg, { maxHeight: '300px'})
Vue.use(VueRouter)
Vue.use(VueBootstrap)
Vue.use(VueHljs)
Vue.use(VueFilterDateFormat);
Vue.use(VueInsProgressBar, {
  position: 'fixed',
  show: true,
  height: '3px'
})
Vue.use(VueGoogleMaps, {
  load: {
    key: '',
    libraries: 'places'
  }
})
Vue.use(VueCookie);
Vue.use(VueSweetalert2);
Vue.use(VueBootstrapToasts);


Vue.filter('formatDate', function (value){
    if(value){
        return moment(String(value)).locale('it').format('D/MM/YYYY')
    }
})
Vue.filter('formatDateExt', function (value){
    return moment(String(value)).locale('it').format('dddd DD/MMMM/YYYY')
})
Vue.filter('formatDateTime', function (value){
    return moment(String(value)).locale('it').format('DD-MMMM-YYYY HH:MM')
})

Vue.component('vue-custom-scrollbar', VueCustomScrollbar)
Vue.component('apexchart', VueApexCharts)
Vue.component('date-range-picker', DateRangePicker)
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)
Vue.component('vue-color-picker', VueColorPicker)
Vue.component('vue-masked-input', VueMaskedInput)
Vue.component(VueCountdown.name, VueCountdown);
Vue.component('multiselect', Multiselect);
const router = new VueRouter({
    mode: 'history',
	routes,
})

Vue.config.devtools = true
new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
