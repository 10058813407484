<template>
    <div>
        <b-form>
            <b-card bg-variant="gray-050">
                <div class="d-inline-flex">
                    <b-form-group class="mr-2" label="*Regione">
                        <b-form-input type="text" id="regione" v-model="form.regione" placeholder="Regione"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mr-2" label="*Provincia" @keydown.enter.native="ricerca">
                        <b-form-input type="text" id="provincia" v-model="form.provincia" placeholder="Provincia"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mr-2" label="Nome Istituto">
                        <b-form-input type="text" id="nome" v-model="form.denominazione_scuola" placeholder="Nome"></b-form-input>
                    </b-form-group>
                    <b-form-group class="mr-2" label="Cod. Istituto">
                        <b-form-input type="text" id="codice" v-model="form.codice_scuola" placeholder="codice scuola"></b-form-input>
                    </b-form-group>
                </div>
            </b-card>
            <b-overlay
                :show="busy"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="mt-2 mb-2"
            >
                    <template #overlay>
                        <div class="text-center">
                            <b-icon style="color: #FFFFFF" icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                            <p id="cancel-label" style="color: #FFFFFF">Please wait...</p>
                        </div>
                    </template>
            <b-button @click="ricerca()" variant="unicollege" :disabled="busy">Search</b-button>
            </b-overlay>
        </b-form>
        <b-card v-if="this.risultati.length >=1">
            Risultato ricerca: {{ this.risultati.length }} scuole

            <b-form-group label="Ricerca tra le scuole:">
                <b-form-input v-model="filter">Ricerca tra i risultati</b-form-input>
            </b-form-group>
                <b-table id="ricerca"
                     :items="risultati"
                     :fields="fields"
                     :filter="filter"
                     :filter-included-fields="filterOn"
                     small
                     hover
                     striped
                     selectable
                     @row-selected="onRowSelected"
                     select-mode="single"
            ></b-table>
        </b-card>
    </div>
</template>

<script>
import ScuoleService from '@/services/scuole.service'
export default {
    name: "searchScuole",
    data(){
        return {
            selected: [],
            busy: false,
            fields:[
                { key:'codice_scuola',label:'CODICE'},
                { key:'denominazione_scuola',label:'DENOMINAZIONE'},
                { key:'descrizione_comune',label:'CITTÀ'},
                { key:'indirizzo_scuola',label:'INDIRIZZO'},
            ],
            filter:null,
            filterOn: [],
            form: {
                regione: '',
                provincia:'',
                denominazione_scuola:'',
                codice_scuola:'',
            },
            risultati:[],
            registraRisultato: [],
            showOverlay:false
        }
    },
    computed: {

    },

    methods: {
        sortOptions() {
            return this.fields
                .filter(f => f.sortable)
                .map(f => {
                    return { text: f.label, value: f.key }
                })
        },
        ricerca() {
            if(this.form) {
                this.busy = true
                const f = this.form
                ScuoleService.getFilterScuole(f)
                    .then(resp => {
                        this.risultati = resp.data
                        this.busy = false
                    })
                    .catch(e => {
                    for (const [key, value] of Object.entries(e.response.data)) {
                        this.$bvToast.toast(`Errore Ricerca!`, {
                            title: `Non è stato possibile fare la ricerca. Campo ${key}: ${value}`,
                            variant: 'danger',
                            solid: true,
                            autoHideDelay: 5000,
                            appendToast: true
                        })
                        this.busy = false
                    }
                })
            }else{
                alert("mancano dei campi")
            }
        },
        inviaRisultato(s) {
            this.$emit('insertCode', s)
        },
        onRowSelected(select) {
            this.selected = select
            this.$emit('insertCode', select[0])
            console.log(select)
        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },

    }
}
</script>

<style scoped>

</style>
