<template>
	<!-- BEGIN register -->
	<div class="register">
		<!-- BEGIN register-content -->
		<div class="register-content" v-if="tokenData.success">
			<form v-on:submit="checkForm" name="register_form">
				<h1 class="text-center">Pagina Per il Reset della Password</h1>
				<p class="text-muted text-center">Inserisci la nuova Password</p>
				<span class="text-center" :class="password.length >= 8 ? 'text-success':'text-muted'">Minimo 8 caratteri</span> /
				<span class="text-center" :class="capitalLetter(password) ? 'text-success':'text-muted'">Almeno una Maiuscola</span> /
				<span class="text-center" :class="containsNumbers(password) ? 'text-success':'text-muted'">Almeno un Numero</span> /
				<span class="text-center" :class="containsSpecialChars(password) ? 'text-success':'text-muted'">Almeno un Carattere Speciale</span>
        <b-form-group>
          <template v-slot:label>
            Password
            <span class="text-danger" v-if="!showIcon">*</span>
             <b-icon v-if="showIcon" icon="check" class="text-success"></b-icon>
          </template>
            <b-input v-model="password" size="lg" class="fs-15px" type="password"></b-input>
          <small class="float-left" v-if="showPassword">{{password}}</small>
          <b-link class="float-right" @click="showPassword = !showPassword">show password</b-link>
        </b-form-group>

        <b-form-group>
          <template v-slot:label>
            Ripeti Password
            <span class="text-danger" v-if="!showIcon">*</span>
            <b-icon v-if="showIcon" icon="check" class="text-success"></b-icon>
          </template>
          <b-input v-model="password2" size="lg" class="fs-15px" type="password"></b-input>
          <small class="float-left" v-if="showPassword">{{password2}}</small>
          <b-link class="float-right" @click="showPassword = !showPassword">show password</b-link>
        </b-form-group>

				<b-form-group>
					<b-button @click="checkForm" :disabled="!enableButton"  class="btn btn-dark btn-lg fs-15px fw-500 btn-block">Reset Password</b-button>
				</b-form-group>
				<div class="text-muted text-center">
					Vuoi Annulare l'operazione di reset password? <router-link to="/login">Sign In</router-link>
				</div>
			</form>
		</div>
    <div v-else>
      <h1 class="text-center">Pagina Per il Reset della Password</h1>
      <h3>Spiacenti ma il link risulta <b>scaduto!</b></h3>
    </div>
		<!-- END register-content -->
	</div>
	<!-- END register -->
</template>

<script>
import AppOptions from '@/config/AppOptions'
import AuthService from "@/services/auth.service";
import Swal from "sweetalert2";

const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});

	export default {
    name:'PageChangePassword',
    data() {
      return {
        password:'',
        password2:'',
        showIcon: false,
        tokenData:[],
        checkInput: {
          minCharacter: false,
          upperCharacter: false,
          numberCharacter: false,
          specialCharacter: false,
        },
        enableButton: false,
        fieldUidb64:'',
        fieldToken:'',
        showPassword: false
      }
    },
		mounted() {
			AppOptions.appWithoutSidebar = true;
			AppOptions.appWithoutHeader = true;
			AppOptions.appContentFullWidth = true;
      this.checkTokenIsActive(
        this.$route.params.uidb64,
        this.$route.params.token
      )
		},
		beforeRouteLeave (to, from, next) {
			AppOptions.appWithoutSidebar = false;
			AppOptions.appWithoutHeader = false;
			AppOptions.appContentFullWidth = false;
			next();
		},
    watch: {
      password2 (value) {
        if (
          this.checkInput.numberCharacter &&
          this.checkInput.specialCharacter &&
          this.checkInput.upperCharacter &&
          value === this.password) {
          this.showIcon = true
          this.enableButton = true
        }
      }
    },
		methods: {
      capitalLetter(str) {
        if (str !== str.toLowerCase()) {
          this.checkInput.upperCharacter = true
          return true
        } return false
      },
      containsNumbers(str) {
        if (/\d/.test(str)) {
          this.checkInput.numberCharacter = true
          return true
        } return false
      },
      containsSpecialChars(str) {
        const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
        if (specialChars.test(str)) {
          this.checkInput.specialCharacter = true
          return true
        } return false
      },
			checkForm() {
        const payload = {
          'password': this.password,
          'uidb64': this.fieldUidb64,
          'token': this.fieldToken
        }
        AuthService.sendNewPassword(payload)
          .then(resp => {
            toast.fire({
              icon: 'success',
              title: 'Password cambiata con successo!',
              background: 'success',
              titleFontColor: 'white'
            })
            this.$router.push('/login')
            console.log(resp.data)
          })
          .catch(error => {
            console.log(error)
          })
			},
      checkTokenIsActive(uidb64, token) {
        this.fieldUidb64 = uidb64
        this.fieldToken = token
        AuthService.checkTokenPassword(uidb64, token)
          .then(resp => {
            this.tokenData = resp.data
          })
          .catch(error => {
            console.log(error)
          })
        console.log(uidb64, token)
      }
		}
	}
</script>
