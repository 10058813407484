var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"ViewPreview","size":"xl","title":"Preview Lezione","header-bg-variant":"dark","header-text-variant":"white"}},[_c('b-card',{attrs:{"border-variant":"white"}},[_c('b-card-title',{staticClass:"d-inline-flex"},[_c('p',{staticClass:"text-muted mr-3"},[_vm._v("Titolo Lezione:")]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.preview.titolo))])]),_c('b-card-sub-title',[_c('div',{staticClass:"d-inline-flex"},[_c('p',{staticClass:"text-muted mr-3"},[_vm._v("Annotazioni:")]),_vm._v(" "),_c('h6',{staticClass:"text-dark"},[_vm._v(_vm._s(_vm.preview.annotazione))])])]),_c('b-card-body',_vm._l((_vm.preview.files),function(file,index){return _c('b-row',{key:index,attrs:{"cols":"12"}},[(file.fileType === 'video')?_c('b-col',[_c('b-card',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('i',{staticClass:"fas fa-film"}),_vm._v(" "+_vm._s(file.fileExt)+" ")]),_c('video-player',{attrs:{"options":{
                         autoplay: false,
                         controls: true,
                         fluid: true,
                         bigPlayButton: true,
                         sources: [{src: file.file} ],
                 }}})],1)],1):_vm._e(),(file.fileType === 'audio')?_c('b-col',[_c('b-card',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('i',{staticClass:"fas fa-volume-up"}),_vm._v(" "+_vm._s(file.fileExt)+" ")]),_c('video-player',{attrs:{"id":"audio","options":{
                        autoplay: false,
                        controls: true,
                        fluid: true,
                        bigPlayButton: true,
                       sources: [{src: file.file} ],
                      }}})],1)],1):_vm._e(),(file.fileType === 'doc')?_c('b-col',[_c('b-card',{staticClass:"mb-2"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('i',{staticClass:"fas fa-file-alt"}),_vm._v(_vm._s(file.nome_file)+" "),_c('a',{attrs:{"download":"","href":file.file,"target":"_blank"}},[_c('b-button',{attrs:{"variant":"outline-primary"}},[_vm._v("Download")])],1)])])],1):_vm._e()],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }