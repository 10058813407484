import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL


class EventService {
  // Eventi Istittuto
  getEventoIstituto(id) {
    return axios.get(API_URL + 'evento_log' + id, { headers: authHeader()})
  }
  getEventLog(params) {
    return axios.get(API_URL + 'eventi_log/', {headers: authHeader(), params:params});
  }
  getEventLogById(id) {
    return axios.get(API_URL + 'eventi_log/' + id, {headers: authHeader()});
  }
  postEventLog(data) {
    return axios.post(API_URL + 'eventi_log/', data, {headers: authHeader()})
  }
  patchEventLog(id, data) {
    return axios.patch(API_URL + `eventi_log/${id}/`, data, {headers: authHeader()})
  }
  filterEventLog(id) {
    return axios.get(API_URL + 'eventi_log/?contatto=' + id, { headers: authHeader()})
  }
  filterEventLogIstituto(id) {
    return axios.get(API_URL + 'eventi_log/?contatto=&istituto=' + id, { headers: authHeader()})
  }
  eventiLogLeadContact(payload) {
    return axios.post(API_URL + 'eventi_log_lead/', payload, {headers: authHeader()})
  }
  getEventiLogLeadContact(id) {
    return axios.get(API_URL + 'eventi_log_lead/?lead=' + id, { headers: authHeader()})
  }
  getSingleEventiLogLeadContact(id) {
    return axios.get(API_URL + 'eventi_log_lead/'+ id, { headers: authHeader()})
  }
  editSingleEventiLogLeadContact(payload) {
    return axios.put(API_URL + 'eventi_log_lead/'+ payload.id + '/', payload, { headers: authHeader()})
  }
  getEventManager(){
    return axios.get(API_URL + 'accounts?is_event_manger=' + true, { headers: authHeader()} )
  }
  deleteEventManager(id){
    return axios.delete(API_URL + 'eventi_log_lead/' + id + '/', { headers: authHeader()})
  }
  getFollowUpLead(id){
    return axios.get(API_URL + 'followup_lead/?lead=' + id, { headers: authHeader()} )
  }
  postFollowUpLead(payload){
    return axios.post(API_URL + 'followup_lead/', payload, { headers: authHeader()} )
  }
  // Note
  addNotadaListaEventi(payload) {
    return axios.post(API_URL + 'note_evento_istituto/', payload, { headers:  authHeader()})
  }
  deleteNotaDaListaEventi(id) {
    return axios.delete(API_URL + 'note_evento_istituto/'+ id,  { headers: authHeader()})
  }
  // Eventi Lead
  getEventi(params) {
    return axios.get(API_URL + 'eventi_lead/', { headers: authHeader(), params:params})
  }
  postEvento(payload) {
    return axios.post(API_URL + 'eventi_lead/', payload, { headers:authHeader() } )
  }
  postEventoBulk(payload) {
    return axios.post(API_URL + 'eventi_lead/post_evento_bulk/', payload, { headers:authHeader() } )
  }
  getFollowUp(params) {
    return axios.get(API_URL + 'lead_followup/', { headers:authHeader(), params:params})
  }
  setFollowUpChecked(payload) {
    return axios.patch(API_URL + 'lead_followup/' + payload.id + '/', payload, { headers: authHeader() })
  }
  deleteFollowup(id) {
    return axios.delete(API_URL + 'lead_followup/' + id + '/', { headers: authHeader()})
  }
  postFollowUp(payload) {
    return axios.post(API_URL + 'lead_followup/', payload, { headers: authHeader() })
  }
  editFollowUp(payload) {
    return axios.patch( API_URL + 'lead_followup/'+ payload.id + '/', payload, { headers: authHeader() })
  }
  deleteEvento(id) {
    return axios.delete(API_URL + 'eventi_lead/' + id + '/', { headers:authHeader()})
  }
  deleteEventoIstituto(id) {
    return axios.delete(API_URL + 'eventi_log/' + id + '/', { headers:authHeader()})
  }
  updateEvento(payload) {
    return axios.patch(API_URL + 'eventi_lead/' + payload.id + '/', payload, {headers: authHeader()})
  }
  async getEvento(id) {
    return await  axios.get(API_URL + 'eventi_lead/' + id + '/', { headers: authHeader()})
  }
  getReplyByEventoId(id) {
    return axios.get(API_URL + 'eventi_log/?parent_by_evento=' + id,  {headers: authHeader() })
  }

}
export default new EventService();
