<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <b-breadcrumb>
            <b-breadcrumb-item href="#">BREADCRUMB</b-breadcrumb-item>
            <b-breadcrumb-item active>TASK</b-breadcrumb-item>
        </b-breadcrumb>
        <h1 class="page-header">
            Lista Task
            <b-link to="/crm/lead/list">
                <small><i class="fas fa-redo"></i></small>
            </b-link>
        </h1>
        <b-row>
            <b-card>
                <b-card-header header-bg-variant="white">
                    che cosa
                </b-card-header>
            <b-table
                class="table"
                :items="task"
                :fields="fields"
                small
                hover
                striped
                select-mode="multi"
                @row-selected="onRowSelected"
                responsive="sm"
                search
                >
                <template v-slot:cell(istituto)="row" >
                    <div v-for="i in row.item.istituto" :key="i">
                        {{ i }}</div>
                </template>
                <template v-slot:cell(contatto)="row" >
                    <div v-for="i in row.item.contatto" :key="i">
                        {{ i }}</div>
                </template>
                <template v-slot:cell(studente)="row" >
                    <div v-for="i in row.item.studente" :key="i">
                        {{ i }}</div>
                </template>
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>
                <template v-slot:cell(stato)="row">
                    <b-badge v-if="row.item.stato === 'In Corso'" variant="success" v-b-toggle.edit_task>in corso</b-badge>
                    <b-badge v-if="row.item.stato === 'Aperto'" variant="warning" v-b-toggle.edit_task>aperto</b-badge>
                    <b-badge v-if="row.item.stato === 'In Attesa'" variant="info" v-b-toggle.edit_task>in attesa</b-badge>
                </template>
                <template v-slot:cell(actions)="row">
                    <div class="text-lg-left">
                        <b-btn size="sm"
                               style="margin: 0px; padding: 0px; color: gray"
                               v-b-tooltip.hover.left
                               variant="link"
                               title="Edit Task"
                               v-b-toggle.edit_task
                                @click="editTask(row.item)">
                            <i class="fas fa-edit"></i>
                        </b-btn> |
                        <b-btn size="sm"
                               style="margin: 0px; padding: 0px; color: gray"
                               v-b-tooltip.hover.top
                               title="Chiudi Task"
                               variant="link">
                            <i class="fas fa-times"></i>
                        </b-btn> |
                        <b-btn size="sm"
                               style="margin: 0px; padding: 0px; color: gray"
                               v-b-tooltip.hover.right
                               title="Elimina Task"
                               variant="link"
                               >
                            <i class="fas fa-trash"></i>
                        </b-btn> |
                        <b-btn size="sm"
                               style="margin: 0px; padding: 0px; color: gray"
                               v-b-tooltip.hover.right
                               title="Aggiorna Task"
                               variant="link">
                            <i class="fas fa-reply"></i>
                        </b-btn>
                    </div>
                </template>
            </b-table>
            </b-card>
        </b-row>
        <b-sidebar
            id="edit_task"
            title="Edit Task"
            backdrop-variant="info"
            backdrop>
            <div class="px-3 py-2">
                <b-form v-if="show">
                    <b-form-group
                        id="input-group-1"
                        label="Stato:"
                        label-for="input-1"
                        description="Inserisci lo stato del task"
                    >
                        <b-form-select
                            id="input-1"
                            v-model="form.stato"
                            :options="stato"
                            type="email"
                            required
                            placeholder="Enter email"
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group
                        id="input-group-2"
                        label="Priorità:"
                        label-for="input-2"
                        description="Inserisci lo priorità del task"
                    >
                        <b-form-select
                            id="input-2"
                            v-model="form.priorita"
                            :options="priorita"
                            type="email"
                            required
                            placeholder="Enter email"
                        ></b-form-select>
                    </b-form-group>

                    <b-form-group id="input-group-3" label="Note" label-for="input-3">
                        <b-form-textarea
                            id="input-3"
                            v-model="form.note"
                            required
                            placeholder="Inserisci Note"
                        ></b-form-textarea>
                    </b-form-group>

                    <b-form-group id="input-group-4" label="Istituto:" label-for="input-4">
                        <b-form-input
                            id="input-4"
                            v-model="form.istituto"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-5" label="Contatto:" label-for="input-5">
                        <b-form-input
                            id="input-5"
                            v-model="form.contatto"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-6" label="Studente:" label-for="input-6">
                        <b-form-input
                            id="input-6"
                            v-model="form.studente"
                            required
                        ></b-form-input>
                    </b-form-group>

                    <b-button type="submit" variant="primary">Modifica</b-button>
                    <b-button type="reset" variant="danger">Reset</b-button>
                </b-form>
            </div>
        </b-sidebar>
        <timeline />
    </div>
    <!-- END #content -->
</template>

<script>
import taskListService from '@/services/tasks.service'
import timeline from "@/components/crm/timeline";
export default {
    name: "ListLead",
    components: {
        timeline,
    },
    data(){
        return{
            form: {
                stato:"",
                id:"",
                priorita:"",
                note:"",
                created_by:"",
                istituto:"",
                contatto:"",
                studente:"",
            },
            stato: [{ text: 'Seleziona Stato', value: null }, 'Aperto', 'In Corso', 'In Attesa', 'Chiuso'],
            priorita: [{ text: 'Seleziona Stato', value: null }, 'Normale', 'Media', 'Molto Importante', 'Estremamente Importante'],
            show: true,
            task:[],
            currentPage: 1,
            fields:[
                {key:'stato',label: "Stato"},
                {key:'id', label:'id', sortable:false},
                {key:'priorita', label:'Priorita', sortable:false},
                {key:'note', label:'note.', sortable:false},
                {key:'created_by', label:'Creato Da', sortable:true},
                {key:'istituto', label:'Istituto', sortable:false},
                {key:'contatto', label:'Contatto', sortable:false},
                {key:'studente', label:'Studente', sortable:false},
                {key:'actions', label:'Actions'}
            ],
            selected: [],
            showAlert: false,
        }
    },
    mounted() {
        this.InitTask()
    },
    methods:{
        InitTask(){
            taskListService.getTask()
                .then(resp => {
                    console.log(resp)
                    this.task = resp.data
                })
        },
        editTask(row) {
            this.form.stato = row.stato
            this.form.priorita = row.priorita
            this.form.note = row.note
            this.form.istituto = row.istituto
            this.form.contatto = row.contatto
            this.form.studente = row.studente
            console.log(row)
        },
        onRowSelected(items) {
            this.selected = items
        },
        checkEmail(){
            this.email_sendend = !this.email_sendend
        },
        // deleteLead(item){
        //   UserService.deleteLead(item.id)
        //     .then(resp => {
        //         this.InitLead()
        //         console.log(resp)
        //     })
        // },
    }
}
</script>

<style>

/*.table tbody tr:hover td, .table tbody tr:hover th {
    background-color: #f4c414;
    color: #212837;
    font-size: 12px;
}*/
/*.table tbody tr td, .table tbody tr th {
    font-size: 12px;
}*/
</style>
