<template>
  <div>
    <b-overlay :show="overlayFollowUp" type="rounded"
               variant="dark"
               blur="blur"
               rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-icon style="color: #FFFFFF" icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
          <p id="cancel-label" style="color: #FFFFFF">Please wait...</p>
        </div>
      </template>
      <small class="text-muted">Nome Component: src/components/crm/followup/tableFollowUp.vue</small>
      <b-card>
        <b-button size="sm" variant="dark" class="float-right mb-2 ml-2" @click="addFollowUp">Aggiungi</b-button>
        <b-button size="sm" variant="yellow" class="float-right mb-2" @click="getFollowUp">Refresh</b-button>
        <h2 class="font-italic">{{ lead.full_name }}</h2>
        <b-card style="background-color: #f9f9f9" no-body>
          <b-card-title style="background-color: #f6aba6" v-b-toggle.collapse-33 class="text-center">
            <h6 class="m-3" style="color: #000"><strong>APERTI</strong></h6>
          </b-card-title>
          <b-card-sub-title class="ml-3 text-underline font-italic">Totale followUp: {{ followupUnchecked.length }}</b-card-sub-title>
          <b-collapse visible id="collapse-33" class="mt-2">
            <b-card-body>
              <b-table
                refs="tableFollowup"
                :items="followupUnchecked"
                :fields="fieldsFollowUpLead"
                hover
                small
                show-empty
                empty-text="Non ci sono ancora FollowUp"
                sticky-header="200px"
              >
                <template v-slot:cell(created_at)="row">
                  <small>{{ row.item.created_at | formatDateTime }}</small>
                </template>
                <template #cell(modified_at)="row">
                  <small>{{ row.item.modified_at | formatDateTime }}</small>
                </template>
                <template #cell(oggetto)="row">
                  <h5><b-badge :variant="row.item.oggetto === 'Richiamare' ? 'danger': (row.item.oggetto === 'Mandare Email' ? 'dark': 'yellow')">
                    {{ row.item.oggetto}}</b-badge></h5>
                </template>
                <template #cell(data_follow)="row">
                  <h5><b-badge variant="dark" class="font-weight-bold text-white">{{ row.item.data_follow | formatDateExt }}</b-badge></h5>
                </template>
                <template #cell(fatto)="" >
                  <span class="text-center" style="color: #9e0808"><i class="fas fa-thumbs-down"></i></span>
                </template>
                <template #cell(dettagli)="row">
                  <span :id="`dettagli-aperti-${row.item.id}`">{{ returnOnlyTenLetters(row.item.dettagli) }} </span>
                  <b-popover id="special-popover" :target="`dettagli-aperti-${row.item.id}`" triggers="hover" placement="right">
                    <template #title>Dettagli:</template>
                    {{ row.item.dettagli }}
                  </b-popover>
                </template>
                <template #cell(orientatori_email)="row">
                  <h5 class="text-center" :id="`email-aperti-${row.item.id}`"><b-badge>{{ row.item.orientatori_email.length }}</b-badge></h5>
                  <b-popover id="special-popover" :target="`email-aperti-${row.item.id}`" triggers="hover">
                    <template #title>Emails Orientatori:</template>
                    <span
                      v-for="(result, index) in row.item.orientatori_email"
                      :key="index"
                      size="sm"
                    >{{ result }}
                    </span>
                  </b-popover>
                </template>
                <template #cell(actions)="row">
                  <b-link @click="setFollowUpChecked(row.item)" style="color: #3c4e71"><i class="fas fa-thumbs-up"></i></b-link>
                  <b-link @click="editFollowUp(row.item)" class="ml-2" style="color: #3c4e71"><i class="fas fa-edit"></i></b-link>
                  <b-link @click="deleteFollowup(row.item.id)" class="ml-2" style="color: #3c4e71"><i class="fas fa-trash"></i></b-link>
                </template>
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>

        <b-card class="mt-2" style="background-color: #f9f9f9" no-body>
          <b-card-title style="background-color: #9bd289" v-b-toggle.collapse-34 class="text-center">
            <h6 class="m-3" style="color: #000"><strong>CHIUSI</strong></h6>
          </b-card-title>
          <!--                    <b-card-title style="background-color: #3c4e71" v-b-toggle.collapse-34>-->
          <!--                        <div class="" style="max-width: 75px; background-color: #c6e9c2">-->
          <!--                            <span class="px-1 text-dark text-center font-italic font-weight-bold" style="font-size:1rem">Fatti</span>-->
          <!--                        </div>-->
          <!--                    </b-card-title>-->
          <b-card-sub-title class="ml-3 text-underline font-italic">Totale followUp: {{ followupChecked.length }}</b-card-sub-title>
          <b-collapse visible id="collapse-34" class="mt-2">
            <b-card-body>
              <b-table
                refs="tableFollowup"
                :items="getReverseChecked"
                :fields="fieldsFollowUpLead"
                hover
                small
                show-empty
                empty-text="Non ci sono ancora FollowUp"
                sticky-header="200px"
              >
                <template #cell(fatto)="row">
                  <span v-if="row.item.fatto" style="color: #70b668"><i class="fas fa-thumbs-up"></i></span>
                  <span v-else style="color: #9e0808"><i class="fa fa-check-circle"></i></span>
                </template>
                <template #cell(oggetto)="row">
                  <h5><b-badge :variant="row.item.oggetto === 'Richiamare' ? 'danger': (row.item.oggetto === 'Mandare Email' ? 'dark': 'yellow')">
                    {{ row.item.oggetto}}</b-badge></h5>
                </template>
                <template #cell(created_at)="row">
                  <small>{{ row.item.created_at | formatDateTime }}</small>
                </template>
                <template #cell(modified_at)="row">
                  <small>{{ row.item.modified_at | formatDateTime }}</small>
                </template>
                <template #cell(data_follow)="row">
                  <h5><b-badge variant="info" class="font-weight-bold text-white">{{ row.item.data_follow | formatDateExt }}</b-badge></h5>
                </template>
                <template #cell(dettagli)="row">
                  <span :id="`dettagli-${row.item.id}`">{{ returnOnlyTenLetters(row.item.dettagli) }}</span>
                  <b-popover style="background-color: #3c4e71; color: #FFFFFF" :target="`dettagli-${row.item.id}`" triggers="hover" placement="top">
                    <template #title>Dettagli:</template>
                    {{ row.item.dettagli }}
                  </b-popover>
                </template>
                <template #cell(orientatori_email)="row">
                  <h5 class="text-center" :id="`email-chiusi-${row.item.id}`"><b-badge>{{ row.item.orientatori_email.length }}</b-badge></h5>
                  <b-popover id="follow-up-chiusi" :target="`email-chiusi-${row.item.id}`" triggers="hover">
                    <template #title>Emails Orientatori:</template>
                    <span size="sm"
                          v-for="(result, index) in row.item.orientatori_email"
                          :key="index"
                    >{{ result }}<br></span>
                  </b-popover>
                </template>
                <template #cell(actions)="row">
                  <b-link @click="editFollowUp(row.item)" class="ml-2" style="color: #3c4e71"><i class="fas fa-edit"></i></b-link>
                </template>
              </b-table>
            </b-card-body>
          </b-collapse>
        </b-card>
      </b-card>
    </b-overlay>
    <modale-edit-followup-lead :itemFollowUp="itemFollowUp" v-if="showModale" @refreshFollowup="this.getFollowUp"></modale-edit-followup-lead>
    <b-modal id="delete-followup" title="Cancella Followup" header-bg-variant="dark" header-text-variant="white" body-bg-variant="white" no-fade>
      Sei sicuro di voler cancellare questo Follow-Up?
    </b-modal>
    <!--        <follow-up ref="aggiungi-followup" :item="item" @refreshFollowup="this.getFollowUp"></follow-up>-->
  </div>
</template>

<script>
import EventService from "../../../../services/event.service";
import ModaleEditFollowupLead from "./ModaleEditFollowupLead";
import LeadService from "../../../../services/lead.service";
import Swal from "sweetalert2";

export default {
    name: "tableFollowUp",
    components: {
      ModaleEditFollowupLead,
    },
    props: {
        item: {
            type: Object
        },
    },
    data() {
        return {
            overlayFollowUp: false,
            followupChecked:[],
            followupUnchecked:[],
            fieldsFollowUpLead:[
                {key:'created_at', label:'Creato il'},
                {key:'modified_at', label:'Modificato il'},
                {key:'data_follow', label:'Per il'},
                {key:'oggetto', label:'Oggetto'},
                {key:'dettagli', label:'Risultato'},
                {key:'fatto', label:'Checked'},
                {key:'orientatori_email', label:'Assegnato a'},
                {key:'actions', label:'Actions'},

            ],
            itemFollowUp:{},
            showModale: false,
            lead: {}
        }
    },
    computed: {
        getReverseChecked() {
            return this.followupChecked.slice().reverse()
        },
    },
    mounted() {
        this.getFollowUpChecked().then(() => {
            this.getFollowUpUnchecked().then(() => {
                this.getLead().then(() => {
                    console.log('ok')
                })
            })
        })
    },
    methods: {
        returnOnlyTenLetters(item) {
            if (item) {
                return item.slice(0, 10) + '...'
            }
            return 'no data'
        },
        getLead(){
            return LeadService.getLeadFromId(this.item.id)
                .then(resp => {
                    console.log(resp)
                    this.lead = resp.data
                })

        },
        getFollowUp() {
            return this.getFollowUpChecked().then(() => {
                return this.getFollowUpUnchecked().then(() => {
                    return this.getLead().then(() => {
                        console.log('ok')
                    })
                })
            })
        },
        setFollowUpChecked(items) {
            console.log(items)
            const payload = {
                id: items.id,
                lead: items.lead,
                fatto: true
            }
            EventService.setFollowUpChecked(payload)
                .then(() =>  {
                    this.getFollowUp()
                    this.$emit('refreshLead')

                    this.$bvModal.hide('mostraFollowUp')
                    this.$bvToast.toast(`Followup Checked!`, {
                        title: 'FollowUp modificato con successo',
                        variant: 'success',
                        solid: true,
                        autoHideDelay: 5000,
                        appendToast: true
                    })

                })
        },
        getFollowUpChecked(){
            const params = {
                lead: this.item.id,
                fatto: true
            }
            return EventService.getFollowUp(params)
                .then(resp =>{
                    this.followupChecked = resp.data.results
                    this.overlayFollowUp = false
                })
                .catch()
        },
        getFollowUpUnchecked(){
            const params = {
                lead: this.item.id,
                fatto: false
            }
            return EventService.getFollowUp(params)
                .then(resp =>{
                    this.followupUnchecked = resp.data.results
                    this.overlayFollowUp = false
                })
                .catch()
        },
        addFollowUp(){
            this.$emit('aggiungi-followup', this.item)
            this.$bvModal.show('aggiungi-followup')
        },
        editFollowUp(item) {
            this.itemFollowUp = item
            console.log("itemFollowUp: ", this.itemFollowUp)
            this.showModale = true
            this.$nextTick(() =>{
                this.$bvModal.show('edit-followup')
            })

        },
        deleteFollowup(id){
            Swal.fire({
                title: 'Sei Sicuro?',
                text: "Non potrai riprendere questo elemento una volta cancellato!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, cancellalo!'
            }).then((result) => {
                if (result.isConfirmed) {
                    EventService.deleteFollowup(id)
                        .then(resp => {
                            Swal.fire(
                                'Deleted!',
                                `Your file has been deleted. Status: ${resp.status}`,
                                'success'
                            )
                            this.getFollowUp()
                        })
                }
            })
        },
        deleteFollowUp(item) {
            this.$emit('elimina-followup', this.itemFollowUp)
            console.log(item)
        }
    }
}
</script>

<style>
special-popover .popover {
    background-color: red;
    color: #c67605;
}
</style>
