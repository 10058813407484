<template>
    <b-sidebar
        no-header
        width="100%"
        id="SidebarAddLead"
        ref="SidebarAddLead"
        :title="`Inserisci nuovo Lead`"
        right
        backdrop-variant="dark"
    >
        <template  #footer="{}">
            <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
                    <b-button variant="success" @click="inserisciLead()" v-if="!spinnerNewLead">Inserisci</b-button>
                    <b-button variant="dark" disabled v-if="spinnerNewLead">
                        <b-spinner small type="grow"></b-spinner>
                        Loading...
                    </b-button>
                    <b-button variant="gray-700" @click="saveAndContinue()" class="ml-3">Salva e Continua</b-button>
                    <b-button variant="danger" @click="resetFormFieldsLead" class="ml-3">Chiudi</b-button>
                    <strong class="ml-auto">{{ $store.state.user.username }}</strong>
            </div>
        </template>
        <b-card bg-variant="dark" class="rounded-0">
            <b-row>
                <b-col cols="3">
                        <h4 style="color: #f9f9fa">Nuovo Lead</h4>
                    <label for="provenienza">Provenienza:</label>
                    <b-form-select
                        id="provenienza"
                        v-model="form.provenienza_lead"
                        :options="fromForm"
                        text-field="label"
                        value-field="key"
                        size="sm"
                    ></b-form-select>
                </b-col>
                <b-col cols="3">
                </b-col>
                <b-col cols="6">
                    <div class="float-left">
                        <b-form-checkbox name="active" v-model="form.active" switch title="Attivo/Disabilitato" inline size="lg"></b-form-checkbox>
                        <span style="color: #ffffff;">{{ this.form.active ? 'Attivo':'Disabilitato'}}</span>
                    </div>
                    <div class="float-right">
                        <coinvolgimento :lead="form"></coinvolgimento><!-- coinvolgimento -->
                    </div>

                </b-col>
            </b-row>
        </b-card>
        <b-card bg-variant="">
            <b-tabs nav-class="nav-tabs-v2" content-class="pt-2" fill>
                <b-form>
                    <!-- INFORMAZIONI ANAGRAFICA LEAD -->
                    <b-tab title="Anagrafica Principale" active>
                        <b-row>
                            <b-col>
                                <b-card bg-variant="gray-050">
                                    <label for="nome">Nome:</label>
                                    <b-input-group id="nome" label="Nome:" label-for="nome" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_nome"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="nome"
                                            size="sm"
                                            placeholder="Inserisci il Nome"
                                            v-model="form.lead_nome"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label for="cognome">Cognome:</label>
                                    <b-input-group
                                        id="cognome"
                                        size="sm"
                                    >
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_cognome"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="cognome"
                                            type="text"
                                            placeholder="Inserisci il cognome"
                                            size="sm"
                                            name=""
                                            v-model="form.lead_cognome"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label for="data_di_nascita">Data di nascita:</label>

                                    <b-input-group id="data_di_nascita" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_data_di_nascita"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-datepicker
                                            id="data_di_nascita"
                                            placeholder="Inserisci la data di nascita"
                                            size="sm"
                                            v-model="form.lead_data_di_nascita"
                                        ></b-form-datepicker>
                                    </b-input-group>
                                    <label for="luogo_di_nascita">Luogo di nascita:</label>
                                    <b-input-group id="luogo_di_nascita" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_luogo_di_nascita"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="luogo_di_nascita"
                                            placeholder="Inserisci luogo di nascita"
                                            size="sm"
                                            v-model="form.lead_luogo_di_nascita"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label for="cf">Cod. Fiscale:</label>
                                    <b-input-group id="cf" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_cf"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="cf"
                                            placeholder="Inserisci Cod. Fiscale"
                                            size="sm"
                                            v-model="form.lead_cf"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-card>
                            </b-col>
                            <b-col>
                                <b-card bg-variant="gray-050">
                                    <label id="indirizzo" for="indirizzo">Indirizzo:</label>
                                    <b-input-group
                                        id="indirizzo"
                                        size="sm"
                                    >
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_indirizzo"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="indirizzo"
                                            type="text"
                                            placeholder="Inserisci l'indirizzo"
                                            size="sm"
                                            v-model="form.lead_indirizzo"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label id="citta" for="citta">Città:</label>
                                    <b-input-group id="citta" label="Città:" label-for="citta" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_citta"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="citta"
                                            placeholder="Inserisci la Città del Lead"
                                            size="sm"
                                            v-model="form.lead_citta"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label id="comune" for="comune">Comune:</label>
                                    <b-input-group id="comune" label="Comune:" label-for="comune" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_comune"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="citta"
                                            placeholder="Inserisci il Comune del Lead"
                                            size="sm"
                                            v-model="form.lead_comune"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label id="provincia" for="provincia">Provincia:</label>
                                    <b-input-group id="provincia" label="Provincia:" label-for="provincia" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_provincia"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="provincia"
                                            placeholder="Inserisci la Provincia"
                                            size="sm"
                                            v-model="form.lead_provincia"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label>Cellulare:</label>
                                    <b-input-group id="cellulare" label="Cellulare:" label-for="cellulare" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_cellulare"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="cellulare"
                                            placeholder="Cellulare"
                                            size="sm"
                                            v-model="form.lead_cellulare"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label id="email" for="email">Email:</label>
                                    <b-input-group id="email" label="Email:" label-for="email" size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.lead_email"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="email"
                                            placeholder="Email"
                                            size="sm"
                                            v-model="form.lead_email"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label id="how-to-know" for="how-to-know">Come ci hai conosciuto?</label>
                                <b-input-group id="how-to-know" label="Come ci hai conosciuto" label-for="how-to-know" size="sm">
                                    <template #append>
                                        <b-input-group-text v-if="form.come_ci_hai_conosciuto"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                        <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                    </template>
                                    <b-form-input
                                        id="how-to-know"
                                        size="sm"
                                        v-model="form.come_ci_hai_conosciuto"
                                    ></b-form-input>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row cols="12" class="mt-2">
                            <b-col>
                                <b-card bg-variant="gray-050">
                                    <label id="note" for="note">Note : </label>
                                    <b-form-textarea
                                        id="note"
                                        placeholder="Ad uso interno..."
                                        rows="3"
                                        max-rows="5"
                                        v-model="form.note"
                                    ></b-form-textarea>
                                </b-card>
                            </b-col>
                        </b-row>
                        <br>
                    </b-tab>
                    <!-- INFORMAZIONI SEDE e ANNO INTERESSE -->
                    <b-tab title="Sede/Anno interesse">
                        <b-card-text>
                            <b-row>
                                <b-col>
                                    <b-form-row>
                                        <b-col>
                                            <label id="anno" for="anno">Anno di Interesse:</label>
                                            <b-form-select
                                                :options="anno"
                                                v-model="form.anno_di_interesse"
                                                size="sm"
                                            ></b-form-select>
                                        </b-col>
                                        <b-col>
                                            <label id="sede" for="sede">Sede Interesse:</label>
                                            <b-form-select
                                                id="sede"
                                                :options="sedeInteresse"
                                                value-field="key"
                                                text-field="label"
                                                v-model="form.sede_interesse"
                                                size="sm">
                                            </b-form-select>
                                        </b-col>
                                    </b-form-row>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-tab>
                    <!-- INFORMAZIONI ISTITUTO -->
                    <b-tab title="Informazioni Istituto">
                        <b-card-text>
                            <b-row>
                                <b-col cols="6">
                                    <b-card bg-variant="gray-050">
                                        <label id="istituto_scolastico" for="istituto_scolastico">Istituto Scolastico:</label>
                                        <b-input-group size="sm">
                                            <template #append>
                                                <b-input-group-text v-if="form.istituto_scolastico"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                                <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                            </template>
                                            <b-form-input
                                                id="istituto-scolastico"
                                                placeholder="Inserisci il nome dell'Istituto"
                                                size="sm"
                                                v-model="form.istituto_scolastico"
                                            ></b-form-input>
                                        </b-input-group>
                                        <label id="classe" for="classe">Classe:</label>
                                        <b-input-group id="classe" size="sm">
                                            <template #append>
                                                <b-input-group-text v-if="form.istituto_classe"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                                <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                            </template>
                                            <b-form-input
                                                id="clasee"
                                                placeholder="Inserisci la Classe"
                                                size="sm"
                                            ></b-form-input>
                                        </b-input-group>
                                    </b-card>
                                </b-col>
                                <b-col cols="6">
                                    <b-card bg-variant="gray-050">
                                        <label id="istituto_citta" for="istituto_citta">Istituti Città</label>
                                        <b-input-group id="istituto_citta" size="sm">
                                            <template #append>
                                                <b-input-group-text v-if="form.istituto_citta"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                                <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                            </template>
                                            <b-form-input
                                                id="istituto-citta"
                                                placeholder="Inserisci la Città dell'Istituto"
                                                size="sm"
                                                v-model="form.istituto_citta"
                                            ></b-form-input>
                                        </b-input-group>
                                    </b-card>
                                </b-col>
                            </b-row>
                        </b-card-text>
                    </b-tab>
<!--                    &lt;!&ndash; INFORMAZIONI INTERAZIONI &ndash;&gt;-->
<!--                    <b-tab title="Interazioni">-->
<!--                        <b-card-text>-->
<!--                            <b-row>-->
<!--                                <b-col>-->
<!--                                    <b-card class="mb-2" bg-variant="gray-050" style="color: #03032d">-->
<!--                                        <b-row>-->
<!--                                            <b-col>-->
<!--                                                <div class="d-lg-inline">-->
<!--                                                    <h3>Interazioni: {{ interaction_logs.length }} | <span style="color: #1ABD36">+5%</span></h3>-->
<!--                                                </div>-->
<!--                                                <b-button v-if="form.id" class="mb-2" @click="nuovoLog()" variant="dark" size="sm">Aggiungi</b-button>-->
<!--                                                <b-form-group class="px-0 mx-0">-->
<!--                                                    <b-table-->
<!--                                                        sticky-header="200px"-->
<!--                                                        :items="interaction_logs"-->
<!--                                                        :fields="evento_field"-->
<!--                                                        striped-->
<!--                                                        head-variant="dark"-->
<!--                                                        size="sm"-->
<!--                                                        class="p-0 m-0"-->
<!--                                                    >-->
<!--                                                        <template v-slot:cell(note)="row">-->
<!--                                                            <b-link v-b-tooltip.note.left="row.item.nota" style="color: #3a3f51"><i class="fa fa-eye"></i></b-link>-->
<!--                                                        </template>-->
<!--                                                        <template v-slot:cell(actions)="row">-->
<!--                                                            <b-link @click="edit_log(row.item.id)" style="color: #03032d"><i class="fa fa-edit"></i></b-link>-->
<!--                                                        </template>-->
<!--                                                        <template v-slot:cell(emails_orientatore)="row">-->
<!--                                                            <div v-for="(user, index) in row.item.emails_orientatore" :key="index">-->
<!--                                                                <span v-b-tooltip.left="user" style="color: #03032d"><i class="fa fa-user d-inline-block"></i></span>-->
<!--                                                            </div>-->
<!--                                                        </template>-->
<!--                                                        <template v-slot:cell(data_interazione)="row">-->
<!--                                                            {{ row.item.data_interazione | formatDate()}}-->
<!--                                                        </template>-->
<!--                                                    </b-table>-->
<!--                                                </b-form-group>&lt;!&ndash; interazione &ndash;&gt;-->
<!--                                            </b-col>-->
<!--                                        </b-row>-->
<!--                                    </b-card>-->
<!--                                </b-col>-->
<!--                            </b-row>-->
<!--                            <b-row>-->
<!--                                <b-col cols="6">-->
<!--                                    <b-card class="mb-2" bg-variant="gray-050" style="color: #03032d">-->
<!--                                        <div class="d-flex align-items-center mb-4 h3">-->
<!--                                            <div>Direct Email Marketing</div>-->
<!--                                            <small class="fw-400 ml-auto text-success">+5%</small>-->
<!--                                        </div>-->
<!--                                        <label id="dem" for="dem">Ultima D.E.M. inviata</label>-->
<!--                                        <b-input-group id="dem" size="sm">-->
<!--                                            <template #append>-->
<!--                                                <b-input-group-text v-if="form.ultima_dem"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>-->
<!--                                                <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>-->
<!--                                            </template>-->
<!--                                            <b-form-input size="sm" id="dem" v-model="form.ultima_dem"></b-form-input>-->
<!--                                        </b-input-group>-->
<!--                                    </b-card> &lt;!&ndash; dem &ndash;&gt;-->
<!--                                    <b-card class="mb-2" bg-variant="gray-050" style="color: #03032d">-->
<!--                                        <div class="d-flex align-items-center mb-4 h3">-->
<!--                                            <div>Fiera</div>-->
<!--                                            <small class="fw-400 ml-auto text-success">+5%</small>-->
<!--                                        </div>-->
<!--                                        <b-form-checkbox size="sm" v-model="form.ti_partecipazione_fiera" switch>Ha partecipato alla Fiera? {{ form.ti_partecipazione_fiera }}</b-form-checkbox>-->
<!--                                        <label id="fiera" for="fiera">A quale Fiera?</label>-->
<!--                                        <b-input-group size="sm" id="fiera" label-for="fiera">-->
<!--                                            <template #append>-->
<!--                                                <b-input-group-text v-if="form.ti_fiera"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>-->
<!--                                                <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>-->
<!--                                            </template>-->
<!--                                            <b-form-input size="sm" id="fiera" v-model="form.ti_fiera"></b-form-input>-->
<!--                                        </b-input-group>-->
<!--                                        <b-form-checkbox size="sm" v-model="form.ti_webinar" name="webinar" switch-->
<!--                                        >Ha partecipato al Webinar?-->
<!--                                        </b-form-checkbox>-->
<!--                                    </b-card> &lt;!&ndash; fiera &ndash;&gt;-->
<!--                                </b-col>-->
<!--                                <b-col cols="6">-->
<!--                                    <b-card class="mb-2" bg-variant="gray-050" style="color: #03032d">-->
<!--                                        <div class="d-flex align-items-center mb-4 h3">-->
<!--                                            <div>FacSimile</div>-->
<!--                                            <small class="fw-400 ml-auto text-success">+15%</small>-->
<!--                                        </div>-->
<!--                                        <b-card-text>-->
<!--                                            <b-form-checkbox title="Richiesto Fac-Simile" v-model="form.ti_fac_simile_test">Richiesto Fac-Simile</b-form-checkbox>-->
<!--                                            <label size="sm" id="ti_fac_simile_test_data" for="ti_fac_simile_test_data">In data:</label>-->
<!--                                            <b-form-datepicker-->
<!--                                                id="dataRichiestaTest"-->
<!--                                                locale="it"-->
<!--                                                today-button-->
<!--                                                reset-button-->
<!--                                                close-button-->
<!--                                                :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"-->
<!--                                                v-model="form.ti_fac_simile_test_data"-->
<!--                                            ></b-form-datepicker>-->
<!--                                        </b-card-text>-->
<!--                                    </b-card>&lt;!&ndash; fac-simile &ndash;&gt;-->
<!--                                    <b-card class="mb-2" bg-variant="gray-050" style="color: #03032d">-->
<!--                                        <div class="d-flex align-items-center mb-4 h3">-->
<!--                                            <div>Evento</div>-->
<!--                                            <small class="fw-400 ml-auto text-success">+15%</small>-->
<!--                                        </div>-->
<!--                                        <b-card-text>-->
<!--                                            <label size="sm" id="tipologia_evento" for="tipologia_evento">Tipologia Evento:</label>-->
<!--                                            <b-form-select-->
<!--                                                :options="tipologiaEvento"-->
<!--                                                text-field="label"-->
<!--                                                value-field="key"-->
<!--                                            >-->
<!--                                            </b-form-select>-->
<!--                                            <label size="sm" id="dataEvento" for="dataEvento">Data Evento:</label>-->
<!--                                            <b-form-datepicker-->
<!--                                                id="dataEvento"-->
<!--                                                locale="it"-->
<!--                                                today-button-->
<!--                                                reset-button-->
<!--                                                close-button-->
<!--                                                :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"-->
<!--                                                v-model="form.ti_evento_data"-->
<!--                                            ></b-form-datepicker>-->
<!--                                            <label size="sm" id="sedeEvento" for="sedeEvento">Sede Evento:</label>-->
<!--                                            <b-form-input size="sm" id="sedeEvento" v-model="form.ti_evento_sede"></b-form-input>-->
<!--                                        </b-card-text>-->
<!--                                    </b-card> &lt;!&ndash; Evento Istituto &ndash;&gt;-->
<!--                                </b-col>-->
<!--                            </b-row>-->
<!--                        </b-card-text>-->
<!--                    </b-tab>-->
<!--                    &lt;!&ndash; INFORMAZIONI OPEN DAY &ndash;&gt;-->
<!--                    <b-tab title="Open Day">-->
<!--                        <b-row>-->
<!--                            <b-col >-->
<!--                                <b-card bg-variant="gray-050">-->
<!--                                    <div class="d-flex align-items-center mb-4 h3">-->
<!--                                        <div>Iscrizione Open Day:</div>-->
<!--                                    </div>-->
<!--                                    <b-row>-->
<!--                                        <b-col>-->
<!--                                            <label id="ti_open_day" for="ti_open_day">Iscritto:</label>-->
<!--                                            <b-form-checkbox-->
<!--                                                v-model="form.ti_open_day"-->
<!--                                                size="sm"-->
<!--                                                name="check-button"-->
<!--                                                switch-->
<!--                                            >Iscritto? {{ form.ti_open_day }}-->
<!--                                            </b-form-checkbox>-->
<!--                                        </b-col>-->
<!--                                        <b-col>-->
<!--                                            <label id="ti_partecipazione_od" for="ti_partecipazione_od">Partecipazione:</label>-->
<!--                                            <b-form-checkbox-->
<!--                                                v-model="form.ti_partecipazione_od"-->
<!--                                                size="sm"-->
<!--                                                name="check-button"-->
<!--                                                switch-->
<!--                                            >Ha Partecipato? {{ form.ti_partecipazione_od }}-->
<!--                                            </b-form-checkbox>-->
<!--                                        </b-col>-->
<!--                                    </b-row>-->
<!--                                    <b-row>-->
<!--                                        <b-col>-->
<!--                                            <label id="ti_data_od" for="ti_data_od">A quale Open Day?:</label>-->
<!--                                            <b-form-input-->
<!--                                                id="ti_data_od"-->
<!--                                                size="sm"-->
<!--                                                v-model="form.ti_data_od"-->
<!--                                            ></b-form-input>-->
<!--                                        </b-col>-->
<!--                                        <b-col>-->
<!--                                            <label id="ti_data2_od" for="ti_data2_od">A quale data?:</label>-->
<!--                                            <b-form-datepicker-->
<!--                                                id="ti_data_od2"-->
<!--                                                locale="it"-->
<!--                                                size="sm"-->
<!--                                                today-button-->
<!--                                                reset-button-->
<!--                                                close-button-->
<!--                                                :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"-->
<!--                                                v-model="form.ti_data_od2"-->
<!--                                            ></b-form-datepicker>-->
<!--                                        </b-col>-->
<!--                                    </b-row>-->
<!--                                    <b-row>-->
<!--                                        <b-col>-->
<!--                                            <label id="ti_sede_od" for="ti_sede_od">In quale Sede?:</label>-->
<!--                                            <b-form-select-->
<!--                                                size="sm"-->
<!--                                                v-model="form.ti_sede_od"-->
<!--                                                :options="sedeInteresse"-->
<!--                                                text-field="label"-->
<!--                                                value-field="key"-->
<!--                                            ></b-form-select>-->
<!--                                        </b-col>-->
<!--                                        <b-col>-->
<!--                                            <label id="ti_tipo_partecipazione_od" for="ti_tipo_partecipazione_od">Come Vuole Partecipare?</label>-->
<!--                                            <b-form-select-->
<!--                                                size="sm"-->
<!--                                                v-model="form.ti_tipo_partecipazione_od"-->
<!--                                                :options="modalitaPartecipazione"-->
<!--                                                text-field="label"-->
<!--                                                value-field="label"-->
<!--                                            ></b-form-select>-->
<!--                                        </b-col>-->
<!--                                    </b-row>-->
<!--                                    </b-card>-->
<!--                            </b-col>-->
<!--                        </b-row>-->
<!--                    </b-tab>-->
<!--                    &lt;!&ndash; INFORMAZIONI TEST &ndash;&gt;-->
<!--                    <b-tab title="Test">-->
<!--                        <b-row>-->
<!--                            <b-col >-->
<!--                                <b-card bg-variant="gray-050">-->
<!--                                    <div class="d-flex align-items-center mb-4 h3">-->
<!--                                        <div>Dati del Test:</div>-->
<!--                                    </div>-->
<!--                                    <label id="data_iscrizione_test" for="data_iscrizione_test">Data Iscrizione Test:</label>-->
<!--                                    <b-form-datepicker-->
<!--                                        id="data_iscrizione_test"-->
<!--                                        locale="it"-->
<!--                                        today-button-->
<!--                                        reset-button-->
<!--                                        close-button-->
<!--                                        :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"-->
<!--                                        v-model="form.test_data_iscrizione"-->
<!--                                        size="sm"-->
<!--                                    ></b-form-datepicker>-->
<!--                                    <label id="codice_test_sudente" for="codice_test_sudente">Codice test studente:</label>-->
<!--                                    <b-input-group size="sm">-->
<!--                                        <template #append>-->
<!--                                            <b-input-group-text v-if="form.test_codice"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>-->
<!--                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>-->
<!--                                        </template>-->
<!--                                        <b-form-input-->
<!--                                            id="codice_test_sudente"-->
<!--                                            placeholder="Codice test studente"-->
<!--                                            size="sm"-->
<!--                                            v-model="form.test_codice"-->
<!--                                        ></b-form-input>-->
<!--                                    </b-input-group>-->
<!--                                    <br>-->
<!--                                    <b-form-checkbox-->
<!--                                        title="Pagamento Test"-->
<!--                                        v-model="form.test_pagamento"-->
<!--                                    >Pagamento Test?</b-form-checkbox>-->
<!--                                </b-card>-->
<!--                            </b-col>-->
<!--                        </b-row>-->
<!--                    </b-tab>-->
<!--                    COLLOQUIO INDIVIDUALE-->
                    <b-tab title="Colloquio Individuale">
                        <b-row>
                            <b-col >
                                <b-card bg-variant="gray-050">
                                    <div class="d-flex align-items-center mb-4 h3">
                                        <div>Colloquio individuale:</div>
                                    </div>
                                    <label id="ti_open_day" for="ti_open_day">Interessato al Colloquio:</label>
                                    <b-form-checkbox
                                        v-model="form.ci_interessato"
                                        size="sm"
                                        name="check-button"
                                        switch
                                    >Interessato? {{ form.ci_interessato }}
                                    </b-form-checkbox>
                                    <label id="ci_modalita_preferenza" for="ci_modalita_preferenza">Preferenza Modalità:</label>
                                    <b-input-group size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.ci_modalita_preferenza"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="ci_modalita_preferenza"
                                            placeholder="Preferenza Modalità"
                                            size="sm"
                                            v-model="form.ci_modalita_preferenza"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label id="ci_preferenza_contatto" for="ci_preferenza_contatto">Preferenza Contatto:</label>
                                    <b-input-group size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.ci_preferenza_contatto"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="ci_preferenza_contatto"
                                            placeholder="Preferenza Contatto"
                                            size="sm"
                                            v-model="form.ci_preferenza_contatto"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label id="ci_data_colloquio" for="ci_data_colloquio">Data Colloquio:</label>
                                    <b-form-datepicker
                                        id="ci_data_colloquio"
                                        locale="it"
                                        today-button
                                        reset-button
                                        close-button
                                        :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                                        v-model="form.ci_data_colloquio"
                                        size="sm"
                                    ></b-form-datepicker>
                                    <label id="ci_orario_colloquio" for="ci_orario_colloquio">Orario Colloquio:</label>
                                    <b-form-timepicker
                                        id="ci_orario_colloquio"
                                        locale="it"
                                        today-button
                                        reset-button
                                        close-button
                                        :hour12="hour12"
                                        v-model="form.ci_orario_colloquio"
                                        size="sm"
                                    ></b-form-timepicker>
                                    <label id="ci_interessato_a" for="ci_interessato_a">Interessato a:</label>
                                    <b-input-group size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.ci_interessato_a"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="ci_interessato_a"
                                            placeholder="Interessato a"
                                            size="sm"
                                            v-model="form.ci_interessato_a"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label id="ci_cosa_vuoi_studiare" for="ci_cosa_vuoi_studiare">Cosa vuoi Studiare?:</label>
                                    <b-input-group size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.ci_cosa_vuoi_studiare"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-input
                                            id="ci_cosa_vuoi_studiare"
                                            placeholder="Cosa vuoi studiare?"
                                            size="sm"
                                            v-model="form.ci_cosa_vuoi_studiare"
                                        ></b-form-input>
                                    </b-input-group>
                                    <label id="ci_messaggio" for="ci_messaggio">Messaggio:</label>
                                    <b-input-group size="sm">
                                        <template #append>
                                            <b-input-group-text v-if="form.ci_messaggio"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                                            <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                                        </template>
                                        <b-form-textarea
                                            id="ci_messaggio"
                                            placeholder="Messaggio"
                                            size="sm"
                                            v-model="form.ci_messaggio"
                                        ></b-form-textarea>
                                    </b-input-group>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-tab>
<!--                    FOLLOW UP-->
                    <b-tab title="FollowUp">
                        <b-row cols="12" class="mt-2">
                            <b-col>
                                <b-card bg-variant="gray-050">
                                    <div class="d-flex align-items-center mb-4 h3">
                                        <div>Followup:</div>
                                        <span class="ml-3" style="color: #c2c6c2"><small>numero Followup: {{ followupLead.length }}</small></span>
                                    </div>
                                    <b-card>
                                        <b-button class="mb-2" @click="nuovoFollowup()" variant="dark" size="sm">Aggiungi</b-button>
                                        <b-table
                                            sticky-header="300px"
                                            head-variant="dark"
                                            :items="followupLead"
                                            :fields="fieldsFollowUpLead"
                                            hover
                                            striped
                                            size="sm"
                                        >
                                            <template v-slot:cell(tipo)="row">
                                                <h6><b-badge pill variant="primary" v-if="row.item.tipo === 'email'">{{ row.item.tipo }}</b-badge>
                                                    <b-badge pill variant="dark" v-else>{{ row.item.tipo }}</b-badge></h6>
                                            </template>
                                        </b-table>
                                    </b-card>
                                </b-card>
                            </b-col>
                        </b-row>
                        <b-row cols="12" class="mt-2">
                            <b-col>
                                <b-card bg-variant="gray-050">
                                    <div class="d-flex align-items-center mb-4 h3">
                                        <div>Immatricolazione:</div>
                                    </div>
                                    <b-form-checkbox
                                        v-model="form.test_immatricolazione"
                                        size="sm"
                                    >
                                        Immatricolato?
                                    </b-form-checkbox>
                                </b-card>
                            </b-col>
                        </b-row>
                    </b-tab>
                </b-form>
            </b-tabs>
        </b-card>
        <b-modal centered no-fade ref="newInteractionNuovo" id="newInteractionNuovo" :title="`Aggiungi Interazione`"  header-bg-variant="dark" header-text-variant="white">
            <b-form>
                <label id="tipo" for="tipo">Tipo:</label>
                <b-form-select id="tipo" placeholder="Tipo" :options="tipoInterazione" v-model="formEvento.tipo_interazione"></b-form-select>
                <label id="oggetto" for="oggetto">Oggetto:</label>
                <b-form-input id="oggetto" placeholder="Oggetto" v-model="formEvento.oggetto"></b-form-input>
                <label id="messaggio" for="messaggio">Messaggio:</label>
                <b-form-textarea id="tipo" placeholder="Messaggio" rows="6" v-model="formEvento.nota"></b-form-textarea>
                <label id="data_evento" for="data_evento" >Data Evento:</label>
                <b-form-datepicker id="data_evento" v-model="formEvento.data_interazione"></b-form-datepicker>
                <label id="followup" for="followup" >FollowUp:</label>
                <b-form-input id="followup" v-model="formEvento.followup"></b-form-input>
                <label id="orientatoriNew" for="orientatoriNew">Orientatori</label>
                <b-form-select
                    v-if="eventManagersLoaded" multiple :options="eventManagers"
                    v-model="formEvento.orientatore"
                    value-field="id" text-field="email">
                </b-form-select>
            </b-form>
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="success"
                        size="sm"
                        class="float-left"
                        @click="inviaEvento"
                    >
                        Salva
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-left ml-2"
                        @click="resetFormFields"
                    >
                        Chiudi
                    </b-button>
                </div>
            </template>
            {{ formEvento.timestamp }}xx
        </b-modal>
        <b-modal centered no-fade ref="editInteractionNuovo" id="editInteractionNuovo" title="Modifica Interazione"  header-bg-variant="dark" header-text-variant="white">
            <b-form>
                <label id="editTipo" for="editTipo">Tipo:</label>
                <b-form-select id="editTipo" placeholder="Tipo" :options="tipoInterazione" v-model="formEvento.tipo_interazione"></b-form-select>
                <label id="editOggetto" for="editOggetto">Oggetto:</label>
                <b-form-input id="editOggetto" placeholder="Oggetto" v-model="formEvento.oggetto"></b-form-input>
                <label id="editMessaggio" for="editMessaggio">Messaggio:</label>
                <b-form-textarea id="editMessaggio" placeholder="Messaggio" rows="6" v-model="formEvento.nota"></b-form-textarea>
                <label id="editData_evento" for="editData_evento" >Data Evento:</label>
                <b-form-datepicker id="editData_evento" v-model="formEvento.data_interazione"></b-form-datepicker>
                <label id="editFollowup" for="editFollowup" >FollowUp:</label>
                <b-form-input id="editFollowup" v-model="formEvento.followup"></b-form-input>
                <label id="orientatori" for="orientatori">Orientatori</label>
                <b-form-select
                    v-if="eventManagersLoaded" multiple :options="eventManagers"
                    v-model="formEvento.orientatore"
                    value-field="id" text-field="email">
                </b-form-select>
            </b-form>
            <template #modal-footer>
                <div class="w-100">
                    <b-button
                        variant="info"
                        size="sm"
                        class="float-left"
                        @click="modificaInteraction"
                    >
                        Modifica
                    </b-button>
                    <b-button
                        variant="primary"
                        size="sm"
                        class="float-left ml-2"
                        @click="$bvModal.hide('editInteractionNuovo')"
                    >
                        Chiudi
                    </b-button>
                </div>
            </template>
        </b-modal>
    </b-sidebar>
</template>

<script>
import LeadService from '@/services/lead.service'
import coinvolgimento from "@/components/crm/coinvolgimento";
import EventService from "@/services/event.service";
export default {
    name: "SidebarAddLead",
    components:{
      coinvolgimento
    },
    data(){
        return {
            hour12:false,
            anno: [
                { value:'2021-2022', text:'2021-2022' },
                { value:'2022-2023', text:'2022-2023' },
                { value:'2023-2024', text:'2023-2024' },
                { value:'2024-2025', text:'2024-2025' },
            ],
            form: {
                "anno_di_interesse": '',
                "id": null,
                "active": true,
                "sede_interesse": null,
                "lead_cognome": "",
                "lead_nome": "",
                "lead_indirizzo": "",
                "lead_cf": "",
                "lead_data_di_nascita": "",
                "lead_luogo_di_nascita": "",
                "istituto_classe": "",
                "lead_citta": "",
                "lead_provincia": "",
                "lead_email": "",
                "lead_cellulare": "",
                "istituto_scolastico": "",
                "istituto_citta": "",
                "ultima_dem": "",
                "come_ci_hai_conosciuto": "",
                "ti_email_chiamata": false,
                "ti_email_colloquio": false,
                "ti_evento_istituto": false,
                "ti_data_lezione": "",
                // fiera
                "ti_partecipazione_fiera": false,
                "ti_fiera": "",
                "ti_webinar": "",
                // open day
                "ti_open_day": false,
                "ti_data_od": "",
                "ti_sede_od": null,
                "ti_partecipazione_od": false,
                "ti_tipo_partecipazione_od": null,
                // Evento e Fac Simile
                "ti_chat": false,
                "ti_chiamata": false,
                "ti_chiamata_detail": "",
                "ti_evento_tipologia": null,
                "ti_evento_data": null,
                "ti_evento_sede": "",
                "ti_fac_simile_test": false,
                "ti_fac_simile_test_data": null,
                "ti_note": "",
                // TEST
                "test_richiesta_ammissione": false,
                "test_data_iscrizione": null,
                "test_codice": "",
                "test_pagamento": false,
                "test_immatricolazione": false,
                "test_seconda_lingua": "",
                "test_terza_lingua": "",
                "test_voto_scuola": "",
                "test_costo_iscrizione": null,
                "coinvolgimento": null,
                // "pagamento": "",
                "note": "",
                "altro": "",
                "associa_scuola": null,
                "followup_email_test": "",
                "followup_oggetto_email_test": "",
                "followup_data_email_test": null,
                "followup_feedback_email_test": "",
                "followup_telefonata_test": "",
                "followup_oggetto_telefonata_test": "",
                "followup_data_telefonata_test": null,
                "followup_feedback_telefonata_test": "",
                // Colloquio
                "ci_interessato": false,
                "ci_modalita_preferenza": "",
                "ci_preferenza_contatto": "",
                "ci_orario_colloquio": "",
                "ci_data_colloquio": null,
                "ci_messaggio": "",
                "ci_interessato_a": "",
                "ci_cosa_vuoi_studiare": null,
                // Vari
                "provenienza_lead":""
            },
            // FOLLOW UP
            followupLead:[],
            fieldsFollowUpLead: [
                {key:"tipo", label:"Tipo", sortable: true},
                {key:"oggetto", label:"Oggetto"},
                {key:"data", label:"Data", sortable: true},
                {key:"feedback", label:"Feedback"},
                {key:"action", label:"Action"},
            ],
            tipoFollowUp: [
                'email',
                'telefonata',
            ],
            oggettoFollowUp: [
                {value:"pagamento_test" , text: "Pagamento del Test"},
                {value:"codice_onore" , text: "Codice d'onore"},
                {value:"esito_test" , text: "Esito del Test"},
                {value:"studentato" , text: "Studentato"},
                {value:"altro" , text: "Altro..."},
            ],
            feedbackFollowUp: [
                {value:"effettuera_pagamento", text: "Effettuerà il pagamento"},
                {value:"non_interessato", text: "Non più interessato"},
                {value:"non_risponde", text: "Non Risponde"},
                {value:"segreteria_telefonica", text: "Scatta la Segreteria telef."},
                {value:"pagato", text: "Ha già pagato"},
                {value:"documenti_spediti", text: "Ha già spedito i Doc."},
                {value:"altro", text: "Altro..."},
            ],
            loaded: false,
            dato: 0,
            sedeInteresse: [
                {key:'Firenze', label:'Firenze'},
                {key:'Mantova', label:'Mantova'},
                {key:'Milano', label:'Milano'},
                {key:'Torino', label:'Torino'},
            ],
            modalitaPartecipazione: [
                {key:'on_line', label:'On Line'},
                {key:'on_line_meet', label:'On Line con Meet'},
                {key:'presenza', label:'Presenza'},
            ],
            tipologiaEvento:[
                {key: "orientamento", label:"Orientamento"},
                {key: "lezione", label:"Lezione/Laboratorio"},
                {key: "pcto", label: "Pcto" },
                {key: "open", label: "Open Event"}
            ],
            tipoInterazione: [
                'to_do',
                'call_in',
                'call_out',
                'email_in',
                'email_out',
                'app_tel',
                'appuntamento',
                'whatsapp',
                'dem',
                'vsa',
                'sms',
                'Spedizione Materiale',
                'll',
                'pcto',
                'oas',
                'Live Chat',
                'Instagram',
                // 'chiamata',
                // 'email',
                // 'colloquio on line',
                // 'colloquio in presenza',
                // 'visite guidate',
                // 'whatsapp'
            ],
            formEvento: {
                lead:null,
                id:'',
                tipo_interazione: '',
                oggetto:'',
                nota: '',
                data_interazione: null,
                data_evento: null,
                followup:'',
                orientatore:[],

            },
            formEventoTmp: {},
            evento_field: [
                {key:'data_interazione', label:'Data Int.'},
                {key:'oggetto', label:'Oggetto'},
                {key:'note', label:'Note'},
                {key:'followup', label:'FollowUp'},
                {key:'emails_orientatore', label:'O.'},
                {key:'actions', label:'#'},
            ],
            interaction_logs:[],
            eventManagersLoaded: false,
            eventManagers:[],
            spinnerNewLead: false,
            saveAndContinueID: null,
            fromForm: [
                {key:'iscrizione_open_day', label: 'Iscrizione Open Day'},
                {key:'iscrizione_od_generico', label: 'Iscrizione OD generico'},
                {key:'simulazione_test_ammissione', label: 'Simulazione test di ammissione'},
                {key:'orientamento_individuale', label: 'Orientamento Individuale'},
                {key:'form_landing_messaggio', label: 'Form Landing messaggio'},
                {key:'form_landing', label: "Form Landing"},
                {key:'grazie_per_aver_contattato_unicollege', label: "Grazie per aver contattato Unicollege"},
                {key: 'test_ammissione', label: "Test Ammissione"}
            ]
        }
    },
    computed: {
        idForm() {
            return this.form.id
        },
        idFormEvento() {
            return this.formEvento.lead
        }
    },
    watch: {},
    methods: {
        // nuovoFollowup(){
        //     this.$refs['newFollowupEdit'].show()
        // },
        // nuovoLog(){
        //     this.resetFormFields()
        //     this.getInteractionsLead()
        //     this.$refs['newInteractionNuovo'].show()
        // },
        // getInteractionsLead(){
        //     EventService.getEventiLogLeadContact(this.form.id)
        //         .then(resp => {
        //             this.interaction_logs = resp.data
        //         })
        // },
        resetFormFields() {
            this.formEvento = { ...this.formEventoTmp };
            this.$refs['newInteractionNuovo'].hide()
        },
        resetFormFieldsLead(){
          this.form = {}
          this.$refs['SidebarAddLead'].hide()
        },
        inserisciLead() {
            if(this.form.id === null) {
                LeadService.insertNewLead(this.form)
                    .then(resp =>{
                        this.$bvToast.toast(`Lead inserito con successo!`, {
                            title: 'lead inserito con successo',
                            variant: 'success',
                            solid: true,
                            autoHideDelay: 5000,
                            appendToast: true
                        })
                        this.spinnerNewLead = !this.spinnerNewLead
                        console.log(resp)
                        this.resetFormFields()
                        this.$refs['add-lead'].hide()

                    } )
                    .catch(e => {
                        for (const [key, value] of Object.entries(e.response.data)) {
                            this.$bvToast.toast(`Lead non Salvato! campo: ${key} --> ${value}`, {
                                title: `Non è stato possibile aggiungere questo Lead`,
                                variant: 'danger',
                                solid: true,
                                autoHideDelay: 5000,
                                appendToast: true
                            })
                        }
                    })
            }
            else {
                LeadService.editLead(this.form)
                .then(resp =>{
                    console.log('lead inserito con successo', resp)
                    this.spinnerNewLead = !this.spinnerNewLead
                    this.resetFormFields()
                    this.$refs['add-lead'].hide()
                })
                .catch(e => {
                        for (const [key, value] of Object.entries(e.response.data)) {
                            this.$bvToast.toast(`Lead non Salvato! campo: ${key} --> ${value}`, {
                                title: `Non è stato possibile aggiungere questo Lead`,
                                variant: 'danger',
                                solid: true,
                                autoHideDelay: 5000,
                                appendToast: true
                            })
                        }
                    })
            }
        },
        saveAndContinue(){
            LeadService.insertNewLead(this.form)
                .then(resp =>{
                    this.form.id = resp.data.id
                    this.formEvento.lead = resp.data.id
                    this.$bvToast.toast(`Lead inserito con successo!`, {
                        title: 'lead inserito con successo',
                        variant: 'success',
                        solid: true,
                        autoHideDelay: 5000,
                        appendToast: true
                    })
                })
                .catch(e => {
                    for (const [key, value] of Object.entries(e.response.data)) {
                        this.$bvToast.toast(`Lead non Salvato! campo: ${key} --> ${value}`, {
                            title: `Non è stato possibile aggiungere questo Lead`,
                            variant: 'danger',
                            solid: true,
                            autoHideDelay: 5000,
                            appendToast: true
                        })
                    }
                })
        },
        inviaEvento(){
            this.formEvento.lead = this.form.id
            EventService.eventiLogLeadContact(this.formEvento)
                .then(() =>{
                    this.resetFormFields()
                    this.getInteractionsLead()
                    this.$refs['newInteractionNuovo'].hide()
                })
                .catch(() => {
                    this.$bvModal.msgBoxOk('Ci sono degli errori o dei campi mancanti ', {
                        title: 'ERROR',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    })
                })
        },
    },
}
</script>

<style scoped>
head {
    text-align: left;
}
hr {
    text-decoration: dotted;
}
.subtitleForm {
    font-size: 12px;
    color: #869ac0;
}
label {
    color: #869ac0;
}

.card-header {
    font-size: 18px;
    font-weight: normal ;
    color: #FFFFFF;
    text-align: center;
}
</style>
