import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL

class DocentiService {
    getDocenti() {
        return axios.get(API_URL + 'docenti/', {headers: authHeader()});
    }
}
export default new DocentiService();
