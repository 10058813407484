import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL


class dashboardListService {
    getDashBoard() {
        this.loading = true;
        return axios.get(API_URL + 'istituti/', {headers: authHeader()});
    }
    getStudenti() {
        this.loading = true
        return axios.get(API_URL +'studenti/', {headers: authHeader()});
    }
}
export default new dashboardListService();
