export const istituto = {
  namespaced: true,
  state: {
    istituto: {},
    contatto: {}
  },
  mutations: {
    SETISTITUTO(state, istituto) {
      state.istituto = istituto
    },
    SETCONTATTO(state, contatto) {
      state.contatto = contatto
    },
    CLEARCONTATTO(state) {
      state.contatto = {}
    }
  },
  actions: {
    setIstituto({commit}, istituto) {
      commit('SETISTITUTO', istituto)
    },
    setContatto({commit}, contatto) {
      commit('SETCONTATTO', contatto)
    },
    clearContatto({commit},) {
      commit('CLEARCONTATTO')
    }
  },
}
