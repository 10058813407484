<template>
    <div>
        <b-form>
            <b-form-group
            label='Issue'
            label-cols="2"
            label-for="issue">
                <b-input id='issue' placeholder="Issue" v-model="form.issue"></b-input>
            </b-form-group>
            <b-form-group
                label='Descrizione'
                label-cols="2"
                label-for="descrizione">
                <b-textarea id="descrizione" placeholder="Descrizione..." rows="4" v-model="form.description">
                </b-textarea>
            </b-form-group>
            <b-row class="mt-3">
                <b-col>
                    <b-form-group
                    label="Priorità"
                    label-cols="4"
                    label-for="priorità">
                        <b-form-select
                            id="priorità"
                            v-model="form.priority"
                            :options="priority"
                            text-field="label"
                            value-field="label"
                            size="sm"
                        >
                        </b-form-select>
                        <small class="text-muted">Non abusare delle priorità</small>
                        {{ form.priority }}
                    </b-form-group>
                </b-col>

                <b-col>
                    <b-form-group
                        label="Status"
                        label-cols="4"
                        label-for="status">
                        <b-form-select
                            id="status"
                            v-model="form.status"
                            :options="status"
                            text-field="status"
                            value-field="id"
                            size="sm"
                            >
                        </b-form-select>
                        <small class="text-muted">Inserire lo stato Send</small>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        label="Tipo"
                        label-cols="4"
                        label-for="tipo">
                        <b-form-select
                            id="status"
                            v-model="form.tipo"
                            :options="tipo"
                            text-field="value"
                            value-field="value"
                            size="sm"
                        >
                        </b-form-select>
                        <small class="text-muted">Inserire il tipo di Ticket</small>
                    </b-form-group>
                </b-col>
                <b-col>
                        <b-datepicker size="sm" v-model="form.date" locale="it"></b-datepicker>
                        <small class="text-muted">Inserire la data del ticket</small>
                </b-col>
            </b-row>
            <hr>
                <div class="float-right">
                    <b-button variant="success" class="mr-3" @click="inviaBug" size="sm">Invia</b-button>
                </div>
        </b-form>
    </div>
</template>
<script>
import BugsService from '@/services/bugs.service'
export default {
    name: "AddBug",
    data() {
        return {
            form: {
                issue: "",
                priority: null,
                description: "",
                date: null,
                status: null,
                tipo: null,
                delete:false,
            },
            priority: [
                {key:1, label: 'basso', variant:'info'},
                {key:2, label: 'medio'},
                {key:3, label: 'urgente'},
                {key:4, label: 'bloccante'},
            ],
            tipo: [
                {key:'bug', value:'Bug'},
                {key:'feature', value:'Feature'},
                {key:'revision', value:'Revision'}
            ],
            status:[],
        }
    },
    mounted() {
        BugsService.getStatus()
            .then(response =>{
                this.status = response.data
            })
            .catch()
    },
    methods: {
        inviaBug() {
            BugsService.addBug(this.form)
                .then(() => (
                    this.$emit('initBugsService'),
                    this.$root.$emit('bv::hide::modal', 'addBug')))
                .catch(error => (console.log(error)))
        },

    }
}
</script>
<style scoped>

</style>
