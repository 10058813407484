<script>
const appOptions = {
  appBoxedLayout: false,
  appSidebarMinified: true,
  appSidebarMobileToggled: false,
  appSidebarMobileClosed: false,
  appContentFullWidth: false,
  appContentFullHeight: false,
  appWithFooter: true,
  appWithoutSidebar: false,
  appWithoutHeader: false
}

export default appOptions;
</script>
