<template>
  <b-sidebar
    id="SidebarEditLead"
    no-header
    width="100%"
    :title="`Edit Lead: ${dati.cognome}`"
    left
    no-slide
  >
    <template #footer="{}">
      <div class="d-flex bg-dark text-light align-items-center px-3 py-2">
        <strong class="mr-auto">{{ $store.state.user.username }}</strong>
        <b-button variant="success" @click="editLead()">Modifica</b-button>
        <b-button variant="danger" class="ml-3" @click="closeSidebar()">Chiudi</b-button>
      </div>
    </template>
    <b-card bg-variant="dark" class="rounded-0">
      <b-row>
        <b-col>
          <h4 style="color: #f9f9fa">Modifica del Lead: <b>{{ form.lead_nome}} {{ form.lead_cognome }}</b></h4>
          <label for="provenienza">Provenienza:</label>
          <b-form-select
            v-model="form.provenienza_lead"
            :options="fromForm"
            text-field="label"
            value-field="label"
            size="sm"
          ></b-form-select>
        </b-col>
        <b-col>
          <StatoLead :form="form"/>
          <coinvolgimento :lead="form"></coinvolgimento><!-- coinvolgimento -->
        </b-col>
      </b-row>
    </b-card>
    <b-card>
      <b-tabs v-model="tabIndex" nav-class="nav-tabs-v2" content-class="pt-2" card>
        <b-form>
          <!-- INFORMAZIONI ANAGRAFICA LEAD -->
          <b-tab class="tabs" title="Anagrafica Principale" active>
            <b-row>
              <b-col>
                <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                  <label>Nome:</label>
                  <b-input-group id="nome" label="Nome" label-for="nome" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_nome"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="nome"
                      size="sm"
                      placeholder="Inserisci il Nome"
                      v-model="form.lead_nome"
                    ></b-form-input>
                  </b-input-group>
                  <label id="cognome" for="cognome">Cognome:</label>
                  <b-input-group id="cognome" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_cognome"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="cognome"
                      type="text"
                      placeholder="Inserisci il Cognome"
                      size="sm"
                      name=""
                      v-model="form.lead_cognome"
                      style="text-transform: capitalize"
                    ></b-form-input>
                  </b-input-group>
                  <label id="data_di_nascita" for="data_di_nascita">Data di Nascita:</label>
                  <b-input-group
                    id="data_di_nascita"
                    size="sm"
                    v-model="form.lead_data_di_nascita">
                    <template #append>
                      <b-input-group-text v-if="form.lead_data_di_nascita"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-datepicker v-model="form.lead_data_di_nascita" class="small" locale="it">{{ form.lead_data_di_nascita }}</b-datepicker>
                  </b-input-group>
                  <label id="luogo_di_nacita" for="luogo_di_nacita">Luogo di nascita:</label>
                  <b-input-group id="luogo_di_nacita" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_luogo_di_nascita"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="luogo_di_nascita"
                      placeholder="Inserisci il luogo di nascita"
                      size="sm"
                      v-model="form.lead_luogo_di_nascita"
                    ></b-form-input>
                  </b-input-group>
                  <label id="cf" for="cf">Cod. Fiscale:</label>
                  <b-input-group id="cf" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_cf"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="cf"
                      placeholder="Inserisci Cod. Fiscale"
                      size="sm"
                      v-model="form.lead_cf"
                    ></b-form-input>
                  </b-input-group>
                  <hr>
                  <label id="how-to-know" for="how-to-know" class="mt-2">Come ci hai conosciuto?</label>
                  <b-input-group id="how-to-know" label="Come ci hai conosciuto" label-for="how-to-know" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.come_ci_hai_conosciuto"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="how-to-know"
                      size="sm"
                      v-model="form.come_ci_hai_conosciuto"
                    ></b-form-input>
                  </b-input-group>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                  <label id="indirizzo" for="indirizzo">Indirizzo:</label>
                  <b-input-group
                    id="indirizzo"
                    size="sm"
                  >
                    <template #append>
                      <b-input-group-text v-if="form.lead_indirizzo"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="indirizzo"
                      type="text"
                      placeholder="Inserisci l'indirizzo"
                      size="sm"
                      v-model="form.lead_indirizzo"
                    ></b-form-input>
                  </b-input-group>
                  <label id="citta" for="citta">Città:</label>
                  <b-input-group id="citta" label="Città:" label-for="citta" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_citta"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="citta"
                      placeholder="Inserisci la Città del Lead"
                      size="sm"
                      v-model="form.lead_citta"
                    ></b-form-input>
                  </b-input-group>
                  <label id="comune" for="comune">Comune:</label>
                  <b-input-group id="comune" label="Comune:" label-for="comune" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_comune"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="citta"
                      placeholder="Inserisci il Comune del Lead"
                      size="sm"
                      v-model="form.lead_comune"
                    ></b-form-input>
                  </b-input-group>
                  <label id="provincia" for="provincia">Provincia:</label>
                  <b-input-group id="provincia" label="Provincia:" label-for="provincia" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_provincia"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="provincia"
                      placeholder="Inserisci la Provincia"
                      size="sm"
                      v-model="form.lead_provincia"
                    ></b-form-input>
                  </b-input-group>
                  <label>Cellulare:</label>
                  <b-input-group id="cell" label="Cellulare:" label-for="cellulare" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_cellulare"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="cell"
                      placeholder="Cellulare"
                      size="sm"
                      v-model="form.lead_cellulare"
                    ></b-form-input>
                  </b-input-group>
                  <label id="email" for="email">Email:</label>
                  <b-input-group id="email" label="Email:" label-for="email" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.lead_email"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="email"
                      placeholder="Email"
                      size="sm"
                      v-model="form.lead_email"
                    ></b-form-input>
                  </b-input-group>
                </b-card>
              </b-col>
            </b-row>
            <b-row cols="12" class="mt-2">
              <b-col>
                <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                  <label id="note" for="note">Note : </label>
                  <b-form-textarea
                    id="note"
                    placeholder="Ad uso interno..."
                    rows="3"
                    max-rows="5"
                    v-model="form.note"
                  ></b-form-textarea>
                </b-card>
              </b-col>
            </b-row>
            <br>
          </b-tab>
          <!-- INFORMAZIONI SEDE e ANNO INTERESSE -->
          <b-tab class="tabs" title="Sede/Anno Interesse">
            <b-row>
              <b-col>
                <b-form-row>
                  <b-col>
                    <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                      <label id="sede" for="sede">Sede Interesse:</label>
                      <b-form-select
                        id="sede"
                        :options="sedeInteresse"
                        value-field="key"
                        text-field="label"
                        v-model="form.sede_interesse"
                        size="md">
                      </b-form-select>
                      <label id="anno" for="anno">Anno di Interesse:</label><br>
                      <b-form-select
                        :options="anno"
                        v-model="form.anno_di_interesse"
                      ></b-form-select>
                    </b-card>
                  </b-col>
                  <b-col>
                    <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                      <label id="specializzazione" for="anno">Indirizzo di Specializzazione:</label><br>
                      <b-form-select
                        :options="anno"
                      ></b-form-select>
                    </b-card>
                  </b-col>
                </b-form-row>
              </b-col>
            </b-row>
          </b-tab>
          <!-- INFORMAZIONI ISTITUTO -->
          <b-tab class="tabs" title="Informazioni Istituto">
            <b-row>
              <b-col cols="6">
                <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                  <label id="istituto_scolastico" for="istituto_scolastico">Istituto Scolastico:</label>
                  <b-input-group size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.istituto_scolastico"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="istituto-scolastico"
                      placeholder="Inserisci il nome dell'Istituto"
                      size="sm"
                      v-model="form.istituto_scolastico"
                    ></b-form-input>
                  </b-input-group>
                  <label id="classe" for="classe">Classe:</label>
                  <b-input-group id="classe" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.istituto_classe"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="classe"
                      placeholder="Inserisci la Classe"
                      size="sm"
                      v-model="form.istituto_classe"
                    ></b-form-input>
                  </b-input-group>
                </b-card>
                <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                  <label id="associa_scuola" for="associa_scuola">Associa Lead alla Scuola:</label>
                  <b-input-group size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.associa_scuola"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-select
                      v-model="form.associa_scuola"
                      id="istituto-scolastico"
                      :options="istituti"
                      text-field="nome_istituto"
                      value-field="id"
                      @change="ricercaScuola"
                    >

                    </b-form-select>
                  </b-input-group>
                  <small class="card-subtitle" style="color: #5d657a">Non trovi l'istituto? clicca su questo <b-link>link</b-link> per inserirne di nuovi</small>
                </b-card>
              </b-col>
              <b-col cols="6">
                <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                  <label id="istituto_citta" for="istituto_citta">Istituto Città</label>
                  <b-input-group id="istituto_citta" size="sm">
                    <template #append>
                      <b-input-group-text v-if="form.istituto_citta"><span class="text-success"><i class="fa fa-check"></i></span></b-input-group-text>
                      <b-input-group-text v-else><strong class="text-danger">!</strong></b-input-group-text>
                    </template>
                    <b-form-input
                      id="istituto-citta"
                      placeholder="Inserisci la Città dell'Istituto"
                      size="sm"
                      v-model="form.istituto_citta"
                    ></b-form-input>
                  </b-input-group>
                  <b-form-input class="mt-2" v-model="istitutiSearch" list="istituti" ></b-form-input>
                  <datalist id="istituti" @select="ricavaIdDaIstituti" size="sm">
                    <option>Manual Option</option>
                    <option v-for="(istituto, index) in istituti" :key="index">
                      {{ istituto.nome_istituto }}</option>
                  </datalist>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>
          <!--  EVENTI -->
          <b-tab class="tabs" title="Eventi" @click="loadEventi">
            <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
              <table-evento-lead
                id="tabella-eventi"
                ref="tableEventi"
                :l_id="dati.id"
                :show="eventiLoad"
                :selecta="true"
                selectMode="single"
                @rigaSelezionata="dettagliEvento"
              ></table-evento-lead>
            </b-card>
            <b-row>
              <b-col>
                <b-card
                  class="mb-2 pb-0 shadow" bg-variant="gray-050"
                  v-if="eventoDetails != null">
                  <b-card-title class="bg-dark text-white">Dettagli: </b-card-title>
                  <b-card-text>
                    <div v-for="(value, key, index) in eventoDetails" :key="index">
                        <span class="ml-2" v-if="value">{{ key }}
                          <b-badge :variant="key === 'tipo_evento' ? 'warning' : 'dark'">{{ value }}</b-badge>
                        </span>
                    </div>
                  </b-card-text>
                </b-card>
              </b-col>
              <b-col>
                <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050" v-if="eventoDetails != null">
                  altre info
                </b-card>
              </b-col>
              <b-col>
                <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050" v-if="eventoDetails != null">
                  altre info
                </b-card>
              </b-col>
              <b-col>
                <b-card class="mt-2" v-if="eventoDetails != null">
                  altre info
                </b-card>
              </b-col>
            </b-row>

          </b-tab>
          <!--  FOLLOW UP-->
          <b-tab class="tabs" title="FollowUp" @click="loadFollowUp">
            <b-overlay :show="overlayFollowUp" type="grow"
                       variant="dark"
                       blur="blur"
                       rounded="sm">
              <template #overlay>
                <div class="text-center">
                  <b-icon style="color: #FFFFFF" icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                  <p id="cancel-label" style="color: #FFFFFF">Please wait...</p>
                </div>
              </template>
              <b-card class="mb-2 pb-0 shadow" bg-variant="gray-050">
                <b-table
                  :items="followup"
                  :fields="fieldsFollowUpLead"
                  hover
                  striped
                  small
                  show-empty
                  empty-text="Non ci sono ancora FollowUp"
                >
                  <template #cell(fatto)="row">
                    <span v-if="row.item.fatto" style="color: #70b668"><i class="fa fa-check-circle"></i></span>
                    <span v-else style="color: #9e0808"><i class="fa fa-check-circle"></i></span>
                  </template>
                  <template #cell(action)="row">
                    <b-link @click="setFollowUpChecked(row.item)"><i class="fas fa-thumbs-up"></i></b-link>
                  </template>
                </b-table>
              </b-card>
            </b-overlay>
          </b-tab>
        </b-form>
      </b-tabs>
    </b-card>
    <small class="text-muted">Nome Component>: src/components/crm/editLead.vue</small>
  </b-sidebar>
</template>

<script>

import LeadService from '@/services/lead.service'
import coinvolgimento from "@/components/crm/coinvolgimento";
import EventService from '@/services/event.service';
import IstitutiService from '@/services/istituto.service';
import TableEventoLead from "../evento/lead/TableEventoLead";
import 'vue2-datepicker/index.css';
import Swal from "sweetalert2";
import StatoLead from "../StatoLead";
export default {
  name: 'SidebarEditLead',
  components: {
    StatoLead,
    coinvolgimento,
    TableEventoLead,
  },
  props: {
    dati: {
      type: Object,
    },
  },
  data(){
    return {
      anno: [
        {value: '2021-2022', text: '2021-2022'},
        {value: '2022-2023', text: '2022-2023'},
        {value: '2023-2024', text: '2023-2024'},
        {value: '2024-2025', text: '2024-2025'},
      ],
      hour12: false,
      form:{},
      tipologiaEvento: [
        {key: "orientamento", label: "Orientamento"},
        {key: "lezione", label: "Lezione/Laboratorio"},
        {key: "pcto", label: "Pcto"},
        {key: "open", label: "Open Event"}
      ],
      formEvento: {
        lead: this.dati.id,
        id: '',
        tipo_interazione: '',
        oggetto: '',
        nota: '',
        data_interazione: null,
        followup: '',
        orientatore: [],

      },
      formFollowUp: {
        tipo: null,
        oggetto: null,
        data: null,
        feedback: null,
        lead: this.dati.id
      },
      formEventoTmp: {},
      loaded: false,
      dato: 0,
      sedeInteresse: [
        {key: 'Firenze', label: 'Firenze'},
        {key: 'Mantova', label: 'Mantova'},
        {key: 'Milano', label: 'Milano'},
        {key: 'Torino', label: 'Torino'},
      ],
      tipoInterazione: [
        {key:"to_do", value:'to do'},
        {key:"call_in", value:'call in'},
        {key:"call_out", value:'call out'},
        {key:"email_in", value:'email in'},
        {key:"email_out", value:'email out'},
        {key:"app_telefonico", value:'app tel'},
        {key:"app", value:'appuntamento'},
        {key:"whapp", value:'whatsapp'},
        {key:"dem", value:'dem'},
        {key:"vsa", value:'vsa'},
        {key:"sms", value:'sms'},
        {key:"spediz_materiale", value:'Spedizione Materiale'},
        {key:"ll", value:'ll'},
        {key:"pcto", value:'pcto'},
        {key:"oas", value:'oas'},
        {key:"live_chat", value:'Live Chat'},
        {key:"instagram", value:'Instagram'},
      ],
      tipoFollowUp: [
        'email',
        'telefonata',
      ],
      oggettoFollowUp: [
        {value: "pagamento_test", text: "Pagamento del Test"},
        {value: "codice_onore", text: "Codice d'onore"},
        {value: "esito_test", text: "Esito del Test"},
        {value: "studentato", text: "Studentato"},
        {value: "altro", text: "Altro..."},
      ],
      feedbackFollowUp: [
        {value: "effettuera_pagamento", text: "Effettuerà il pagamento"},
        {value: "non_interessato", text: "Non più interessato"},
        {value: "non_risponde", text: "Non Risponde"},
        {value: "segreteria_telefonica", text: "Scatta la Segreteria telef."},
        {value: "pagato", text: "Ha già pagato"},
        {value: "documenti_spediti", text: "Ha già spedito i Doc."},
        {value: "altro", text: "Altro..."},
      ],
      evento_field: [
        {key: 'data_interazione', label: 'Data Int.'},
        {key: 'tipo_interazione', label: 'Tipo'},
        {key: 'oggetto', label: 'Oggetto'},
        {key: 'note', label: 'Note'},
        {key: 'followup', label: 'FollowUp'},
        {key: 'emails_orientatore', label: 'Staff'},
        {key: 'actions', label: '#'},
      ],
      modalitaPartecipazione: [
        {key: 'on_line', label: 'On Line'},
        {key: 'on_line_meet', label: 'On Line con Meet'},
        {key: 'presenza', label: 'Presenza'},
      ],
      interaction_logs: [],
      eventManagersLoaded: false,
      eventManagers: [],
      istituti: [],
      istitutiSearch:"",
      search: '',
      deleteEvent: false,
      interaction: [],
      idInteraction: '',
      fromForm: [
        {key: 'contatti', label: "Contatti"},
        {key: 'iscrizione_open_day', label: 'Iscrizione Open Day'},
        {key: 'iscrizione_od_generico', label: 'Iscrizione open day generico'},
        {key: 'simulazione_test_ammissione', label: 'Simulazione test di ammissione'},
        {key: 'orientamento_individuale', label: 'Orientamento Individuale'},
        {key: 'form_landing_messaggio', label: 'Form Landing con messaggio'},
        {key: 'form_landing', label: "Form Landing"},
        {key: 'grazie_per_aver_contattato_unicollege', label: "Grazie per aver contattato Unicollege"},
        {key: 'test_ammissione', label: "Test Ammissione"},
        {key: 'altri_canali', label: "Altri Canali"},

      ],
      // Eventi Lead
      eventoDetails: null,
      eventiLead: [],
      eventiLeadFields: [
        {key:'created_at', label:'Creato'},
        {key:'tipo', label:'Tipo Evento'},
        {key:'sede', label:'Sede'},
        {key:'email_staff', label:'Staff'},
        {key:'note', label:'note'},
      ],
      eventiLoad: false,
      // fieldEventiLead
      // FollowUp
      overlayFollowUp: false,
      followupLead: [],
      followup: [],
      fieldsFollowUpLead: [
        {key: "data_follow", label: "Data:", sortable: true},
        {key: "oggetto", label: "Oggetto"},
        {key: "dettagli", label: "Dettagli"},
        {key: "fatto", label: "Checked", sortable: true},
        {key: "action", label: "Action"},
      ],
      // Tabs
      tabIndex: 0,
    }
  },
  mounted() {
    this.getIstituti()
    this.insertDati()
    this.initEventManagerChoices()
    this.getFollowUp()
    this.formEventoTmp = {...this.formEvento }
    console.log('mounted edit lead', 'form: ', this.form, 'dati: ', this.dati)
  },
  destroyed() {
    console.log("destroyed edit lead")
  },
  computed: {
    capitalizeFirstLetter(string) {
      return string && string.charAt(0).toUpperCase() + string.substring(1);
    },
    filteredList() {
      return this.istituti.filter(istituto => {
        return istituto.nome_istituto.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    // ISTITUTI
    getIstituti(){
      IstitutiService.getIstitutiSoloNome()
        .then(resp =>{
          this.istituti = resp.data.results
        })
        .catch()
    },
    ricercaScuola(e){
      console.log(e)
    },
    // associa_istituto(id){
    //     this.form.associa_scuola = id
    // },
    getFollowUp(){
      const params = {
        lead: this.dati.id
      }
      console.log('params:', params)
      EventService.getFollowUp(params)
        .then(resp =>{
          this.followup = resp.data.results
          this.overlayFollowUp = false
        })
        .catch()

    },
    setFollowUpChecked(items) {
      const payload = {
        id: items.id,
        lead: this.dati.id,
        fatto: true
      }
      EventService.setFollowUpChecked(payload)
        .then(() =>  {
          this.getFollowUp()
          this.$refs['nuovoFollowup'].hide()
          this.$bvToast.toast(`Followup Checked!`, {
            title: 'FollowUp modificato con successo',
            variant: 'success',
            solid: true,
            autoHideDelay: 5000,
            appendToast: true
          })

        })
    },
    resetFormFields() {
      this.formEvento = { ...this.formEventoTmp };
      this.$refs['newInteractionEdit'].hide()
    },
    initEventManagerChoices() {
      EventService.getEventManager()
        .then(
          (resp) => {
            this.eventManagers = resp.data;
            this.eventManagersLoaded = true;
          }
        )
    },
    inviaEvento(){
      EventService.eventiLogLeadContact(this.formEvento)
        .then(() =>{
          this.resetFormFields()
          this.$refs['newInteractionEdit'].hide()
          const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          toast.fire({
            icon: 'success',
            title: 'Lead Modificato con successo!',
            background: 'success'
          })
        })
        .catch(() =>{
          const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          toast.fire({
            icon: 'warning',
            title: "<span style=\"color:#FFFFFF\">Non è stato possibile inserire l'evento!</span>",
            type: "warning",
            background: 'rgba(210,48,48,0.7)',
            text: "<span style=\"color:#FFFFFF\">ERRORE: ${e}</span>",

          })
        })
    },
    insertDati() {
      this.loaded = false
      console.log(this.form)
    },
    closeSidebar() {
      this.$emit('sidebarClosed')
      this.$root.$emit('bv::toggle::collapse', 'edit-lead')
    },
    editLead() {
      this.form.associa_scuola = this.istitutiSearch.id
      LeadService.editLead(this.form)
        .then(() => {
          this.$nextTick(() => {
              this.$emit('alertEditSuccess', true)
              this.$root.$emit('bv::toggle::collapse', 'SidebarEditLead')
            }
          )})
        .catch(e => {
          for (const [key, value] of Object.entries(e.response.data)) {
            this.$bvToast.toast(`Lead non Salvato! campo: ${key} --> ${value}`, {
              title: `Non è stato possibile modificare questo Lead`,
              variant: 'danger',
              solid: true,
              autoHideDelay: 5000,
              appendToast: true
            })
          }
        })
    },
    ricavaIdDaIstituti(e) {
      var jwtBody = e.replace(/([a-zA-Z]+):/g,'"$1":');
      var myJwtBodyObject = JSON.parse(jwtBody);
      console.log(myJwtBodyObject.id)
    },
    areYouSure(row){
      this.interaction = row
      this.idInteraction = row.item.id
      console.log(row.index)

      this.$bvModal.show('areYouSure')
    },
    delete_log(){
      EventService.deleteEventManager(this.idInteraction)
        .then(() =>{
          this.interaction_logs.splice(this.interaction.index)
          const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          toast.fire({
            icon: 'success',
            title: 'Interazione Eliminata con successo!',
            background: 'success'
          })
          // this.$bvToast.toast(`Interazione rimossa con successo!`, {
          //     title: 'interazione eliminata',
          //     variant: 'success',
          //     solid: true,
          //     autoHideDelay: 5000,
          //     appendToast: true
          // })

        })
        .catch(error => {
          console.log(error)
        })
    },
    getInteractionsLead(){
      EventService.getEventiLogLeadContact(this.dati.id)
        .then(resp => {
          this.interaction_logs = resp.data
        })
    },
    chiudiInterazione() {
      this.$bvModal.hide('newInteractionEdit')
    },
    // Eventi
    dettagliEvento(evento) {
      this.eventoDetails = evento
    },
    loadEventi() {
      this.$refs.tableEventi.getEventi();
      this.eventiLoad = true
    },
    loadFollowUp() {
      this.overlayFollowUp = true
      this.getFollowUp()
    },
  },
  watch: {
    dati: {
      handler() {
        this.form = Object.assign({}, this.dati)
        this.tabIndex = 0
      },
      immediate: true
    },
    istitutiSearch(newValue) {
      if(newValue.length >= 4){
        const params = {
          search: newValue
        }
        IstitutiService.searchIstituti(params)
          .then(resp => {
            this.istituti = resp.data.results
          })
        console.log(newValue)
      }

    }
  }
}
</script>

<style scoped>
label {
    color: #869ac0;
}
.capitalize {
    text-transform: capitalize;
}
</style>
