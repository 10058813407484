import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_APP_URL

class ListaLeadService {
    getListeLeads(params){
        return axios.get(API_URL + 'lista_leads/', { headers: authHeader(), params:params })
    }
    postListaLeads(payload) {
        return axios.post(API_URL + 'lista_leads/', payload, { headers: authHeader() })
    }
    modificaListaLead(payload) {
        return axios.post(API_URL + 'lista_leads/' + payload.id + '/update_list/', payload, { headers: authHeader() })
    }
    addListaLead(payload) {
        return axios.post(API_URL + 'lista_leads/' + payload.id + '/add_to_list/', payload, { headers: authHeader() })
    }
    deleteList(id) {
      return axios.delete(API_URL + 'lista_leads/' + id + '/', { headers: authHeader()})
    }
}
export default new ListaLeadService();
