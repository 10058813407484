<template>
  <b-modal
    v-model="showModal"
    ref="modale-evento"
    id="modale-evento"
    size="lg"
    header-bg-variant="dark"
    header-text-variant="white"
    :title="`Aggiungi Evento al Lead: ${ leadInfo.full_name }`"
    @hide="$emit('hidden')"
    @ok="saveEvento">
    <b-card>
      <b-card-title style="font-size: small;">
        <b-button size="sm" variant="dark" class="float-right mt-0 mr-0" @click="clearForm">Clear Form</b-button>
        <b-form-group label="Scegli il tipo di Evento"  class="d-inline-flex">
          <b-form-radio class="d-inline-flex" v-model="action"  name="evento" value="evento">Evento</b-form-radio>
          <b-form-radio class="d-inline-flex ml-2" v-model="action"  name="interazione" value="interazione">Interazione</b-form-radio>
          <b-form-radio class="d-inline-flex ml-2" v-model="action"  name="altro" value="altro">Altro</b-form-radio>
        </b-form-group>
        {{ action }}
      </b-card-title>
      <b-card-body v-if="action==='evento'">
        <b-form id="evento" class="mt-0">
          <b-row  class="pt-3"
                  style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-group label="Seleziona il Tipo di Evento*:">
                <b-form-select
                  size="sm"
                  :options="tipo_evento"
                  value-field="value"
                  text-field="text"
                  v-model="form_evento.tipo_evento"
                ><b-form-select-option value="Scegli un'opzione">Scegli un'opzione</b-form-select-option></b-form-select>
              </b-form-group>
              <b-form-group label="Data Ricezione Form:">
                <b-form-datepicker size="sm" v-model="form_evento.data_invio_form" ></b-form-datepicker>
                <small class="text-muted text-underline"><b-link @click="insertToday">inserisci la data di oggi</b-link></small>
                <small>{{ this.form_evento.data_invio_form }}</small>
              </b-form-group>
              <b-form-group label="Come vuole partecipare?">
                <b-form-select
                  size="sm"
                  :options="modalita_partecipazione"
                  text-field="text"
                  value-field="value"
                  v-model="form_evento.modalita_di_partecipazione"
                ><b-form-select-option value="Scegli un'opzione">Scegli un'opzione</b-form-select-option></b-form-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Scegli la sede*">
                <b-form-select size="sm" :options="sedeInteresse" text-field="text" value-field="value" v-model="form_evento.sede"
                ><b-form-select-option value="Scegli un'opzione">Scegli un'opzione</b-form-select-option></b-form-select>
              </b-form-group>
              <b-form-group label="Inserisci la data dell'evento:">
                <b-form-datepicker size="sm" v-model="form_evento.data_iscrizione"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <!--                    Colloquio Individuale-->
          <b-row v-if="form_evento.tipo_evento === 'Colloquio Individuale'"
                 class="pt-3"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-group label="Seleziona la data del Colloquio:">
                <b-form-datepicker size="sm" v-model="form_evento.ci_data" ></b-form-datepicker>
              </b-form-group>
              <b-form-group label="Nome ultimo Istituto:">
                <b-form-input size="sm" v-model="form_evento.ci_nome_istituto"></b-form-input>
              </b-form-group>
              <b-form-group label="Cosa vuole studiare:">
                <b-form-input size="sm" v-model="form_evento.ci_cosa_vuole_studiare"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Seleziona l'orario del Colloquio:">
                <b-form-timepicker size="sm" v-model="form_evento.ci_orario" :hour12=false></b-form-timepicker>
              </b-form-group>
              {{ form_evento.ci_orario}}
              <b-form-group label="Nome Università:">
                <b-form-input size="sm" v-model="form_evento.ci_nome_universita"></b-form-input>
              </b-form-group>
              ****ci_universita_frequentata***
            </b-col>
          </b-row>
          <b-row v-if="form_evento.tipo_evento === 'Colloquio Individuale'" class="pt-3"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-group label="Interssato a:">
                <b-form-textarea size="sm" v-model="form_evento.ci_interessato_a"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <!--                    Fiera-->
          <b-row v-if="form_evento.tipo_evento === 'Fiera'"  class="pt-3"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-group label="A quale Fiera:">
                <b-form-input size="sm" v-model="form_evento.fi_a_quale_fiera"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Di quale Anno:">
                <b-form-input size="sm" v-model="form_evento.fi_di_quale_anno"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!--                    Open Day-->
          <b-row v-if="form_evento.tipo_evento === 'Open Day'" class="pt-3"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-checkbox size="sm" switch inline v-model="form_evento.od_accompagnatori">Accompagnatori</b-form-checkbox>
              <b-form-group label="Nome Cognome Accompagnatore:" v-if="form_evento.od_accompagnatori">
                <b-form-input size="sm" v-model="form_evento.od_accompagnatori_nome"></b-form-input>
              </b-form-group>
              <b-form-group label="Nome Cognome Accompagnatore2:" v-if="form_evento.od_accompagnatori">
                <b-form-input size="sm" v-model="form_evento.od_accompagnatori_nome2"></b-form-input>
              </b-form-group>
              <b-form-group label="Open Day Data:">
                <b-form-datepicker size="sm" v-model="form_evento.od_data"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col>
              <br>
              <b-form-group label="Professione Accompagnatore:" v-if="form_evento.od_accompagnatori">
                <b-form-input size="sm" v-model="form_evento.od_accompagnatori_professione"></b-form-input>
              </b-form-group>
              <b-form-group label="Professione Accompagnatore2:" v-if="form_evento.od_accompagnatori">
                <b-form-input size="sm" v-model="form_evento.od_accompagnatori_professione2"></b-form-input>
              </b-form-group>
              <b-form-group label="Nome Open Day:">
                <b-form-input size="sm" v-model="form_evento.od_nome"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="form_evento.tipo_evento === 'Open Day'" style="background-color: #f3f3f3">
            <b-col>
              <b-form-group label="Come ha saputo dell'Open Day:">
                <b-form-textarea size="sm" v-model="form_evento.od_come_ha_saputo"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <!--                    Test-->
          <b-row v-if="form_evento.tipo_evento === 'Test'"  class="pt-3"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-checkbox size="sm" switch inline v-model="form_evento.tst_iscrizione">Si vuole Iscrivere ad un Test</b-form-checkbox>
              <b-form-checkbox size="sm" switch inline v-model="form_evento.tst_fac_simile">Ha richiesto un FacSimile</b-form-checkbox>
            </b-col>
          </b-row>
          <b-row style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-group label="Seconda Lingua:" v-if="form_evento.tst_iscrizione">
                <b-form-input size="sm" v-model="form_evento.tst_seconda_lingua"></b-form-input>
              </b-form-group>
              <b-form-group label="Voto Diploma:" v-if="form_evento.tst_iscrizione">
                <b-form-input size="sm" v-model="form_evento.tst_voto_diploma"></b-form-input>
              </b-form-group>
              <b-form-checkbox size="sm" switch inline v-model="form_evento.tst_ha_pagato" v-if="form_evento.tst_iscrizione">Ha Pagato</b-form-checkbox>
            </b-col>
            <b-col>
              <b-form-group label="Terza Lingua:" v-if="form_evento.tst_iscrizione">
                <b-form-input size="sm" v-model="form_evento.tst_terza_lingua"></b-form-input>
              </b-form-group>
              <b-form-group label="Seleziona la Data del Test:" v-if="form_evento.tst_iscrizione">
                <b-form-datepicker size="sm" v-model="form_evento.tst_data"></b-form-datepicker>
              </b-form-group>
              <b-form-group label="Scegli il Tipo di Pagamento" v-if="form_evento.tst_ha_pagato">
                <b-form-select size="sm" :options="tipo_pagamento" value-field="value" text-field="text" v-model="form_evento.tst_pagamento_tipo"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <hr>
          <b-row class="pt-3"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-checkbox size="sm" switch inline v-model="form_evento.partecipazione">Ha Patercipato all'evento</b-form-checkbox>
              <b-form-checkbox size="sm" switch inline v-model="form_evento.follow_up">Richiede un FollowUp</b-form-checkbox>
            </b-col>
            <b-col>

            </b-col>
          </b-row>
          <b-row v-if="form_evento.follow_up" class="pt-3 pb-2"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-group label="Seleziona la data del FollowUp:">
                <b-form-datepicker size="sm" v-model="form_followup.data_follow"></b-form-datepicker>
                <small class="card-subtitle text-danger">Obbligatorio</small>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Azione:">
                <b-form-textarea size="sm" v-model="form_followup.cosa_fare"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <hr>
              <b-form-group label="Commenti/Note/Altro">
                <b-form-textarea v-model="form_evento.note"></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
      <b-card-body v-if="action==='interazione'">
        <b-form id="interazione">
          <b-row class="pt-3"
                 style="background-color: #f3f3f3; border-radius: 5px">
            <b-col>
              <b-form-group label="Seleziona il Tipo di Interazione:">
                <b-form-select v-model="form_interazione.tipo_interazione" size="sm" :options="tipo_interazione" text-field="text" value-field="value" ></b-form-select>
              </b-form-group>
              <b-form-group label="Inserisci la data di inserimento dell'evento:">
                <b-form-datepicker size="sm" v-model="form_interazione.data_interazione"></b-form-datepicker>
                <small class="text-muted text-underline"><b-link @click="insertToday">inserisci la data di oggi</b-link></small>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Scegli la sede*">
                <b-form-select size="sm" :options="sedeInteresse" text-field="text" value-field="value" v-model="form_interazione.sede"></b-form-select>
              </b-form-group>
              <b-form-group label="Inserisci la data dell'evento:">
                <b-form-datepicker size="sm" v-model="form_interazione.data_iscrizione"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <!--                        <b-row class="pt-3"-->
          <!--                               style="background-color: #f3f3f3; border-radius: 5px">-->
          <!--                            <b-col>-->
          <!--                                <label title="Scegli Orientatore"></label>-->
          <!--                                <div style="border-radius: 0.3rem">-->
          <!--                                    <v-select size="sm" multiple :options="users" label="email" v-model="form_interazione.staff" :reduce="users.id"></v-select>-->
          <!--                                </div>-->
          <!--                            </b-col>-->
          <!--                        </b-row >-->
          <b-form-group label="Commenti/Note/Altro">
            <b-form-textarea v-model="form_interazione.note"></b-form-textarea>
          </b-form-group>
        </b-form>
      </b-card-body>
      <b-card-body v-if="action==='altro'">
        <b-form id="altro">
          <b-row>
            <b-col>
              <b-form-select :options="sedeInteresse"></b-form-select>
            </b-col>
            <b-col>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <small class="text-muted">Nome Component: src/components/crm/evento/lead/ModaleEventoLead.vue</small>
  </b-modal>
</template>

<script>
import EventService from "../../../../services/event.service";
import UserService from "../../../../services/user.service";
import LeadService from "@/services/lead.service";
// import vSelect from 'vue-select'

export default {
    name:'modale-evento',
    components: {
        // vSelect,
    },
    props: ['l_id', 'modeBulk'],

    data() {
        return {
            // Select
            tipo_evento: [
                {value: 'Colloquio Individuale', text: 'Colloquio Individuale'},
                {value: 'Fiera', text: 'Fiera'},
                {value: 'Informazioni', text: 'Informazioni'},
                {value: 'Open Day', text: 'Open Day'},
                {value: 'Test', text: 'Test'},
            ],
            tipo_interazione: [
                {text: "Appuntamento", value:"Appuntamento"},
                {text: "AppuntamentoTelef", value:"AppuntamentoTelef"},
                {text: "CallIn", value:"CallIn"},
                {text: "CallOut", value:"CallOut"},
                {text: "DEM", value:"DEM"},
                {text: "EmailIn", value:"EmailIn"},
                {text: "EmailOut", value:"EmailOut"},
                {text: "Instagram", value:"Instagram"},
                {text: "LL", value:"LL"},
                {text: "LiveChat", value:"LiveChat"},
                {text: "OAS", value:"OAS"},
                {text: "PCTO", value:"PCTO"},
                {text: "Sms", value:"Sms"},
                {text: "SpedizioneMateriale", value:"SpedizioneMateriale"},
                {text: "ToDo", value:"ToDo"},
                {text: "VSA", value:"VSA"},
                {text: "WhatsappIn", value:"WhatsappIn"},
                {text: "WhatsappOut", value:"WhatsappOut"},
            ],
            tipo_pagamento: [
                {value:"Contanti", text:"Contanti"},
                {value:"Bonifico", text:"Bonifico"},
                {value:"PayPal", text:"PayPal"},
                {value:"Carta di Credito", text:"Carta di Credito"}
            ],
            sedeInteresse: [
                {value:'Firenze', text:'Firenze'},
                {value:'Mantova', text:'Mantova'},
                {value:'Milano', text:'Milano'},
                {value:'Torino', text:'Torino'},
            ],
            modalita_partecipazione: [
                {value:'On Line', text:'On Line'},
                {value:'Presenza', text:'Presenza'}
            ],
            ti_come_hai_conosciuto_questo_od: [
                "----- Social -----",
                "Facebook",
                "Instagram",
                "----- Giornali----",
                "La Gazzetta di Firenze"
            ],
            form_evento: {
                classe_frequentata: '',
                data_invio_form: '',
                data_iscrizione: null,
                note: "",
                sede: '',
                tipo_evento: '',
                tipo_interazione: '',
                follow_up: false,
                ha_partecipato: false,
                ci_cosa_vuole_studiare: "",
                ci_data: null,
                ci_interessato_a: "",
                ci_nome_istituto: "",
                ci_nome_universita: "",
                ci_orario: "",
                ci_universita_frequentata: "",
                fi_a_quale_fiera: "",
                fi_di_quale_anno: "",
                od_iscrizione: false,
                od_cosa_vuole_studiare: "",
                od_accompagnatori: false,
                od_accompagnatori_nome: "",
                od_accompagnatori_nome2: "",
                od_accompagnatori_professione: "",
                od_accompagnatori_professione2: "",
                od_come_ha_saputo: "",
                od_data: null,
                od_nome: "",
                od_modalita_di_partecipazione: '',
                od_test_iscrizione: false,
                tst_nome: "",
                tst_iscrizione: false,
                tst_data: "",
                tst_fac_simile: false,
                tst_ha_pagato: false,
                tst_pagamento_tipo: '',
                tst_seconda_lingua: "",
                tst_terza_lingua: "",
                tst_voto_diploma: "",
                info_messaggio: "",
                staff: []
            },
            form_evento2: {
                // Generici
                classe_frequentata: '',
                data_invio_form: "",
                data_iscrizione: "",
                note: "",
                modalita_di_partecipazione: '',
                ha_partecipato: false   ,
                sede:'',
                staff: [],
                tipo_evento: '',
                tipo_interazione: '',
                // Colloquio Individuale
                ci_cosa_vuole_studiare: "",
                ci_data: "",
                ci_interessato_a: "",
                ci_modalita_preferenza: '',
                ci_nome_istituto: "",
                ci_nome_universita: "",
                ci_orario: "",
                ci_universita_frequentata: "",
                // Fiera
                fi_a_quale_fiera: "",
                fi_di_quale_anno: "",
                // Open Day
                od_accompagnatori: false,
                od_accompagnatori_nome: "",
                od_accompagnatori_nome2: "",
                od_accompagnatori_professione: "",
                od_accompagnatori_professione2: "",
                od_come_ha_saputo: "",
                od_data: "",
                od_nome: "",
                // Test
                tst_data: "",
                tst_iscrizione: false,
                tst_fac_simile: false,
                tst_ha_pagato: false,
                tst_pagamento_tipo:'',
                tst_seconda_lingua: "",
                tst_terza_lingua: "",
                tst_voto_diploma: "",
                ti_come_hai_conosciuto_questo_od: '',
                ti_a_quale_od:'',
                follow_up: false,
            },
            form_interazione: {
                data_interazione: "",
                evento_log: "",
                followup: "",
                sede:"",
                nota: "",
                oggetto: "",
                staff: [],
                tipo_interazione: "",
            },
            form_followup: {
                data_follow:'',
                cosa_fare:''
            },
            // select
            users: null,
            // actions
            // action_evento: false,
            // action_interazione: false,
            action: '',
            followUp: false,
            lead: this.getLead,
            showModal: true,
            leadInfo: {}
        }
    },
    mounted() {
        // this.initCustomUser()
        this.retrieveLead()
    },
    computed: {
        getLead: {
            get() {
                return this.l_id
            }
        },

    },
    methods: {
        initCustomUser(){
            const payload = {
                is_event_manager: true
            }
            UserService.getUser(payload)
                .then(resp => {
                    this.users = resp.data
                })
        },
        retrieveLead() {
          LeadService.getLeadFromId(this.l_id)
              .then(resp => {
                  this.leadInfo = resp.data
              })
              .catch()
        },
        saveEvento() {
            if(this.modeBulk) {
                const payload = {
                    lead: this.l_id,
                    orientatore_id: this.$store.state.user.user_id,
                    ...this.form_evento
                }
                console.log('salvato correttamente', payload)
            }
            else if(this.action === 'evento') {
                console.log(this.$store, this.l_id)
                const payload = {
                    lead: this.l_id,
                    orientatore_id: this.$store.state.user.user_id,
                    ...this.form_evento
                }
                EventService.postEventoBulk(payload)
                    .then(() => {
                        const payload = {
                            lead: this.l_id,
                            ...this.form_followup
                        }
                        if(this.form_evento.follow_up) {
                            EventService.postFollowUp(payload)
                                .then(() => {
                                    this.clearForm()
                                    this.$store.dispatch('settings/refreshLead', true)
                                    this.$bvToast.toast(`FolloUp Creato con successo`, {
                                      title: 'Aggiungi FollowUP',
                                      autoHideDelay: 5000,
                                      variant: 'success'
                                    })
                                    this.$bvModal.hide('aggiungi-evento')
                                    }
                                )
                                .catch(error => {
                                    console.log(error)
                                })
                        }else {
                            this.clearForm()
                            this.$bvModal.hide('modale-evento')
                            this.$bvToast.toast(`Evento Creato con successo`, {
                                title: 'Aggiungi Evento',
                                autoHideDelay: 5000,
                                variant: 'success'
                            })
                            this.$emit('getEventi')
                        }
                    })
                    .catch(error => {
                        this.$bvToast.toast(`Evento non Creato: ${error}`, {
                            title: 'Evento Errore',
                            autoHideDelay: 5000,
                            variant: 'danger'
                        })
                        console.log(error)
                    })
            }
            else if(this.action === 'interazione') {
                const payload = {
                    lead: this.l_id,
                    ...this.form_interazione
                }
                console.log('Payload: ', payload)
                EventService.postEvento(payload)
                    .then(resp => {
                        console.log(resp)
                        this.$bvModal.hide('aggiungi-evento')
                        this.$emit('InitLead')
                    })
            }
        },
        clearForm() {
            this.form_evento = {
                // Generici
                classe_frequentata: '',
                data_invio_form: "",
                data_iscrizione: "",
                note: "",
                modalita_di_partecipazione: '',
                ha_partecipato: false   ,
                sede: '',
                staff: [],
                tipo_evento: '',
                tipo_interazione: '',
                // Colloquio Individuale
                ci_cosa_vuole_studiare: "",
                ci_data: "",
                ci_interessato_a: "",
                ci_modalita_preferenza: '',
                ci_nome_istituto: "",
                ci_nome_universita: "",
                ci_orario: "",
                ci_universita_frequentata: "",
                // Fiera
                fi_a_quale_fiera: "",
                fi_di_quale_anno: "",
                // Open Day
                od_accompagnatori: false,
                od_accompagnatori_nome: "",
                od_accompagnatori_nome2: "",
                od_accompagnatori_professione: "",
                od_accompagnatori_professione2: "",
                od_come_ha_saputo: "",
                od_data: "",
                od_nome: "",
                // Test
                tst_data: "",
                tst_fac_simile: false,
                tst_ha_pagato: false,
                tst_pagamento_tipo: '',
                tst_seconda_lingua: "",
                tst_terza_lingua: "",
                tst_voto_diploma: "",
                ti_come_hai_conosciuto_questo_od: '',
                ti_a_quale_od:'',
                follow_up: false,
            }
            this.form_interazione = {
                data_interazione: "",
                evento_log: "",
                followup: "",
                nota: "",
                oggetto: "",
                staff: [],
                tipo_interazione: "",
            }
        },
        insertToday() {
            return this.form_interazione.data_interazione = new Date().toLocaleDateString('it-IT')
        }
    },
    watch: {
     l_id(value) {
         console.log(value)
     },
    editEvento: {
        handler() {
            this.form_evento = Object.assign({}, this.editEvento)
            this.tabIndex = 0
        },
        immediate: true
    },
    }
}
</script>

<style scoped>
>>> {
    --vs-controls-color: #6c6b6c;
    /*--vs-border-color: #ffffff;*/

    /*--vs-dropdown-bg: #282c34;*/
    /*--vs-dropdown-color: #cc99cd;*/
    --vs-dropdown-option-color: #6c6b6c;

    --vs-selected-bg: #664cc3;
    --vs-selected-color: #eeeeee;

    --vs-search-input-color: #eeeeee;

    --vs-dropdown-option--active-bg: #ffffff;
    --vs-dropdown-option--active-color: #110a8d;
}
</style>
