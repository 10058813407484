<script>
    import {Role} from "./role";
    const appSidebarMenu = [
        {
            url: '/home',
            icon: 'fas fa-sitemap',
            text: 'Dashboard',
            permission: [Role.Staff],
        },
        {
            text: 'CRM',
            is_header: true,
            permission: [Role.Staff,],
        },
        {
            url: '/crm/',
            icon: 'fas fa-user-astronaut',
            text: 'CRM',
            children: [
                {url: '/crm/istituto/list', text: "Istituti", permission: [Role.Staff,],},
                {url: '/crm/lead/list', text: "Lead", permission: [Role.Staff,],},
                {url: '/crm/form/formdalsito', text: "Form da Sito", permission: [Role.Staff,],}
            ],
            permission: [Role.Staff,],
        },
        {
            text: 'Università',
            is_header: true,
            permission: [Role.Staff, Role.Docente],
        },
        {
            url: '/universita/',
            icon: 'fa fa-university',
            text: 'UNIVERSITÀ',
            children: [
                // {text: '----', is_header: true, permission: [Role.Staff, Role.Docente]},
                // {url: '/universita/prenotazione-aule-corsi', text: 'Prenotazione Aule - Corsi', permission: [Role.Staff]},
                // {url: '/universita/piano-di-studi', text: 'Piani di Studio', permission: [Role.Staff]},
                // {url: '/universita/sessioni', text: 'Sessioni Esami', permission: [Role.Staff]},
                // {url: '/universita/appelli', text: 'Prenotazione Appelli', permission: [Role.Staff]},
                // {url: '/sessioni/lista', text: 'Sessioni Test', permission: [Role.Staff]},
                // {url: '/test/nuovo/', text: 'Nuovo Test', permission: [Role.Staff]},
                {url: '/universita/dashboardLms', text: 'LMS', permission: [Role.Staff, Role.Docente]},
            ],
            permission: [Role.Staff, Role.Docente],
        },
        {
            text: 'Studenti',
            is_header: true,
            permission: [Role.Staff],
        },
        {
            url: '/studenti/',
            icon: 'fas fa-user-graduate',
            text: 'STUDENTI',
            children: [
                {url: '/studenti/lista', text: 'Lista Studenti', permission: [Role.Staff],},
                {url: '/studente/libretto', text: 'Lista Libretti', permission: [Role.Staff],},

            ],
            permission: [Role.Staff],
        },
        {
            text: 'Settings',
            is_header: true,
            permission: [Role.Staff],
        },
        {
            url: '/settings/',
            text: "SETTINGS",
            icon: 'fas fa-cogs',
            children: [
                {url: '/settings/scuole', text: 'Database Istituti'},
                {url: '/settings/settings', text: 'Settings'},
            ],
            permission: [Role.Staff,],
        },
        {
            text: 'Info',
            is_header: true,
            permission: [Role.Studente,],
        },
        {
            url: '/user/',
            text: "Studente",
            icon: 'fas fa-user-graduate',
            profile: 'Studente',
            children: [
                // {url: '/profile-studente', text: 'Profilo', permission: [Role.Studente,]},
                // {url: '/studente/libretto', text: 'Libretto', permission: [Role.Studente,]},
                {url: '/studenti/studenteLms', text: 'LMS', permission: [Role.Studente,]},
            ],
            permission: [Role.Studente,],
        },
        {
            text: 'Bug & Test',
            is_header: true,
            permission: [Role.Staff]
        },
        {
            icon: 'fa fa-bug',
            text: 'Bugs',
            url: '/bug',
            permission: [Role.Staff]
        },
        {
            url: '/paginaTest',
            text: 'pagina dei test',
            icon: 'fas fa-cogs',
            permission: [Role.Staff]
        }
    ]
    export default appSidebarMenu
</script>
