<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
            <h1 class="page-header">Dashboard 1</h1>
        </div>
    <!-- END #content -->
</template>
<script>
import dashboardListService from '@/services/dashboard.service'
export default {
    data() {
        return {
            lead:[],
            nome:'',
            cognome:'',
        }
    },
    created(){
        // send a request to get result, and assign the value to a, b, c here
        this.getStatistiche()
    },
    computed: {
        setData(){
            return [
                this.statistiche.leads['how_to_know']['internet'],
                this.statistiche.leads['how_to_know']['orientamento'],
                this.statistiche.leads['how_to_know']['salone'],
                this.statistiche.leads['how_to_know']['informagiovani'],
                this.statistiche.leads['how_to_know']['instagram'],
                this.statistiche.leads['how_to_know']['facebook'],
                this.statistiche.leads['how_to_know']['tv'],
                this.statistiche.leads['how_to_know']['mantovauno'],
                this.statistiche.leads['how_to_know']['passaparola'],
                this.statistiche.leads['how_to_know']['professore'],
                this.statistiche.leads['how_to_know']['affissioni'],
                this.statistiche.leads['how_to_know']['altro'],
            ]
        },
    },
    methods:{
        editLead(l){
            console.log(l)
        },
        getStatistiche(){
            this.statistiche = null;
            dashboardListService.getDashBoard()
                .then(async (response) => {
                    this.statistiche = response.data.statistiche
                    this.dataLoaded = true
                })
        }},
}
</script>
<style>
    .link {
        color: #3a3f51;
        text-shadow: #FFCC00;
        text-transform: uppercase;
    }
</style>
