<template>
  <div>
    <b-card  class="shadow">
        <span class="sub-title">Eventi con Istituto</span>
        <b-link class="text-dark ml-2" v-b-tooltip.top title="Ricarica Eventi" @click="initEventi">
          <b-icon icon="arrow-clockwise" :animation='showAnimation ? `spin-pulse` : `false`' font-scale="1"></b-icon>
        </b-link>
        <b-button class="mb-1 ml-lg-5 px-1" size="sm" variant="dark" @click="openModaleAddOrEditEventoIstituto(null)">Aggiungi</b-button>
        <b-table
          ref="table"
          class="font-weight-light"
          thead-class="font-weight-light"
          :fields="fields"
          :items="eventi"
          head-variant="dark"
          select-mode="single"
          selectable
          striped
          show-empty
          small
          sticky-header
        >
          <template #cell(notes)="row">
            <div v-for="(note, index) in row.item.notes" :key="index">
              {{ note.nota }}
            </div>
          </template>
          <template #cell(tipo)="row" >
            <div class="d-inline-flex">
              <h6 class="mb-0"><b-badge class="mb-0" :class='`variant-${row.item.tipo}`'>{{ stripText(row.item.tipo) }}</b-badge></h6>
              <span class="mb-0 ml-2"><b-icon class="mt-0" icon="arrow-return-right" variant="red"></b-icon></span>
              <span v-if="row.item.contatto_full_name !== null">{{row.item.contatto_full_name}}</span>
              <span v-else>{{row.item.nome_istituto }}</span>
            </div>
          </template>
          <template #cell(data_evento)="row" class="text-inline">
            <span class="text-inline">{{ row.item.data_evento | formatDate }}</span>
          </template>
          <template #cell(action)="row">
            <b-link @click="openModaleAddOrEditEventoIstituto(row.item)" v-b-tooltip.left title="Modifica">
              <b-icon icon="pencil"></b-icon>
            </b-link>
            <b-link class="ml-2" @click="replyModaleEventoIstituto(row.item)" v-b-tooltip.left title="Reply">
              <b-icon icon="reply-all-fill" flip-h font-scale="1.5" variant="orange"></b-icon>
            </b-link>
            <b-link class="ml-2 text-decoration-none"  @click="richiamaReply(row)" v-if="row.item.reply">
              <b-icon v-if="row.detailsShowing" v-b-tooltip.left title="Nascondi" icon="eye-slash" variant="green"></b-icon>
              <b-icon v-if="!row.detailsShowing" v-b-tooltip.top title="Mostra" icon="eye" variant="green"></b-icon>
              <span v-if="row.item.how_many_reply > 0" style="color: green">{{ row.item.how_many_reply }}</span>
            </b-link>
            <b-link v-b-tooltip.left title="Elimina" class="ml-2" @click="deleteEvento(row.item)"><b-icon icon="trash" variant="red"></b-icon></b-link>
          </template>
          <template #row-details="">
            <div>
              <b-table
                class="mb-5"
                small
                :items="eventi_parent"
                :fields="fieldsReply"
                head-variant="dark"
              >
                <template #cell(tipo)="row" >
                  <div class="d-inline-flex">
                  <h6 class="mb-0"><b-badge class="mb-0" :class='`variant-${row.item.tipo}`'>{{ stripText(row.item.tipo) }}</b-badge></h6>
                  <span class="text-xl-center">
                    <b-icon class="mt-0" icon="chevron-double-right" variant="green" font-scale="0.8"></b-icon>
                    <b-icon class="mt-0" icon="chevron-double-right" variant="green" font-scale="0.8"></b-icon>
                  </span>
                  <span v-if="row.item.contatto_full_name !== null">{{row.item.contatto_full_name}}</span>
                  <span v-else>{{row.item.nome_istituto }}</span>
                  </div>
                </template>
              </b-table>
            </div>
          </template>
        </b-table>
      <small class="text-muted">Nome Componente: src/components/crm/evento/istituto/TableEventoIstituto.vue</small>
    </b-card>
    <modale-evento-istituto
      id="ModaleEventoIstituto"
      :eventLogIdToEdit="idEventoToEdit"
      :replyID="replayID"
      @hidden="resetInfoModal"
    ></modale-evento-istituto>
  </div>
</template>

<script>
import EventService from "@/services/event.service";
import ModaleEventoIstituto from "./ModaleEventoIstituto";

export default {
  name: "EventoIstituto",
  components: {
    ModaleEventoIstituto
  },
  props: {
    istitutoId: [Number, String]
  },
  data() {
    return {
      eventi:[],
      eventi_parent: [],
      fields: [
        {key:'creatoil', label:'Creato', thClass: 'font-weight-light'},
        {key:'oggetto', label:'Oggetto', thClass: 'font-weight-light', thStyle: { width: "10%" }},
        {key:'tipo', label:'Tipo', thClass: 'font-weight-light'},
        {key:'account_name', label:'Account', thClass: 'font-weight-light'},
        {key:'data_evento', label:'Data Evento', thClass: 'font-weight-light', thStyle: { width: "5%" }},
        {key:'luogo', label:'Luogo', thClass: 'font-weight-light'},
        {key:'note', label:'Note', thClass: 'font-weight-light'},
        {key:'action', label:'Actions', thClass: 'font-weight-light'},
      ],
      fieldsReply: [
        {key:'creatoil', label:'Creato', thClass: 'font-weight-light'},
        {key:'tipo', label:'Tipo', thClass: 'font-weight-light'},
        {key:'account_name', label:'Account', thClass: 'font-weight-light'},
        {key:'note', label:'Note', thClass: 'font-weight-light'},
      ],
      idEventoToEdit: null,
      nomeModale: '',
      replayID: null,
      // BOOLEAN
      showEvento: false,
      showAnimation: false,
      showIstitutoEventoModal: false
    }
  },
  watch: {
    showAnimation (value) {
      if (value === true) {
        setTimeout(() => {
          this.showAnimation = false
        }, 1500)
      }
    }
  },
  mounted() {
    this.initEventi()
  },
  methods: {
    // CRUD
    richiamaReply(row) {
      console.log(row)
      if(row.item.id !== null) {
        EventService.getReplyByEventoId(row.item.id)
          .then(resp => {
            this.eventi_parent = resp.data
            row.toggleDetails()

          })
          .catch()
      } else {
        console.log(row.item)
      }
    },
    initEventi(){
      this.showAnimation = true
      const params = {
        by_istituto: this.istitutoId
      }
      EventService.getEventLog(params).then(
        resp => {
          this.eventi = resp.data
        }
      )
    },
    replyModaleEventoIstituto(item){
      this.replayID = item.id
      this.$bvModal.show('ModaleEventoIstituto')
      console.log('è un reply...', item)
    },
    deleteEvento(item){
      this.$bvModal.msgBoxConfirm('Sei sicuro di voler cancellare questo evento?', {
        title: 'Conferma Eliminazione',
        size: 'default',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si!',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
        headerBgVariant: 'dark',
        bodyBgVariant: 'white',
        bodyTextVariant: 'red',
        headerTextVariant: 'white'
      })
        .then(value => {
          if (value) {
            EventService.deleteEventoIstituto(item.id)
              .then(() => {
                this.getContatti()
                this.showAlert()
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            console.log('ok non cancello')
          }
        })
        .catch(err => {
          alert(err)
        })
    },
    openModaleAddOrEditEventoIstituto(item) {
      this.idEventoToEdit = item ? item.id : null
      this.$bvModal.show('ModaleEventoIstituto')
    },
    resetInfoModal() {
      this.idEventoToEdit = null
      this.replayID = null
      this.showIstitutoEventoModal = false
    },
    stripText(value) {
      return value.replace("_", " ").toUpperCase()
    }
  }
}
</script>

<style scoped>
.variant-to_do {
  background-color: #ff9900;
  font-weight: lighter;
  color: #fff;
}
.variant-pcto {
  background-color: #aa9900;
  font-weight: lighter;
  color: #fff;
}
.variant-call_out {
  background-color: #98cc25;
  font-weight: lighter;
  color: #fff;
}
.variant-call_in {
  background-color: #98cc25;
  font-weight: lighter;
  color: #fff;
}
.variant-whatsapp {
  background-color: #c3f56a;
  font-weight: lighter;
}
.variant-email_in {
  background-color: #608ef6;
  font-weight: lighter;
  color: #fff;
}
.variant-email_out {
  background-color: #608ef6;
  font-weight: lighter;
  color: #fff;
}
.variant-vsa {
  background-color: #f273d2;
  font-weight: lighter;
  color: #fff;
}
.variant-oas {
  background-color: #07f0f2;
  font-weight: lighter;
  color: #000;
}
.variant-ll {
  background-color: #f3b799;
  font-weight: lighter;
  color: #FFF;
}
.variant-appuntamento {
  background-color: #d97297;
  font-weight: lighter;
  color: #FFF;
}
.variant-app_tel {
  background-color: #d97297;
  font-weight: lighter;
  color: #FFF;
}
.text-inline {
  display: inline-block;
  white-space: no-wrap;
  clear: both;
  overflow: hidden;
}
</style>
