<template>
  <div id="content" class="app-content">
    <b-card>
      <b-card-header header-bg-variant="dark">
        <b-row>
          <b-col cols="7">
            <h3 style="color: #f9f9fa">
              <i class="fa fa-graduation-cap"></i>  Ciao <b>{{ this.$store.state.auth.user.first_name }}</b>, seleziona un corso.
            </h3>
          </b-col>
        </b-row>
      </b-card-header>
      <hr>
      <b-row cols="12">
        <b-col cols="6">
          <b-card>
            <b-card-title>Inserisci nuovi contenuti in una lezione:</b-card-title>
            <b-card-body>
              <b-form>
                <b-form-group label="Corso" class="text-warning">
                  <b-form-select
                    v-model="form.corso"
                    :options="corsi"
                    value-field="id"
                    text-field="nome"
                    @change="this.initLezioni"
                  >
                  </b-form-select>
                   <small :class="errors.indexOf('Seleziona il corso') > -1 ? 'text-danger':'text-muted'">Seleziona il corso</small>
                </b-form-group>
                <b-form-group label="Lezione" class="text-warning">
                  <b-form-input v-model="form.lezione" placeholder="Inserisci il titolo della Lezione">
                  </b-form-input>
                  <small :class="errors.indexOf('Inserisci il titolo della lezione') > -1 ?'text-danger':'text-muted'">Inserisci il titolo della Lezione</small>
                </b-form-group>
                <b-form-group label="Files Upload" class="text-warning">
                  <file-pond ref="pond" :initPond="resetPond" @idFile="getId"/>
                  <small :class="errors.indexOf('Carica files Video/Audio/Doc di qualsiasi formato') > -1 ?'text-danger':'text-muted'">Carica files Video/Audio/Doc di qualsiasi formato</small>
                </b-form-group>
                <b-form-group label="Annotazione" class="text-warning">
                  <b-form-textarea v-model="form.annotazione" placeholder="Inserisci descrizione" rows="3">
                  </b-form-textarea>
                  <small class="text-muted">Nel caso servisse inserisci delle annotazioni per la lezione</small>
                </b-form-group>
                <b-button class="align-right" variant="dark" @click="submitForm">Salva</b-button>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="6">
          <div v-if="tempCorsoId">
            <h4>
              <b-list-group-item class="d-flex justify-content-between align-items-center" variant="light">Lezioni esistenti nel corso selezionato
                <h3><b-badge  variant="dark">{{ lezioniCount }}</b-badge></h3></b-list-group-item>
            </h4>
            <b-list-group-item @click="openPreview" button class="d-flex justify-content-between align-items-center" v-for="(lezione, index) in lezioni" :key="index">
              {{ lezione.titolo }}
              <div>
                <b-icon icon="eye" v-b-modal.ViewPreview class="button-class h5" variant="success" @click="previewLezione(lezione.id)"></b-icon>
                <b-icon class="button-class h5 text-danger ml-2" icon="trash" @click="deleteLezione(lezione.id)"></b-icon>
              </div>
            </b-list-group-item>
          </div>
          <div v-if="this.tempCorsoId">
            <b-card  class="mt-3" v-for="(commento, index) in comments" :key="index">
              <div class="d-flex justify-content-between align-items-center"><b-avatar></b-avatar>
                <div>
                  <b>{{ commento.nome }}</b>
                  <b-button class="ml-4" variant="info"  @click="deleteCommento(commento)"><i class="fas fa-trash-alt"></i></b-button>
                </div>
              </div>
              <hr>
              <b-card-text>{{commento.contenuto}}</b-card-text>
              <template #footer>
                <small class="text-muted">{{commento.creato_il}}</small>
              </template>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <view-preview :preview="lezionePreview"></view-preview>
  </div>
</template>
<script>
import FilePond from "@/components/fpond.vue";
import axios from "axios";
import Swal from "sweetalert2"
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_API_URL
import "video.js/dist/video-js.css";
import LmsServices from "@/services/lms.services";
import ViewPreview from "@/pages/universita/lms/ViewPreview";

const toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
});
export default {
  name: "App",
  components: {
    FilePond,
    ViewPreview
  },
  data() {
    return {
      signedFiles: [],
      form: {
        corso: "",
        corsoSlug: "",
        lezione: "",
        lezioni_complementari: false,
        annotazione: "",
        fileS3: "",
      },
      UserDetails: [],
      comments: [],
      componentKey: 0,
      corsi: [],
      errors: [],
      fileExt: [],
      lezionePreview: [],
      lezioni: [],
      lezioniCount: null,
      newComments: [],
      polling: null, // per timer
      returnUrl: [], // Id
      resetPond: false,
      tempCorsoId: null,
      tempLezioneId: "",
      readyToSave: true,
    };
  },
  created() {
    this.pollData()
  },
  watch: {
    errors (value) {
      console.log(value)
    }
  },
  mounted() {
    this.initcorsi();
    if (this.$route.fullPath !== '/login' && this.$store.state.auth.user.primo_login) {
      this.$bvModal.show('first_login_app')
    }
    document.title = "LMS"; // Imposta nome corso come titolo della scheda del browser
  },
  methods: {
    urlSigner() {},
    pollData() {
      if (this.tempLezioneId !== "") {
        this.polling = setInterval(() => {
          this.updateComments()
        }, 5000)
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    initcorsi() { // OK---
      axios
        .get(API_URL + 'corsi/', {headers: authHeader()})
        .then((resp) => {
          this.corsi = resp.data;
        });
    },
    initLezioni(id) {
      this.tempLezioneId = ""
      this.comments = []
      this.tempCorsoId = id
      LmsServices.getlezioniByCorsoId(id)
        .then((resp) => {
          this.lezioni = resp.data.results;
          this.lezioniCount = this.lezioni.length
        })
        .catch(error => {
          console.log(error)})
    },
    previewLezione(id) {
      this.fileExt = []
      LmsServices.getLezionePreviw(id)
        .then((resp) => {
          this.lezionePreview = resp.data
          this.signedFiles = []
          this.urlSigner()
        })
        .catch(error => {
          console.log(error)
        })
    },
    deleteLezione(event) {
      Swal.fire({
        title: "Elimino la lezione?",
        text: "Non sarai più in grado di recuperarla!",
        // type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF160C",
        cancelButtonColor: "#393F50",
        confirmButtonText: "Si, Elimina",
        cancelButtonText: "No, Annulla"
      })
        .then((result) => { // <--
          if (result.value) { // <-- if confirmed
            axios
              .delete(API_URL + `lms/${event}`, {headers: authHeader()})
              .then(() => {
                this.initLezioni(this.tempCorsoId)
              })
          }
        });
    },
    getComments(event) {
      this.tempLezioneId = event
      axios
        .get(API_URL + `lms/${event}/get-commenti/`, {headers: authHeader()})
        .then((response) => {
          this.comments = response.data;
          this.comments = this.comments.reverse()
        });
      this.pollData()
      // this.comments = []
    },
    updateComments() {
      if (this.tempLezioneId !== "") {
        axios
          .get(API_URL + `lms/${this.tempLezioneId}/get-commenti/`, {headers: authHeader()})
          .then((response) => {
            this.comments = response.data;
            this.comments = this.comments.reverse()
          })

      }


    },
    deleteCommento(event) {
      Swal.fire({
        title: "Elimino il Commento?",
        text: "Non sarai più in grado di recuperarlo!",
        // type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FF160C",
        confirmButtonText: "Si, Elimina",
        cancelButtonText: "No, Annulla"
      }).then((result) => {
        if (result.value) {
          axios.delete(API_URL + `lms/${event['lezione'].id}/get-commenti/${event.id}`, {headers: authHeader()})
            .then(() => {
              axios
                .get(API_URL + `lms/${event['lezione'].id}/get-commenti/`, {headers: authHeader()})
                .then((response) => {
                  this.comments = response.data;
                });
            });
        }
      });
    },
    getId(id) {
      return this.returnUrl.push({
          files: id,
        });
    },
    submitForm() {
      const payload = {
        corso: this.form.corso,
        titolo: this.form.lezione,
        annotazione: this.form.annotazione,
        filesIds: this.returnUrl
      };
      this.errors = [];
      if (this.form.lezione === "") {
        this.errors.push("Inserisci il titolo della lezione");
      }
      if(this.form.corso === "") {
        this.errors.push("Seleziona il corso")
      }
      if(this.returnUrl.length < 1) {
        this.errors.push('Carica files Video/Audio/Doc di qualsiasi formato')
      }
      if (!this.errors.length) {
        LmsServices.postLezione(payload)
          .then(() => {
            toast.fire({
              icon: 'success',
              title: 'Lezione inserita con successo!',
              background: 'green'
            })
            this.form.lezione = "";
            this.form.annotazione = "";
            this.returnUrl = [];
            this.$refs.pond.emptyFiles();
            this.forceRerender();
            this.initLezioni(this.tempCorsoId)
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {});
      }
    },
    openPreview(evento) {
      console.log(evento)
    }
  }
}
</script>
<style scoped>
  .button-class {
    cursor: pointer;
  }
</style>
