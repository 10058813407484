<template>
  <!-- BEGIN login -->
  <b-row class="app" cols="12">
    <b-col cols="3"></b-col>
    <b-col cols="6" class="login">
      <div class="login-content" style="background: #4d6593; border-box: 0px">
        <img src="assets/img/unicolle.png" alt="Unicollege SSML" width="300" style="align-items: center">
        <form v-on:submit.prevent="handleLogin" name="login_form">
          <h2 class="text-center text-white">Sign In</h2>
          <div class="text-center mb-4 text-dark">
            Portale per gli iscritti alle Università di Unicollege
          </div>
          <b-alert
            :show="dismissCountDown"
            variant="danger"
            dismissible
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
          >Login o Password sbagliata!
          </b-alert>
          <b-form-group label="Email Address:" class="text-white">
            <b-input size="lg" class="fs-15px" v-model="username" autocomplete="Email"
                     placeholder="username@address.com"></b-input>
            <span style="color: #0ce0b6;" v-if="emailValidate">Email non valida</span>
          </b-form-group>

          <b-form-group class="text-dark">
            <div class="d-flex">
              <label class="text-white">Password:</label>
              <b-link class="ml-auto text-dark" @click="openModalPassword">Password Dimenticata?</b-link>
            </div>
            <b-input type="password" v-model="password" size="lg" autocomplete="current-password" class="fs-15px"
                     placeholder="Enter your password"></b-input>
            <span style="color: #0ce0b6;" v-if="passwordValidate">Password non valida</span>
          </b-form-group>

          <!-- <b-form-group> -->
          <!-- <b-form-checkbox id="rememberMe" name="remember_me">Ricordami</b-form-checkbox> -->
          <!-- </b-form-group> -->
          <button type="submit" class="btn btn-primary btn-lg btn-block fw-500 mb-3">Entra</button>
          <!-- <button type="submit" class="btn btn-lg btn-block fw-500 mb-3" style="background: #fa645a; color: white">Entra con Google</button> -->
          <!-- <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure">Login</GoogleLogin> -->
          <!-- <div class="text-center text-muted"> -->
          <!-- Non hai un account? <router-link to="/page/register">Iscriviti</router-link>. -->
          <!-- </div> -->
        </form>
        <div class="text-center text-underline mb-4 text-dark">
          Per ottenere le credenziali di accesso richiederle direttamente in Segreteria
        </div>
      </div>
    </b-col>
    <b-col cols="3"></b-col>
    <reset-password id="resetPassword"></reset-password>
  </b-row>
  <!-- END login -->
</template>

<script>
import AppOptions from '@/config/AppOptions.vue'
import ResetPassword from "@/pages/Login/ResetPassword";
export default {
  name: 'Login',
  components: {
    ResetPassword
  },
  data() {
    return {
      emailValidate: false,
      passwordValidate: false,
      error: '',
      loading: false,
      message: [],
      API_URL: process.env.API_URL,
      username: '',
      password: '',
      dismissSecs: 3,
      dismissCountDown: 0
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    AppOptions.appWithoutSidebar = true;
    AppOptions.appWithoutHeader = true;
    AppOptions.appWithFooter = false;
    AppOptions.appContentFullWidth = true;
    if (this.$store.state.auth.status.loggedIn) {
      this.redirectInFirstPage()
    }
  },
  beforeRouteLeave(to, from, next) {
    AppOptions.appWithoutSidebar = false;
    AppOptions.appWithoutHeader = false;
    AppOptions.appWithFooter = true;
    AppOptions.appContentFullWidth = false;
    AppOptions.appContentFullHeight = false;
    next();
  },
  methods: {
    redirectInFirstPage() {
      if (this.$store.state.auth.user.profile === 'Staff') {
        this.$router.push("/dashboard").then().catch()
      } else if (this.$store.state.auth.user.profile === 'Docente') {
        this.$router.push({name: 'LMS'}).then().catch()
      } else if (this.$store.state.auth.user.profile === 'Studente') {
        this.$router.push("/profile-studente").then().catch()
      }
    },
    handleLogin() {
      this.loading = true;
      if (this.username && this.password) {
        const user = {
          username: this.username,
          password: this.password
        }
        this.$store.dispatch("auth/login", user)
          .then(() => {
            this.redirectInFirstPage()
          })
          .catch(() => {
            this.showAlert()
          })
      }
    },
    openModalPassword() {
      this.$bvModal.show('resetPassword')
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    }
  }
}
</script>
<style scoped>
.app {
  /*background: url("/assets/img/gallery/gallery-11.jpg") no-repeat;*/
  /*background-color:rgba(0, 0, 0, 0.2);*/
  background-size: cover;
  background-color: #010e3b;
}
</style>
