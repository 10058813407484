<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <!-- BEGIN container -->
        <b-container fluid>
            <!-- BEGIN row -->
            <b-row class="justify-content-left">
                <!-- BEGIN col-10 -->
                <b-col xl="12">
                    <!-- BEGIN row -->
                    <b-row>
                        <!-- BEGIN col-9 -->
                        <b-col xl="12">
                            <b-breadcrumb>
                                <b-breadcrumb-item to="/home">HomePage</b-breadcrumb-item>
                                <b-breadcrumb-item active>Segnalazione Bugs</b-breadcrumb-item>
                            </b-breadcrumb>
                            <b-card>
                                <b-card-title>
                                    Segnalazione Bug
                                    <b-button v-b-toggle.filtriBug class="float-right">Filtra</b-button>
                                </b-card-title>
                                <b-card-body>
                                    <b-collapse id="filtriBug">
                                        <b-row>
                                            <b-col>
                                                <b-form-group class="ml-0 font-italic" label="Per Status:" v-slot="{ ariaDescribedby }">
                                                <b-form-checkbox
                                                    id="checkbox-1.1"
                                                    v-model='status_ticket'
                                                    :aria-describedby="ariaDescribedby"
                                                    name="Completed"
                                                    unchecked-value=""
                                                    value="4"
                                                    @change="searchAndFilterBug"
                                                >Completed</b-form-checkbox>
                                                <b-form-checkbox
                                                    id="checkbox-1.2"
                                                    v-model='status_ticket'
                                                    :aria-describedby="ariaDescribedby"
                                                    name="Send"
                                                    unchecked-value=""
                                                    value="1"
                                                    @change="searchAndFilterBug"
                                                >Send</b-form-checkbox>
                                                <b-form-checkbox
                                                    id="checkbox-1.3"
                                                    v-model='status_ticket'
                                                    :aria-describedby="ariaDescribedby"
                                                    name="inPending"
                                                    unchecked-value="2"
                                                    value="2"
                                                    @change="searchAndFilterBug"
                                                >inPending</b-form-checkbox>
                                                <b-form-checkbox
                                                    id="checkbox-1.4"
                                                    v-model='status_ticket'
                                                    :aria-describedby="ariaDescribedby"
                                                    name="onWorking"
                                                    unchecked-value="3"
                                                    value="3"
                                                    @change="searchAndFilterBug"
                                                >onWorking</b-form-checkbox>
                                                </b-form-group>
                                            </b-col>
                                            <b-col>
                                                <b-form-group class="ml-0 font-italic" label="Per Priorità:" v-slot="{ ariaDescribedby }">
                                                    <b-form-checkbox
                                                        id="checkbox-2.1"
                                                        v-model='priority_ticket'
                                                        :aria-describedby="ariaDescribedby"
                                                        name="Basso"
                                                        unchecked-value=""
                                                        value="basso"
                                                        @change="searchAndFilterBug"
                                                    >Basso</b-form-checkbox>
                                                    <b-form-checkbox
                                                        id="checkbox-2.2"
                                                        v-model='priority_ticket'
                                                        :aria-describedby="ariaDescribedby"
                                                        name="Medio"
                                                        unchecked-value=""
                                                        value="medio"
                                                        @change="searchAndFilterBug"
                                                    >Medio</b-form-checkbox>
                                                    <b-form-checkbox
                                                        id="checkbox-2.3"
                                                        v-model='priority_ticket'
                                                        :aria-describedby="ariaDescribedby"
                                                        name="Urgente"
                                                        unchecked-value=""
                                                        value="urgente"
                                                        @change="searchAndFilterBug"
                                                    >Urgente</b-form-checkbox>
                                                    <b-form-checkbox
                                                        id="checkbox-2.4"
                                                        v-model='priority_ticket'
                                                        :aria-describedby="ariaDescribedby"
                                                        name="Bloccante"
                                                        unchecked-value="3"
                                                        value="bloccante"
                                                        @change="searchAndFilterBug"
                                                    >Bloccante</b-form-checkbox>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-collapse>
                                </b-card-body>
                            </b-card>
                            <b-card>
                                <div>
                                    <div class="float-left pt-5">
                                        <b-link v-b-modal.addBug><i class="fas fa-bug"></i>Aggiungi Bug</b-link> |
                                        <span>Bugs Totali: <b-badge pill variant="success">{{bugs.length}}</b-badge></span> |
                                        <span>Bugs Aperti: <b-badge pill variant="danger">{{ $store.state.bugs.openBugs.length }}</b-badge> </span> |
                                        <span>Bugs Chiusi: <b-badge pill variant="warning">{{ getCompletedBug() }}</b-badge></span>
                                        <span>Bugs onWorking: <b-badge pill variant="info">{{ getOnWorkingBug() }}</b-badge></span>
                                    </div>
                                    <div class="float-lg-right">

                                    </div>
                                </div>


                                <b-table
                                    hover
                                    class="flex-column"
                                    sort-by="status"
                                    sort-desc
                                    small
                                    id="bugTable"
                                    ref="bugTable"
                                    striped
                                    :items="bugs"
                                    :fields="fields"
                                    :tbody-tr-class="deleteRow">
                                    <template v-slot:cell(id)="{item}">
                                        <small>#idTCK00{{item.id}}</small>
                                    </template>
                                    <template v-slot:cell(issue)="{item}">
                                        <span v-b-tooltip.hover :title="item.description" >{{ item.issue }}</span>
                                    </template>
                                    <template v-slot:cell(statusTicket)="row">
                                        <h6><b-badge pill :variant="colorBadgeStatus(row)">{{row.item.statusTicket}}</b-badge></h6>
                                    </template>
                                    <template v-slot:cell(priority)="row">
                                        <h6><b-badge pill :variant="colorBadgePriority(row)">{{row.item.priority}}</b-badge></h6>
                                    </template>
                                    <template v-slot:cell(date)="row">
                                        <h6><b-badge pill variant="primary">{{row.item.date}}</b-badge></h6>
                                    </template>
                                    <template v-slot:cell(actions)="row">
                                        <div class="text-lg-left">
                                            <b-btn size="sm"
                                                   style="margin: 0px; padding: 0px; color: olivedrab"
                                                   v-b-tooltip.hover.left
                                                   variant="link"
                                                   title="Edit Bug"
                                                   @click="passDataBug(row)">
                                                <i class="fas fa-edit"></i>
                                            </b-btn> |
                                            <b-btn size="sm"
                                                   style="margin: 0px; padding: 0px; color: lightskyblue"
                                                   v-b-tooltip.hover.left
                                                   title="Chiudi Ticket"
                                                   @click="chiudiTicket(row)"
                                                   variant="link">
                                                <b-icon-bag-check-fill />
                                            </b-btn> |
                                            <b-btn size="sm"
                                                   style="margin: 0px; padding: 0px; color: red"
                                                   v-b-tooltip.hover.left
                                                   title="Elimina Ticket"
                                                   @click="deleteBug(row)"
                                                   variant="link">
                                                <i class="fas fa-trash"></i>
                                            </b-btn>
                                        </div>
                                    </template>
                                    <template v-slot:cell(progress)="row">
                                        <b-progress class="mt-2" show-progress :value="statusProgress(row)" :variant="colorBadgeStatus(row)" height="0.7rem"></b-progress>
                                    </template>
                                </b-table>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
        <b-modal
            id="addBug"
            ref="addBug"
            title="Aggiungi Bug"
            header-bg-variant="dark"
            header-text-variant="white"
            no-fade
            centered
            size="lg" hide-footer>
            <add-bug v-on:initBugsService="initBugsService"></add-bug>
        </b-modal>
        <b-modal
            id="editBug"
            ref="editBug"
            title="Modifica Bug"
            header-bg-variant="dark"
            header-text-variant="white"
            no-fade
            centered
            size="lg" hide-footer>
            <edit-bug :bug="bugToedit" v-on:initBugsService="initBugsService"></edit-bug>
        </b-modal>
    </div>
</template>

<script>
import BugsService from '@/services/bugs.service'
import AddBug from "@/components/bug/AddBug";
import EditBug from "@/components/bug/EditBug";
import { BIconBagCheckFill } from 'bootstrap-vue'

export default {
    components:{
        AddBug,
        EditBug,
        BIconBagCheckFill
    },
    name: 'Bug',
    data(){
        return{
            bugs:[],
            bugToedit:[],
            fields:['id','tipo','issue','statusTicket','priority','date','progress','actions',],
            status_ticket:'',
            priority_ticket:'',
        }
    },
    computed:{
        // getOpenedBug(){
        //     const openedBugs = this.bugs.filter((bug) => bug.statusTicket !== 'Complete');
        //     return this.$store.commit('updateOpenBug', openedBugs);
        // },
    },
    mounted() {
        this.initBugsService()
        // this.getOpenedBug
    },
    methods:{
        getCompletedBug(){
            var completedBugs = this.bugs.filter((bug) => bug.statusTicket === 'Complete');
            return completedBugs.length;
        },
        getOnWorkingBug() {
          var onWorkingBug = this.bugs.filter((bug) => bug.statusTicket === 'onWorking');
          return onWorkingBug.length;
        },
        deleteRow(item){
            if (item.delete){
                return "delete"
            }
        },
        passDataBug(row){
            this.bugToedit = row
            this.$refs['editBug'].show()
        },
        colorBadgePriority(row) {
            if (row.item.priority === 'basso'){
                return 'dark'
            }if (row.item.priority === 'medio'){
                return 'info'
            }if (row.item.priority === 'urgente'){
                return 'warning'
            }else{
                return 'danger'
            }
        },
        colorBadgeStatus(row) {
            if (row.item.statusTicket === 'Send'){
                return 'dark'
            }if (row.item.statusTicket === 'inPending'){
                return 'info'
            }if (row.item.statusTicket === 'onWorking'){
                return 'warning'
            }else{
                return 'success'
            }
        },
        initBugsService(){
            BugsService.getOnlyBugs()
                .then(response => {
                    this.bugs = response.data
                })
                .catch()
        },
        chiudiTicket(row){
            BugsService.closeBug(row)
                .then(
                    this.initBugsService
                )
                .catch()
        },
        statusProgress(row){
            if (row.item.statusTicket === 'Send'){
                return 10
            }if (row.item.statusTicket === 'inPending'){
                return 35
            }if (row.item.statusTicket === 'onWorking'){
                return 75
            }else{
                return 100
            }
        },
        deleteBug(row){
            BugsService.deleteBug(row)
                .then(response => {
                    console.log('delete success', response)
                })
                .catch()
        },
        searchAndFilterBug() {
            const params = {
                status: this.status_ticket,
                priority: this.priority_ticket,
            }
            BugsService.filterBugs(params)
                .then(response => {
                    this.bugs = response.data
                })
                .catch()
            console.log(this.status_ticket)
        }
    }

};
</script>
<style>
    .delete{
        text-decoration: line-through;
        color: #cecbcb;
    }
</style>
