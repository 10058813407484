<template>
  <div>
    <div v-for="p in  this.items" :key="p.id" class="d-inline" >
      <b-card class="mr-5 d-inline-block" bg-variant="light" no-body>
        <b-card-header header-bg-variant="gray" header-text-variant="white">
          <b-card-title align="left">
            <h4>{{ p.nome }} {{ p.cognome }}</h4>
            <b-link class="mt-2 h6" href="#" @click="inviaEvento(p)" title="Invia Task" style="color: #ffffff">Crea Interazione</b-link> |
            <b-link class="h6" @click="openTime(p)" style="color: #ffffff">Timeline
              <span class='badge badge-light' id='CartCount1' v-if="p.numero_eventi >= 1 " >{{ p.numero_eventi }}</span></b-link> |
            <b-link class="mt-2 h6 ml-2" href="#" @click="eventiList(p)" title="Lista Task" style="color: #ffffff">Lista Interazioni
              <span class='badge badge-light' id='CartCount2' v-if="p.numero_eventi >= 1 ">{{ p.numero_eventi }}</span></b-link> |
            <b-link class="mt-2 h6 ml-2" href="#" @click="editContatto(p)" title="Modifica Contatto" v-b-modal.edit-contatto style="color: #ffffff"><i class="fas fa-edit"></i> </b-link>
            <b-link class="mt-2 h6 ml-2" href="#" @click="deleteContatto(p)" title="Elimina Contatto" style="color: #ffffff"><i class="fas fa-trash"></i> </b-link>
          </b-card-title>
        </b-card-header>
        <b-card-body>
          <b-row class="mt-2">
            <b-col cols="8">
              <span style="color: #5d657a">Tipo: </span>{{ p.tipo }}<br>
              <span style="color: #5d657a">Istituto: </span> {{ istitutoDetail.nome_istituto }}<br>
              <span style="color: #5d657a">Email: </span><b-link :href="`mailto:${p.email_personale}`">{{ p.email_personale }}</b-link><br>
              <span style="color: #5d657a">Email:</span><b-link :href="`mailto:${p.email}`">{{ p.email }}</b-link><br>
              <span style="color: #5d657a">Cellulare: </span>{{ p.cellulare }}<br>
              <span style="color: #5d657a">Sesso: {{ p.sesso }}</span><br>
              <span style="color: #5d657a">Materia: </span>{{ p.materia }}
            </b-col>
            <b-col>
              <div v-if="p.note.length<80" v-b-tooltip.hover :title="p.note">
                <span style="color: #5d657a">Note: </span>
                <br>{{ p.note }}
              </div>
              <div v-else v-b-tooltip.hover :title="p.note">
                <span style="color: #5d657a">Note: </span>
                <br>{{ p.note.substring(0,80)+".." }}</div>
            </b-col>
          </b-row>
        </b-card-body>
        <small class="text-muted float-left mx-4">Nome Componente: src/components/crm/cardDetails.vue</small>
      </b-card>
    </div>
  </div>
</template>

<script>
import ContattiService from "@/services/contatti.service"
export default {
    name: "cardDetails",
    props:['items', 'istitutoDetail'],
    computed:{
        full_name(){
            return this.items.nome + " " + this.items.cognome
        }
    },
    methods:{
        inviaEvento(p) {
            console.log('Card Details',p)
            this.$emit('inviaEvento', p)
        },
        eventiList(p){
            this.$emit('eventiList', p )
            // this.evento.id_contatto = p.id
            // this.evento.cognome = p.cognome
        },
        editContatto(p){
            this.$emit('editContatto', p)
        },
        openTime(p){
          this.$emit('openTime', p)
        },
        deleteContatto(p) {
            this.$bvModal.msgBoxConfirm('Sei Sicuro?')
                .then(value => {
                    if(value) {
                        ContattiService.deleteContatto(p.id)
                            .then(() => {
                                this.$bvToast.toast(`Contatto eliminato!`, {
                                    title: 'Contatto eliminato con successo',
                                    solid: true,
                                    variant: 'success',
                                    autoHideDelay: 3000,
                                    appendToast: true
                                })
                            })
                    }else {
                        console.log('non ho fatto nulla')
                    }

                })
        }
    },
}
</script>

<style>
.badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
    background-color: #c67605;
}
#CartCount {
    font-size: 10px;
    background: #091439;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px;
}

</style>
