import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_APP_URL


class SessioneService {
    getSessioni(){
        return axios.get(API_URL + 'sessioni/', { headers: authHeader() })
    }
    getDetails(id){
        return axios.get(API_URL +'sessioni/' + id + '/', { headers: authHeader() })
    }
    postSessione(payload) {
        return axios.post(API_URL + 'sessioni/', payload, { headers: authHeader() })
    }
    deleteSessione(id) {
        return axios.delete(API_URL + 'sessioni/' + id + '/', { headers: authHeader()})
    }

}
export default new SessioneService();
