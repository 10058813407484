import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL


class ContattiService {
    getFilterContatti(item){
        return axios.all([
            axios.get(API_URL + `contatti/?istituto=${item.id}&tipo=Dirigente+Scolastico`, {headers: authHeader()}),
            axios.get(API_URL + `contatti/?istituto=${item.id}&tipo=Docente`, {headers: authHeader()}),
            axios.get(API_URL + `contatti/?istituto=${item.id}&tipo=Referente+Scolastico`, {headers: authHeader()}),
            axios.get(API_URL + `contatti/?istituto=${item.id}&tipo=Generico`, {headers: authHeader()}),
        ])
    }
    postContatti(payload) {
        return axios.post(API_URL + 'contatti/', payload, {headers: authHeader()})
    }
    editContatti(payload) {
        return axios.put(API_URL + 'contatti/' + payload.id + '/', payload, {
            headers: authHeader(),
        })
    }
    deleteContatto(id){
        return axios.delete(API_URL + 'contatti/' + id + '/', { headers: authHeader() })
    }
    getContatti(id) {
      return axios.get(API_URL + 'contatti/?istituto=' + id, { headers: authHeader()})
    }
    getContatto(id) {
      return axios.get(API_URL + 'contatti/' + id, { headers: authHeader()})
    }

}
export default new ContattiService();
