<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <!-- BEGIN container -->
        <b-container>
            <!-- BEGIN row -->
            <b-row class="justify-content-center">
                <!-- BEGIN col-10 -->
                <b-col xl="10">
                    <!-- BEGIN row -->
                    <b-row>
                        <!-- BEGIN col-9 -->
                        <b-col xl="9">
                            <!-- BEGIN #settings -->
                            <div id="settings" class="mb-5">
                                <h4><i class="fas fa-sliders-h"></i> Settings</h4>
                                <p>Settings Generali</p>
                                <b-card no-body>
                                    <b-list-group flush>
                                        <b-list-group-item class="d-flex align-items-center">
                                            <div class="flex-fill" >
                                                <div >Anni Accademici:</div>
                                                <small style="color: #5d657a">Validi per il PIANO DI STUDI</small>
                                                <h4>
                                                    <b-badge class="ml-2" variant="orange" v-for="aa in anniAccademici" :key="aa.id">{{ aa.anno_accademico }}</b-badge>
                                                </h4>
                                            </div>
                                            <div class="width-100">
                                                <b-button v-b-modal.modalEditAA variant="default" class="width-100">Edit</b-button>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <div class="flex-fill" >
                                                <div >Importa Excel Piano di Studi:</div>
                                                <small style="color: #5d657a">Puoi importare l'excel una sola volta e poi duplicare i pds</small>
                                                <h4>
                                                    <b-form-file
                                                        v-model="uploadFile"
                                                        size="sm"
                                                        :state="Boolean(uploadFile)"
                                                    ></b-form-file>
                                                    <small>Selected file: {{ uploadFile ? uploadFile.name : '' }}</small>
                                                </h4>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item class="d-flex align-items-center">
                                            <div class="flex-fill">
                                                <div>Atenei</div>
                                                <div v-for="ateneo in atenei" :key="ateneo.id">
                                                    <b-badge class="ml-2">{{ ateneo.nome }}</b-badge>
                                                </div>
                                            </div>
                                            <div class="width-100">
                                                <b-button v-b-modal.modalEditAtenei variant="default" class="width-100">Add/Edit</b-button>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item class="d-flex align-items-center">
                                            <div class="flex-fill">
                                                <div>Lingue Istituto</div>
                                                <small style="color: #5d657a">Queste sono solo lingue dell'ISTITUTO non del PIANO DI STUDI</small>
                                                <h4>
                                                    <b-badge class="ml-2" variant="orange" v-for="li in lingue" :key="li.id">{{ li.lingua }}</b-badge>
                                                </h4>
                                            </div>
                                            <div class="width-100">
                                                <b-button v-b-modal.modalLingue variant="default" class="width-100">Add/Edit</b-button>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item class="d-flex align-items-center">
                                            <div class="flex-fill">
                                                <div>Tipologia Istituto</div>
                                                <small style="color: #5d657a">Queste sono le tipologie d'Istituto</small>
                                                <h4>
                                                    <b-badge class="ml-2" variant="orange" v-for="ist in tipologie_istituti" :key="ist.id">{{ ist.tipologia }}</b-badge>
                                                </h4>
                                            </div>
                                            <div class="width-100">
                                                <b-button v-b-modal.modalTipologia variant="default" class="width-100">Add/Edit</b-button>
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </div>
                            <!-- END #general -->
                        </b-col>
                        <!-- END col-9-->
                        <!-- BEGIN col-3 -->
                        <b-col xl="3">
                            <!-- BEGIN #sidebar-bootstrap -->
                            <div class="navbar navbar-sticky d-none d-xl-block">
                                <b-nav v-b-scrollspy="120">
                                    <b-nav-item href="#settings" @click="scrollIntoView">Settings</b-nav-item>
                                    <b-nav-item href="#notifications" @click="scrollIntoView">Notifications</b-nav-item>
                                    <b-nav-item href="#privacyAndSecurity" @click="scrollIntoView">Privacy and security</b-nav-item>
                                    <b-nav-item href="#payment" @click="scrollIntoView">Payment</b-nav-item>
                                    <b-nav-item href="#shipping" @click="scrollIntoView">Shipping</b-nav-item>
                                    <b-nav-item href="#mediaAndFiles" @click="scrollIntoView">Media and Files</b-nav-item>
                                    <b-nav-item href="#languages" @click="scrollIntoView">Languages</b-nav-item>
                                    <b-nav-item href="#system" @click="scrollIntoView">System</b-nav-item>
                                    <b-nav-item href="#resetSettings" @click="scrollIntoView">Reset settings</b-nav-item>
                                </b-nav>
                            </div>
                            <!-- END #sidebar-bootstrap -->
                        </b-col>
                        <!-- END col-3 -->
                    </b-row>
                    <!-- END row -->
                </b-col>
                <!-- END col-10 -->
            </b-row>
            <!-- END row -->
        </b-container>
        <!-- END container -->

        <b-modal
            id="modalEditAA"
            title="Aggiungi Anni Accademici"
            no-fade
            header-bg-variant="dark"
            header-text-variant="white"
            @close="InitAnniAccademici">
            <b-form-group label="Anno Accademico">
                <b-row class="row-space-10 mt-2" v-for="aa in anniAccademici" :key="aa.id">
                    <b-col>
                        <b-row>
                            <b-col cols="4">
                                {{ aa }}
                                <b-form-input size="sm" class="form-control" :value="aa.anno_accademico"/>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="5">
                                <small>Data Inizio Primo Semestre</small>
                                <input size="sm" class="form-control" :value="aa.data_inizio_primo_semestre"/>
                                <small>Data Fine Primo Semestre</small>
                                <input size="sm" class="form-control" :value="aa.data_fine_primo_semestre"/>
                            </b-col>
                            <b-col cols="5">
                                    <small>Data Inizio Secondo Semestre</small>
                                    <input class="form-control" placeholder="Last" :value="aa.data_inizio_secondo_semestre" />
                                    <small>Data Fine Secondo Semestre</small>
                                    <input class="form-control" placeholder="Last" :value="aa.data_fine_secondo_semestre" />
                            </b-col>
                            <b-col cols="2">
                                <b-button type="submit" class="ml-2" style="color: #39b15b" @click="modificaAA(row)"><i class="fa fa-save"></i></b-button>
                                <b-button  type="submit" class="ml-2 mt-2" style="color: #e00c3e" @click="cancellaAA(aa.id)"><i class="fa fa-trash"></i></b-button>
                            </b-col>
                        </b-row>
                    </b-col>
                    <hr>
                </b-row>
            </b-form-group>
            <b-form-group label="Anni Accademici">
                <div>
                    <b-link href="#" @click="aggiungiAnniAccademici"><i class="fa fa-plus fa-fw" ></i> Aggiungi Anni Accademici</b-link>
                    <b-form-group label="Add Anno Accademico" v-if="!addAA">
                            <b-row class="row-space-10 mt-2">
                                <b-col>
                                    <b-row>
                                        <b-col cols="4">
                                            <input size="sm" class="form-control" placeholder="2020/2021"/>
                                        </b-col>
                                    </b-row>
                                    <b-row>
                                        <b-col cols="6">
                                            <small>Data Inizio Primo Semestre</small>
                                            <input size="sm" class="form-control" placeholder="inserisci una data" />
                                            <small>Data Fine Primo Semestre</small>
                                            <input size="sm" class="form-control" placeholder="inserisci una data"/>
                                        </b-col>
                                        <b-col cols="6">
                                            <small>Data Inizio Secondo Semestre</small>
                                            <input class="form-control" placeholder="inserisci una data"/>
                                            <small>Data Fine Secondo Semestre</small>
                                            <input class="form-control" placeholder="inserisci una data" />
                                        </b-col>
                                    </b-row>
                                    <b-button variant="success" size="sm" class="mt-2" @click="inserisciAA()">Inserisci</b-button>
                                </b-col>
                            </b-row>
                    </b-form-group>
                </div>
            </b-form-group>
            <b-alert class="mt-2" :show="showAlert" dismissible variant="success">Anno Inserito con successo!</b-alert>
            <template v-slot:modal-footer="{ ok, cancel }">
                <b-button variant="default" @click="cancel()">Close</b-button>
            </template>
        </b-modal>
        <b-modal
            id="modalEditAtenei"
            title="Aggiungi Atenei"
            no-fade
            no-stacking
            header-bg-variant="dark"
            header-text-variant="white"
            footer-bg-variant="dark"
            ok-variant="primary"
            ok-title="Inserisci"
            cancel-title="Annulla"
            cancel-variant="danger"
            @ok="addAteneo">
            <add-atenei id="add-ateneo" ref="add-ateneo" :addAteneo="ateneoSubmit" @success="alertSuccess"></add-atenei>
        </b-modal>
        <b-modal
            id="modalLingue"
            no-fade
            no-stacking
            header-bg-variant="dark"
            header-text-variant="white"
            footer-bg-variant="dark"
            ok-variant="primary"
            ok-title="Inserisci"
            cancel-title="Annulla"
            cancel-variant="danger"
            @ok="addLingue">
            <label id="lingua">Lingua:</label>
            <b-form-input id="lingua" v-model="lingua"></b-form-input>
        </b-modal>
        <b-modal
            id="modalTipologia"
            no-fade
            no-stacking
            header-bg-variant="dark"
            header-text-variant="white"
            footer-bg-variant="dark"
            ok-variant="primary"
            ok-title="Inserisci"
            cancel-title="Annulla"
            cancel-variant="danger"
            @ok="addTipologiaIstituto">
            <label id="tipologia">Tipologia:</label>
            <b-form-input id="tipologia" v-model="tipologia_istituto"></b-form-input>
        </b-modal>
    </div>
    <!-- END #content -->
</template>

<script>
import SettingService from '@/services/universita/settings.service'
import addAtenei from "@/components/universita/addAtenei";

export default {
    name: 'settings',
    components: {addAtenei,},
    data(){
        return {
            // Logica Anno Accademico
            anniAccademici: {},
            addAA: true,
            formAA: {
                id:'',
                anno_accademico:'',
                data_inizio_primo_semestre:null,
                data_fine_primo_semestre:null,
                data_inizio_secondo_semestre:null,
                data_fine_secondo_semestre:null,
            },
            showAlert: false,
            // Lingue Istituto
            lingua:"",
            lingue: [],
            atenei: [],
            tipologia_istituto: "",
            tipologie_istituti: [],
            ateneoSubmit: false,
            variant:'',
            uploadFile:''
        }
    },
    mounted() {
        this.InitAnniAccademici()
        this.InitLingueIstituto()
        this.InitAtenei()
        this.initTipologieIstituto()
    },
    methods: {
        // ALERT
        alertSuccess() {
            this.$refs['add-ateneo'].hide()
            this.$bvToast.toast('Ateneo aggiunto con successo', {
                title: 'Ateneo Inserito con Successo',
                variant: 'success',
                solid: true
            })
        },
        alertDelete() {
            this.$refs['add-ateneo'].hide()
            this.$bvToast.toast('Ateneo aggiunto con successo', {
                title: 'Ateneo Inserito con Successo',
                variant: 'success',
                solid: true
            })
        },
        aggiungiAnniAccademici(){
            this.addAA = !this.addAA
        },
        inserisciAA(){
            SettingService.postAnnoAccademico(this.formAA)
                .then(resp => {
                    this.showAlert = true
                    console.log(resp.status)
                })
                .catch()

        },
        cancellaAA(id){
          console.log(id)
            SettingService.deleteAnnoAccademico(id)
                .then(() => {
                    this.$bvToast.toast('Anno Accademico Eliminato', {
                        title: 'Anno Accademico Eliminato',
                        variant: 'danger',
                        solid: true
                    })
                    this.InitAnniAccademici()
                })
        },
        modificaAA(row){
            console.log('Passo questo', row)
            const id = row.item.id
            SettingService.patchAnnoAccademico(id, this.formAA)
                .then(resp => {
                    this.showAlert = true
                    console.log(resp.status)
                })
                .catch()

        },
        InitAnniAccademici(){
            SettingService.getAnniAccademici()
                .then(resp => { this.anniAccademici = resp.data })
                .catch()
        },
        scrollIntoView(evt) {
            evt.preventDefault()
            const href = evt.target.getAttribute('href')
            const el = href ? document.querySelector(href) : null
            if (el) {
                window.scrollTo(0, el.offsetTop);
            }
        },
        // Lingue Istituto
        InitLingueIstituto() {
            console.log('Lingue Istituto')
            SettingService.getLingue()
                .then(resp => {
                    this.lingue = resp.data
                })
                .catch()
        },
        addLingue() {
            const payload= {
                "lingua": this.lingua
            }
            SettingService.addLingue(payload)
                .then(
                    console.log("lingua inserita")
                )
                .catch()
            console.log('aggiungi lingue')
        },
        InitAtenei() {
            SettingService.getAtenei()
                .then(resp => {
                    this.atenei = resp.data
                })
                .catch()
        },
        addAteneo(){
            this.ateneoSubmit = true
        },
        initTipologieIstituto() {
            SettingService.getTipologieIstituto()
                .then(resp => {
                    this.tipologie_istituti = resp.data
                })
                .catch()
        },
        addTipologiaIstituto() {
            const payload= {
                "tipologia": this.tipologia_istituto
            }
            SettingService.addTipologiaIstituto(payload)
                .then(
                    this.initTipologieIstituto()
                )
                .catch()
            console.log('aggiungi lingue')
        },
    }
}
</script>
