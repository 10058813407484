<template>
  <!-- BEGIN #content -->
  <div id="content" class="app-content">
    <small class="text-muted">nomePagina: src/pages/crm/ListaIstituti.vue</small>
    <b-alert
      id="alertEditSuccess-"
      variant="success"
      dismissible
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
      :show="istitutoModificato"
    >Operazione avvenuta con Successo
    </b-alert>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="overlay"
          type="rounded"
          variant="dark"
          blur="blur"
          rounded="sm">
          <template #overlay>
            <div class="text-center">
              <b-icon style="color: #FFFFFF" icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
              <p id="cancel-label" style="color: #FFFFFF">Please wait...</p>
            </div>
          </template>
          <b-card bg-variant="dark">
            <b-row>
              <b-col cols="3">
                <h3 style="color: #f9f9fa">
                  <i class="fas fa-university"></i> Lista Istituti: {{ istitutiTotali }}
                </h3>
              </b-col>
              <b-col cols="5">
                <b-input-group size="default">
                  <b-form-input
                    v-model="searchTerm"
                    type="search"
                    id="filterInput"
                    placeholder="Digita per Ricercare"
                    @keydown.enter.native="searchIstituto"
                    size="sm"
                  ></b-form-input>
                  <b-input-group-append>
                    <b-button @click="searchIstituto" size="sm">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="4">
                <b-btn class="float-right ml-2" variant="primary" size="sm" @click="scegliColonne">Colonne</b-btn>
                <b-btn class="float-right ml-2" variant="danger" size="sm" v-b-modal.filtra>Filtra</b-btn>
                <b-btn class="float-right ml-2" variant="giallo" size="sm" @click="esportaIstituti">Esporta Istituti</b-btn>
                <b-btn class="float-right ml-2" variant="success" size="sm" v-b-toggle.aggiungi-istituto>Aggiungi Istituto</b-btn>
              </b-col>
            </b-row>
          </b-card>
          <b-card class="mt-2">
            <b-table
              head-variant="dark"
              class="font-weight-light"
              id="istituti"
              :items="istituti"
              :fields="fields"
              hover
              small
              striped
              :current-page="currentPage"
              responsive="sm"
              @row-dblclicked="editIstituto"
            >
              <template v-slot:cell(nome_tipologia)="row">
                <b-badge >{{ row.item.nome_tipologia.length}}</b-badge>
              </template>
              <template v-slot:cell(codice_istituto)="row">
                <b-link @click="passaDati(row)" v-b-toggle.edit-istituto style="color: #3A4051; font-style: normal" v-b-tooltip.hover="'Modifica Istituto'"><b>{{row.item.codice_istituto}}</b></b-link>
              </template>
              <template v-slot:cell(nome_istituto)="row">
                <b-link @click="passaDati(row)" v-b-toggle.edit-istituto style="color: #3A4051; font-style: normal" v-b-tooltip.hover="'Modifica Istituto'"><b>{{row.item.nome_istituto}}</b></b-link>
                <br>
                <small class="text-muted">{{row.item.regione}}</small> -
                <small class="text-muted">({{row.item.provincia}})</small> -
                <small class="text-muted">{{row.item.indirizzo1}}</small> -
                <small class="text-muted">{{row.item.citta}}</small>
              </template>
              <template v-slot:cell(importanza)="row" class="text-left">
                <b-form-rating
                  class="ratings"
                  :options="row.item.importanza"
                  size="sm"
                  no-border
                  color="unicollege"
                  v-model="row.item.importanza"
                  @change="saveImportanza(row.item)"
                >
                </b-form-rating>
              </template>
              <template v-slot:cell(leads)="row">
                <b-link @click="initLead(row.item.id)" ><h5><b-badge class="font-weight-light" :variant="parseInt(row.item.leads) >= 1 ? 'success':'secondary'" size="xl">{{ row.item.leads }}</b-badge></h5></b-link>
              </template>
              <template v-slot:cell(contatti)="row">
                <b-link @click="editIstituto(row.item)"><h5><b-badge class="font-weight-light" :variant="parseInt(row.item.contatti) >= 1 ? 'success':'secondary'">{{ row.item.contatti}}</b-badge></h5></b-link>
              </template>
              <template v-slot:cell(eventi)="row">
                <b-link @click="editIstituto(row.item)"><h5><b-badge class="font-weight-light" :variant="parseInt(row.item.eventi) >= 1 ? 'success':'secondary'">{{ row.item.eventi}}</b-badge></h5></b-link>
              </template>
              <template v-slot:cell(nome_tipologia)="row">
                <div v-for="(tipo, index) in row.item.nome_tipologia" :key="index" class="d-lg-inline-flex">
                  <h6 class="text-capitalize ml-2"><b-badge class="fontBadge" variant="dark"> {{ tipo.tipologia }} </b-badge></h6>
                </div>
              </template>
              <template v-slot:cell(action)="row">
                <b-dropdown dropleft size="sm"  variant="dark" align="center" toggle-class="text-decoration-none #ff9900" no-caret >
                  <template #button-content>
                    <i class="fas fa-ellipsis-v"></i>
                  </template>
                  <!--                                    <b-link href="#" style="color: #30bdfe" v-b-toggle.edit-istituto @click="passaDati(row)" v-b-tooltip.hover="'Modifica Istituto'"><i class="fas fa-edit"></i></b-link>-->
                  <b-dropdown-item @click="openDetail(row.item)" v-b-modal.dettaglio-contatti v-b-tooltip.left="`Numero Contatti ${row.item.contatti}`">
                    <i class="fas fa-id-card-alt mr-2"></i>Contatti
                    <b-badge v-if="row.item.contatti > 0">{{ row.item.contatti}}</b-badge>
                  </b-dropdown-item>
                  <hr>
                  <b-dropdown-item @click="eventiListIstituto(row.item)" v-b-tooltip.left="`Numero di eventi con Istituto ${row.item.eventi}`">
                    <i class="fa fa-tasks mr-2"></i>Eventi con Istituto
                    <b-badge v-if="row.item.eventi > 0">{{ row.item.eventi}}</b-badge>
                  </b-dropdown-item>
                  <b-dropdown-item @click="inviaEventoIstituto(row.item)" v-b-tooltip.left="`Crea Evento con l'istituto`"><i class="fas fa-reply-all mr-2"></i>Crea Evento con Istituto</b-dropdown-item>
                  <hr>
                  <b-dropdown-item @click="initLead(row.item.id)" v-b-modal.lista-studenti v-b-tooltip.left="`Studenti presenti ${row.item.leads}`">
                    <i class="fas fa-user-graduate mr-2"></i>Studenti Presenti
                    <b-badge v-if="row.item.leads > 0">{{ row.item.leads}}</b-badge>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteIstituto(row.item)" v-b-tooltip.left="'Elimina Istituto'"><i class="fas fa-trash mr-2"></i> Elimina</b-dropdown-item>
                  <!--                                    <b-dropdown-item><i class="fas fa-hands-helping"></i></b-dropdown-item>-->
                  <!--                                    <b-link class="ml-2" href="#" style="color: #092152" @click="initLead()">| Associa Scuola</b-link>-->
                </b-dropdown>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="istitutiTotali"
              :per-page="perPage"
              aria-controls="istituti"
              size="sm"
            ></b-pagination>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row> <!--  Tabella Istituto-->
    <!-- Inizio Modali -->
    <b-modal size="xl" id="lista-studenti" ref="lista-studenti" title="Lista Studenti" hide-footer hide-header>
      <lista-leads-associati :id="istituto_id"></lista-leads-associati>
    </b-modal>

    <lista-contatti
      :dirigenti="dirigenti"
      :eventi-list="eventiList"
      :generico="generico"
      :invia-evento="inviaEvento"
      :istituto-detail="istitutoDetail"
      :istituto-show="istitutoShow"
      :open-time="openTime"
      :professori="professori" :referente="referente"/>

    <b-modal
      id="ModaleListaEventiIstituto"
      ref="ModaleListaEventiIstituto"
      title="Lista Eventi"
      size="xl"
      scrollable
      header-bg-variant="dark"
      header-text-variant="white" >
      <table-evento-istituto :istitutoId="this.evento.istituto"></table-evento-istituto>
    </b-modal>

    <b-modal size="lg" id="scuole" title="Cerca Scuola" hide-footer header-bg-variant="dark"
             header-text-variant="white">
    </b-modal>

    <b-modal id="modal-timeline" ref="modal-timeline" title="Timeline Eventi" scrollable size="lg" hide-footer header-bg-variant="dark"
             header-text-variant="white">
      <template #modal-title>
        TimeLine Eventi Contatto {{ evento.cognome }} dell'istituto {{ evento.nome_istituto }}
      </template>
      <timeline :id_contatto="evento.id_contatto" :cognome="evento.cognome" />
      <small class="text-muted float-left mx-4">Nome Componente: src/pages/crm/istituto/listaIstituti.vue</small>
    </b-modal>

    <b-modal id="filtra" header-bg-variant="dark" header-text-variant="white" title="Filtra Istituti per:">
      <b-card no-body>
        <b-form-select
          size="sm"
          v-model="regioneSelezionata"
          :options="regioniChoices"
          value-field="value"
          text-field="text"
        >
          <template #first>
            <option value="">Tutte le Regioni</option>
          </template>
        </b-form-select >
        <b-form-select
          size="sm"
          v-model="provinciaSelezionata"
          :options="provinceChoices"
          value-field="value"
          text-field="text"
        >
          <template #first>
            <option value="">Tutte le Province</option>
          </template>
        </b-form-select>
      </b-card>
    </b-modal>

    <!-- Add & Edit Istituto -->
    <sidebar-add-istituto id="aggiungi-istituto"  @show_alert="showAlertNewInsert" />
    <sidebar-edit-istituto v-if="dati" :dati="dati" @alertEditSuccess="showAlert" @hidden="dati = null"/>
    <export-modal :fields="this.fields" @choiceField=cambiaCampi></export-modal>
    <!-- Inizio Sidebars -->
  </div>
  <!-- END #content -->
</template>

<script>
import timeline from "@/components/crm/timeline";
import SidebarAddIstituto from "@/components/crm/istituto/SidebarAddIstituto";
import SidebarEditIstituto from "@/components/crm/istituto/SidebarEditIstituto";
import IstitutiService from '@/services/istituto.service';
import TableEventoIstituto from "@/components/crm/evento/istituto/TableEventoIstituto";
import listaLeadsAssociati from '@/components/crm/listaLeadsAssociati'
import FileSaver from 'file-saver'
import exportModal from '@/components/exportExcel/export'
import axios from 'axios'
import listaContatti from "../listaContatti";

export default {
  components: {
    listaContatti,
    TableEventoIstituto,
    SidebarAddIstituto,
    SidebarEditIstituto,
    timeline,
    listaLeadsAssociati,
    exportModal,
  },
  data() {
    return {
      dati: null,
      delete: '',
      detail: false,
      detailStudenti: false,
      dirigenti: [],
      dismissCountDown: 0,
      istitutoModificato: false,
      dismissSecs: 3,
      editContact: {},
      evento: {
        id_contatto: null,
        tipo: '',
        cognome: '',
        istituto: '',
        nome_istituto: '',
      },
      fields: [
        // {key: 'id', label: 'ID', sortable: true, class: "text-center"},
        {key: 'codice_istituto', label: 'Cod. Scuola', sortable: false, thClass: 'font-weight-light'},
        {key: 'nome_istituto', label: 'Nome Ist.', sortable: true, thClass: 'font-weight-light'},
        // {key: 'regione', label: 'Regione', sortable: true},
        // {key: 'provincia', label: 'Provincia', sortable: true},
        // {key: 'citta', label: 'Città', sortable: true},
        {key: 'telefono1', label: 'Telefono', sortable: false, thClass: 'font-weight-light'},
        {key: 'nome_tipologia', label: 'Tipologia', sortable: false, thClass: 'font-weight-light'},
        {key: 'email1', label: 'Email', sortable: false, class: "text-left", thClass: 'font-weight-light'},
        {key: 'numero_iscritti', label: 'Iscritti', sortable: false, class: "text-center", thClass: 'font-weight-light'},
        {key: 'importanza', label: 'Importanza', sortable: true, class: "text-center", thClass: 'font-weight-light'},
        {key: 'leads', label: 'Leads', sortable: true, class: "text-center", thClass: 'font-weight-light'},
        {key: 'eventi', label: 'Eventi', sortable: true, class: "text-center", thClass: 'font-weight-light'},
        {key: 'contatti', label: 'Contatti', sortable: true, class: "text-center", thClass: 'font-weight-light'},
        {key: 'action', label: 'Actions', sortable: false, class: 'text-center', thClass: 'font-weight-light'},
      ],
      generico: [],
      istituto_id: 0,
      istituto_nome: '',
      istituti: [],
      istitutoDetail: '',
      istitutoDetailID: null,
      loaded: false,
      overlay: true,
      professori: [],
      referente: '',
      studentiTotal: 0,
      studentiShow: false,
      istitutoShow: false,
      showData: true,
      // Paginazione
      perPage: 50,
      currentPage: 1,
      istitutiTotali: '',
      currentModal: null,
      regioniChoices: [],
      regioneSelezionata: '',
      provinceChoices: [],
      provinciaSelezionata: '',
      // Ricerca
      searchTerm: '',

    }
  },
  computed: {
    rows() {
      return this.istituti.length
    },
    headerDirigenti() {
      if (this.dirigenti.length >= 1)
        return 'Numero dirigenti: ' + this.dirigenti.length
      else return 'Non sono stati inseriti Dirigenti'
    },
    headerProfessori() {
      if (this.professori.length >= 1)
        return 'Numero professori: ' + this.professori.length
      else return 'Non sono stati inseriti Professori'
    },
    headerReferenti() {
      if (this.referente.length >= 1)
        return 'Numero referenti: ' + this.referente.length
      else return 'Non sono stati inseriti Referenti'
    },
    newColumns() {
      return ''
    }
  },
  mounted() {
    this.initIstituti()
    this.loaded = true
    this.InitRegioniChoices()
    this.InitProvinceChoices()
  },
  methods: {
    cambiaCampi(campi) {
      this.istituti = campi
    },
    apriNuovoEvento(istituto) {
      this.evento.nome_istituto = istituto
      this.$refs["invia-evento"].show()
    },
    editIstituto(item) {
      this.dati = item
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', 'edit-istituto')
      })
    },
    expandAdditionalInfo(row) {
      row._showDetails = !row._showDetails;
    },
    onOver() {
      this.$refs.dropdown.visible = false;
    },
    onLeave() {
      this.$refs.dropdown.visible = false;
    },
    saveImportanza(row) {
      const payload = {
        'id': row.id,
        'nome_istituto': row.nome_istituto,
        'importanza': row.importanza
      }
      IstitutiService.setImportanza(payload)
        .then(() => {
          console.log('ok')
        })
        .catch(() => {
          console.log('error')
        })
    },
    // addContatto() {
    //     this.$refs['dettaglio-contatti'].hide()
    // },
    closedWindowIstituto() {
      this.istitutoShow = !this.istitutoShow
    },
    closedWindowStudenti() {
      this.studentiShow = !this.studentiShow
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.initIstituti()
      this.$bvToast.toast(`Item modificato!`, {
        title: 'Modificato/Inserito con successo',
        solid: true,
        variant: 'success',
        autoHideDelay: 5000,
        appendToast: true
      })
    },
    showAlertNewInsert() {
      this.initIstituti()
      this.$bvToast.toast(`Istituto inserito!`, {
        title: 'Istituto inserito con successo',
        solid: true,
        variant: 'success',
        autoHideDelay: 3000,
        appendToast: true
      })
    },
    initIstituti() {
      const params = {
        regione: this.regioneSelezionata,
        provincia: this.provinciaSelezionata
      }
      IstitutiService.getIstituti(params)
        .then(resp => {
          this.istituti = resp.data.results
          this.istitutiTotali = resp.data.total
          this.overlay = false
        })
        .catch(err => {
          console.log(err.status)
        })
    },
    InitRegioniChoices() {
      IstitutiService.getRegioniChoices()
        .then(resp => {
          this.regioniChoices = resp.data
        })
    },
    InitProvinceChoices() {
      IstitutiService.getProvinceChoices(this.regioneSelezionata)
        .then(resp => {
          this.provinceChoices = resp.data
        })
    },
    openDetail(item) {
      this.istitutoDetail = item
      this.istitutoShow = true
      IstitutiService.getFilterContatti(item)
        .then(axios.spread((...responses) => {
          this.dirigenti = responses[0].data
          this.professori = responses[1].data
          this.referente = responses[2].data
          this.generico = responses[3].data
        }))
        .catch(err => {
          console.log(err)
        })
    },
    inviaEventoIstituto(row) {
      this.evento.istituto = row.id
      this.evento.nome_istituto = row.nome_istituto
      this.evento.id_contatto = null
      this.evento.cognome = ''
      this.$refs["invia-evento"].show();
    },
    inviaEvento(p) {
      this.evento.id_contatto = p.id
      this.evento.tipo = p.tipo
      this.evento.cognome = p.cognome
      this.evento.istituto = p.istituto
      this.$refs["invia-evento"].show();

    },
    eventiListIstituto(item) {
      // this.evento.id_contatto = null
      // this.evento.cognome = ''
      this.evento.istituto = item.id
      // this.evento.nome_istituto = item.nome_istituto
      this.$bvModal.show('ModaleListaEventiIstituto')
    },
    openTime(p) {
      this.evento.id_contatto = p.id
      this.evento.cognome = p.cognome
      this.evento.istituto = null
      this.evento.nome_istituto = p.nome_istituto
      this.$refs['modal-timeline'].show()
    },
    eventiList(p) {
      this.evento.id_contatto = p.id
      this.evento.cognome = p.cognome
      this.evento.istituto = null
      this.$bvModal.show('ModaleListaEventiIstituto')
    },
    viewTimeline(p) {
      this.evento.id_contatto = p.id
      this.evento.cognome = p.cognome
    },
    deleteIstituto(item) {
      this.delete = item.id
      this.$bvModal.msgBoxConfirm('Sei sicuro di voler cancellare questo istituto?', {
          title: 'Conferma Eliminazione',
          size: 'default',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Si!',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
          headerBgVariant: 'dark',
          headerTextVariant: 'white'
        })
        .then(value => {
          if (value) {
            IstitutiService.deleteIstituto(item.id)
              .then(() => {
                this.initIstituti()
                this.showAlert()
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            console.log('ok non cancello')
          }
        })
        .catch(err => {
          alert(err)
        })
    },
    passaDati(row) {
      this.dati = row.item
    },
    initLead(id) {
      this.istituto_id = id
      this.$bvModal.show('lista-studenti')
    },
    // editContatto(p) {
    //     this.editContact = p
    //     //this.$refs['dettaglio-contatti'].hide()
    //     this.currentModal = this.$refs['dettaglio-contatti']
    //     // this.currentModal.hide()
    // },
    searchIstituto() {
      const params = {
        search: this.searchTerm
      }
      IstitutiService.searchIstituti(params)
        .then(resp => {
          this.istituti = resp.data.results
          this.istitutiTotali = resp.data.total
          this.overlay = false
        })
        .catch()
      console.log("Termine di ricerca:", this.searchTerm)
    },
    scegliColonne() {
      this.$bvModal.show('exportModal')
    },
    esportaIstituti() {
      this.loadingSpinnerExportExcel = true
      IstitutiService.getExportIstituti()
        .then(response => {
          FileSaver.saveAs(response.data, 'istituti.xlsx')
          this.loadingSpinnerExportExcel = false
        })
        .catch()
    },
  },
  watch: {
    regioneSelezionata() {
      this.InitProvinceChoices()
      this.initIstituti()
    },
    provinciaSelezionata() {
      this.initIstituti()
    },
    currentPage: function (newVal) {
      this.overlay = true
      IstitutiService.getPageIstituti(newVal)
        .then(resp => {
          this.istituti = resp.data.results
          this.istitutiTotali = resp.data.total
          this.overlay = false
        })
    },
    searchTerm(newVal){
      if (newVal.length >= 4) {
        console.log(newVal)
        this.searchIstituto()
      }
    }
  }
}
</script>
<style scoped>
main {
  width: 150px;
}
h1 {
  margin: 0;
  display: inline-block;
}
button {

}
.ratings {
  background-color: transparent;
  color: orangered;
}
.tabs {
  color: #52713c;
}

</style>
