<template>
    <b-modal
        header-bg-variant="dark"
        header-text-variant="white"
        id="exportModal"
        title="Export in Excel"
        size="xl"
        @ok="exportToExcel"

    >
        Scegli i campi da esportare:
        <hr>
        <b-form-group>
            <b-form-checkbox-group v-model="fieldsChecked" :options="fields" value-field="key" text-field="label" >
            </b-form-checkbox-group>
        </b-form-group>
        {{ this.fieldsChecked }}
    </b-modal>
</template>

<script>
import IstitutoService from "@/services/istituto.service";
export default {
    name: "export-modal",
    props: {
        fields: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            fieldsChecked: []
        }
    },
    methods: {
        exportToExcel() {
            IstitutoService.choiceFieldIstituti(this.fieldsChecked)
                .then(resp => {
                    this.$emit('choiceField', resp.data.results[0])
                    console.log(resp.data)
                })
            console.log(this.fieldsChecked)
        }
    }
}
</script>

<style scoped>

</style>
