<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <b-overlay :show="overlay" type="grow"
                   variant="dark"
                   blur="blur"
                   rounded="sm">
            <template #overlay>
                <div class="text-center">
                    <b-icon style="color: #FFFFFF" icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                    <p id="cancel-label" style="color: #FFFFFF">Please wait...</p>
                </div>
            </template>
        <b-row>
            <b-col cols="12">
                    <b-card bg-variant="dark">
                        <b-row>
                            <b-col cols="2">
                                <div class="main">
                                    <h3 style="color: #f9f9fa">
                                        <i class="fas fa-user-graduate"></i>
                                        Studenti: {{ studentiTotali }}
                                    </h3>
                                </div>
                            </b-col>
                            <b-col cols="7">
                                <b-input-group class="mb-2">
                                    <b-form-input
                                        v-model="searchTerm"
                                        type="search"
                                        id="filterInput"
                                        placeholder="Digita per Ricercare"
                                        @keydown.enter.native="searchAndfilterStudent"
                                        size="sm"
                                    ></b-form-input>
                                    <b-input-group-append>
                                        <b-button @click="searchAndfilterStudent" size="sm">Search</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                            <b-col cols="3">
                                <b-btn v-b-toggle.collapse-1 class="float-right ml-2" variant="danger" size="sm" v-b-modal.filtra>Filtra</b-btn>
                                <b-btn class="float-right ml-2" variant="giallo" size="sm" v-b-modal.filtra>Esporta</b-btn>
                                <b-btn class="float-right" variant="success" size="sm" @click="aggiungiStudente">Aggiungi Studenti</b-btn>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-collapse id="collapse-1" class="mt-2">
                                    <span style="color: white" class="text-center">Decidere quali saranno i filtri da applicare...</span>
                                </b-collapse>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-card class="mt-2">
                        <b-table
                            :fields="fieldStudenti"
                            :items="studenti"
                            dense
                            hover
                            striped
                            :per-page="0"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                        >
                            <template v-slot:cell(actions)="row">
                                <b-dropdown dropleft size="sm" class="buttonX"  variant="dark" toggle-class="text-decoration-none" no-caret >
                                    <template #button-content>
                                      <i class="fas fa-sort-down"></i>
                                    </template>
                                    <b-dropdown-item @click="editaStudente(row.item)"><i class="fas fa-edit mr-2"></i>Modifica Studente</b-dropdown-item>
                                    <b-dropdown-item @click="eliminaStudente(row.item.id)" variant="danger"><i class="fas fa-trash mr-2"></i>Elimina Studente</b-dropdown-item>
                                </b-dropdown>
                            </template>
                          <template #cell(full_name)="row">
                            <b-link class="text-muted" @click="editaStudente(row.item)">{{ row.item.full_name }}</b-link>
                          </template>
                        </b-table>
                      <b-pagination
                        class="mr-3"
                        size="sm"
                        :total-rows="studentiTotali"
                        :per-page="perPage"
                        v-model="currentPage"
                        next-text="next"
                        prev-text="previous"
                        @change="initStudenti"
                        aria-controls="LeadTable"
                      >
                      </b-pagination>
                    </b-card>
            </b-col>
        </b-row>
        </b-overlay>
        <sidebar-studente
            id="sidebar-studente"
            ref="sidebar-studente"
            :title_modal="this.title"
            :studente="this.studente"
            :transition="this.transition"
            :edit="this.edit"
            @studenteModificato="editaStudenteAlert()"
        ></sidebar-studente>
    </div>
</template>

<script>
import StudentiService from '../../../services/studenti.service.js'
import sidebarStudente from "@/components/studente/sidebarStudente";
import Swal from "sweetalert2";

export default {
    name: "listaStudenti",
    components: {
        sidebarStudente,
    },
    data(){
        return {
          overlay: false,
          studenti:[],
          fieldStudenti:[
              {key:'matricola', text:'Matricola'},
              {key:'pds', text:'Perc. di Studi'},
              {key:'full_name', text:'Studente'},
              {key:'email_istituzionale', text:'Email UNI'},
              {key:'telefono_cellulare', text:'Cellulare'},
              {key:'istituto_scolastico', text:'Ist. di Prov.'},
              {key:'actions', text:'Actions'},
          ],
          // Pagination
          sortBy: 'cognome',
          sortDesc: false,
          studentiTotali: null,
          currentPage: 1,
          page: null,
          pageOptions:[10,25,50],
          perPage: 50,
          // sidebar
          title: null,
          studente: {},
          transition: false,
          searchTerm:'',
          show: false,
          edit: false,
          studenteModificato: false
        }
    },
    mounted() {
        this.initStudenti()
    },
    methods: {
        aggiungiStudente() {
            this.transition = true
            this.title = 'Aggiungi'
            this.edit = false
            this.show = true
            this.$nextTick(() =>{
                // this.$refs["sidebar-studente"].$emit('bv::tooggle::collapse')
                this.$root.$emit('bv::toggle::collapse', 'sidebar-studente')
            })
        },
        initStudenti(pageNumber){
            this.page = pageNumber
            const params = {
              page: pageNumber
            }
            StudentiService.getStudenti(params)
                .then(resp => {
                    this.studenti = resp.data.results
                  this.studentiTotali = resp.data.total
                })
                .catch()
        },
        eliminaStudente(id) {
          Swal.fire({
            title: 'Sei Sicuro?',
            text: "Non potrai riprendere questo elemento una volta cancellato!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, cancellalo!'
          }).then((result) => {
            if (result.isConfirmed) {
              StudentiService.deleteStudente(id)
                .then(resp => {
                  Swal.fire(
                    'Deleted!',
                    `Your file has been deleted. Status: ${resp.status}`,
                    'success'
                  )
                  this.initStudenti(this.page)
                })
            }
          })
        },
        editaStudente(studente) {
            this.studente = studente
            this.edit = true
            this.title = "Modifica"
            this.transition = false
            this.show = true
            this.$nextTick(() => {
                this.$root.$emit('bv::toggle::collapse', 'sidebar-studente')
            })


        },
        editaStudenteAlert(){
            this.$bvToast.toast(`Studente modificato!`, {
                title: 'Studente modificato con successo',
                variant: 'success',
                solid: true,
                autoHideDelay: 5000,
                appendToast: true
            })
            this.studente = {}
            this.initStudenti()
        },
        // Ricerca e Filtri
        searchAndfilterStudent() {
          const params = {
            // 'cognome__icontains': this.searchTerm
            search: this.searchTerm
          }
          StudentiService.getStudenti(params).then(
            resp => {
              this.studenti = resp.data.results
              this.studentiTotali = resp.data.total
            }
          ).catch(() =>{})
            console.log(this.searchTerm)
        }

    }
}
</script>

<style scoped>
.buttonX {
  width: 20px;
  height: 20px;
}
</style>
