
export const bugs = {
    namespaced: true,
    state : {
        numberBugs:0,
        openBugs:0,
        closedBugs:0,
    },
    mutations: {
        updateOpenBug(state, openedBugs){
            state.openBugs = openedBugs
        }
        },
}
