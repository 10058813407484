<template>
    <FullCalendar class="fc" :options='calendarOptions'>
        <template v-slot:eventContent='arg'>
            <b>{{ arg.timeText }}</b>
            <i>{{ arg.event.title }}</i>
        </template>
    </FullCalendar>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import listPlugin from '@fullcalendar/list';
import itLocale from '@fullcalendar/core/locales/it';

export default {
    components: {
        FullCalendar, // make the <FullCalendar> tag available
    },
    props: {
      // [{ field: 'title', headerContent: 'Aule', width:'70%'}]
      resourceAreaColumns: {
          type: Array,
          default: () => {
              return []
          }
      },
      events: {
          type: Array,
          default: () => {
              return []
          }
      },
      resources: {
          type: Array,
          default: () => {
              return []
          }
      },
      start: {
          type: [String, null],
      },
      end: {
        type: [String, null],
      }
    },
    data: function() {
        return {
            calendarOptions: {
                height: 'auto',
                timeZone: 'UTC',
                initialView: 'resourceTimelineWeek',
                schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
                plugins: [
                    dayGridPlugin,
                    timeGridPlugin,
                    resourceTimelinePlugin,
                    listPlugin,
                    interactionPlugin, // needed for dateClick,
                ],
                headerToolbar: {
                    left: 'toggleWeekends, prev,next',
                    center: 'title',
                    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth, listWeek'
                },
                editable: true,
                droppable: true,
                eventResourceEditable: true,
                // navLinks: true,
                selectable: true,
                selectMirror: true,
                dayMaxEvents: true,
                // weekend
                weekends: false,
                customButtons: {
                    toggleWeekends: {
                        text: 'WE',
                        click: this.handleWeekendsToggle
                    },
                },
                // trigger
                select: this.handleDateSelect,
                // eventi
                eventClick: this.handleEventClick,
                eventsSet: this.handleEventsSet,
                eventChange: this.eventDrop,
                eventColor: '#ff9900',
                // orari
                slotMinTime:'09:00:00',
                slotMaxTime:'20:00:00',
                // initialDate: this.start,
                // visibleRange: {
                //     start: this.start,
                //     end: this.end
                // },
                /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
                // businessHours: {
                //     // days of week. an array of zero-based day of week integers (0=Sunday)
                //     daysOfWeek: [ 1, 2, 3, 4, 5 ], // Monday - Thursday
                //
                //     startTime: '09:00', // a start time (10am in this example)
                //     endTime: '18:00', // an end time (6pm in this example)
                // },
                // views: {
                //     resourceTimelineFourDays: {
                //         type: 'resourceTimeline',
                //         duration: {days: 4},
                //     }
                // },
                slotDuration: "00:30",
                locale: itLocale,
                resourceAreaColumns: this.resourceAreaColumns,
                events: [],
                resources: [],
                // resources: [
                //     { id: this.aule.id, title: this.aule.nome, occupancy: this.aule.occupazione , eventColor: 'orange'},
                // ],
            },
        }
    },
    mounted() {
    },
    methods: {
        eventDrop(info) {
            this.$emit('eventDrop', info)
        },
        handleDateSelect(args) {
            this.$emit('dateSelect', args)
        },
        handleEventClick(args) {
            this.$emit('eventClick', args)
        },
        handleEventsSet(args) {
            this.$emit('eventsSet', args)
        },
        eventRender(info) {
            console.log(info)
        },
        handleWeekendsToggle() {
            this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
        },
    },
    watch: {
        events: {
            handler(value) {
                this.calendarOptions.events = value
                console.log('qua arrivano: ', value)
            }
        },
        resources: {
            handler(value) {
                this.calendarOptions.resources = value
                console.log('qua arrivano le resources: ', value)
            }
        },
    }
}
</script>

<style scoped>

h2 {
    margin: 0;
    font-size: 16px;
}

ul {
    margin: 0;
    padding: 0 0 0 1em;
}

li {
    margin: 1.5em 0;
    padding: 0;
}

b { /* used for event dates/times */
    margin-right: 3px;
}

.demo-app-sidebar {
    width: 300px;
    line-height: 1.5;
    background: #1e789b;
    border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
    padding: 0.5em;
}

.demo-app {
    display: flex;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
}

.demo-app-main {
    flex-grow: 1;
    padding: 2em;
}
.fc fc-timeline-header-row {
    background: #3a3f51;
}
table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
</style>
