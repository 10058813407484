<template>
    <b-form ref="form"  @submit.prevent="submit">
        <b-row>
            <label for="aula">Aula</label>
            <b-form-input id="aula" :value="this.event.resource.title" disabled></b-form-input>
        </b-row>
        <b-row>
            <b-col>
                <label for="occupazione">Occupazione</label>
                <b-form-input id="ocucpazione" :value="this.event.resource.extendedProps.occupancy" disabled></b-form-input>
                <label for="Inizio">Inizio</label>
                <b-form-timepicker show-seconds :value="startTime"></b-form-timepicker>
            </b-col>
            <b-col>
                <b-row ></b-row>
                <label for="occupazioneSARS">Occupazione Covid</label>
                <b-form-input id="ocucpazioneSARS" :value="this.event.resource.extendedProps.occupazione_covid" disabled></b-form-input>
                <label for="Inizio">Fine</label>
                <b-form-timepicker show-seconds :value="endTime"></b-form-timepicker>
            </b-col>
        </b-row>
        <b-row>
            <label for="materia">Corsi</label>
            <b-form-select v-model="form.corso" :options="corsi_" value-field="id" text-field="nome"></b-form-select>
            <label for="titolo">Titolo Appello</label><b-link class="ml-2 small" @click="copiaCorso()">Copia da Corso</b-link>
            <b-form-input v-model="form.title"></b-form-input>
            <label for="colore">Colore Appello</label>
            <!--            <b-form-input v-model="form.colore" type='color' id='colorPicker'></b-form-input>-->
            <b-form-input type="color" :value="color" v-model="form.colore"></b-form-input>
        </b-row>
        <b-button  class="mt-3 float-right" type="submit" variant="dark">Inserisci Appello</b-button>
    </b-form>
</template>

<script>
import moment from "moment";
import Schedule_classroomService from "../../services/schedule_classroom/schedule_classroom.service";
export default {
    name: "prenotazioneAula",
    props: {
        event: {
            type: Object,
            required: true
        },
        sessione: {
            type: Number,
            required: true
        },
        corsi: {
            type: Array,
            required: true
        }
    },
    data() {
        return{
            prenotazione:this.event,
            form: {
                resourceId:this.event.resource.id,
                sessione: this.sessione,
                corso: '',
                title: '',
                start: this.event.start,
                end: this.event.end,
                colore: ''
            },
            corsi_:this.corsi,
            color: [
                'red',
                'orange',
                'dark'
            ]
        }
    },
    computed: {
        startTime() {
            return moment.utc(this.event.start).format('HH:mm:ss')
        },
        endTime() {
            return moment.utc(this.event.end).format('HH:mm:ss')
        }
    },
    mounted() {
        this.initMaterie()
    },
    methods: {
        copiaCorso() {
            const nome_corso = this.corsi_.filter(item => item.id === this.form.corso)
            this.form.title = nome_corso[0].nome
            return this.form.title
        },
        initMaterie() {
            Schedule_classroomService.getMaterie()
                .then(resp => {
                    this.materie = resp.data
                })
        },
        submit() {
            Schedule_classroomService.postAppelli(this.form)
                .then(resp => {
                    this.$emit('refreshEvent')
                    this.$bvModal.hide('prenotazione')
                    console.log(resp)
                })
                .catch()
        }
    }
}
</script>

<style scoped>

</style>
