<template>
    <!-- BEGIN #content -->
    <div id="content" class="app-content">
        <b-breadcrumb>
            <b-breadcrumb-item href="#">BREADCRUMB</b-breadcrumb-item>
            <b-breadcrumb-item active>SCUOLE</b-breadcrumb-item>
        </b-breadcrumb>
        <h1 class="page-header">
            DB Ministeriale Istituti , totale: {{ this.total }}
        </h1>
        <b-row>
            <b-col>
                <b-card>
                    <b-card-header header-bg-variant="white">
                        <b-button style="margin:0 0 20px 20px;" @click="handleDownload">
                        Export Excel
                    </b-button>
                    </b-card-header>

                    <b-table
                        id="table-scuole"
                        class="table"
                        :items="scuole"
                        :fields="fields"
                        small
                        hover
                        striped
                        responsive="sm"
                        search
                    >
                    </b-table>
                    <div class="overflow-auto">
                        <b-pagination
                            aria-controls="table-scuole"
                            size="md"
                            :total-rows="total"
                            :page="this.page"
                            :per-page="per_page"
                            v-model="currentPage"
                        >
                        </b-pagination>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
    <!-- END #content -->
</template>

<script>
import ScuoleService from '@/services/scuole.service'


export default {
    name: "scuole",
    components: {

    },
    data() {
        return {
            scuole:[],
            fields: [
                {key:'regione',label: "Regione"},
                {key:'provincia',label: "Provincia"},
                {key:'codice_scuola',label: "Cod."},
                {key:'denominazione_scuola',label: "Denominazione"},
                {key:'indirizzo_scuola',label: "Indirizzo"},
                {key:'indirizzo_email_scuola',label: "Stato"},
            ],
            pagination:[],
            total: null,
            page: this.pages,
            currentPage: 1,
            per_page: 20,
        }
    },
    computed:{
        pages(){
            let pages = this.total / this.pagination.per_page
            return pages
        }
    },
    mounted() {
        this.InitScuole()
    },
    methods: {
        InitScuole() {
            ScuoleService.getScuole()
                .then(resp => {
                    this.scuole = resp.data.data
                    this.pagination = resp.data.pagination
                    this.total = resp.data.pagination.total
                })
        },
        handleDownload(){
            import('@/components/Export2Excel').then(excel => {
                const tHeader = ['regione', 'provincia','codice_scuola','denominazione_scuola','indirizzo_scuola','indirizzo_email_scuola',]
                const data = this.scuole
                excel.export_json_to_excel({
                    header: tHeader, //Header Required
                    data, //Specific data Required
                    filename: 'excel-list', //Optional
                    autoWidth: true, //Optional
                    bookType: 'xlsx' //Optional
                })
            })
        },
    }
}
</script>

<style scoped>

</style>
