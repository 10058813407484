<template>
    <b-modal
        ref="aggiungiFollowup"
        id="aggiungi-followup"
        size="lg"
        header-bg-variant="dark"
        header-text-variant="white"
        title="Aggiungi FollowUp"
        @ok="salvaFollowUp">
            <b-form>
            <b-card>
                <b-card-title >
                    <small>Follow Up per: <strong class="text-underline">{{ form.full_name }}</strong></small>
                </b-card-title>
                <b-card-body>
                    <b-row>
                        <b-col>
                            <label title="Scegli Orientatore">Scegli Orientatore con cui condivide il FollowUp:</label>
                            <div style="border-radius: 0.3rem">
                                <v-select multiple :options="users" label="email_istituzionale" v-model="form.orientatori" :reduce="user => user.id"></v-select>
                                <small class="card-subtitle text-muted">Non Obbligatorio</small>
                            </div>

                        </b-col>
                        <b-col>
                            <b-form-group label="Data FollowUp">
                                <b-form-datepicker v-model="form.data_follow"></b-form-datepicker>
                                <small class="card-subtitle text-danger">Obbligatorio</small>
                            </b-form-group>

                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <b-form-group label="Azione">
                                <b-form-select v-model="form.oggetto" :options="action" text-field="text" value-field="text"></b-form-select>
                                <small class="card-subtitle text-danger">Obbligatorio</small>
                            </b-form-group>
                            <b-form-group label="Dettagli">
                                <b-form-textarea v-model="form.dettagli"></b-form-textarea>
                                <small class="card-subtitle text-muted">Non Obbligatorio</small>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-body>
              <small class="text-muted">Nome Componente: src/components/crm/followup/lead/ModaleFollowupLead.vue</small>
            </b-card>
        </b-form>
    </b-modal>
</template>

<script>
import EventService from "../../../../services/event.service";
import UserService from "@/services/user.service";
import {mapState} from "vuex";

export default {
    name: "ModaleFollowupLead",
    props: {
        item: Object
    },
    data() {
        return {
            lead: this.item || undefined,
            action: [
                {value:"richiamare", text: 'Richiamare'},
                {value:"mandare_email", text: 'Mandare Email'},
                {value:"fissare_app", text: 'Fissare Appuntamento'},
                {value:"whatsapp", text: 'WhatsApp Out'},
            ],
            form: {
                'orientatori':[],
                'data_follow':'',
                'oggetto':'',
                'dettagli':'',
                'risposta':'',
                'fatto':false,
            },
            users:[]
        }
    },
    mounted() {
        this.initCustomUser()
        this.initForm()
    },
    computed: {
        ...mapState(
            ["auth"]
        )
    },
    methods:{
        initForm() {
            this.form = {
                'orientatori':[],
                'data_follow':'',
                'oggetto':'',
                'dettagli':'',
                'risposta':'',
                'fatto':false,
            }
        },
        initCustomUser(){
            const payload = {
                is_event_manager: true
            }
            UserService.getUser(payload)
                .then(resp => {
                    this.users = resp.data.results
                })
        },
        salvaFollowUp(){
            const payload = {
                lead: this.item.id,
                orientatore: this.$store.state.auth.user.account_id,
                ...this.form
            }
            EventService.postFollowUp(payload)
                .then(resp => {
                    console.log(resp)
                    this.initForm()
                    this.$emit('getFollowUp')
                    this.$emit('refreshLead')
                    this.$bvModal.hide('aggiungi-followup')

                })
                .catch()
            console.log(payload)
        }
    }
}
</script>

<style scoped>

</style>

