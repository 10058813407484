
export const lead = {
    namespaced: true,
    state : {
        lead:{},
        activeLeadFromList: false
    },
    mutations : {
        saveLeadEdited(state, lead) {
            state.lead = lead
        },
        tmpLead(state, leads) {
            console.log('passo nelle mutations', leads)
            state.lead = leads
            state.activeLeadFromList = true

        },
        CLEARLEAD(state) {
            state.lead.lead = {}
        }
    },
    actions : {
        tmpLead({commit}, leads) {
            console.log('passo nello store', leads)
            commit('tmpLead', leads)

        },
        saveLeadEdited(commit, lead) {
            commit('saveLeadEdited', lead)
        },
        clearLead:(commit) => {
            commit('CLEARLEAD')
        }
    },

}
