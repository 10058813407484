<template>
  <div>
    <b-card class="shadow">
    <b-card-header>
      <span class="sub-title">Contatti Istituto</span>
      <b-link class="text-dark ml-2" v-b-tooltip.top title="Ricarica Contatti" @click="getContatti">
        <b-icon icon="arrow-clockwise" :animation='showAnimation ? `spin-pulse` : `false`' font-scale="1"></b-icon>
      </b-link>
      <b-button class="mb-1 ml-lg-5 px-1" size="sm" variant="dark" v-b-modal.add-contatto>Aggiungi</b-button>
    </b-card-header>
    <b-card-body>
      <b-table
        thead-tr-class="font-weight-light"
        :fields="fields"
        :items="contatti"
        @row-selected="showDetails"
        head-variant="dark"
        select-mode="single"
        selectable
        striped
        hover
        small
        sticky-header
      >
        <template #cell(actions)="row">
          <b-link @click="editContatto(row.item)"><b-icon icon="pencil"></b-icon></b-link>
          <b-link class="ml-2" @click="addEvento(row.item)"><b-icon icon="calendar2-plus" variant="success"></b-icon></b-link>
          <b-link class="ml-2" @click="deleteContatto(row.item)"><b-icon icon="trash" variant="red"></b-icon></b-link>

        </template>
    </b-table>
    </b-card-body>
      <small class="text-muted">Nome Componente: src/components/crm/istituto/TableContattiIstituto.vue</small>
  </b-card>
    <modale-edit-contatto-istituto v-if="contatto" :item="contatto"></modale-edit-contatto-istituto>
    <modale-contatto-istituto :istitutoID="this.$store.state.istituto.istituto.id"></modale-contatto-istituto>
  </div>
</template>

<script>
import ModaleEditContattoIstituto from "@/components/crm/istituto/ModaleEditContattoIstituto";
import ModaleContattoIstituto from "@/components/crm/istituto/ModaleContattoIstituto";
import ContattiService from "@/services/contatti.service"
export default {
  name: "TableContatti",
  components: {
    ModaleContattoIstituto,
    ModaleEditContattoIstituto
  },
  props: {
    IdIstituto: Number
  },
  data() {
    return {
      contatti:null,
      fields: [
        {key:'id', label:'ID'},
        {key:'tipo', label:'Tipo'},
        {key:'nome', label:'Nome'},
        {key:'cognome', label:'Cognome'},
        {key:'email_personale', label:'Email Personale'},
        {key:'email', label:'Email Istituto'},
        {key:'cellulare', label:'Cellulare'},
        {key:'sesso', label:'Sesso'},
        {key:'materia', label:'Materia'},
        {key:'note', label:'Note'},
        {key: 'eventi', label:'Eventi'},
        {key: 'actions', label:'Actions'},
      ],
      showAnimation: false,
      contatto: {},
    }
  },
  watch: {
    showAnimation (value) {
     if (value === true) {
       setTimeout(() => {
         this.showAnimation = false
       }, 2000)
     }
    }
  },
  mounted() {
    this.getContatti()
  },
  methods: {
    getContatti(){
      this.showAnimation = true
      let id = this.IdIstituto
      ContattiService.getContatti(id).then(
        resp =>{
          this.contatti = resp.data
        }
      )
    },
    addEvento(item) {
      this.$bvModal.show('ModaleEventoIstituto')
      console.log(item)
    },
    editContatto(item) {
      this.contatto = item
      this.$bvModal.show('edit-contatto')
    },
    deleteContatto(item) {
      this.$bvModal.msgBoxConfirm('Sei sicuro di voler cancellare questo contatto?', {
        title: 'Conferma Eliminazione',
        size: 'default',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Si!',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
        headerBgVariant: 'dark',
        bodyBgVariant: 'white',
        bodyTextVariant: 'red',
        headerTextVariant: 'white'
      })
        .then(value => {
          if (value) {
            ContattiService.deleteContatto(item.id)
              .then(() => {
                this.getContatti()
                this.showAlert()
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            console.log('ok non cancello')
          }
        })
        .catch(err => {
          alert(err)
        })
    },
    showDetails(row) {
      console.log(row)
    },
  }
}
</script>

<style scoped>

</style>
