<template>
  <b-modal
      v-model="showModal"
      ref="edit-contatto"
      id="edit-contatto"
      size="lg"
      header-bg-variant="dark"
      header-text-variant="white"
      title="Modifica Contatto"
      @hide="clearStore"
      @ok="editCo">
    <b-row v-if="showModal">
      <b-col>
        <b-tabs nav-class="nav-tabs-v2" content-class="pt-2" fill>
          <b-tab title="Informazioni di Base" active>
            <b-card bg-variant="gray-050">
              <b-form>
                <b-row>
                  <b-col>
                    <b-form-group
                        id="nome"
                        label="Nome:"
                        label-for="nome">
                      <b-form-input
                          id="nome"
                          v-model="contatto.nome"
                          placeholder="Inserisci il nome"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="cognome" label="Cognome:" label-for="cognome">
                      <b-form-input
                          id="cognome"
                          v-model="contatto.cognome"
                          placeholder="Inserisci il Cognome"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="tipo" label="Tipo:" label-for="tipo">
                      <b-form-select
                          id="tipo"
                          v-model="contatto.tipo"
                          :options="tipo"
                          :text-field="tipo.label"
                          :value-field="tipo.key"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group id="email-personale" label="Email Personale:" label-for="email-personale">
                      <b-form-input
                          id="email-personale"
                          placeholder="Email Personale"
                          v-model="contatto.email_personale"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="email" label="Email:" label-for="email">
                      <b-form-input
                          id="email"
                          placeholder="Email"
                          v-model="contatto.email"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group id="cellulare" label="Cellulare:" label-for="cellulare">
                      <b-form-input
                          id="cellulare"
                          placeholder="Inserisci il cellulare"
                          v-model="contatto.cellulare"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="sesso" label="Sesso:" label-for="sesso">
                      <b-form-select
                          id="sesso"
                          :options="sesso"
                          placeholder="Inserisci il sesso"
                          v-model="contatto.sesso"
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group id="materia" label="Materia:" label-for="materia">
                      <b-form-input
                          id="materia"
                          placeholder="Inserisci la materia"
                          v-model="contatto.materia"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="note" label="Note:" label-for="note">
                      <b-form-textarea
                          id="note"
                          placeholder="Note"
                          rows="6"
                          v-model="contatto.note"
                      ></b-form-textarea>
                    </b-form-group>
                    <!--                            <b-form-input v-model="contatto.avatar"></b-form-input>-->
                  </b-col>
                </b-row>
              </b-form>
            </b-card>
          </b-tab>
          <b-tab title="Informazioni Scolastiche"></b-tab>
          <b-tab title="Informazioni Personali"></b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-modal><!-- Edit Contatto -->
</template>

<script>
import ContattiService from '@/services/contatti.service'
export default {
  name: "ModaleEditContattoIstituto",
  props: {
    item: Object
  },
  data() {
    return {
      tipo: [
        {value:'Dirigente Scolastico',text:'Dirigente'},
        {value:'Referente Scolastico',text:'Referente'},
        {value:'Docente',text:'Docente'},
        {value:'Generico',text:'Generico'},
      ],
      sesso: [
        {value:'Uomo',text:'Uomo'},
        {value:'Donna',text:'Donna'},
      ],
      showModal: false,
      contatto: null,
    }
  },
  watch: {
    item() {
      this.initContatto()
    }
  },
  mounted() {
    this.assignItem()
  },
  methods: {
    assignItem() {
      return this.contatto = Object.assign(this.item)
    },
    editCo() {
      const payload = {
        'id': this.contatto.id,
        "nome": this.contatto.nome,
        "cognome": this.contatto.cognome,
        "tipo": this.contatto.tipo,
        "email_personale": this.contatto.email_personale,
        "email": this.contatto.email,
        "cellulare": this.contatto.cellulare,
        "sesso": this.contatto.sesso,
        "materia": this.contatto.materia,
        "istituto": this.contatto.istituto,
        "note": this.contatto.note
      }
      ContattiService.editContatti(payload)
        .then(() =>{
          this.contatto = {}
          this.$bvToast.toast('Contatto modificato con successo', {
            title: 'Edit Contatto',
            variant: 'success',
            solid: true
          })
        })
        .catch(e => {
          this.$bvToast.toast(`Errore: ${e.status}`, {
            title: 'Errore Contatto',
            variant: 'danger',
            solid: true
          })
        })
    },
    initContatto() {
      let id = this.item.id
      ContattiService.getContatto(id).then(resp => {
        this.contatto = resp.data
        this.showModal = true
      }).catch()
    },
    clearStore() {}
  }
}
</script>

<style>
.b-sidebar-body {
    background-color: #EBEEF4;
}

</style>
