<template>
    <b-modal
      id="resetPassword"
      header-bg-variant="dark"
      header-text-variant="white"
      title="Reset della Password"
      @ok="sendEmailToResetPassword"
    >
      <b-jumbotron>
        <h4 class="text-danger text-center">ATTENZIONE</h4>
        Inserire l'email d'Istituto <b>[...@...unicollegessml.it]</b>, il gestionale invierà un link alle email di istituto,
        alternativa chiedere il reset della password direttamente in segreteria.
      </b-jumbotron>
      <b-form-group label="Inserisci Email Istituzionale">
        <b-form-input v-model="email" type="email"></b-form-input>
      </b-form-group>
      <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" :sitekey="siteKey"></vue-recaptcha>
    </b-modal>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import AuthService from "@/services/auth.service";
export default {
  name: "ResetPassword",
  components: { VueRecaptcha },
  data() {
    return {
      email: '',
      siteKey:'6Lc5xTwjAAAAAJ3NDkJ6LeHhwUygDlp0dEH0HTvo'
    }
  },
  methods: {
    sendEmailToResetPassword() {
      const payload = {
        'email': this.email
      }
      AuthService.sendEmailToReset(payload)
        .then(resp => {
          console.log(resp.data)
        })
        .catch()
      console.log(this.email)
    },
    onVerify: function (response) {
      console.log('Verify: ' + response)
    },
    onExpired: function () {
      console.log('Expired')
    },
    resetRecaptcha() {
      this.$refs.recaptcha.reset() // Direct call reset method
    },
  }
}
</script>

<style scoped>

</style>
