<template>
  <div id="app" class="app" v-bind:class="{
		'app-sidebar-minified': appOptions.appSidebarMinified,
		'app-sidebar-mobile-toggled': appOptions.appSidebarMobileToggled,
		'app-sidebar-mobile-closed': appOptions.appSidebarMobileClosed,
		'app-content-full-height': appOptions.appContentFullHeight,
		'app-content-full-width': appOptions.appContentFullWidth,
		'app-without-sidebar': appOptions.appWithoutSidebar,
		'pt-0': appOptions.appWithoutHeader,
		'app-boxed-layout': appOptions.appBoxedLayout,
		'app-footer-fixed': appOptions.appWithFooter
	}">
    <vue-ins-progress-bar></vue-ins-progress-bar>
    <Header v-if="!appOptions.appWithoutHeader"/>
    <Sidebar v-if="!appOptions.appWithoutSidebar"/>
    <router-view></router-view>
    <Footer v-if="appOptions.appWithFooter"/>
    <b-modal
      id="first_login_app"
      centered
      no-close-on-backdrop
      hide-footer
      header-bg-variant="dark"
      header-text-variant="white"
      title="CAMBIO PASSWORD"
      no-close-on-esc
      hide-header-close
    >
      <b-form>
        <b-form-group label="Vecchia Password">
          <b-form-input type="password" v-model="old_password"></b-form-input>
          {{ old_password }}
        </b-form-group>
        <b-form-group label="Nuova Password">
          <b-form-input type="password" v-model="new_password"></b-form-input>
          {{ new_password }}
        </b-form-group>
        <b-form-group label="Ripeti Password">
          <b-form-input type="password" v-model="new_password2"></b-form-input>
          {{ new_password2 }}
          <span v-show="showError">Le password non coincidono</span>
        </b-form-group>
      </b-form>
      <b-button v-show="allOk" class="float-right" variant="dark" @click="cambioPassword">Cambia Password</b-button>
    </b-modal>
  </div>
</template>

<script>
import AppOptions from './config/AppOptions.vue'
import Sidebar from './components/Sidebar.vue'
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import AuthService from "@/services/auth.service";


export default {
  name: 'App',

  components: {
    Sidebar,
    Header,
    Footer
  },
  data() {
    return {
      appOptions: AppOptions,
      old_password: '',
      new_password: '',
      new_password2: '',
      showError: false,
      allOk: false,
    }
  },
  created() {
    if (!localStorage.getItem('local_user') && this.$route.fullPath !== '/login') {
      this.$router.push('/login')
    }
    this.$insProgress.start()

    this.$router.beforeEach((to, from, next) => {
      if (to.path === '/reset-password/:uidb64/:token') {
        next('/reset-password/:uid/:token')
      } else {
        const {authorize} = to.meta;
        const currentUser = this.$store.state.auth.user
        this.$insProgress.start()

        if (this.appOptions.appSidebarMobileToggled === true) {
          this.appOptions.appSidebarMobileClosed = true;
          setTimeout(() => {
            this.appOptions.appSidebarMobileClosed = false;
            this.appOptions.appSidebarMobileToggled = false;
          }, 250);
        }
        next()
        // check if route is restricted by role
        if (authorize && !authorize.includes(currentUser.profile)) {
          // role not authorised so redirect to home page
          return next({path: '/restricted'});
        }
      }

      // if (!this.$store.state.auth.status.loggedIn) {
      //     this.$router.push('/login')
      // }
    })
    this.$router.afterEach(() => {
      this.$insProgress.finish()
    })
  },
  mounted() {
    this.$insProgress.finish()
    if (this.$route.fullPath !== '/login' && this.$route.fullPath !== '/reset-password' && this.$store.state.auth.user.primo_login) {
      this.$bvModal.show('first_login_app')
    }
  },
  watch: {
    new_password2(value) {
      const lunghezzaPassword = this.new_password.length
      if (value.length === lunghezzaPassword && this.new_password !== value) {
        this.showError = true
        this.allOk = false
      }
      if (value.length === lunghezzaPassword && this.new_password === value) {
        this.showError = false
        this.allOk = true
      }
    }
  },
  methods: {
    cambioPassword() {
      const payload = {
        old_password: this.old_password,
        new_password: this.new_password
      }
      AuthService.changePassword(payload).then(resp => {
        console.log(resp)
        this.$bvModal.hide('first_login_app')
        AuthService.logout().then(() => {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.push({name: 'Login'})
          })
        })
      }).catch(error => {
        console.log(error)
        this.$bvToast.toast('Cambio Password Errore', {
          title: 'Cambio Password:',
          autoHideDelay: 5000,
          variant: 'danger'
        })
      })
      console.log(this.old_password, this.new_password, this.new_password2)
    },
  },
}
</script>
<style>
h1 {
  font-size: 1.2em;
}
h2 {
  font-size: 1em;
}
h3 {
  font-size: 0.9em;
}
h4 {
  font-size: 0.85em;
}
h5 {
   font-size: 0.80em;
 }
h6 {
  font-size: 0.75em;
}

.menu-link {
  font-size: 1.2em;
}

html, body {
  font-family: 'Ubuntu', sans-serif;
}

#app {
  font-family: 'Ubuntu', sans-serif;
  font-size: 0.8em;
}
.table.table>tbody>tr:hover td,
.table.table>tbody>tr:hover th {
  /*background-color: #9e0808 !important;*/
  background-color: #f2e2ba !important;
}
.fontBadge {
  font-weight: lighter;
}
</style>
