import axios from 'axios';
import authHeader from '@/services/auth.header';


const API_URL = process.env.VUE_APP_APP_URL

class LMSService {
    getLezioni(params){
        return axios.get(API_URL + 'lms/', {headers: authHeader(), params:params})
    }
    getLezionePreviw(id) {
      return       axios.get(API_URL + `lms/${id}`, {headers: authHeader()})
    }
    getlezioniByCorsoId(id) {
      return axios.get(API_URL + 'lms/?corso=' + id, {headers: authHeader()})
    }
    getCorsi(params) {
        return axios.get(API_URL + 'corsi/', {headers: authHeader(), params:params})
    }
    postLezione(payload) {
      return axios.post(API_URL + 'lms/', payload, {headers: authHeader()})
    }
    // Studenti page
  getLezione(id) {
       return axios.get(API_URL + `lms/${id}`, {headers: authHeader()})
  }
}

export default new LMSService();
