import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL

class ScuoleService {
    getScuole() {
        return axios.get(API_URL + 'scuole/', {headers: authHeader()});
    }
    getNextPage(next) {
        return axios.get(next, { headers: authHeader()})
    }
    getFilterScuole(f){
        return axios.get(API_URL + `filtro_scuole/?regione=${f.regione}&provincia=${f.provincia}&denominazione_scuola=${f.denominazione_scuola}&codice_scuola=${f.codice_scuola}`,{headers: authHeader()})
    }
    deleteScuole(id){
        return axios.delete(API_URL + 'istituti/' + id + '/', { headers: authHeader()})
    }
}
export default new ScuoleService();
