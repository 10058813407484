// SERVICE DELLO STUDENTE LATO STUDENTE
import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_APP_URL
const STU_URL = process.env.VUE_APP_STU_URL

class StudenteService {
    getStudenti(){
        return axios.get(API_URL + 'studenti/', { headers: authHeader() })
    }
    getPianiDiStudi(params){
        console.log('params: ', params)
        return axios.get(API_URL + 'piano_di_studi/', { headers: authHeader(), params: params})
    }
    getProfile() {
        return axios.get(STU_URL + 'studenti/', { headers:authHeader() })
    }
    getLibretto() {
        return axios.get(STU_URL + 'libretto/', { headers: authHeader()})
    }
    getDetails(params) {
        return axios.get( STU_URL + 'details/', { headers: authHeader(), params:params })
    }
    updateStudente(payload) {
        return axios.put(API_URL + 'studenti/' + payload.id + '/', payload, { headers: authHeader() })
    }

}
export default new StudenteService();
