export default function authHeader() {
    let user =  JSON.parse(localStorage.getItem('local_user'));

    if (user && user.token) {
        return {
            Authorization: 'Token ' + user.token,
        };
    // } else {
    //     this.$router.push('/login')
    //         .then(r => console.log(r))
    //         .catch(()=>{});
    }
}
