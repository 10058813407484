<template>
	<!-- BEGIN #footer -->
	<div id="footer" class="app-footer">
		{{new Date().getFullYear()}} PcBuster&copy; - All Right Reserved
	</div>
	<!-- END #footer -->
</template>

<script>
import AppOptions from '../config/AppOptions.vue'

export default {
  name: 'Footer',
  data() {
		return {
			appOptions: AppOptions
		}
  }
}
</script>
