<template>
    <div class="page-content page-container" id="page-content">
        <div class="padding">
            <div class="row">
                <div class="col-lg-12">
                    <div class="timeline p-4 mb-4" v-if="eventi.length >= 1">
                        <div class="tl-item" v-for="e in eventi" :key="e.id">
                            <div :class="colorDot(e.tipo)"></div>
                            <div class="tl-content">
                                <div class=""><b>#{{e.id}}</b> Oggetto:<b>{{ e.oggetto }}</b></div>
                                <div class="ml-3 text-muted d-inline-block">Creato in data: </div><span class="ml-2"><b>{{ e.creatoil }}</b></span>
                                <div class="ml-3 d-inline"><b-badge variant="dark">{{ e.tipo }}</b-badge> </div>
                                <div class="d-inline-block"><b-badge variant="orange">{{ e.nome_istituto }}</b-badge></div>
                                <div class="ml-3 text-muted">Data Evento: <span style="color: #3A4051">{{ e.data_evento }}</span> </div>
                                <div class="ml-3 text-muted">Luogo Evento: <span style="color: #3A4051">{{ e.luogo }}</span> </div>
                                <div class="ml-3 text-muted" v-for="n in e.notes" :key="n.index">Nota Evento: <span style="color: #3A4051">{{ n.nota }}</span> </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>Non ci sono interazioni con il contatto</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventService from '@/services/event.service'

export default {
    name: "timeline",
    props: {
        id_contatto: Number,
        cognome: String,
    },
    data() {
        return {
            evento: {
                oggetto:'',
                note:'',
            },
            show: true,
            tipo: [
                {value:null,text: 'Scegli un tipo di evento'},
                {value:'call_in',text: 'Chiamata in entrata'},
                {value:'call_out',text: 'Chiamata in uscita'},
                {value:'email_in',text: 'Email Ricevuta'},
                {value:'email_out',text: 'Email Inviata'},
                {value:'app_tel',text: 'Appuntamento Telefonico'},
                {value:'app',text: 'Appuntamento'},
                {value:'whatsapp',text: 'Whatsapp'},
                {value:'dem',text:'DEM'},
            ],
            selected: null,
            eventi: [],
            dotTemplate:'',
        }
    },
    computed: {

    },
    mounted() {
        this.initEventiList()
    },
    methods: {
        colorDot(tipo) {
            return `tl-dot ${tipo}`
        },
        initEventiList() {
            EventService.filterEventLog(this.id_contatto)
                .then(resp => {
                    this.eventi = resp.data
                })
                .catch()
        },
        onSubmit(e) {
            e.preventDefault()
            EventService.postEventLog({
                "oggetto": this.evento.oggetto,
                "note": this.evento.note,
                "tipo": this.selected,
                "account": this.$store.state.auth.user.user_id,
                "contatto": this.id_contatto,
                "studente": null
            })
                .then(
                    resp => {
                        console.log(resp)
                        this.$bvModal.hide('invia-evento')
                        this.$emit("showAlert")
                    }
                )
                .catch(error => {
                    console.log(error)
                })
        },
        onReset(evt) {
            evt.preventDefault()
            // Reset our form values
            this.evento.oggetto = ''
            this.evento.note = ''
            this.selected = ''
            this.show = false
            this.$nextTick(() => {
                this.show = true
            })
        },
    }
}
</script>

<style scoped>
body {
    background-color: #f9f9fa
}

@media (min-width:992px) {
    .page-container {
        max-width: 1140px;
        margin: 0 auto
    }

    .page-sidenav {
        display: block !important
    }
}

.padding {
    padding: 2rem
}

.w-32 {
    width: 32px !important;
    height: 32px !important;
    font-size: .85em
}

.tl-item .avatar {
    z-index: 2
}

.circle {
    border-radius: 500px
}

.gd-warning {
    color: #fff;
    border: none;
    background: #f4c414 linear-gradient(45deg, #f4c414, #f45414)
}

.timeline {
    position: relative;
    border-color: rgba(160, 175, 185, .15);
    padding: 0;
    margin: 0
}

.p-4 {
    padding: 1.5rem !important
}

.block,
.card {
    background: #fff;
    border-width: 0;
    border-radius: .25rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
    margin-bottom: 1.5rem
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.tl-item {
    border-radius: 3px;
    position: relative;
    display: -ms-flexbox;
    display: flex
}

.tl-item>* {
    padding: 10px
}

.tl-item .avatar {
    z-index: 2
}

.tl-item:last-child .tl-dot:after {
    display: none
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-item:last-child .tl-dot:after {
    display: none
}

.tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%)
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
}

.tl-dot {
    position: relative;
    border-color: rgba(160, 175, 185, .15)
}

.tl-dot:after,
.tl-dot:before {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    transform: translateX(-50%)
}

.tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0
}

.tl-content p:last-child {
    margin-bottom: 0
}

.tl-date {
    font-size: .85em;
    margin-top: 2px;
    min-width: 100px;
    max-width: 100px
}

.avatar {
    position: relative;
    line-height: 1;
    border-radius: 500px;
    white-space: nowrap;
    font-weight: 700;
    border-radius: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 500px;
    box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .15)
}

.call_in {
    border-color: #f4c414 !important
}

.call_out {
    border-color: #448bff !important
}

.to_do {
    border-color: #e00c3e !important
}

.email_in {
    border-color: #f54394 !important
}
.email_out {
    border-color: #f58a43 !important
}
.appuntamento {
    border-color: #39b15b !important
}
.dem {
    border-color: #67c2bb !important
}
.whatsapp {
    border-color: #70b668 !important
}
</style>
