// SERVICE DI STUDENTI LATO STAFF
import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_APP_URL

class StudentiService {
    getStudenti(params){
        return axios.get(API_URL + 'studenti/', { headers: authHeader(), params:params })
    }
    getStudente(params) {
        return axios.get(API_URL + 'studenti', { headers: authHeader(), params:params})
    }
    putStudente(payload) {
        return axios.put(API_URL + "studenti/" + payload.id + "/", payload, { headers: authHeader() })
    }
    postStudente(payload) {
        return axios.post(API_URL + 'studenti/', payload, { headers: authHeader() })
    }
    postLeadToStudente(payload) {
        return axios.post(API_URL + 'studenti/', payload, { headers: authHeader() })
    }
    deleteStudente(id) {
      return axios.delete(API_URL + 'studenti/' + id + '/', { headers: authHeader() })
    }
}
export default new StudentiService();
