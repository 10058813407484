import axios from 'axios';
import authHeader from '@/services/auth.header';

const API_URL = process.env.VUE_APP_APP_URL

class LeadService {
    getLead(params){
        return axios.get(API_URL +'nuovi-lead/', {headers: authHeader(), params:params});
    }
    getLeadFromId(id){
        return axios.get(API_URL + 'nuovi-lead/' + id + '/', { headers: authHeader() })
    }
    selectLeadFromInstitute(id){
        return axios.get(API_URL + 'nuovi-lead/?istituto=' + id, { headers:authHeader() })
    }
    getExportLead(params){
        return axios.get(API_URL +'export-leads-csv/', {headers: authHeader(),params:params, responseType: 'blob'});
    }
    getDubt(){
        return axios.get(API_URL + 'lead/doppioni', { headers: authHeader() });
    }
    editLead(payload){
        return axios.patch(API_URL + 'nuovi-lead/' + payload.id + '/', payload, { headers: authHeader()})
    }
    editLeadBulk(payload){
        return axios.post(API_URL + 'istituti/' + payload.associa_scuola + '/associa_leads/', payload, { headers: authHeader()})
    }
    deleteLead(id){
        return axios.delete(API_URL +'nuovi-lead/'+ id + '/', {headers: authHeader()});
    }
    saveLead(payload){
        return axios.put(API_URL + 'nuovi-lead/' + payload.id + '/', payload, {headers: authHeader()})
    }
    insertNewLead(payload){
        return axios.post(API_URL + "nuovi-lead/", payload, { headers: authHeader()})
    }
    async filterLeadDeactivate(){
        return  await axios.get(API_URL + 'nuovi-lead/?active=false', {headers: authHeader()})
    }
    getLeadIDafterSave() {
        return axios.get(API_URL + 'nuovi-lead/')
    }
    getLeadFromList(params) {
      return axios.get(API_URL + 'nuovi-lead/', { headers: authHeader(), params:params })
    }
    getFormDalSito() {
        return axios.get(API_URL + 'formfromsite/', { headers: authHeader() })
    }
    deleteFormDalSito(id) {
        return axios.delete(API_URL + 'formfromsite/' + id + '/', { headers:authHeader()} )
    }
    trasformFormToLeadAndEvent(id) {
        return axios.post(API_URL + 'formfromsite/'+id+'/create_lead_if_email_not_exist/', null ,{ headers:authHeader() } )
    }
    getFilterFormDalSito(payload) {
        return axios.get(API_URL + 'formfromsite/', { headers: authHeader(), params: payload })
    }
    setReaded(payload) {
        return axios.patch(API_URL + "formfromsite/" + payload.id + "/", payload, { headers: authHeader()} )
    }
    filterLead(params) {
        return axios.get(API_URL + 'nuovi-lead/', {headers: authHeader(), params: params})
    }
}

export default new LeadService();
