import  axios from 'axios';
import authHeader from '@/services/auth.header';
const API_URL = process.env.VUE_APP_APP_URL


class SettingService {
    getAnniAccademici() {
        return axios.get(API_URL + 'anno_accademico/', {headers: authHeader()});
    }
    postAnnoAccademico(payload) {
        return axios.post(API_URL + 'anno_accademico/', payload, { headers: authHeader() })
    }
    patchAnnoAccademico(id, payload) {
        return axios.patch(API_URL + 'anno_accademico/' + id + '/', payload , { headers: authHeader() })
    }
    deleteAnnoAccademico(id) {
        return axios.delete(API_URL + 'anno_accademico/' + id + '/', { headers: authHeader()})
    }
    addLingue(payload) {
        return axios.post(API_URL + "lingua_istituto/", payload, { headers: authHeader()})
    }
    getLingue() {
        this.loading = true
        return axios.get(API_URL +'lingua_istituto/', {headers: authHeader()});
    }
    getAtenei() {
        return axios.get(API_URL + 'universita/', { headers : authHeader() })
    }
    addAteneo(payload){
        return axios.post(API_URL + 'universita/', payload, { headers: authHeader() })
    }
    getTipologieIstituto() {
        return axios.get(API_URL + 'tipologia_istituti/', { headers:  authHeader() })
    }
    addTipologiaIstituto(payload) {
        return axios.post(API_URL + 'tipologia_istituti/', payload, { headers: authHeader() })
    }
}
export default new SettingService();
