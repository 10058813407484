<template>
    <b-modal
        v-model="showModal"
        v-if="form_evento"
        ref="modale-edit-evento"
        id="modale-edit-evento"
        size="lg"
        header-bg-variant="dark"
        header-text-variant="white"
        title="Modifica Evento"
        ok-title="Update"
        @hidden="$emit('hidden', $event)"
        @ok="saveEvento">
            <b-card>
                <b-card-title style="font-size: small;">
                    <b-button size="sm" variant="dark" class="float-right mt-0 mr-0" @click="clearForm">Clear Form</b-button>
                    <b-form-group label="Scegli il tipo di Evento" v-slot="{ ariaDescribedby }" class="d-inline-flex">
                        <b-form-radio class="d-inline-flex" v-model="action" :aria-describedby="ariaDescribedby" name="some-radios" value="evento">Evento</b-form-radio>
                        <b-form-radio class="d-inline-flex ml-2" v-model="action" :aria-describedby="ariaDescribedby" name="some-radios" value="interazione">Interazione</b-form-radio>
                        <b-form-radio class="d-inline-flex ml-2" v-model="action" :aria-describedby="ariaDescribedby" name="some-radios" value="altro">Altro</b-form-radio>
                    </b-form-group>
                    <hr>
                </b-card-title>
                <b-card-body v-if="action ==='evento'">
                    <b-form id="evento">
                        <b-row  class="pt-3"
                                style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-group label="Seleziona il Tipo di Evento*:">
                                    <b-form-select
                                        v-model="form_evento.tipo_evento"
                                        size="sm"
                                        :options="tipo_evento"
                                        value-field="value"
                                        text-field="text"
                                    ><b-form-select-option value="Scegli un'opzione">Scegli un'opzione</b-form-select-option></b-form-select>
                                </b-form-group>
                                <b-form-group label="Data Ricezione Form:" v-if="form_evento.data_invio_form !== undefined ? `${form_evento.data_invio_form}`:`${form_evento.created_at}`">
                                    <b-form-datepicker
                                        size="sm"
                                        v-model="form_evento.data_invio_form"
                                    ></b-form-datepicker>
<!--                                    <small class="text-muted text-underline"><b-link @click="insertToday">inserisci la data di oggi</b-link></small>-->
<!--                                    <small v-if="form_evento.data_invio_form">{{ this.form_evento.data_invio_form }}</small>-->
                                </b-form-group>
                                <b-form-group label="Come vuole partecipare?">
                                    <b-form-select
                                        size="sm"
                                        :options="modalita_partecipazione"
                                        text-field="text"
                                        value-field="text"
                                        v-model="form_evento.modalita_partecipazione"
                                    ><b-form-select-option value="Scegli un'opzione">Scegli un'opzione</b-form-select-option></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Scegli la sede*">
                                    <b-form-select size="sm" :options="sedeInteresse" text-field="text" value-field="value" v-model="form_evento.sede"
                                    ><b-form-select-option value="Scegli un'opzione">Scegli un'opzione</b-form-select-option></b-form-select>
                                </b-form-group>
                                <b-form-group label="Inserisci la data dell'evento:">
                                    <b-form-datepicker size="sm" v-model="form_evento.data_iscrizione"></b-form-datepicker>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr>
    <!--                    Colloquio Individuale-->
                        <b-row v-if="form_evento.tipo_evento === 'Colloquio Individuale'"
                               class="pt-3"
                               style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-group label="Seleziona la data del Colloquio:">
                                    <b-form-datepicker size="sm" v-model="form_evento.ci_data" ></b-form-datepicker>
                                </b-form-group>
                                <b-form-group label="Nome ultimo Istituto:">
                                    <b-form-input size="sm" v-model="form_evento.ci_nome_istituto"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Cosa vuole studiare:">
                                    <b-form-input size="sm" v-model="form_evento.ci_cosa_vuole_studiare"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Seleziona l'orario del Colloquio:">
                                    <b-form-timepicker size="sm" v-model="form_evento.ci_orario" :hour12=false></b-form-timepicker>
                                </b-form-group>
                                {{ form_evento.ci_orario}}
                                <b-form-group label="Nome Università:">
                                    <b-form-input size="sm" v-model="form_evento.ci_nome_universita"></b-form-input>
                                </b-form-group>
                                ****ci_universita_frequentata***
                            </b-col>
                        </b-row>
                        <b-row v-if="form_evento.tipo_evento === 'Colloquio Individuale'" class="pt-3"
                               style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-group label="Interssato a:">
                                    <b-form-textarea size="sm" v-model="form_evento.ci_interessato_a"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
    <!--                    Fiera-->
                        <b-row v-if="form_evento.tipo_evento === 'Fiera'"  class="pt-3"
                               style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-group label="A quale Fiera:">
                                    <b-form-input size="sm" v-model="form_evento.fi_a_quale_fiera"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Di quale Anno:">
                                    <b-form-input size="sm" v-model="form_evento.fi_di_quale_anno"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
    <!--                    Open Day-->
                        <b-row v-if="form_evento.tipo_evento === 'Open Day'" class="pt-3"
                               style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-checkbox size="sm" switch inline v-model="form_evento.od_accompagnatori">Accompagnatori</b-form-checkbox>
                                <b-form-group label="Nome Cognome Accompagnatore:" v-if="form_evento.od_accompagnatori">
                                    <b-form-input size="sm" v-model="form_evento.od_accompagnatori_nome"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Nome Cognome Accompagnatore2:" v-if="form_evento.od_accompagnatori">
                                    <b-form-input size="sm" v-model="form_evento.od_accompagnatori_nome2"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Open Day Data:">
                                    <b-form-datepicker size="sm" v-model="form_evento.od_data"></b-form-datepicker>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <br>
                                <b-form-group label="Professione Accompagnatore:" v-if="form_evento.od_accompagnatori">
                                    <b-form-input size="sm" v-model="form_evento.od_accompagnatori_professione"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Professione Accompagnatore2:" v-if="form_evento.od_accompagnatori">
                                    <b-form-input size="sm" v-model="form_evento.od_accompagnatori_professione2"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Nome Open Day:">
                                    <b-form-input size="sm" v-model="form_evento.od_nome"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row v-if="form_evento.tipo_evento === 'Open Day'" style="background-color: #f3f3f3">
                            <b-col>
                                <b-form-group label="Come ha saputo dell'Open Day:">
                                    <b-form-textarea size="sm" v-model="form_evento.od_come_ha_saputo"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
    <!--                    Test-->
                        <b-row v-if="form_evento.tipo_evento === 'Test'"  class="pt-3"
                               style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-checkbox size="sm" switch inline v-model="form_evento.tst_iscrizione">Si vuole Iscrivere ad un Test</b-form-checkbox>
                                <b-form-checkbox size="sm" switch inline v-model="form_evento.tst_fac_simile">Ha richiesto un FacSimile</b-form-checkbox>
                            </b-col>
                        </b-row>
                        <b-row style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-group label="Seconda Lingua:" v-if="form_evento.tst_iscrizione">
                                    <b-form-input size="sm" v-model="form_evento.tst_seconda_lingua"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Voto Diploma:" v-if="form_evento.tst_iscrizione">
                                    <b-form-input size="sm" v-model="form_evento.tst_voto_diploma"></b-form-input>
                                </b-form-group>
                                <b-form-checkbox size="sm" switch inline v-model="form_evento.tst_ha_pagato" v-if="form_evento.tst_iscrizione">Ha Pagato</b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-group label="Terza Lingua:" v-if="form_evento.tst_iscrizione">
                                    <b-form-input size="sm" v-model="form_evento.tst_terza_lingua"></b-form-input>
                                </b-form-group>
                                <b-form-group label="Seleziona la Data del Test:" v-if="form_evento.tst_iscrizione">
                                    <b-form-datepicker size="sm" v-model="form_evento.tst_data"></b-form-datepicker>
                                </b-form-group>
                                <b-form-group label="Scegli il Tipo di Pagamento" v-if="form_evento.tst_ha_pagato">
                                    <b-form-select size="sm" :options="tipo_pagamento" value-field="value" text-field="text" v-model="form_evento.tst_pagamento_tipo"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row class="pt-3"
                                style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-checkbox size="sm" switch inline v-model="form_evento.partecipazione">Ha Patercipato all'evento</b-form-checkbox>
                                <b-form-checkbox size="sm" switch inline v-model="form_evento.follow_up">Richiede un FollowUp</b-form-checkbox>
                            </b-col>
                            <b-col>

                            </b-col>
                        </b-row>
                        <b-row v-if="form_evento.follow_up" class="pt-3 pb-2"
                               style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <b-form-group label="Seleziona la data del FollowUp:">
                                    <b-form-datepicker size="sm" v-model="form_followup.data_follow" ></b-form-datepicker>
                                </b-form-group>
                                <div style="border-radius: 0.3rem">
                                    <label>Scegli Orientatore:</label>
                                    <v-select
                                        style="background-color: white"
                                        multiple
                                        :options="users"
                                        label="email_istituzionale"
                                        v-model="form_followup.orientatori"
                                        :reduce="user => user.id"></v-select>
                                </div>
                            </b-col>
                            <b-col>
                                <b-form-group label="Azione:">
                                    <b-form-select size="sm" :options="action_followup" text-field="text" value-field="text" v-model="form_followup.oggetto"></b-form-select>
                                </b-form-group>
                                <b-form-group label="Dettagli:">
                                    <b-form-textarea v-model="form_followup.dettagli"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2"
                               style="background-color: #f3f3f3; border-radius: 5px">
                            <b-col>
                                <hr>
                                <b-form-group label="Commenti/Note/Altro">
                                    <b-form-textarea v-model="form_evento.note"></b-form-textarea>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card-body>
                <b-card-body v-if="action==='interazione'">
                    <b-form id="interazione">
                        <b-row>
                            <b-col>
                                <b-form-group label="Seleziona il Tipo di Interazione:">
                                    <b-form-select :options="tipo_interazione" text-field="text" value-field="value" v-model="form_evento.tipo_interazione"></b-form-select>
                                </b-form-group>
                                <b-form-group label="Inserisci la data di inserimento dell'evento:">
                                    <b-form-datepicker v-model="form_evento.data_interazione"></b-form-datepicker>
                                    <small class="text-muted text-underline"><b-link @click="insertToday">inserisci la data di oggi</b-link></small>
                                </b-form-group>
                            </b-col>
                            <b-col>
                                <b-form-group label="Scegli la sede">
                                    <b-form-select :options="sedeInteresse" text-field="text" value-field="value" v-model="form_evento.sede"></b-form-select>
                                </b-form-group>
                                <b-form-group label="Inserisci la data dell'evento:">
                                    <b-form-datepicker v-model="form_interazione.data_iscrizione"></b-form-datepicker>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <label title="Scegli Orientatore"></label>
                                <div style="border-radius: 0.3rem">
                                    <v-select multiple :options="users" label="email_istituzionale" v-model="form_evento.staff" :reduce="users => users.user"></v-select>
                                </div>
                            </b-col>
                        </b-row>
                        <b-form-group label="Commenti/Note/Altro">
                            <b-form-textarea v-model="form_evento.note"></b-form-textarea>
                        </b-form-group>
                    </b-form>
                </b-card-body>
                <b-card-body v-if="action==='altro'">
                    <b-form id="interazione">
                        <b-row>
                            <b-col>
                            </b-col>
                            <b-col>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-card-body>
            </b-card>
      <small class="text-muted">Nome Componente: src/components/crm/evento/lead/ModaleEditEvento.vue</small>
    </b-modal>
</template>

<script>
import EventService from "../../../../services/event.service";
import UserService from "../../../../services/user.service";
import vSelect from 'vue-select'

export default {
    name:'ModaleEditEventoLead',
    components: {
        vSelect,
    },
    props: {
        idEvento: {
            type: [String, Number]
        },
        modeBulk: {
            type: Array
        }
    },
    data() {
        return {
            // Select
            tipo_evento: [
                {value: 'Colloquio Individuale', text: 'Colloquio Individuale'},
                {value: 'Fiera', text: 'Fiera'},
                {value: 'Informazioni', text: 'Informazioni'},
                {value: 'Open Day', text: 'Open Day'},
                {value: 'Test', text: 'Test'},
            ],
            tipo_interazione: [
                {value: "Appuntamento", text:"Appuntamento"},
                {value: "AppuntamentoTelef", text:"Appuntamento Telefonico"},
                {value: "CallIn", text:"Call In"},
                {value: "CallOut", text:"Call Out"},
                {value: "DEM", text:"DEM"},
                {value: "EmailIn", text:"Email In"},
                {value: "EmailOut", text:"Email Out"},
                {value: "Instagram", text:"Instagram"},
                {value: "LL", text:"LL"},
                {value: "LiveChat", text:"LiveChat"},
                {value: "OAS", text:"OAS"},
                {value: "PCTO", text:"PCTO"},
                {value: "Sms", text:"Sms"},
                {value: "SpedizioneMateriale", text:"Spedizione Materiale"},
                {value: "ToDo", text:"To Do"},
                {value: "VSA", text:"VSA"},
                {value: "WhatsappIn", text:"Whatsapp In"},
                {value: "WhatsappOut", text:"Whatsapp Out"},
            ],
            tipo_pagamento: [
                {value:"Contanti", text:"Contanti"},
                {value:"Bonifico", text:"Bonifico"},
                {value:"PayPal", text:"PayPal"},
                {value:"Carta di Credito", text:"Carta di Credito"}
            ],
            sedeInteresse: [
                {value:'Firenze', text:'Firenze'},
                {value:'Mantova', text:'Mantova'},
                {value:'Milano', text:'Milano'},
                {value:'Torino', text:'Torino'},
            ],
            modalita_partecipazione: [
                {value:'onLine', text:'On Line'},
                {value:'presenza', text:'Presenza'}
            ],
            ti_come_hai_conosciuto_questo_od: [
                "----- Social -----",
                "Facebook",
                "Instagram",
                "----- Giornali----",
                "La Gazzetta di Firenze"
            ],
            // Data
            form_evento: null,
            form_evento2: {
                // Generici
                classe_frequentata: '',
                data_invio_form: "",
                data_iscrizione: "",
                note: "",
                modalita_di_partecipazione: '',
                ha_partecipato: false   ,
                sede:'',
                staff: [],
                tipo_evento: '',
                tipo_interazione: '',
                // Colloquio Individuale
                ci_cosa_vuole_studiare: "",
                ci_data: "",
                ci_interessato_a: "",
                ci_modalita_preferenza: '',
                ci_nome_istituto: "",
                ci_nome_universita: "",
                ci_orario: "",
                ci_universita_frequentata: "",
                // Fiera
                fi_a_quale_fiera: "",
                fi_di_quale_anno: "",
                // Open Day
                od_accompagnatori: false,
                od_accompagnatori_nome: "",
                od_accompagnatori_nome2: "",
                od_accompagnatori_professione: "",
                od_accompagnatori_professione2: "",
                od_come_ha_saputo: "",
                od_data: "",
                od_nome: "",
                // Test
                tst_data: "",
                tst_iscrizione: false,
                tst_fac_simile: false,
                tst_ha_pagato: false,
                tst_pagamento_tipo:'',
                tst_seconda_lingua: "",
                tst_terza_lingua: "",
                tst_voto_diploma: "",
                ti_come_hai_conosciuto_questo_od: '',
                ti_a_quale_od:'',
                follow_up: false,
            },
            form_interazione: {
                data_interazione: "",
                evento_log: "",
                followup: "",
                nota: "",
                oggetto: "",
                staff: [],
                tipo_interazione: "",
            },
            form_followup: {
                orientatore: this.$store.state.auth.user.account_id,
                data_follow:'',
                oggetto:'',
                dettagli:'',
                orientatori: []
            },
            action_followup: [
                {value:"richiamare", text: 'Richiamare'},
                {value:"mandare_email", text: 'Mandare Email'},
                {value:"fissare_app", text: 'Fissare Appuntamento'},
                {value:"whatsapp", text: 'WhatsApp Out'},
            ],
            // select
            users: [],
            // actions
            action_evento: false,
            action_interazione: false,
            action: 'evento',
            followUp: false,
            lead: this.getLead,
            showModal: true
        }
    },
    async created() {
        console.log('created')
        await this.getEvento()
    },
    beforeMount() {
        console.log('BeforeMount')
    },
    mounted() {
        this.initCustomUser()
        console.log('mounted')
    },
    beforeDestroy() {
        console.log('BeforeDestroy')
    },
    destroyed() {
        console.log('destroyed')
    },
    computed: {
        getLead: {
            get() {
                return this.l_id
            }
        },
    },
    methods: {
        getEvento(){
            EventService.getEvento(this.idEvento)
                .then(resp => {
                     this.form_evento = resp.data
                        if(this.form_evento.tipo_evento.length > 0){
                            this.action = 'evento'
                        } if (this.form_evento.tipo_interazione.length > 0) {
                            this.action = 'interazione'
                    }
                    console.log('EVENTO: ',resp.data, 'Tipo Evento:', this.form_evento.length)
                }).catch()
        },
        initCustomUser(){
            const payload = {
                is_event_manager: true
            }
            UserService.getUser(payload)
                .then(resp => {
                    this.users = resp.data.results
                    console.log('Users:', this.users)
                })
        },
        saveEvento() {
            if(this.modeBulk) {
                const payload = {
                    lead: this.form_evento.lead,
                    orientatore_id: this.$store.state.user.user_id,
                    ...this.form_evento
                }
                console.log('salvato correttamente', payload)
            }
            else {
                console.log(this.$store)
                const payload = {
                    lead: this.form_evento.lead,
                    ...this.form_evento
                }
                EventService.updateEvento(payload)
                    .then(() => {
                        this.action = ''
                        this.$bvToast.toast(`Evento Creato con successo`, {
                            title: 'Aggiungi Evento',
                            autoHideDelay: 5000,
                            variant: 'success'
                        })}).then(() => {
                        const payload_fu = {
                            lead: this.form_evento.lead,
                            ...this.form_followup
                        }
                        if(this.form_evento.follow_up) {
                            console.log('Payload:', payload_fu)
                            EventService.postFollowUp(payload_fu)
                                .then(() => {
                                    this.action = ''
                                    this.clearForm()
                                    this.$store.dispatch('settings/refreshLead', true)
                                    this.$bvModal.hide('aggiungi-evento')
                                    }
                                )
                                .catch(error => {
                                    console.log(error)
                                })
                        }else {
                            this.clearForm()
                            this.$bvModal.hide('modale-edit-evento')
                            this.$emit('InitLead')
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    })
            }
            // else if(this.action === 'interazione') {
            //     const payload = {
            //         lead: this.$store.state.lead.lead.id,
            //         ...this.form_interazione
            //     }
            //     EventService.postEvento(payload)
            //         .then(() => {
            //             this.action = ''
            //             this.$bvModal.hide('aggiungi-evento')
            //             this.$emit('InitLead')
            //         })
            // }
        },
        clearForm() {
            this.action = ''
            this.form_evento = {
                // Generici
                classe_frequentata: '',
                data_invio_form: "",
                data_iscrizione: "",
                note: "",
                modalita_di_partecipazione: '',
                ha_partecipato: false   ,
                sede: '',
                staff: [],
                tipo_evento: '',
                tipo_interazione: '',
                // Colloquio Individuale
                ci_cosa_vuole_studiare: "",
                ci_data: "",
                ci_interessato_a: "",
                ci_modalita_preferenza: '',
                ci_nome_istituto: "",
                ci_nome_universita: "",
                ci_orario: "",
                ci_universita_frequentata: "",
                // Fiera
                fi_a_quale_fiera: "",
                fi_di_quale_anno: "",
                // Open Day
                od_accompagnatori: false,
                od_accompagnatori_nome: "",
                od_accompagnatori_nome2: "",
                od_accompagnatori_professione: "",
                od_accompagnatori_professione2: "",
                od_come_ha_saputo: "",
                od_data: "",
                od_nome: "",
                // Test
                tst_data: "",
                tst_fac_simile: false,
                tst_ha_pagato: false,
                tst_pagamento_tipo: '',
                tst_seconda_lingua: "",
                tst_terza_lingua: "",
                tst_voto_diploma: "",
                ti_come_hai_conosciuto_questo_od: '',
                ti_a_quale_od:'',
                follow_up: false,
            }
            this.form_interazione = {
                data_interazione: "",
                evento_log: "",
                followup: "",
                nota: "",
                oggetto: "",
                staff: [],
                tipo_interazione: "",
            }
        },
        insertToday() {
            return new Date().toLocaleDateString()
        }
    },
    watch: {
         l_id(value) {
             console.log(value)
         },
        // editEvento: {
        //     handler() {
        //         this.form_evento = this.$store.state["evento/evento"]
        //     },
        // },
    }
}
</script>

<style scoped>
</style>
