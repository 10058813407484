<template>
    <div>
        ricerca istituto:
        <b-input type="search" v-model="ricerca" @keydown.enter.native="searchIstituto"></b-input>
        <b-table :items="risultatiIstituti" :fields="fieldIstituti" selectable select-mode="single" @row-selected="onRowSelected"></b-table>
    </div>
</template>

<script>
import IstitutiService from "@/services/istituto.service"
export default {
    name: "searchIstituti",
    data() {
        return {
            ricerca:'',
            risultatiIstituti:[],
            fieldIstituti:[
            {key:'parent', label:'Associato a'},
            {key:'nome_istituto', label:'Nome Ist.', sortable:true},
            {key:'regione', label:'Regione', sortable:true},
            {key:'provincia', label:'Provincia', sortable:true},
            {key:'citta', label:'Città', sortable:true},
        ],
            istitutoSelezionato:[]
        }
    },
    methods: {
        searchIstituto() {
            IstitutiService.searchIstituti(this.ricerca)
                .then(resp =>{
                    this.risultatiIstituti = resp.data.results
                })
                .catch()
        },
        onRowSelected(items) {
            this.istitutoSelezionato = items
            this.$emit('inserisciIstituto', items[0])
        },
    }
}
</script>

<style scoped>

</style>
