<template>
  <div id="content" class="app-content">
    <b-card>
      <b-card-header header-bg-variant="dark">
        <b-row v-if="$store.state.auth.status.loggedIn">
          <b-col cols="12">
            <h3 style="color: #f9f9fa">
              <i class="fa fa-graduation-cap"></i> Ciao <b>{{ this.$store.state.auth.user.first_name }}</b>, ci sono
              {{ corsi.length }} corsi disponibili.
            </h3>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col>
            <b-card>
              <b-row>
                <b-col>
                  <b-input-group class="mt-3">
                    <template #append>
                      <b-input-group-text>
                        <strong class="text-white">
                          <i class="fas fa-search"></i>
                        </strong>
                      </b-input-group-text>
                    </template>
                    <b-form-input v-model="searchPerNome" placeholder="Cerca corso per nome"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-table
                striped
                size="sm"
                :items="corsi"
                :fields="fields"
                selectable
                select-mode="single"
                @row-selected="onRowSelected"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template #cell(numero_lezioni)="row">
                  <b-badge style="font-size: 12px" v-if="row.item.numero_lezioni > 0" variant="dark"
                           class="align-items-center">{{ row.item.numero_lezioni }}
                  </b-badge>
                  <b-badge style="font-size: 12px" v-else variant="yellow" class="align-items-center">
                    {{ row.item.numero_lezioni }}
                  </b-badge>
                </template>
              </b-table>
              <hr>

              <div class="d-inline-flex">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="corsi.length"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0"
                >
                </b-pagination>
                <b-form-group
                  label="Num.:"
                  label-for="per-page-select"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  class="ml-5"
                >
                  <b-form-select
                    class="ml-4"
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </div>

            </b-card>
          </b-col>
          <b-col>
            <b-list-group v-if="activeCourse">
              <b-list-group-item v-for="(lezione, index) in lezioniStudente" :key="index">
                <b-link @click="setActiveLesson(lezione.id)">{{ index + 1 }} - {{ lezione.titolo }}</b-link>
              </b-list-group-item>
            </b-list-group>
            <b-card
              v-if="activeLesson"
              border-variant="white"
            >
              <b-card-header header-bg-variant="dark" header-text-variant="white">
                {{ lezioneSelect.titolo }}
              </b-card-header>
              <b-card-body>
                <p>Annotazioni:</p> <span class="font-italic">{{ lezioneSelect.annotazione }}</span>
                <b-row class="mt-3" v-for="(file, index) in lezioneSelect.files" :key="index">
                  <b-col v-if="file.fileType === 'video'">
                    <b-card
                      class="mb-2">
                      <div class="d-flex justify-content-between align-items-center">
                        <i class="fas fa-file-video"></i>
                        {{ file.fileExt }}
                      </div>
                      <video-player
                        :options="{
                        autoplay: false,
                        controls: true,
                        fluid: true,
                        bigPlayButton: true,
                        sources: [{src: file.file}, ],
                      }"
                      />
                    </b-card>
                  </b-col>
                  <b-col v-if="file.fileType === 'audio'">
                    <b-card
                      class="mb-2">
                      <div class="d-flex justify-content-between align-items-center">
                        <i class="fas fa-file-audio"></i>
                        {{ file.fileExt }}
                      </div>
                      <video-player
                        id="audio"
                        :options="{
                          autoplay: false,
                          controls: true,
                          fluid: true,
                          bigPlayButton: true,
                          sources: { src: file.fileUrl },
                         }"
                      />
                    </b-card>
                  </b-col>
                  <b-col v-if="file.fileType === 'doc'">
                    <b-card
                      class="mb-2">
                      <div class="d-flex justify-content-between align-items-center">
                        <i class="fas fa-file"></i>{{ file.nome_file }}
                        <a v-bind:href="file.fileUrl" target="_blank">
                          <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
                        </a></div>
                    </b-card>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-modal
      id="first_login"
      centered
      no-close-on-backdrop
      hide-footer
      header-bg-variant="dark"
      header-text-variant="white"
      title="CAMBIO PASSWORD"
      no-close-on-esc
      hide-header-close
    >
      <b-form>
        <b-form-group label="Vecchia Password">
          <b-form-input type="password" v-model="old_password"></b-form-input>
          {{ old_password }}
        </b-form-group>
        <b-form-group label="Nuova Password">
          <b-form-input type="password" v-model="new_password"></b-form-input>
          {{ new_password }}
        </b-form-group>
        <b-form-group label="Ripeti Password">
          <b-form-input type="password" v-model="new_password2"></b-form-input>
          {{ new_password2 }}
          <span v-show="showError">Le password non coincidono</span>
        </b-form-group>
      </b-form>
      <b-button v-show="allOk" class="float-right" variant="dark" @click="cambioPassword">Cambia Password</b-button>
    </b-modal>
  </div>
</template>

<script>
import LMSService from "@/services/lms.services";
import AuthService from "@/services/auth.service";
import VideoPlayer from "@/components/VideoPlayer.vue";
import "video.js/dist/video-js.css";
// window.addEventListener("contextmenu", e => e.preventDefault()); // disabilita tasto destro

export default {
    name: "App",
    components: {
        VideoPlayer,
    },

    data() {
        return {
            fields: [
                { key:'nome', label:'Corso' },
                { key:'numero_lezioni', label:'N°Lez.' },
                { key:'anno_di_corso', label:'Anno' },
                { key:'anno_accademico_esteso', label:'AAccad.' },
            ],
            corsi: [],
            selected:{},
            totalRows: 1,
            currentPage: 1,
            perPage: 15,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            // Password
            old_password: '',
            new_password: '',
            new_password2: '',
            showError: false,
            allOk: false,
            searchPerNome:'',
            signedFiles: [],
            userCorsi: [],
            lezioniStudente: [],
            lezioniCount: null,
            activeLesson: false,
            activeCourse: false,
            lezioneSelect: [],
            fileAud: [],
            fileDoc: [],
            fileVid: [],
            fileExt: [],
            nomeCorso: [],
            comment: {
                name: "",
                content: "",
            },
            commenti: [],
            polling: null, // per timer
        };
    },
    mounted() {
        this.getCorsi()
        document.title = "Lezioni Online"; // Imposta nome corso come titolo della scheda del browser
        if (this.$store.state.auth.user.primo_login) {
            this.$bvModal.show('first_login')
        }
    },
    methods: {
      getCorsi() {
        const params = {
          nome: this.searchPerNome
        }
        LMSService.getCorsi(params).then(
          resp => {
            this.corsi = resp.data
          }
        ).catch()
      },
      selectedLezione(lezione) {
        console.log(lezione)
      },
      urlSigner() {
      },
      onRowSelected(items) {
        this.selected = items[0]
        this.lezioniStudente = []
        this.tempLezioneId = "" // forse non serve
        this.comments = []
        let params = {
          corso: items[0].id
        }
        LMSService.getLezioni(params).then(resp => {
          this.lezioniStudente = resp.data.results
          this.activeCourse = true
          this.activeLesson = false
        }).catch(error => {
          console.log(error)
        })
      },
        // getNomeCorso() {
        //     axios
        //         .get(API_URL + 'studenti/?user=' + this.$store.state.auth.user.user_id,  {headers: authHeader()})
        //         .then((resp) => {
        //                 // console.log('CORSI', resp.data.results[0].corsi)
        //                 const id_corsi = resp.data.results[0].corsi
        //                 Object.entries(id_corsi).forEach(item =>
        //                 {
        //                     axios
        //                         .get(API_URL + 'corsi/?id=' + (item[1]), {headers: authHeader()})
        //                         .then((resp) => {
        //                             // console.log(resp.data[0].nome)
        //                             this.nomeCorso = resp.data[0].nome
        //                         })
        //
        //
        //                 })
        //             }
        //         )
        //
        //
        // },

      setActiveLesson(id) {
        this.lezioneSelect = []
        LMSService.getLezione(id)
          .then(resp => {
            this.activeLesson = true
            this.lezioneSelect = resp.data
            this.signedFiles = []
            this.urlSigner()
          })
      },
        cambioPassword() {
            const payload = {
                old_password: this.old_password,
                new_password: this.new_password
            }
            AuthService.changePassword(payload).then(resp => {
                console.log(resp)
                this.$bvModal.hide('first_login')
                AuthService.logout().then(() => {
                    this.$store.dispatch('auth/logout').then(() => {
                        this.$router.push({name: 'Login'})
                    })
                })
            }).catch(error => {
                console.log(error)
                this.$bvToast.toast('Cambio Password Errore', {
                    title: 'Cambio Password:',
                    autoHideDelay: 5000,
                    variant:'danger'
                })
            })
            console.log(this.old_password, this.new_password, this.new_password2)
        }

    },

    watch: {
        new_password2(value) {
            const lunghezzaPassword = this.new_password.length
            if (value.length === lunghezzaPassword && this.new_password !== value) {
                this.showError = true
                this.allOk = false
            } if (value.length === lunghezzaPassword && this.new_password === value) {
                this.showError = false
                this.allOk = true
            }
        },
        searchPerNome(value) {
            console.log(value)
            this.getCorsi()
        }
    },

}
</script>
<style scoped>
.video-js .vjs-control-bar {
    display: flex;
}
div#audio .vjs-tech {
    background-image: url(https://lms-uploader.s3.eu-central-1.amazonaws.com/spettoaudio.png);
}
.button a {
    color: inherit;
}
.vjs-matrix-forest .vjs-big-play-button {
    display: none;
}
</style>
