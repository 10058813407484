<template>
    <b-card>
        <b-form>
            <b-row>
                <b-col>
                    <b-form-group label="Nome Ateneo">
                        <b-form-input type="text" v-model="form.nome"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Indirizzo Ateneo">
                        <b-form-input type="text" v-model="form.indirizzo"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Città Ateneo">
                        <b-form-select type="text" v-model="form.citta" :options="sedeInteresse" text-field="label" value-field="key"></b-form-select>
                    </b-form-group>
                    <b-form-group label="Cap Ateneo">
                        <b-form-input type="text" v-model="form.cap"></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="Telefono Ateneo">
                        <b-form-input type="text" v-model="form.telefono"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Fax Ateneo">
                        <b-form-input type="text" v-model="form.fax"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Email Ateneo">
                        <b-form-input type="text" v-model="form.email"></b-form-input>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-card>
</template>

<script>
import SettingService from '@/services/universita/settings.service'
export default {
    props: {
      addAteneo: {
          type: Boolean,
          required: true
      }
    },
    name: "addAtenei",
    data(){
        return {
            form: {
                "nome": "",
                "indirizzo": "",
                "citta": "",
                "cap": 0,
                "telefono": "",
                "fax": "",
                "email": ""
            },
            sedeInteresse: [
                {key:'F', label:'Firenze'},
                {key:'MN', label:'Mantova'},
                {key:'M', label:'Milano'},
            ],
            attAteneo: this.addAteneo
        }
    },
    methods: {
        aggiungiAteneo() {
            const payload = this.form
            SettingService.addAteneo(payload)
                .then(() => {
                    this.$emit('success')
                })
                .catch(() =>{
                    this.$bvModal.msgBoxOk('Ci sono degli errori o dei campi mancanti ', {
                        title: 'ERROR',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'success',
                        headerClass: 'p-2 border-bottom-0',
                        footerClass: 'p-2 border-top-0',
                        centered: true
                    })
                })
        }
    },
    watch: {
        addAteneo: {
            handler(value) {
                if(value === true) {
                    this.aggiungiAteneo()
                }
            }
        }
    },
}
</script>

<style scoped>

</style>
