<template>
    <b-card bg-variant="dark" class="w-20 float-right">
        <span style="color: #ffffff;">{{ this.form.active ? 'Attivo':'Disabilitato'}}</span>
        <b-form-checkbox name="active" v-model="form.active" switch size="lg"></b-form-checkbox>
    </b-card>
</template>
<script>
export default {
  name: 'StatoLead',
  props: {
    form: {}
  }
}
</script>
<style scoped>

</style>
